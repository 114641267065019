import { CrisisAlertOutlined } from "@mui/icons-material"
import { styled } from "@mui/material"
import type { CriticalThreatWithReferences } from "@phc/common"
import type React from "react"
import { Link, generatePath } from "react-router-dom"
import Siren from "../../assets/svg-components/Siren"
import { ROUTES } from "../../utils/constants"
import { extraColors } from "../../utils/theme"

export const CrisisAlertOutlinedStyled = styled(CrisisAlertOutlined)({
  color: extraColors.purple,
  height: 10,
  width: 10,
  marginRight: 2,
  marginTop: 1,
  marginLeft: -1,
})

const CrisisAlertStyled = styled(Siren)({
  paddingBottom: 4,
})

const LinkStyled = styled(Link)({
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  alignSelf: "center",
})

export const CriticalThreatTag: React.FC<{
  relatedCriticalThreat: CriticalThreatWithReferences | undefined
  className?: string
  size?: "small" | "large"
}> = ({ relatedCriticalThreat, className, size }) => {
  if (!relatedCriticalThreat) return null

  return (
    <LinkStyled
      className={className}
      to={generatePath(ROUTES.CRITICAL_THREAT, {
        slug: relatedCriticalThreat.slug?.current ?? "",
      })}
      onClick={event => event.stopPropagation()}
    >
      <CrisisAlertStyled
        height={size === "large" ? 28 : 18}
        width={size === "large" ? 28 : 18}
      />
    </LinkStyled>
  )
}
