import { MenuItem, TextField } from "@mui/material"
import type { Role } from "@phc-health/connect-query"
import type { ChangeEvent } from "react"
import { usePermissions } from "../../hooks/usePermissions"
import { getRolesByAvailablility } from "./UserFormShared"

/**
 * INTERFACES
 */

interface UserRoleDropdownProps {
  userRoleName?: string
  roles: Role[]
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  userEmail?: string
}

/**
 * COMPONENTS
 */

export const UserRoleDropdown: React.FC<UserRoleDropdownProps> = ({
  userRoleName,
  roles,
  onChange,
  userEmail,
}) => {
  const permissions = usePermissions()
  const isPHCAdmin = !!permissions.phcAdminUser.organizations.users.write
  const isAdmin =
    isPHCAdmin || !!permissions.standardAdminUser.organizations.users.write

  const rolesByAvailablility = getRolesByAvailablility(
    roles,
    isAdmin,
    isPHCAdmin,
    userEmail
  )

  return (
    <TextField
      value={userRoleName || ""}
      select
      label="Role"
      required
      onChange={onChange}
    >
      {rolesByAvailablility.map((entry, _) => {
        return (
          <MenuItem
            key={entry[0].name}
            value={entry[0].name}
            divider
            disableRipple
            disabled={!entry[1]}
          >
            {entry[0].displayName}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
