import { Box, styled, Tab, Tabs } from "@mui/material"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import { useState } from "react"
import { Navigate } from "react-router-dom"
import { usePermissions } from "../../hooks/usePermissions"
import { ROUTES } from "../../utils/constants"
import { MainContent } from "../MainContent"
import TabPanel from "../TabsPanel"
import { AssetServiceOrganizations } from "./AssetServiceManagement/AssetServiceOrganizations"
import Organizations from "./Organizations"

const StyledTabPanel = styled(TabPanel)(() => ({
  "& .MuiBox-root": {
    padding: "24px 0 0 0",
  },
  marginBottom: 70,
}))

const AssetServiceTabPanel = styled(StyledTabPanel)(() => ({
  "& .MuiBox-root": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}))

export const AdminSettings = () => {
  const permissions = usePermissions()
  const { showAccessManagement } = useFlags()
  const [activeTab, setActiveTab] = useState(1)

  if (!permissions.isLoading && !permissions.organizations.read) {
    return <Navigate replace to={ROUTES.BASE} />
  }

  function a11yProps(index: number) {
    return {
      id: `settings-tab-${index}`,
      "aria-controls": `settings-tabpanel-${index}`,
    }
  }

  if (!showAccessManagement) {
    return (
      <MainContent
        headerProps={{
          title: "Account Management",
        }}
      >
        <AssetServiceOrganizations />
      </MainContent>
    )
  }

  return (
    <MainContent
      headerProps={{
        title: "Account Management",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", width: "fit-content" }}
        >
          <Tabs
            value={activeTab}
            onChange={(event: React.SyntheticEvent, newTab: number) =>
              setActiveTab(newTab)
            }
            aria-label="Account Management Tabs"
          >
            <Tab label="Manage Organizations" {...a11yProps(0)} />
            <Tab label="Manage Trials" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <StyledTabPanel
          value={activeTab}
          index={0}
          ariaLabel={activeTab.toString()}
        >
          <Organizations />
        </StyledTabPanel>
        <AssetServiceTabPanel
          sx={{ display: "flex", placeContent: "center" }}
          value={activeTab}
          index={1}
          ariaLabel={activeTab.toString()}
        >
          <AssetServiceOrganizations />
        </AssetServiceTabPanel>
      </Box>
    </MainContent>
  )
}
