import { Button, checkboxClasses, styled } from "@mui/material"
import type { GridRowSelectionModel } from "@mui/x-data-grid-pro"
import { gridClasses } from "@mui/x-data-grid-pro"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useOrganizations } from "../hooks/useOrganizations"
import { usePermissions } from "../hooks/usePermissions"
import { ROUTES, stringifySearchParams } from "../utils/constants"
import { getOrganizationStatusNameFromStatus } from "../utils/helpers/identityHelpers"
import AddOrganizationModal from "./AccountManagement/AddOrganizationDialog"
import { BaseGrid } from "./BaseGrid/BaseGrid"

const ToolbarBtnContainer = styled("div")(() => ({
  display: "flex",
  gap: "25px",
}))

const OrganizationTable: React.FC = () => {
  const { isLoading, data } = useOrganizations({
    pageSize: 100,
  })
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const navigate = useNavigate()
  const [addOrgModalOpen, setAddOrgModalOpen] = useState(false)
  const permissions = usePermissions()

  return (
    <>
      <BaseGrid
        data-testid="organization-table"
        sx={{
          [`& .${gridClasses.columnHeaderTitleContainerContent} > .${checkboxClasses.root}`]:
            {
              display: "none",
            },
        }}
        loading={isLoading}
        rowCount={data?.totalSize ?? 0}
        placeholder="Search Organization"
        columns={[
          { field: "organization", headerName: "Organization", flex: 1 },
          // We still don't have this data
          { field: "location", headerName: "Location", flex: 1 },
          {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: rowData => {
              return getOrganizationStatusNameFromStatus(rowData.row.status)
            },
          },
        ]}
        rows={(data?.organizations ?? []).map(organization => ({
          id: organization.name,
          organization: organization.displayName,
          // We don't have this data
          location: "Organization Location",
          status: organization.status,
        }))}
        filter={false}
        onRowSelectionModelChange={setSelectedRows}
        onCellClick={rowData => {
          if (!permissions.organizations.read) return
          if (rowData.field === "__check__") {
            return
          }
          const organizationId = `organizations/${rowData.id.toString()}`
          const organization = rowData.value as string

          navigate({
            pathname: ROUTES.USERS,
            search: stringifySearchParams({
              organizationId,
              organizationName: organization,
            }),
          })
        }}
        headerActionButton={
          <ToolbarBtnContainer>
            {permissions.organizations.write && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAddOrgModalOpen(true)}
                  sx={{ width: "210px" }}
                >
                  Add Organization
                </Button>
              </>
            )}
          </ToolbarBtnContainer>
        }
        footerActionButton={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const organizationId = selectedRows[0]?.toString()
              const organization = data?.organizations.find(
                o => o.name === organizationId
              )
              if (!organization) {
                throw new Error(
                  "Organization not found when clicking edit selected"
                )
              }
              navigate({
                pathname: ROUTES.MANAGE_ORGANIZATION,
                search: stringifySearchParams({
                  organizationId,
                  organizationName: organization.displayName,
                }),
              })
            }}
            disabled={
              permissions.organizations.read ? selectedRows.length === 0 : true
            }
          >
            {permissions.organizations.write
              ? "Edit Selected"
              : "View Selected"}
          </Button>
        }
        disableMultipleRowSelection
      />
      <AddOrganizationModal
        orgsList={data?.organizations}
        isOpen={addOrgModalOpen}
        toggle={() => setAddOrgModalOpen(!addOrgModalOpen)}
      />
    </>
  )
}

export default OrganizationTable
