import type { IdToken } from "@auth0/auth0-react"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { organizationIdClaim, queries } from "../utils/constants"

async function getOrganizationId(
  getIdTokenClaims: () => Promise<IdToken | undefined>
) {
  const claims = await getIdTokenClaims()
  const newOrganizationId = claims?.[organizationIdClaim] as string
  return newOrganizationId
}

export function useOrganizationId() {
  const { getIdTokenClaims, isAuthenticated } = useAuth0()
  return useQuery({
    queryKey: [queries.organizationId, getIdTokenClaims],
    queryFn: () => getOrganizationId(getIdTokenClaims),
    enabled: isAuthenticated,
  })
}
