export type IconSize = "small" | "large"

export const getTrendIconSize = (iconSize?: IconSize) => {
  switch (iconSize) {
    case "small":
      return { width: 8, height: 8, viewBox: "1 0 12 12" }
    case "large":
    case undefined:
    default:
      return { width: 11, height: 18, viewBox: "1 0 12 12" }
  }
}
