import { defineField, defineType } from "sanity"

export const category = defineType({
  type: "document",
  name: "category",
  title: "Category",
  fields: [
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "description",
      title: "Description",
      type: "text",
    }),
  ],
})
