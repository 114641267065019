import { Divider, styled, Typography } from "@mui/material"

import type React from "react"

import type { PortableTextMarkComponent } from "@portabletext/react"
import type { TypedObject } from "@portabletext/types"
import type { PortableTextObject } from "sanity"
import { ExternalLink } from "./ExternalLinks"

/**
 * STYLES
 */

const DividerStyled = styled(Divider)({
  margin: "20px 0 30px 0",
  "@media print": {
    pageBreakBefore: "always",
  },
})

const ListItemStyled = styled("li")({
  fontSize: 13,
})

const OrderedListStyled = styled("ol")({
  marginTop: 5,
})

const Footnote = styled("sup")({
  lineHeight: 0,
})

/**
 * INTERFACES
 */

interface FootnoteRendererProps extends TypedObject {
  order?: number
  reference?: string
}

/**
 * COMPONENTS
 */

export const Footnotes: React.FC<{
  footnotesOrder: (PortableTextObject | undefined)[] | undefined
}> = ({ footnotesOrder }) => {
  if (!footnotesOrder?.length) return null

  return (
    <>
      <DividerStyled />
      <Typography variant="h6">References</Typography>
      <OrderedListStyled>
        {footnotesOrder.map(f => {
          const href = f?.reference as string
          return (
            <ListItemStyled key={f?._key}>
              <ExternalLink id={href} href={href}>
                {href}
              </ExternalLink>
            </ListItemStyled>
          )
        })}
      </OrderedListStyled>
    </>
  )
}

export const FootnoteRenderer: PortableTextMarkComponent<
  FootnoteRendererProps
> = props => {
  const { children, value } = props
  return (
    <span>
      {children}
      <Footnote>
        <a href={`#${props.value?.reference ?? ""}`}>{value?.order}</a>
      </Footnote>
    </span>
  )
}
