import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Typography, styled } from "@mui/material"
import type { Bucket } from "@phc-health/connect-query"
import {
  severityMapping,
  trendMapping,
} from "../../../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../../../utils/theme"
import { ConfidenceIndicator } from "../../../Shared/ConfidenceIndicator"
import { LightTooltip, SectionContainer, getDataDate } from "../Shared"
import { OverallRiskVisualizer } from "./OverallRiskVisualizer"

const SectionContainerStyled = styled(SectionContainer)({
  marginTop: 26,
  marginBottom: 16,
})

const OverallRiskDetails = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const InfoIconStyled = styled(InfoOutlinedIcon)({
  height: 16,
  marginLeft: -2,
  marginBottom: -2,
  fill: extraColors.purpleMiddle,
})

interface OverallRiskProps {
  resp: Bucket
}

export const OverallRisk: React.FC<OverallRiskProps> = ({ resp }) => {
  const { dateThrough, dateFrom, dateTo, trendType, severity, confidence } =
    resp

  const trendText = `${trendMapping(trendType)} and ${severityMapping(
    severity
  )}`

  return (
    <SectionContainerStyled>
      <OverallRiskDetails>
        <Typography variant="body1">
          Disease burden is{" "}
          <Typography variant="body1Bold">{trendText}</Typography>{" "}
          <LightTooltip
            title={
              "The respiratory illness severity tool describes the current level of disease burden and expected change over the next 2 weeks among people at this location. This forecast is based on clinic and hospital visits for infectious respiratory illnesses including COVID-19, influenza, respiratory syncytial virus (RSV), rhinovirus, adenovirus, and several others"
            }
            arrow
            placement="top-end"
          >
            <InfoIconStyled />
          </LightTooltip>
        </Typography>
        <Typography
          variant="small1"
          sx={{
            marginLeft: "12px",
            alignSelf: "center",
            color: extraColors.hint,
          }}
        >
          {`Data through: ${getDataDate(dateThrough)}`}
        </Typography>
      </OverallRiskDetails>
      <OverallRiskVisualizer severity={severity} />
      <OverallRiskDetails>
        <Typography variant="small1" sx={{ color: extraColors.hint }}>
          {`From: ${getDataDate(dateFrom)} - ${getDataDate(dateTo)}`}
        </Typography>
        <ConfidenceIndicator confidence={confidence} />
      </OverallRiskDetails>
    </SectionContainerStyled>
  )
}
