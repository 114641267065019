import { Checkbox, Typography, styled } from "@mui/material"
import { extraColors } from "../../../utils/theme"

export const CheckboxContainer = styled("div")({
  display: "flex",
  alignItems: "start",
})

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
})

interface SettingCheckboxProps {
  settingEnabled: boolean
  setSettingEnabled?: (newState: boolean) => void
  title: string | React.ReactNode
  indeterminate?: boolean
  value?: unknown
  disabled?: boolean
  disabledText?: string
  customSettingFn?: () => void
}

export const SettingCheckbox: React.FC<SettingCheckboxProps> = ({
  setSettingEnabled,
  settingEnabled,
  title,
  indeterminate,
  value,
  disabled,
  disabledText,
  customSettingFn,
}) => {
  return (
    <CheckboxContainer>
      <Checkbox
        disabled={disabled}
        checked={settingEnabled}
        onChange={() => {
          customSettingFn?.() ?? setSettingEnabled?.(!settingEnabled)
        }}
        indeterminate={indeterminate}
        value={value}
        sx={{
          padding: 0,
          marginRight: "12px",
          marginTop: "3px",
        }}
      />
      <TextContainer>
        <Typography>{title}</Typography>
        {disabled && disabledText && (
          <Typography variant="small1" sx={{ color: extraColors.medium }}>
            {disabledText}
          </Typography>
        )}
      </TextContainer>
    </CheckboxContainer>
  )
}
