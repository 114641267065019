import { Coronavirus, LocalHospitalOutlined } from "@mui/icons-material"
import { styled, Typography } from "@mui/material"
import { Disease_Indicator, type Bucket } from "@phc-health/connect-query"
import { diseaseIndicatorMapping } from "../../../../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../../../../utils/theme"
import { ConfidenceIndicator } from "../../../../Shared/ConfidenceIndicator"
import { SeverityVisualIndicator } from "../../SeverityVisualIndicator"
import { getDataDate } from "../../Shared"
import { TrendIndicator } from "../../TrendVisualIndicator"

const SeverityIndicatorStyled = styled(SeverityVisualIndicator)({
  maxHeight: 28,
  marginRight: 8,
})

const IndicatorListItem = styled("li")({
  fontSize: 10,
  lineHeight: "26px",
})

const IndicatorTextElements = styled("ul")({
  marginTop: 16,
  marginLeft: 12,
  paddingInlineStart: "unset",
  marginBlockEnd: "unset",
})

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
  },
}))

const ConfidenceIndicatorStyled = styled(ConfidenceIndicator)(({ theme }) => ({
  paddingTop: 6,
  alignSelf: "unset",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    paddingTop: 0,
  },
}))

const IndicatorTitle = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

const SignalContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: extraColors.subtle,
  padding: 12,
  borderRadius: 6,
  marginTop: 12,
})

const IndicatorContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  backgroundColor: extraColors.white,
  padding: 12,
  borderRadius: 6,
  marginTop: 8,
  alignItems: "center",
  justifyContent: "space-between",
})

interface DiseaseIndicatorProps {
  bucket: Bucket
}

const IndicatorRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})
export const DiseaseIndicator: React.FC<DiseaseIndicatorProps> = ({
  bucket,
}) => {
  return (
    <>
      <HeaderContainer>
        <IndicatorRow>
          <Typography
            variant="small1"
            sx={{ color: extraColors.medium, marginRight: "8px" }}
          >
            Current Assessment
          </Typography>
          <SeverityIndicatorStyled severity={bucket.severity} showTrend />
          <TrendIndicator trend={bucket.trendType} size={24} />
        </IndicatorRow>
        <ConfidenceIndicatorStyled confidence={bucket.confidence} />
      </HeaderContainer>

      <IndicatorTextElements>
        {bucket.factors.map(text => {
          return (
            <IndicatorListItem key={text}>
              <Typography variant="body2">{text}</Typography>
            </IndicatorListItem>
          )
        })}
      </IndicatorTextElements>
      <SignalContainer>
        <Typography variant="body2">Signal</Typography>
        <IndicatorContainer>
          <IndicatorTitle>
            {bucket.diseaseIndicator === Disease_Indicator.HOSPITALIZATIONS ? (
              <LocalHospitalOutlined
                sx={{ height: "18px", color: extraColors.medium }}
              />
            ) : (
              <Coronavirus sx={{ height: "18px", color: extraColors.medium }} />
            )}
            <Typography variant="body2Bold" sx={{ color: extraColors.medium }}>
              {diseaseIndicatorMapping(bucket.diseaseIndicator)}
            </Typography>
          </IndicatorTitle>
          <Typography
            variant="small1"
            sx={{
              color: extraColors.hint,
            }}
          >
            {`Data through: ${getDataDate(bucket.dateThrough)}`}
          </Typography>
        </IndicatorContainer>
      </SignalContainer>
    </>
  )
}
