import type { PortableTextMarkComponentProps } from "@portabletext/react"
import { useMemo } from "react"
import type { PortableTextObject } from "sanity"
import { FootnoteRenderer } from "../Footnotes"
import { components } from "../PostComponents"

export function useDynamicComponents(
  footnotesOrder: (PortableTextObject | undefined)[] | undefined,
  sanityDataset: string
) {
  return useMemo(() => {
    const footnoteComponent = (
      props: React.PropsWithChildren<
        PortableTextMarkComponentProps<{
          _type: "footnote"
          reference: string
        }>
      >
    ) => {
      if (!props.value) return null

      const order =
        (footnotesOrder?.findIndex(
          f => f?.reference === props.value?.reference
        ) ?? 0) + 1

      return (
        <FootnoteRenderer
          {...props}
          value={{
            ...props.value,
            order: order,
          }}
        />
      )
    }

    return {
      ...components(sanityDataset),
      marks: {
        ...components(sanityDataset).marks,
        footnote: footnoteComponent,
      },
    }
  }, [footnotesOrder, sanityDataset])
}
