import { Typography } from "@mui/material"
import type React from "react"
import { dayjsUTC } from "../../utils/helpers"
import { extraColors } from "../../utils/theme"

export const DateRenderCell: React.FC<{
  dateString: string | Date | undefined
}> = ({ dateString }) => {
  return (
    <Typography variant="small1" sx={{ color: extraColors.hint }}>
      {dayjsUTC(dateString).local().format("MM/DD/YYYY")}
    </Typography>
  )
}
