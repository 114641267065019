import { Box, styled, Typography } from "@mui/material"
import { AlertLevel } from "@phc/common"
import { useEffect, useState } from "react"
import usePersistentContext from "../../hooks/usePersistentContext"
import { extraColors } from "../../utils/theme"
import { AlertPill } from "../Shared/AlertPill"
import { Banner } from "./Banner"

const BannerStyled = styled(Banner)({
  ".MuiPaper-root": {
    backgroundColor: extraColors.white,
    border: `1px solid ${extraColors.disabled}`,
    borderRadius: 6,
  },
  marginBottom: 24,
})

const ALERT_DICTIONARY = {
  [AlertLevel.ADVISORY]: (
    <>
      For awareness only, <strong>no action</strong> recommended.
    </>
  ),
  [AlertLevel.WATCH]: (
    <>
      Tracking for awareness recommended to inform{" "}
      <strong>early preventive action</strong>.
    </>
  ),
  [AlertLevel.WARNING]: (
    <>
      Urgent attention or mitigation <strong>action recommended</strong>.
    </>
  ),
}

export const AlertDefinitionBanner = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [
    lastAlertDefinitionBannerDate,
    setLastAlertDefinitionBannerDate,
    isLoading,
  ] = usePersistentContext(["last-alert-definition-banner-date"])
  /**
    check last date that info banner was shown.
    if never shown before or if its been more
    than 30 days since the last time it was displayed,
    show the info banner.
  */
  useEffect(() => {
    const now = new Date().getTime()
    const thirtyDaysInMilliseconds = 2592000000
    if (isLoading) return

    if (
      !lastAlertDefinitionBannerDate ||
      now - parseInt(lastAlertDefinitionBannerDate) >= thirtyDaysInMilliseconds
    ) {
      setOpen(true)
    }
  }, [
    isLoading,
    lastAlertDefinitionBannerDate,
    setLastAlertDefinitionBannerDate,
  ])

  const handleClose = () => {
    const now = new Date().getTime()

    setOpen(!open)
    void setLastAlertDefinitionBannerDate(now.toString())
  }

  return (
    <BannerStyled open={open} type="info" dismissFunction={handleClose}>
      <Typography variant="body2Bold" color={extraColors.purpleDark}>
        Interpreting Alert Levels
      </Typography>
      <Box display="grid" gap="10px" pt={2} alignItems={"center"}>
        {Object.values(AlertLevel)
          .toReversed()
          .map(alert => (
            <Box
              key={alert}
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <AlertPill alertLevel={alert} />
              <Typography variant="small1">
                {ALERT_DICTIONARY[alert]}
              </Typography>
            </Box>
          ))}
      </Box>
    </BannerStyled>
  )
}
