import phcLogoFull from "../assets/phc_global_logo_gradient.png"
import phcLogoIcon from "../assets/phc_logo_icon.png"
import { STATIC_ASSET_URL } from "../utils/constants"
import { useOrganization } from "./useOrganization"
import { useOrganizationId } from "./useOrganizationId"

export const logoList: Record<
  string,
  { full: string; short: string; altText: string }
> = {
  phc: {
    full: phcLogoFull,
    short: phcLogoIcon,
    altText: "phc global logo",
  },
  parsons: {
    full: `${STATIC_ASSET_URL}org_l8Exf3cN2tyGKkfO-full.png`,
    short: `${STATIC_ASSET_URL}org_l8Exf3cN2tyGKkfO-short.png`,
    altText: "parsons logo",
  },
  // Add more orgs here
}

export const useAppLogo = () => {
  const orgId = useOrganizationId()
  const organization = useOrganization(orgId.data ?? "")
  const orgName = organization.data?.name

  return orgName ? logoList[orgName] : undefined
}
