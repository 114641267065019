import FeedIcon from "@mui/icons-material/Feed"
import { Button, Typography, styled } from "@mui/material"
import {
  GridActionsCellItem,
  gridClasses,
  type GridColDef,
} from "@mui/x-data-grid-pro"
import type { AlertWithReferences } from "@phc/common"
import { AlertLevel } from "@phc/common"
import type React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import { useCapServiceContext } from "../CriticalThreats/capServiceContext"
import { getCriticalThreatByContentId } from "../CriticalThreats/criticalThreatHelper"
import { CriticalThreatTag } from "../CriticalThreats/CriticalThreatTag"
import {
  InfoIconStyled,
  LightTooltip,
} from "../LocationDetails/LocationRisk/Shared"
import { GeotagsContainer } from "../Map/Cards/GeotagsContainer"
import { getConfidenceFromJustification } from "../Shared/AlertConfidence"
import { getAlertColor } from "../Shared/AlertPill"
import { ConfidenceIndicator } from "../Shared/ConfidenceIndicator"
import { DateRenderCell } from "./AlertsShared"

const ConfidenceIndicatorStyled = styled(ConfidenceIndicator)({
  alignContent: "center",
  alignSelf: "center",
  display: "inline-flex",
})

const TitleBox = styled("div")(() => ({
  alignSelf: "start",
  display: "grid",
  gap: 4,
}))

export const ALERT_TABLE_COLUMNS: GridColDef<AlertWithReferences>[] = [
  {
    field: "alertLevel",
    headerName: "Level",
    headerAlign: "left",
    align: "left",
    display: "flex",
    renderHeader: props => (
      <>
        <LightTooltip title={<AlertLevelTooltip />} arrow placement="top-end">
          <InfoIconStyled />
        </LightTooltip>
        <div className={gridClasses.columnHeaderTitle} style={{ padding: 0 }}>
          {props.colDef.headerName}
        </div>
      </>
    ),
    minWidth: 100,
    renderCell: ({ row }) => <AlertLevelCell alertLevel={row.alertLevel} />,
    sortComparator: (v1: string, v2: string) => {
      const levels = [
        AlertLevel.ADVISORY,
        AlertLevel.WATCH,
        AlertLevel.WARNING,
      ].map(String)
      return levels.indexOf(v1) - levels.indexOf(v2)
    },
  },
  {
    field: "date",
    headerName: "Date",
    headerAlign: "left",
    width: 110,
    type: "date",
    renderCell: props => {
      const value = props.value as string
      return <DateRenderCell dateString={value} />
    },
    valueGetter: (_, row) => {
      return new Date(row.publishedAt ?? row.updatedAt ?? "")
    },
  },
  {
    field: "overview",
    headerName: "Overview",
    minWidth: 250,
    flex: 1,
    renderCell: props => {
      return <TitleRenderCell row={props.row} />
    },
    valueGetter: (_, row) => {
      return (
        (row.title || "") +
        (row.justification || "") +
        (row.recommendation || "")
      )
    },
    disableExport: true,
  },
  { field: "title" },
  { field: "recommendation" },
  { field: "justification", headerName: "Context" },
  {
    field: "category",
    headerName: "Threat",
    minWidth: 200,
    display: "flex",
    renderCell: ({ row }) => {
      return (
        <Typography
          variant="body1Bold"
          color={extraColors.purple}
          sx={{ textDecoration: "none", textTransform: "uppercase" }}
        >
          {row.categories?.[0]?.title}
        </Typography>
      )
    },
    valueGetter: (_, row) => {
      return row.categories?.[0]?.title?.toUpperCase() ?? ""
    },
  },
  {
    field: "categories",
    headerName: "",
    minWidth: 50,
    display: "flex",
    align: "right",
    renderCell: ({ row }) => {
      return <CriticalThreatIndicator contentId={row._id} />
    },
  },
  { field: "link" },
  {
    field: "confidence",
    headerName: "confidence",
    minWidth: 100,
    display: "flex",
    headerAlign: "center",
    align: "center",

    renderCell: props => {
      return (
        <ConfidenceIndicatorStyled
          hideLink={true}
          confidenceInteger={
            getConfidenceFromJustification(props.row.justification)
              .parsedConfidence
          }
        />
      )
    },
    valueGetter: (_, row) => {
      return row.categories?.[0]?.title?.toUpperCase() ?? ""
    },
  },
  {
    field: "actions",
    type: "actions",
    width: 50,
    getActions: params => [
      <GridActionsCellItem
        key="view-source"
        label="View Source"
        icon={<FeedIcon />}
        showInMenu
        // TODO: make this a link
        // https://github.com/mui/mui-x/issues/9913
        // href={params.row.link}
        onClick={() => {
          trackEvent("CLICK_ALERT_SOURCE", {
            title: params.row.title ?? "",
            link: params.row.link ?? "",
          })
          window.open(params.row.link, "_blank")
        }}
      />,
    ],
  },
] satisfies GridColDef<AlertWithReferences>[]

const AlertLevelTooltip: React.FC = () => {
  return (
    <div>
      <Typography variant="small1" display="block">
        Table includes biorisk alerts PHC is tracking. Brief justifications are
        listed for each entry. Alert levels are as follows:
      </Typography>
      <Typography variant="small1" display="block">
        <Typography variant="small1Bold">Warning</Typography> - Urgent attention
        or mitigation action(s) recommended.
      </Typography>
      <Typography variant="small1" display="block">
        <Typography variant="small1Bold">Watch</Typography> - Tracking for
        awareness recommended to inform early preventive action(s).
      </Typography>
      <Typography variant="small1" display="block">
        <Typography variant="small1Bold">Advisory</Typography> - For awareness
        only, no action(s) recommended.
      </Typography>
    </div>
  )
}

export const AlertLevelCell = ({
  alertLevel,
}: {
  alertLevel: AlertLevel | undefined
}) => (
  <Typography
    sx={{
      alignSelf: "start",
      color: getAlertColor(alertLevel)?.text,
      textTransform: "uppercase",
      margin: "4px",
    }}
    variant="tiny"
  >
    {alertLevel}
  </Typography>
)

const TitleRenderCell: React.FC<{ row: AlertWithReferences }> = ({ row }) => {
  const title = row._id.startsWith("drafts.")
    ? `${row.title ?? "<untitled>"} (Draft)`
    : row.title
  const [showMore, setShowMore] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    if (contentRef.current) {
      // Get the full scrollable height of the content, including any content not currently visible due to overflow
      const contentHeight = contentRef.current.scrollHeight
      // Get the computed line height of the content in pixels, converting it to a number if necessary
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      )
      // Calculate the number of visible lines by dividing the total content height by the line height
      const visibleLines = contentHeight / lineHeight
      setIsOverflowing(visibleLines > 3)
    }
  }, [row.justification])

  const handleShowMore = () => {
    setShowMore(!showMore)
    trackEvent("CLICK_ALERT_SEE_MORE", {
      title: row.title ?? "",
      link: row.link ?? "",
    })
  }

  return (
    <TitleBox>
      <Typography variant="body2Bold">{title}</Typography>
      <GeotagsContainer geotags={row.geotags} />
      <Typography
        variant="body2"
        color={extraColors.medium}
        sx={{
          display: showMore ? "block" : "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
        ref={contentRef}
      >
        {row.recommendation && (
          <Typography variant={"body2"} sx={{ color: extraColors.medium }}>
            <Typography variant="small1Bold" sx={{ color: extraColors.medium }}>
              RECOMMENDATION -{" "}
            </Typography>
            {row.recommendation}
          </Typography>
        )}
        {row.justification && (
          <Typography
            variant={"body2"}
            sx={{
              marginTop: "10px",
              color: extraColors.medium,
            }}
          >
            <Typography variant="small1Bold" sx={{ color: extraColors.medium }}>
              SITUATION -{" "}
            </Typography>
            {
              getConfidenceFromJustification(row.justification)
                .justificationWithoutConfidence
            }
          </Typography>
        )}
      </Typography>
      {isOverflowing && (
        <Button
          component={Typography}
          onClick={handleShowMore}
          style={{
            display: "inline",
            padding: 0,
            margin: 0,
            fontSize: 14,
            color: extraColors.purpleMiddle,
            fontWeight: 400,
            height: "auto",
          }}
        >
          {showMore ? "see less" : "see more"}
        </Button>
      )}
    </TitleBox>
  )
}

const CriticalThreatIndicator: React.FC<{ contentId: string }> = ({
  contentId,
}) => {
  const { useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()

  const relatedCriticalThreat = useMemo(() => {
    return getCriticalThreatByContentId(contentId, data)
  }, [data, contentId])
  return <CriticalThreatTag relatedCriticalThreat={relatedCriticalThreat} />
}
