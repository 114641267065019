import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import {
  useGridApiRef,
  type GridColDef,
  type GridSlotProps,
  type GridSlots,
} from "@mui/x-data-grid-pro"
import type React from "react"
import { useEffect, useMemo } from "react"
import { Link, generatePath, useNavigate } from "react-router-dom"
import { BaseGrid } from "../components/BaseGrid/BaseGrid"
import { SelectFilter } from "../components/BaseGrid/SelectFilter"
import { useCapServiceContext } from "../components/CriticalThreats/capServiceContext"
import { MainContent } from "../components/MainContent"
import { PageContainer } from "../components/PageContainer"
import { GridRowCard } from "../components/Shared/GridRowCard"
import { Tag } from "../components/Shared/Tag"
import { ROUTES } from "../utils/constants"
import { convertStringToTitleCase, dayjsUTC } from "../utils/helpers"
import { extraColors } from "../utils/theme"
import type { CMSRowData } from "../utils/types"

/** Override:
 * https://mui.com/x/react-data-grid/components/#overriding-components
 */
const MobileRowRender = (props: GridSlotProps["row"] & { row: Row }) => {
  const row = props.row

  return (
    <Link
      style={{ textDecoration: "none" }}
      to={generatePath(ROUTES.GUIDANCE_POST, {
        slug: row.slug || "",
      })}
    >
      <GridRowCard {...props}>
        <Box display="grid" width="100%" gap="10px" padding={"16px"}>
          <Box display="flex" justifyContent="space-between">
            <Tag sx={{ fontSize: 13 }}>{row.category}</Tag>
            <Typography variant="body2" color={extraColors.hint}>
              {" "}
              {dayjsUTC(row.last_updated).local().format("MM/DD/YYYY")}
            </Typography>
          </Box>

          <Typography variant="body2">{row.title}</Typography>
        </Box>
      </GridRowCard>
    </Link>
  )
}
const columns = [
  {
    field: "last_updated" as const,
    headerName: "Last Updated",
    headerAlign: "left",
    type: "date",
    display: "flex",
    minWidth: 150,
    renderCell: ({ value }) => {
      const date = value as string
      return (
        <Typography variant="body2" sx={{ color: extraColors.hint }}>
          {dayjsUTC(date).local().format("MM/DD/YYYY")}
        </Typography>
      )
    },
  },
  {
    field: "title" as const,
    headerName: "Title",
    minWidth: 200,
    display: "flex",
    flex: 4,
    renderCell: ({ value }) => <Typography variant="body2">{value}</Typography>,
  },
  {
    field: "category" as const,
    headerName: "Category",
    minWidth: 150,
    display: "flex",
    flex: 1,
    renderCell: ({ value }) => <Tag sx={{ fontSize: 13 }}>{value}</Tag>,
  },
  { field: "slug" as const, filterable: false },
] satisfies GridColDef<Row>[]

interface Row {
  id: string
  title: string
  last_updated: Date
  category: string | undefined
  slug: string
}

const Guidance: React.FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { useGuidance } = useCapServiceContext()
  const { data, isLoading } = useGuidance()
  const gridApiRef = useGridApiRef()
  useEffect(() => {
    if (isMobile) {
      gridApiRef.current.setColumnIndex("category", 0)
    } else {
      gridApiRef.current.setColumnIndex("category", 2)
    }
  }, [gridApiRef, isMobile])

  const rows = useMemo(
    () =>
      data?.map(item => {
        const category = item.categories
          ? item.categories[0]?.title?.toUpperCase()
          : ""

        //if the _id starts with "drafts." it's a draft
        const isDraft = item._id.startsWith("drafts.")
        //add draft to the title if it's a draft
        const title = isDraft ? `${item.title ?? ""} (Draft)` : item.title

        return {
          id: item._id,
          title: title ?? "",
          last_updated: new Date(item.updatedAt ?? ""),
          category,
          slug: item.slug?.current ?? "",
        }
      }),
    [data]
  )

  // get categories, convert to title case and filter our empty values
  const categoryList = useMemo(
    () =>
      [
        ...new Set(
          rows?.map(row => convertStringToTitleCase(row.category ?? ""))
        ),
      ].filter(val => val !== ""),
    [rows]
  )

  const handleCellClick = (rowData: { row: CMSRowData; field: string }) => {
    navigate(
      generatePath(ROUTES.GUIDANCE_POST, {
        slug: rowData.row.slug || "",
      })
    )
  }

  return (
    <MainContent
      headerProps={{
        title: "Guidance",
      }}
      disableLeftGutter
    >
      <PageContainer>
        <BaseGrid
          apiRef={gridApiRef}
          search={true}
          filter={false}
          checkbox={false}
          columnVisibilityModel={
            {
              slug: false,
              title: !isMobile,
            } satisfies Partial<
              Record<(typeof columns)[number]["field"], boolean>
            >
          }
          loading={isLoading && !data}
          rows={rows ?? []}
          columns={columns}
          slots={
            isMobile
              ? {
                  row: MobileRowRender as GridSlots["row"],
                }
              : undefined
          }
          onCellClick={handleCellClick}
          autoHeight
          initSortModel={{
            field: "last_updated",
            sort: "desc",
          }}
          noTextOverlay="No PHC guidance found"
          subNav={
            <SelectFilter
              items={categoryList}
              ALL_ITEMS_TEXT="All guidance"
              filterField={
                "category" satisfies (typeof columns)[number]["field"]
              }
            />
          }
          striped
        />
      </PageContainer>
    </MainContent>
  )
}

export default Guidance
