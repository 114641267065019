import { Divider, Typography, styled } from "@mui/material"
import { ChartsTooltip } from "@mui/x-charts"
import type { AbsenteeismForecast } from "@phc-health/connect-query"
import { dayjsUTC } from "../../utils/helpers"
import { extraColors } from "../../utils/theme"
import {
  CURRENT_WEEK_TEXT,
  ForecastRangeKey,
  ForecastedAbsenteeismKey,
  HistoricKey,
  makeGraphDateString,
  present2Decimal,
  sortPercentile,
} from "./workforcePlanningShared"

const TooltipContent = styled("div")(({ theme }) => ({
  backgroundColor: extraColors.subtle,
  padding: theme.spacing(1),
  borderRadius: 2,
  boxShadow: theme.shadows[3],
  marginBottom: "6px",
  display: "grid",
  gap: 8,
  // add a triangle to the bottom center of the tooltip
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "0px",
    left: "calc(50% - 8px)",
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: `8px solid ${extraColors.subtle}`,
  },
}))

const TooltipRow = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  gap: 8,
  alignItems: "center",
}))

export const WorkforcePlanningTooltip: React.FC<{
  data: AbsenteeismForecast[] | undefined
}> = ({ data }) => {
  if (!data) return null
  return (
    <ChartsTooltip
      slots={{
        axisContent: props => {
          if (
            props.dataIndex == null ||
            typeof props.axisData.x?.value !== "number"
          )
            return null
          const weekStartDate = makeGraphDateString(props.axisData.x.value)
          const isCurrentWeek = weekStartDate === CURRENT_WEEK_TEXT
          const weekEndDate = isCurrentWeek
            ? makeGraphDateString(
                props.axis.data?.[props.axisData.x.index + 1] as number
              )
            : dayjsUTC(weekStartDate).add(7, "days").format("MMM D")
          return (
            <TooltipContent>
              <Typography
                variant="tiny"
                textTransform={"uppercase"}
                justifySelf={"center"}
              >
                {weekStartDate} - {weekEndDate}
              </Typography>
              <TooltipRow>
                <ForecastedAbsenteeismKey />
                <Typography variant="small1">Forecasted Absenteeism</Typography>
                <Typography variant="small1Bold">
                  {present2Decimal(data[props.dataIndex]?.overallAbsenteeism)}%
                </Typography>
              </TooltipRow>
              <TooltipRow>
                <ForecastRangeKey />
                <Typography variant="small1">
                  Forecasted Range (confidence)
                </Typography>
                <Typography variant="small1Bold">
                  {present2Decimal(
                    sortPercentile(data[props.dataIndex]?.percentiles)[0]
                  )}
                  % -{" "}
                  {present2Decimal(
                    sortPercentile(data[props.dataIndex]?.percentiles)[1]
                  )}
                  %
                </Typography>
              </TooltipRow>
              <Divider />
              <TooltipRow>
                <HistoricKey />
                <Typography variant="small1">Historic Absenteeism</Typography>
                <Typography variant="small1">
                  {present2Decimal(data[props.dataIndex]?.industryBaseline)}%
                </Typography>
              </TooltipRow>
            </TooltipContent>
          )
        },
      }}
      slotProps={{
        popper: {
          placement: "top",
        },
      }}
    />
  )
}
