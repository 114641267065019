import { useAuth0 } from "@auth0/auth0-react"
import { httpBatchLink } from "@trpc/react-query"
import { useMemo } from "react"
import superjson from "superjson"
import { cap } from "../utils/cap"
import { CAP_URL } from "../utils/constants"

export function useCapClient() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const client = useMemo(
    () =>
      cap.createClient({
        links: [
          httpBatchLink({
            transformer: superjson,
            maxURLLength: 2083,
            url: `${CAP_URL}/trpc`,
            async headers() {
              return isAuthenticated
                ? {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                  }
                : {}
            },
          }),
        ],
      }),
    [getAccessTokenSilently, isAuthenticated]
  )
  return client
}
