import { styled } from "@mui/material"
import type React from "react"
import { MapContextProvider } from "../../contexts/MapContext"
import { MainContent } from "../MainContent"
import { MapType, ReactMapbox } from "./Mapbox/ReactMapbox"
import { useMigrateAssetPlaceTypes } from "./migrations/placetypeMigration"

const Main = styled("main")(({ theme }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr auto",
  alignContent: "start",
  height: "100%",
  width: "100%",
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr auto",
    gridTemplateRows: "1fr",
  },
}))

export const Map: React.FC = () => {
  useMigrateAssetPlaceTypes()

  return (
    <MapContextProvider>
      <MainContent
        headerProps={{
          title: "Map",
        }}
      >
        <Main>
          <ReactMapbox type={MapType.MAP} />
        </Main>
      </MainContent>
    </MapContextProvider>
  )
}
