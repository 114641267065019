import axios from "axios"
import { getSource, mapboxConfig, type MapboxConfig } from "../mapboxConfig"

interface Tileset {
  type: string
  id: string
  name: string
  center: [number, number, number]
  created: string
  modified: string
  visibility: string
  description: string
  filesize: number
  status: string
  tileset_precisions: Record<string, number>
  created_by_client: string
}

/** Thanks copilot for replace parse-link-header
 *
 * Parses a link header from mapbox paginated responses
 *
 * https://docs.mapbox.com/api/overview/#pagination
 *
 * example `Link: <https://api.mapbox.com/uploads/v1/1454024795582?start=cijywvxlm004rtikohhqf99jv&limit=100>; rel="next"`
 */
const parseLinkHeader = (linkHeader: string) => {
  const linkHeaderParsed: Record<string, { url: string }> = {}
  linkHeader.split(",").forEach(link => {
    const [url, rel] = link.split(";")
    const urlMatch = url?.match(/<(.*)>/)
    const relMatch = rel?.match(/rel="(.*)"/)
    if (urlMatch?.[1] && relMatch?.[1]) {
      linkHeaderParsed[relMatch[1]] = {
        url: urlMatch[1],
      }
    }
  })
  return linkHeaderParsed
}

const getTilesets = async ({
  url,
  accessToken,
}: {
  url: string
  accessToken: string
}): Promise<Tileset[]> => {
  const response = await axios.get<Tileset[]>(url, {
    params: {
      access_token: accessToken,
      limit: 500,
    },
  })

  if (typeof response.headers.link !== "string") {
    return response.data
  }

  const linkHeader = response.headers.link
  const linkHeaderParsed = parseLinkHeader(linkHeader)

  if (linkHeaderParsed.next) {
    const nextUrl = linkHeaderParsed.next.url
    const nextTilesets = await getTilesets({ url: nextUrl, accessToken })
    return response.data.concat(nextTilesets)
  } else {
    return response.data
  }
}

interface Recipe {
  recipe: {
    layers: Record<
      string,
      {
        source: string
        minzoom: number
        maxzoom: number
      }
    >
    version?: number
  }
  id: string
}

const getContext = (
  id: string
): MapboxConfig["sourceLayer"][number]["context"] => {
  if (id.length === 2) {
    return "country"
  }
  if (id.includes("subnational")) {
    return "subnational"
  }
  if (id.includes("subdivision") || id.includes("ltla")) {
    return "subdivision"
  }
  throw new Error(`unhandled case - id: ${id}`)
}

const getSaneZoomLevels = (
  layers: string[],
  context: MapboxConfig["sourceLayer"][number]["context"]
) => {
  const numLayers = layers.length
  // if there is only one layer, return 0-22
  if (numLayers === 1) {
    return {
      minzoom: 0,
      maxzoom: 22,
    }
  }
  // if there are 2 or more layers, and current layer is a country, return 0-5
  if (numLayers >= 2 && context === "country") {
    return {
      minzoom: 0,
      maxzoom: 5,
    }
  }
  // if there are just 2 layers, and current layer is not a country
  if (numLayers === 2 && context !== "country") {
    return {
      minzoom: 5,
      maxzoom: 22,
    }
  }
  // if there are 3 layers, and current layer is subnational, return 5-7
  if (numLayers >= 3 && context === "subnational") {
    return {
      minzoom: 5,
      maxzoom: 7,
    }
  }
  // if there are 3 layers, and current layer is subdivision, return 7-22
  if (numLayers >= 3 && context === "subdivision") {
    return {
      minzoom: 7,
      maxzoom: 22,
    }
  }
  throw new Error(
    `unhandled case - numLayers: ${numLayers.toLocaleString()} context: ${context}`
  )
}

const LAYER_IGNORE_INCLUDE = [
  "_states",
  "-states",
  "_provinces",
  "_regions",
  "counties",
  "uk",
  "uk-",
  "jp_prefectures",
]
const LAYER_IGNORE_EXACT = ["uk", "gb-nhsregion-e"]

export const getMapboxSourceLayers = async ({
  username,
  accessToken,
}: {
  username: string
  accessToken: string
}) => {
  // print performance
  console.time("getTilesets")
  const tilesets = await getTilesets({
    url: `https://api.mapbox.com/tilesets/v1/${username}`,
    accessToken,
  })
  console.timeEnd("getTilesets")
  const recipeRequests = tilesets.map(tileset => {
    const recipeUrl = `https://api.mapbox.com/tilesets/v1/${tileset.id}/recipe`
    const recipeResponse = axios.get<Recipe>(recipeUrl, {
      params: {
        access_token: accessToken,
      },
    })
    return recipeResponse
  })

  console.time("getRecipes")
  const recipesResponses = await Promise.all(recipeRequests)
  console.timeEnd("getRecipes")

  const recipes = recipesResponses
    .map(recipe => recipe.data)
    .sort((a, b) => a.id.localeCompare(b.id))

  const newMapboxConfigLayers: MapboxConfig["sourceLayer"] = recipes.flatMap(
    recipe => {
      const countryCode = recipe.id.split(".")[1]
      if (!countryCode) {
        console.error("no country code found in id", recipe.id)
        return []
      }
      return Object.entries(recipe.recipe.layers)
        .flatMap(([key]) => {
          if (
            LAYER_IGNORE_INCLUDE.some(ignore => key.includes(ignore)) ||
            LAYER_IGNORE_EXACT.some(ignore => key === ignore)
          ) {
            console.log("ignoring:", key)
            return []
          }
          return key
        })
        .map((key, _, layers) => {
          let defaultZoomLevels
          const existingZoomLevels = mapboxConfig.sourceLayer.find(
            layer => layer.id === key
          )?.defaultZoomLevels
          if (
            !existingZoomLevels ||
            layers.length !== mapboxConfig.allBySource.get(countryCode)?.length
          ) {
            defaultZoomLevels = getSaneZoomLevels(layers, getContext(key))
            console.log(`new zoom levels: ${key}`, defaultZoomLevels)
          } else {
            defaultZoomLevels = existingZoomLevels
          }
          return {
            id: key,
            sourceInfo: getSource(username, countryCode),
            context: getContext(key),
            defaultZoomLevels,
          }
        })
    }
  )
  return newMapboxConfigLayers
}
