import { AssetType } from "@phc-health/connect-query"
import * as Papa from "papaparse"
import { z } from "zod"

export const assetSchema = z.object({
  Name: z.string().describe("Name"),
  Type: z
    .string()
    .describe("Type")
    .transform((t, ctx) => {
      const type = AssetType[t as keyof typeof AssetType]
      if (!type) {
        throw new Error(
          `Invalid asset type: ${t}, path: ${JSON.stringify(ctx.path)}`
        )
      }
      return type
    }),
  Latitude: z.string().describe("Latitude"),
  Longitude: z.string().describe("Longitude"),
  "Threat Radius": z
    .string()
    .describe("Threat Radius")
    .transform(s => parseInt(s)),
  Groups: z
    .string()
    .transform(g => g.split(",").map(s => s.trim()))
    .describe("Groups"),
})

export type AssetCSVRow = z.infer<typeof assetSchema>

export const assetSchemaArray = z.array(assetSchema)

export const parseBulkCSV = async (file: File) => {
  const text = await file.text()
  const json = Papa.parse(text, {
    header: true,
    skipEmptyLines: true,
  })
  const result = assetSchemaArray.safeParse(json.data)
  // todo: make more robust by continuing despite errors
  if (!result.success) {
    const errorMgs = result.error.errors.map(e => {
      const badIdx = e.path[0]
      const badField = e.path[1] as keyof AssetCSVRow
      if (typeof badIdx !== "number" || typeof badField !== "string") {
        throw new Error(`Bad path - idx: ${badIdx ?? ""}, field: ${badField}`)
      }
      const badRow = json.data[badIdx] as AssetCSVRow
      console.log("Bad Row", badRow)
      console.warn(e)
      return `Error on row ${
        // add 2 to account for header and 0-index
        badIdx + 2
      }, column "${badField}", received "${JSON.stringify(
        badRow[badField]
      )}": ${e.message}`
    })
    throw new Error(errorMgs.join("\n"))
  }
  console.log("parseBulkCSV", result)
  return result.data
}
