import type { SVGProps } from "react"
import { extraColors } from "../../utils/theme"

const SvgSiren = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill={props.fill || extraColors.navy}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.17578 0.515625L4.86328 1.64062L5.56641 2.13281L4.61719 3.53906L3.91406 3.04688L2.22656 1.92188L1.52344 1.46484L2.47266 0.0585938L3.17578 0.515625ZM21.7383 1.92188L20.0508 3.04688L19.3477 3.53906L18.3984 2.13281L19.1016 1.64062L20.7891 0.515625L21.4922 0.0585938L22.4414 1.46484L21.7383 1.92188ZM1.59375 6H3.84375H4.6875V7.6875H3.84375H1.59375H0.75V6H1.59375ZM20.1562 6H22.4062H23.25V7.6875H22.4062H20.1562H19.3125V6H20.1562ZM17.9766 9.9375H16.2539L15.5508 4.3125H8.41406L7.71094 9.9375H6.02344L6.9375 2.625H17.0625L17.9766 9.9375ZM9.29297 9.9375L9.75 6.49219L9.78516 6H10.9453L10.8398 6.63281L10.4531 9.9375H9.29297ZM18.1875 12.75H5.8125V14.4375H18.1875V12.75ZM5.8125 11.0625H18.1875H19.875V12.75V14.4375V16.125H18.1875H5.8125H4.125V14.4375V12.75V11.0625H5.8125Z" />
  </svg>
)

export default SvgSiren
