import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogTitle, IconButton, Stack } from "@mui/material"
import type { Role } from "@phc-health/connect-query"
import type { ReactNode } from "react"
import Button from "../Shared/Button"

/**
 * INTERFACES
 */

interface UserManagementDialogProps {
  title: string
  handleSave: () => void
  canSave: boolean
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  roles?: Role[]
}

/**
 * COMPONENTS
 */

export const UserManagementDialog: React.FC<UserManagementDialogProps> = ({
  isOpen,
  onClose,
  title,
  handleSave,
  canSave,
  children,
}) => {
  return (
    <Dialog open={isOpen} title={title}>
      <DialogTitle>
        <span>{title}</span>
        <IconButton
          aria-label="close"
          size="small"
          onClick={onClose}
          sx={{ padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
      <Stack spacing={2} direction="row-reverse">
        <Button variant="contained" onClick={handleSave} disabled={!canSave}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  )
}
