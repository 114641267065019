import NotificationsIcon from "@mui/icons-material/Notifications"
import { Typography } from "@mui/material"
import { ROUTES } from "../../../../utils/constants.js"
import { ActionMenuItem, ActionMenuLink, IconStyles } from "./ActionsShared.js"

export const NotificationPreferencesItem: React.FC<{
  handleClose: () => void
}> = ({ handleClose }) => {
  return (
    <ActionMenuItem onClick={handleClose}>
      <ActionMenuLink to={ROUTES.PREFERENCES}>
        <NotificationsIcon sx={{ ...IconStyles }} />
        <Typography>Notification Preferences</Typography>
      </ActionMenuLink>
    </ActionMenuItem>
  )
}
