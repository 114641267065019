import CloseIcon from "@mui/icons-material/Close"
import {
  IconButton,
  Drawer as MuiDrawer,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { isAlertLevel } from "@phc/common"
import type React from "react"
import { useMapContext } from "../../../../contexts/MapContext"
import { dayjsUTC, sortAlertLevel } from "../../../../utils/helpers"
import { extraColors } from "../../../../utils/theme"
import { useCapServiceContext } from "../../../CriticalThreats/capServiceContext"
import { AlertCard } from "../../Cards/AlertCard"
import { AnalysisCard } from "../../Cards/AnalysisCard"
import { getMapBreakpoint } from "../../Shared"
import { MobileDrawerHeader } from "../DrawerHeader"
import { DrawerContainer } from "../Locations/Locations"

const DRAWER_HORIZONTAL_PADDING = 85

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  overflow: "auto",
  padding: 0,
  scrollbarColor: "transparent transparent",
  [getMapBreakpoint(theme)]: {
    gap: "12px",
  },
  "*::-webkit-scrollbar": {
    display: "none",
    width: "none",
  },
  "*::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "*::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
  "& .MuiDrawer-paper": {
    position: "absolute",
    border: "none",
    backgroundColor: "transparent",
    padding: `16px ${DRAWER_HORIZONTAL_PADDING}px`,
    minWidth: "max-content",
    [getMapBreakpoint(theme)]: {
      position: "relative",
      gridAutoFlow: "row",
      overflowX: "auto",
      maxHeight: "fit-content",
      padding: 0,
    },
  },
})) as typeof MuiDrawer

const AlertDrawerCard = styled(AlertCard)(({ theme }) => ({
  boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
  borderRadius: 8,
  height: "auto",
  [getMapBreakpoint(theme)]: {
    width: 324,
    boxShadow: "none",
    margin: "8px 0px",
  },
}))

const AlertDrawerAnalysisCard = styled(AnalysisCard)(({ theme }) => ({
  boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
  borderRadius: 8,
  [getMapBreakpoint(theme)]: {
    width: 324,
    boxShadow: "none",
    margin: "8px 0px",
  },
}))

const StyledIconButton = styled(IconButton)(() => ({
  position: "absolute",
  pointerEvents: "all",
  zIndex: 1301,
  right: `${DRAWER_HORIZONTAL_PADDING - 10}px`,
  top: "4px",
  cursor: "pointer",
  border: `1px solid ${extraColors.white}`,
  padding: 4,
  backgroundColor: extraColors.subtle,
  boxShadow: `0px 3px 7px 1px ${extraColors.shadow}`,
  "&:hover": {
    backgroundColor: extraColors.light,
  },
}))

const CardGroup = styled("div")(({ theme }) => ({
  pointerEvents: "all",
  position: "relative",
  display: "grid",
  gap: "12px",
  maxHeight: "fit-content",
  [getMapBreakpoint(theme)]: {
    display: "flex",
    overflowX: "auto",
    width: "auto",
  },
}))

export const AlertDrawer: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(getMapBreakpoint(theme))
  const {
    openAlertDrawer,
    setOpenAlertDrawer,
    relatedAlerts,
    relatedAnalyses,
    clusterAlertsIds,
    alertFilters,
  } = useMapContext()

  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()

  const alertsToDisplay = clusterAlertsIds.length
    ? alerts?.filter(alert => clusterAlertsIds.includes(alert._id))
    : []
  const allCards = [relatedAlerts, relatedAnalyses, alertsToDisplay]
    .flatMap(a => (a ? [...a] : []))
    // filter unique
    .filter((a, i, arr) => arr.findIndex(b => b._id === a._id) === i)
    // filter by active filters
    .filter(a =>
      a._type === "alert" && isAlertLevel(a.alertLevel)
        ? alertFilters.includes(a.alertLevel)
        : true
    )
    .sort((a, b) => {
      const aDate = dayjsUTC(a.publishedAt ?? a.updatedAt)
      const bDate = dayjsUTC(b.publishedAt ?? b.updatedAt)

      if (aDate.isSame(bDate)) {
        // if same date, sort by alert level
        if (
          a._type === "alert" &&
          b._type === "alert" &&
          a.alertLevel &&
          b.alertLevel
        ) {
          return sortAlertLevel(a.alertLevel, b.alertLevel)
        }
      }
      return bDate.diff(aDate)
    })

  if (!allCards.length) return null

  return (
    <DrawerContainer
      sx={{ borderBottom: `1px solid ${extraColors.disabled}`, padding: "0px" }}
    >
      {isMobile && (
        <MobileDrawerHeader
          toggleDrawer={() => setOpenAlertDrawer(!openAlertDrawer)}
          open={!!openAlertDrawer}
          title="Related Intelligence"
          hideManage
        />
      )}
      {!isMobile && openAlertDrawer && (
        <StyledIconButton onClick={() => setOpenAlertDrawer(false)}>
          <CloseIcon sx={{ fontSize: 18, color: extraColors.navy }} />
        </StyledIconButton>
      )}
      <Drawer
        open={openAlertDrawer}
        variant="persistent"
        anchor={isMobile ? "bottom" : "right"}
        transitionDuration={0}
      >
        <CardGroup>
          {openAlertDrawer &&
            allCards.map(card =>
              card._type === "alert" ? (
                <AlertDrawerCard
                  key={card._id}
                  geotags={card.geotags}
                  alertLevel={
                    isAlertLevel(card.alertLevel) ? card.alertLevel : undefined
                  }
                  title={card.title ?? ""}
                  justification={card.justification || ""}
                  recommendation={card.recommendation}
                  date={card.publishedAt ?? card.updatedAt ?? ""}
                  sourceURL={card.link}
                  id={card._id}
                  threat={card.categories?.[0]?.title || ""}
                />
              ) : (
                <AlertDrawerAnalysisCard
                  key={card._id}
                  title={card.title ?? ""}
                  geotags={card.geotags}
                  date={card.publishedAt ?? card.updatedAt ?? ""}
                  id={card._id}
                  sourceURL={card.slug?.current}
                  threat={card.categories?.[0]?.title || ""}
                  content={card.excerpt || ""}
                  slug={card.slug?.current}
                />
              )
            )}
        </CardGroup>
      </Drawer>
    </DrawerContainer>
  )
}
