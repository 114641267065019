import type React from "react"
import OrganizationTable from "../OrganizationTable"
import { PageContainer } from "../PageContainer"

const Organizations: React.FC = () => {
  return (
    <PageContainer>
      <OrganizationTable />
    </PageContainer>
  )
}

export default Organizations
