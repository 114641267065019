import { Typography, styled } from "@mui/material"
import { PiWarningDiamondFill } from "react-icons/pi"
import { extraColors } from "../../utils/theme"

const PillContainer = styled("div")({
  borderRadius: 60,
  borderWidth: 1,
  borderStyle: "solid",
  padding: "1px 6px",
  minWidth: 90,
  width: "fit-content",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 3,
  marginRight: 8,
})

export const WorkforcePlanningPill: React.FC<{
  text: string
  isHigh?: boolean
}> = ({ text, isHigh = true }) => {
  const textColor = isHigh ? extraColors.status.redDark : extraColors.dark
  const backgroundColor = isHigh
    ? extraColors.status.redSubtle
    : extraColors.subtle
  const borderColor = isHigh
    ? extraColors.status.redMiddle
    : extraColors.disabled
  return (
    <PillContainer
      style={{
        backgroundColor,
        borderColor,
      }}
    >
      {isHigh && (
        <PiWarningDiamondFill
          style={{ color: extraColors.status.redMiddle, fontSize: 12 }}
        />
      )}
      <Typography variant="small1Bold" color={textColor}>
        {text}
      </Typography>
    </PillContainer>
  )
}
