import { useQuery } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useOrganizations({
  pageToken,
  pageSize,
  skip,
}: {
  pageToken?: string
  pageSize?: number
  skip?: number
}) {
  const transport = useTransport(ACCESS_API)
  return useQuery(
    AccessManagementServiceQuery.listOrganizations,
    {
      pageToken,
      pageSize,
      skip,
    },
    {
      transport,
    }
  )
}
