import { Box, Typography } from "@mui/material"
import type { Risk_Trend } from "@phc-health/connect-query"
import type React from "react"
import type { ReactNode } from "react"
import { extraColors } from "../../utils/theme"
import { TrendSVG } from "../LocationDetails/LocationRisk/TrendVisualIndicator"

export interface PillColors {
  background: string
  border: string
  text: string
}

const pillVariants = {
  red: {
    background: extraColors.status.redLight,
    border: extraColors.status.redMiddle,
    text: extraColors.status.redDark,
  },
  orange: {
    background: extraColors.status.orangeLight,
    border: extraColors.status.orangeMiddle,
    text: extraColors.status.orangeDark,
  },
  yellow: {
    background: extraColors.status.yellowLight,
    border: extraColors.status.yellowMiddle,
    text: extraColors.status.yellowDark,
  },
  green: {
    background: extraColors.status.greenLight,
    border: extraColors.status.green,
    text: extraColors.status.greenDark,
  },
  teal: {
    background: extraColors.status.tealLight,
    border: extraColors.status.teal,
    text: extraColors.status.tealDark,
  },
  gray: {
    background: extraColors.light,
    border: extraColors.disabled,
    text: extraColors.dark,
  },
  white: {
    background: extraColors.white,
    border: extraColors.disabled,
    text: extraColors.dark,
  },
} satisfies Record<string, PillColors>

export type PillProps = {
  value: string | ReactNode
  trend?: Risk_Trend
} & (
  | { colors: PillColors; variant?: never }
  | { variant: keyof typeof pillVariants | undefined; colors?: never }
)

export const Pill: React.FC<PillProps> = ({
  colors: pillColors,
  value,
  trend,
  variant,
}) => {
  const colors = pillColors || (variant && pillVariants[variant])
  if (!colors) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: trend ? "space-between" : "center",
        alignItems: "center",
        gap: "5px",
        textTransform: "uppercase",
        backgroundColor: colors.background,
        borderRadius: "12px",
        padding: "0px 6px",
        borderColor: colors.border,
        borderWidth: "1px",
        borderStyle: "solid",
        maxHeight: 40,
        minWidth: "60px",
        width: "fit-content",
      }}
    >
      <Typography variant="tiny" color={colors.text}>
        {value}
      </Typography>
      {trend && <TrendSVG trend={trend} color={colors.border} size="small" />}
    </Box>
  )
}
