import type MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import { Autocomplete, TextField, Typography, styled } from "@mui/material"
import { IndustryType } from "@phc-health/connect-query"
import { useCallback, useRef } from "react"
import { useSearchParams } from "../../hooks/useSearchParams"
import { getLocationId } from "../../utils/helpers"
import { extraColors } from "../../utils/theme"
import { PrintTemplate, type PrintHandle } from "../PrintTemplate"
import { SaveAsPDFButton } from "../SaveAsPDFButton"
import { Pill } from "../Shared/Pill"
import {
  AssetGeoLocationSearchInput,
  type GeoLocationSearchInputProps,
} from "../WatchedLocations/AssetManagement/GeoLocationSearchInput"
import { useListAssets } from "../WatchedLocations/hooks/useAssetService"
import { WorkforcePlanningContent } from "./WorkforcePlanningContent"
import { WorkforcePlanningContainer } from "./workforcePlanningShared"

const ToolContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  width: "100%",
  // for container queries in inputs
  containerType: "inline-size",
}))

const Inputs = styled("div")(() => ({
  display: "grid",
  flex: 1,
  width: "100%",
  gap: "16px",
  alignItems: "start",
  justifyItems: "center",
  maxWidth: 800,
  ["@container (min-width: 580px)"]: {
    gridTemplateColumns: "1fr auto 1fr",
    justifyContent: "start",
    gridAutoFlow: "column",
    "& > p": {
      marginTop: 10,
    },
  },
}))

const AssetHeaderContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 6,
  paddingBottom: 24,
  alignItems: "center",
  ["@container (max-width: 580px)"]: {
    flexDirection: "column",
    alignItems: "unset",
  },
  "@media print": {
    paddingLeft: 25,
  },
}))

interface Location {
  locationId: string
  name: string
  address: string
  groups?: string[]
}

export const WorkforcePlanningTool = () => {
  const printRef = useRef<PrintHandle>(null)
  const geocoderRef = useRef<MapboxGeocoder>(null)
  const { data: assets, isLoading } = useListAssets({
    includeGlobal: false,
    excludeNotifications: true,
  })

  const { id, assetId, title, subtitle, setSearchParams } = useSearchParams()
  const selectedAsset = assets.assets.find(a => a.assetId === assetId) ?? null

  const onChangeGeo = useCallback<
    NonNullable<GeoLocationSearchInputProps["onChangeGeo"]>
  >(
    (geo, locationIds) => {
      if (!geo) return

      const geoTitle = geo.result?.place_name.split(",")[0]
      const geoSubtitle = geo.result?.place_name.split(",").splice(1).join(", ")
      const locationId = locationIds?.[0]
      if (!locationId) {
        console.error("no locationId from geocoder", geo)
        return
      }
      setSearchParams({
        id: locationId,
        title: geoTitle,
        subtitle: geoSubtitle,
      })
    },
    [setSearchParams]
  )

  return (
    <ToolContainer>
      <WorkforcePlanningContainer
        sx={{
          // For geo query result box mixing with industry autocomplete
          zIndex: 1,
          position: "relative",
          display: "grid",
          gap: "24px",
        }}
      >
        <Typography>
          Select your workplace location and industry to generate a
          comprehensive absenteeism forecast driven by viral respiratory
          illnesses.
        </Typography>
        <Inputs>
          <Autocomplete
            fullWidth
            sx={{ minWidth: 260 }}
            options={assets.assets.sort((a, b) => {
              const aVal =
                (a.name || a.baseEvent?.mapboxLocation?.placeName) ?? ""
              const bVal =
                (b.name || b.baseEvent?.mapboxLocation?.placeName) ?? ""
              return aVal.localeCompare(bVal)
            })}
            getOptionLabel={option =>
              (option.name || option.baseEvent?.mapboxLocation?.placeName) ?? ""
            }
            renderInput={params => (
              <TextField {...params} label="Location Nickname" />
            )}
            value={selectedAsset}
            onChange={(_, newValue) => {
              geocoderRef.current?.clear()
              if (!newValue) {
                return
              }

              const assetTitle =
                newValue.name ||
                // fallback to the beginning of place name if no nickname
                newValue.baseEvent?.mapboxLocation?.placeName.split(",")[0] ||
                ""
              const assetSubtitle =
                (newValue.name
                  ? newValue.baseEvent?.mapboxLocation?.placeName
                  : // fallback to the rest of the place name if no nickname
                    newValue.baseEvent?.mapboxLocation?.placeName
                      .split(",")
                      .splice(1)
                      .join(", ")) ?? ""

              setSearchParams({
                id: getLocationId(newValue),
                assetId: newValue.assetId,
                title: assetTitle,
                subtitle: assetSubtitle,
              })
            }}
            isOptionEqualToValue={(option, value) => {
              return option.assetId === value.assetId
            }}
            loading={isLoading}
          />
          <Typography variant="body2">or</Typography>
          <div style={{ width: "100%" }}>
            <AssetGeoLocationSearchInput
              ref={geocoderRef}
              placeholder={"Location"}
              onChangeGeo={onChangeGeo}
              helperText="Locations currently limited to U.S. only due to data constraints"
            />
          </div>
        </Inputs>
      </WorkforcePlanningContainer>
      {id && (
        <PrintTemplate ref={printRef}>
          <>
            <AssetHeaderContainer>
              <AssetHeader
                name={title || ""}
                address={subtitle || ""}
                groups={selectedAsset?.assetGroups.map(g => g.name)}
              />
              <SaveAsPDFButton printRef={printRef} />
            </AssetHeaderContainer>
            <WorkforcePlanningContent
              locationId={id}
              defaultIndustry={selectedAsset?.industry || IndustryType.BASELINE}
            />
          </>
        </PrintTemplate>
      )}
    </ToolContainer>
  )
}

export const AssetHeader = ({
  name,
  address,
  groups,
}: Omit<Location, "locationId">) => {
  return (
    <div>
      <Typography variant="h2Bold">{name}</Typography>
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <Typography variant="body1" color={extraColors.medium}>
          {address}
        </Typography>

        {groups?.map(g => (
          <Pill
            key={g}
            value={g}
            colors={{
              background: extraColors.light,
              border: extraColors.disabled,
              text: extraColors.dark,
            }}
          />
        ))}
      </div>
    </div>
  )
}
