import { useMemo } from "react"
import { useMapContext } from "../../../../contexts/MapContext"
import { getLocationCenter } from "../../../../utils/helpers/locationCenterHelper"
import { useAssetMarkerLocations } from "../../LocationCard/hooks/useAssetMarkerLocations"

export type AssetGeoJson = GeoJSON.Feature<
  GeoJSON.Point,
  {
    id: string
    location_code: string
  }
>

export const isAssetGeoJson = (
  geoJson: GeoJSON.Feature
): geoJson is AssetGeoJson => {
  return (
    geoJson.properties?.id !== undefined &&
    geoJson.properties.location_code !== undefined
  )
}

export const useAssetGeoJson = () => {
  const markerLocations = useAssetMarkerLocations()

  const { assetFilters } = useMapContext()
  const filteredMarkerLocations = markerLocations.filter(location => {
    return location.type?.some(type => assetFilters?.[type])
  })

  const alertGeoJSON = useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: filteredMarkerLocations
          .filter(asset => {
            const hasCenter = getLocationCenter(
              asset.locationId,
              asset.wktCentroid
            )

            return hasCenter
          })
          .map(asset => {
            const location = getLocationCenter(
              asset.locationId,
              asset.wktCentroid
            )
            if (!location || !asset.locationId) {
              console.error(
                "Could not find location center for alert",
                asset.id,
                asset.locationId
              )
              return
            }

            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
              id: asset.id,
              properties: {
                id: asset.id,
                location_code: asset.locationId,
              },
            } satisfies AssetGeoJson
          })
          .flatMap(a => (a ? a : [])) satisfies GeoJSON.Feature[],
      }) satisfies GeoJSON.FeatureCollection,
    [filteredMarkerLocations]
  )
  return alertGeoJSON
}
