import { Skeleton, styled, Typography } from "@mui/material"
import type { Bucket } from "@phc-health/connect-query"
import { Disease_Type } from "@phc-health/connect-query"
import analysis from "../../../assets/pngs/related-analysis.png"
import { useLayersByLocationId } from "../../../hooks/useLayersApi"
import { extraColors } from "../../../utils/theme"
import { ContributingFactors } from "./ContributingFactors/ContributingFactors"
import { OverallRisk } from "./Summary/OverallRisk"

const HeaderText = styled(Typography)({
  color: extraColors.medium,
  fontWeight: 600,
})

const ContributingFactorsStyled = styled(ContributingFactors)({
  marginTop: 0,
})

const InfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  FlexDirection: "column",
  background: extraColors.white,
  padding: "24px",
  borderRadius: "8px",
  border: `1px solid ${extraColors.disabled}`,
  marginTop: 25,
  minHeight: 300,
})

const AnalysisImg = styled("img")({
  width: "256px",
  height: "200px",
})

const NoData: React.FC = () => {
  return (
    <InfoContainer>
      <AnalysisImg src={analysis} />
      <Typography
        variant="body1Bold"
        style={{ color: extraColors.disabled, marginTop: 10 }}
      >
        No Data Available
      </Typography>
    </InfoContainer>
  )
}

const LoadingContainer: React.FC = () => {
  return (
    <InfoContainer>
      <Skeleton
        variant="text"
        width="20%"
        height={50}
        sx={{ alignSelf: "flex-start" }}
      />
      <Skeleton
        variant="text"
        width="30%"
        height={60}
        sx={{ alignSelf: "flex-start" }}
      />
      <Skeleton width="100%" height={170} />
    </InfoContainer>
  )
}

export const RiskDetails: React.FC<{
  locationId?: string
}> = ({ locationId }) => {
  const { data, isLoading } = useLayersByLocationId(locationId)
  const buckets = data?.buckets

  if (isLoading)
    return (
      <>
        <HeaderText variant="h3Bold">Risk Factors</HeaderText>
        <LoadingContainer />
      </>
    )

  // if no data or threatGroup, render NoData component
  if (!buckets?.length || !locationId)
    return (
      <>
        <HeaderText variant="h3Bold">Risk Factors</HeaderText>
        <NoData />
      </>
    )

  const resp = buckets.find(f => f.disease === Disease_Type.RESP)
  //check if severity is unspecified to determine which component to render
  const hasTopLevelSeverity = !!resp

  if (!hasTopLevelSeverity)
    // if severity is unspecified, fallback international risk details
    return (
      <InternationalRiskDetails buckets={buckets} locationId={locationId} />
    )

  return (
    <>
      <HeaderText variant="h3Bold">Respiratory Illness</HeaderText>
      <OverallRisk resp={resp} />
      <ContributingFactors
        buckets={buckets.filter(b => b.disease !== Disease_Type.RESP)}
        title={"Current Conditions"}
        defaultCollapsed={true}
      />
    </>
  )
}

const InternationalRiskDetails: React.FC<{
  buckets: Bucket[]
  locationId: string
}> = ({ buckets }) => {
  return (
    <>
      <HeaderText variant="h3Bold">Risk Factors</HeaderText>
      <ContributingFactorsStyled
        buckets={buckets}
        title=""
        defaultCollapsed={false}
      />
    </>
  )
}
