import { Button, Typography, styled } from "@mui/material"
import {
  filterBlockArray,
  getFootnotes,
  type CriticalThreatWithReferences,
} from "@phc/common"
import { PortableText } from "@portabletext/react"
import type React from "react"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { onMissingComponent } from "../../pages/SinglePost/SinglePost"
import { useDynamicComponents } from "../../pages/SinglePost/hooks/useDynamicComponents"
import { ROUTES, TRUE, stringifySearchParams } from "../../utils/constants"
import { extraColors } from "../../utils/theme"
import { useListAssets } from "../WatchedLocations/hooks/useAssetService"
import { DEFAULT_TRANSFORM_SCALE } from "./RelatedContent/Shared"
import { useCapServiceContext } from "./capServiceContext"
import { getAssetsImpactedByCriticalThreat } from "./criticalThreatHelper"

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "grid",
  justifyContent: "space-between",
  minHeight: "100%",
  gap: 20,
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "1fr auto",
    gap: 6,
  },
}))

const CriticalThreatDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginRight: 0,
  flex: 1,
  transform: DEFAULT_TRANSFORM_SCALE,
  [theme.breakpoints.up("sm")]: {
    marginRight: 20,
  },
}))

/* The scrollbar styling allows the user to see the scrollbar without interacting if it's active. */
const ImpactedAssetsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: 3,
    background: extraColors.subtle,
    borderRadius: 6,
  },
  "::-webkit-scrollbar-thumb": {
    background: extraColors.disabled,
    borderRadius: 6,
    width: 3,
  },
  background: extraColors.subtle,
  padding: 24,
  border: `1px solid ${extraColors.light}`,
  borderRadius: 6,
  alignSelf: "center",
  alignItems: "center",
  textAlign: "center",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 370,
    maxWidth: 370,
  },
}))

const LinkStyled = styled(Link)<{ cursor: string }>(({ cursor }) => ({
  cursor,
}))

export const CriticalThreatHeader: React.FC<{
  criticalThreat?: CriticalThreatWithReferences
  showThreatLink?: boolean
}> = ({ criticalThreat, showThreatLink }) => {
  return (
    <HeaderContainer>
      <ThreatDetails criticalThreat={criticalThreat} />
      <ImpactedAssets
        criticalThreat={criticalThreat}
        showThreatLink={showThreatLink}
      />
    </HeaderContainer>
  )
}

const ThreatDetails: React.FC<{
  criticalThreat?: CriticalThreatWithReferences
}> = ({ criticalThreat }) => {
  const { sanityDataset } = useCapServiceContext()
  const { footnotesOrder } = useMemo(
    () => getFootnotes(filterBlockArray(criticalThreat?.body ?? [])),
    [criticalThreat?.body]
  )

  const dynamicComponents = useDynamicComponents(footnotesOrder, sanityDataset)

  return (
    <CriticalThreatDetailsContainer>
      <Typography
        variant={"h2Bold"}
        sx={{ color: extraColors.medium, marginBottom: "12px" }}
      >
        {criticalThreat?.title}
      </Typography>
      <PortableText
        value={criticalThreat?.body ?? []}
        components={dynamicComponents}
        onMissingComponent={onMissingComponent}
      />
    </CriticalThreatDetailsContainer>
  )
}

const ImpactedAssets: React.FC<{
  criticalThreat?: CriticalThreatWithReferences
  showThreatLink?: boolean
}> = ({ criticalThreat, showThreatLink }) => {
  const { data } = useListAssets({ includeGlobal: false })

  const impactedAssets = useMemo(() => {
    return getAssetsImpactedByCriticalThreat(data.assets, criticalThreat)
  }, [criticalThreat, data.assets])

  return (
    <ImpactedAssetsContainer>
      <Typography variant="body1" component={"span"}>
        <Typography variant="body1Bold">
          {`${(impactedAssets?.length || 0).toLocaleString()} asset${impactedAssets?.length === 1 ? " " : "s "} `}
        </Typography>
        affected by this critical threat.
      </Typography>
      <LinkStyled
        cursor={impactedAssets?.length ? "auto" : "default"}
        onClick={e => e.stopPropagation()}
        to={
          impactedAssets?.length
            ? {
                pathname: ROUTES.ASSETS,
                search: stringifySearchParams({
                  affectedByCriticalThreat: TRUE,
                }),
              }
            : ""
        }
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginTop: "12px", height: "fit-content", width: "280px" }}
          disabled={!impactedAssets?.length}
        >
          View My Affected Assets{" "}
        </Button>
      </LinkStyled>
      {showThreatLink && (
        <Button
          variant="contained"
          sx={{ marginTop: "12px", height: "fit-content", width: "280px" }}
          disabled={!impactedAssets?.length}
        >
          View Threat
        </Button>
      )}
    </ImpactedAssetsContainer>
  )
}
