import type { CriticalThreatWithReferences } from "@phc/common"
import { useMemo } from "react"
import { useCapServiceContext } from "./capServiceContext"
import { getFirstRelatedCriticalThreat } from "./criticalThreatHelper"

interface RelatedCriticalThreatProps {
  relatedCriticalThreat?: CriticalThreatWithReferences
  isLoading: boolean
}

export const useGetFirstRelatedCriticalThreat = (
  locationId?: string
): RelatedCriticalThreatProps => {
  const { useCriticalThreats } = useCapServiceContext()
  const { data, isLoading } = useCriticalThreats()

  const relatedCriticalThreat = useMemo(() => {
    if (!locationId) return undefined
    return getFirstRelatedCriticalThreat(locationId, data)
  }, [data, locationId])

  return { relatedCriticalThreat, isLoading }
}
