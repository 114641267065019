import type { SVGProps } from "react"
const SvgCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    viewBox="0 0 19 18"
    {...props}
  >
    <rect
      width={17}
      height={17}
      x={0.912}
      y={0.5}
      fill="rgba(24, 52, 94, 1)"
      rx={1.5}
    />
    <path
      fill="#fff"
      d="m15.893 5.25-.63.629-7 7-.6.629-.63-.63-3.5-3.5-.629-.628 1.258-1.23.602.628 2.898 2.872 6.371-6.372.63-.628 1.23 1.23Z"
    />
    <rect
      width={17}
      height={17}
      x={0.912}
      y={0.5}
      stroke={"rgba(24, 52, 94, 1)"}
      rx={1.5}
    />
  </svg>
)
export default SvgCheckboxChecked
