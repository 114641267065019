import type { SVGProps } from "react"
import { extraColors } from "../utils/theme"

const SvgRadioSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 5.5A4.502 4.502 0 0 0 5.5 10c0 2.484 2.016 4.5 4.5 4.5s4.5-2.016 4.5-4.5-2.016-4.5-4.5-4.5ZM10 1c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9Zm0 16.2A7.198 7.198 0 0 1 2.8 10c0-3.978 3.222-7.2 7.2-7.2s7.2 3.222 7.2 7.2-3.222 7.2-7.2 7.2Z"
      fill={props.fill || extraColors.status.teal}
    />
  </svg>
)

export default SvgRadioSelected
