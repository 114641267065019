import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Link, styled } from "@mui/material"
import type { PortableTextMarkComponent } from "@portabletext/react"
import type { TypedObject } from "@portabletext/types"
import type React from "react"

/**
 * STYLES
 */

const LinkStyled = styled(Link)({
  fontSize: "inherit",
  overflowWrap: "anywhere",
})

/**
 * INTERFACES
 */

interface ExternalLink extends TypedObject {
  href: string
  id?: string
}

/**
 * COMPONENTS
 */

export const ExternalLink: React.FC<{
  children?: React.ReactNode | React.ReactNode[]
  href?: string
  id?: string
}> = ({ children, href, id }) => {
  return (
    <LinkStyled
      // fallback to children text if href is not set
      href={href || children?.toString()}
      target="__blank"
      rel="noopener noreferrer"
      id={id}
    >
      {children}
      <OpenInNewIcon fontSize="inherit" />
    </LinkStyled>
  )
}

export const ExternalLinkRenderer: PortableTextMarkComponent<ExternalLink> = ({
  value,
  children,
}) => {
  return (
    <ExternalLink
      // fallback to children text if href is not set
      href={value?.href || children?.toString()}
    >
      {children}
    </ExternalLink>
  )
}
