import { InfoOutlined } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
import Popover from "@mui/material/Popover"
import * as React from "react"
import { extraColors } from "../utils/theme"

export default function PopOver({
  children,
  vertical = "top",
  horizontal = "right",
  size = "lg",
  style = {},
}: {
  children: React.ReactNode
  vertical?: number | "bottom" | "top" | "center"
  horizontal?: number | "center" | "right" | "left"
  size?: "sm" | "lg"
  style?: React.CSSProperties
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "risk-popover" : undefined

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        data-testid="popover-icon_button"
        style={{
          pointerEvents: "all",
          ...style,
          marginBottom: size === "lg" ? "4px" : "1px",
        }}
      >
        {size === "lg" && (
          <InfoOutlined
            sx={{
              color: extraColors.purpleMiddle,
              height: "22px",
              width: "22px",
            }}
          />
        )}
        {size === "sm" && (
          <InfoOutlined
            sx={{
              color: extraColors.purpleMiddle,
              height: "14px",
              width: "14px",
            }}
          />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-testid="popover-test"
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              boxShadow: `2px 2px 8px 2px ${extraColors.medium}`,
              borderRadius: 10,
              maxWidth: 332,
            },
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: extraColors.subtle,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  )
}
