import { styled } from "@mui/material/styles"
import type { HeaderProps } from "./AppHeader/Header"
import { Header } from "./AppHeader/Header"

const ContentLayout = styled("div")<{
  disableHorizontalGutters?: boolean
  disableLeftGutter?: boolean
}>(({ disableLeftGutter, theme }) => ({
  minWidth: 0,
  width: "100%",
  marginLeft: disableLeftGutter ? 0 : undefined,
  [theme.breakpoints.up("md")]: {
    marginLeft: disableLeftGutter ? 0 : undefined,
  },
  [theme.breakpoints.up("xl")]: {
    marginLeft: disableLeftGutter ? 0 : undefined,
  },
  "@media print": {
    margin: 0,
  },
}))

interface ContentProps extends React.PropsWithChildren {
  headerProps: HeaderProps
  disableLeftGutter?: boolean
}
export const MainContent: React.FC<ContentProps> = ({
  headerProps,
  children,
  disableLeftGutter,
}) => {
  const pageTitle =
    typeof headerProps.title === "string"
      ? headerProps.title
      : headerProps.documentTitle

  // dynamically update the document title based on the value of title in headerProps
  document.title = `PHC Global${pageTitle ? ` - ${pageTitle}` : ""}`

  return (
    <>
      <Header {...headerProps} />
      <ContentLayout disableLeftGutter={disableLeftGutter}>
        {children}
      </ContentLayout>
    </>
  )
}
