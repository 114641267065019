import { useQuery } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useUser({
  orgId,
  email,
}: {
  orgId: string | undefined
  email: string | undefined
}) {
  const transport = useTransport(ACCESS_API)
  const name =
    orgId && email
      ? `organizations/${orgId.replace("organizations/", "")}/users/${email}`
      : ""
  return useQuery(
    AccessManagementServiceQuery.getUser,
    { name },
    {
      transport,
      enabled: !!name,
      staleTime: 1000 * 60 * 5,
    }
  )
}
