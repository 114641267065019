import {
  Breadcrumbs as MuiBreadcrumbs,
  styled,
  Typography,
} from "@mui/material"
import type React from "react"
import { Link as RouterLink } from "react-router-dom"
import { ROUTES, stringifySearchParams } from "../utils/constants"
import { extraColors } from "../utils/theme"

const Link = styled(Typography)(() => ({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
    color: extraColors.dark,
  },
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    outline: "none",
    textDecoration: "underline",
    textDecorationColor: extraColors.dark,
    textDecorationThickness: 2,
  },
})) as typeof Typography

const Breadcrumbs: React.FC<{
  organizationName: string
  organizationId: string
  userId?: string
  userName?: string
  currentPage?: string
  testId?: string
  linkFirst?: boolean
}> = ({ organizationName, organizationId, currentPage, testId, linkFirst }) => {
  return (
    <MuiBreadcrumbs
      style={{
        padding: 16,
        borderRadius: 12,
        background: "white",
        width: "max-content",
      }}
      separator={<Typography variant="body1Bold">{">"}</Typography>}
      data-testid={testId}
    >
      {linkFirst ? (
        <Link
          variant="body1Bold"
          component={RouterLink}
          to={{
            pathname: ROUTES.USERS,
            search: stringifySearchParams({ organizationId, organizationName }),
          }}
        >
          {organizationName}
        </Link>
      ) : (
        <Typography variant="body1Bold">{organizationName}</Typography>
      )}
      {currentPage && (
        <Typography variant="body1Bold">{currentPage}</Typography>
      )}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
