import type { OAuthError } from "@auth0/auth0-react"
import { useAuth0 } from "@auth0/auth0-react"
import type { Interceptor } from "@connectrpc/connect"
import { createConnectTransport } from "@connectrpc/connect-web"
import type { ApiKey } from "../utils/constants"

const useInterceptors = (): Interceptor[] => {
  const { getAccessTokenSilently } = useAuth0()
  return [
    next => async req => {
      try {
        const accessToken = await getAccessTokenSilently()
        req.header.append("Authorization", `Bearer ${accessToken}`)
      } catch (err) {
        const error = err as OAuthError
        if (error.error !== "login_required") {
          console.error({ ...error })
          throw err
        }
      }
      return await next(req)
    },
    /** Delete headers not allowed by phc endpoints */
    next => req => {
      req.header.delete("connect-protocol-version")
      req.header.delete("user-agent")
      return next(req)
    },
  ]
}

export const useTransport = (baseUrl: ApiKey) => {
  const interceptors = useInterceptors()
  return createConnectTransport({
    baseUrl,
    interceptors,
    useHttpGet: true,
  })
}
