import { styled, Typography } from "@mui/material"
import type React from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import clipboard from "../assets/clipboard.png"
import { ROUTES } from "../utils/constants"
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../utils/env"

const Authenticating = styled("div")(() => ({
  minHeight: "400px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}))

const Image = styled("img")(() => ({
  height: 100,
  padding: "10px",
}))

export const Authenticate: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const organizationId = urlParams.get("organization")
    const invitationId = urlParams.get("invitation")
    const orgName = urlParams.get("organization_name")
    //TODO: change /login to something more specific
    // if any required param for authenticating the invite link is missing, send user to /sign-in
    // handles cases where user tries to navigate directly to /login
    if (!organizationId || !invitationId) {
      navigate(ROUTES.SIGN_IN)
      return
    } else {
      const orgNameParam = orgName ? `organization_name=${orgName}&` : ""

      // https://auth0.com/docs/manage-users/organizations/configure-organizations/invite-members#specify-route-behavior
      // /authorize endpoint does not work with xhr/fetch requests...must use top level browser navigation --> https://community.auth0.com/t/received-cors-issue-trying-to-reproduce-quickstart-demo-in-rails/60143/4
      window.location.href = `https://${AUTH0_DOMAIN}/authorize?audience=${AUTH0_AUDIENCE}&response_type=token&client_id=${AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/sign-in?${orgNameParam}organization=${organizationId}&invitation=${invitationId}`
    }
  }, [navigate])

  return (
    <>
      <div data-testid="authenticate-page">
        <Authenticating>
          <Image src={clipboard} alt={"clipboard"} />
          <Typography>Authenticating...Please wait</Typography>
        </Authenticating>
      </div>
    </>
  )
}
