import { styled } from "@mui/material"
import type {
  AlertWithReferences,
  AnalysisWithReferences,
  GuidanceWithReferences,
} from "@phc/common"
import type React from "react"
import { useCallback } from "react"
import { extraColors } from "../../../utils/theme"
import { SearchInput } from "../../Shared/SearchInput"
import { ContentTypeDropdown, type ContentTypeValue } from "./ContentTypeFilter"
import { GeoTagFilters } from "./GeotagFilter"

const SearchInputStyled = styled(SearchInput)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: 6,
  },
  background: extraColors.white,
})

const FilterRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}))

export const ContentFilters: React.FC<{
  content?: (
    | AnalysisWithReferences
    | GuidanceWithReferences
    | AlertWithReferences
  )[]
  selectedGeotagIds: Set<string>
  setSelectedGeotagIds: React.Dispatch<React.SetStateAction<Set<string>>>
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  contentType: ContentTypeValue | undefined
  setContentType: React.Dispatch<
    React.SetStateAction<ContentTypeValue | undefined>
  >
}> = ({
  content,
  selectedGeotagIds,
  setSelectedGeotagIds,
  searchText,
  setSearchText,
  contentType,
  setContentType,
}) => {
  const onFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchText(e.target.value)
    },
    [setSearchText]
  )

  return (
    <>
      <GeoTagFilters
        content={content}
        selectedGeotagIds={selectedGeotagIds}
        setSelectedGeotagIds={setSelectedGeotagIds}
      />
      <FilterRow>
        <SearchInputStyled onChange={onFilter} searchText={searchText} />
        <ContentTypeDropdown
          contentType={contentType}
          setContentType={setContentType}
        />
      </FilterRow>
    </>
  )
}
