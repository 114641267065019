import { Risk_Severity } from "@phc-health/connect-query"
import { getIndicatorStyles } from "./LocationDetails/LocationRisk/SeverityVisualIndicator"
import { Pill } from "./Shared/Pill"

interface RiskPillProps {
  severity: Risk_Severity
}

export const RiskPill = ({ severity }: RiskPillProps) => {
  const riskColors = getIndicatorStyles(severity)

  return <Pill value={Risk_Severity[severity]} colors={riskColors} />
}
