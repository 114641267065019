import { AssetType } from "@phc-health/connect-query"
import { useMemo } from "react"
import { getLocationId } from "../../../../utils/helpers"
import { useListAssets } from "../../../WatchedLocations/hooks/useAssetService"

interface MarkerLocation {
  id: string
  locationId: string | undefined
  type?: AssetType[]
  wktCentroid?: string | undefined
}

export const useAssetMarkerLocations = (): MarkerLocation[] => {
  const { data: assetData } = useListAssets({
    includeGlobal: false,
    excludeGroups: true,
    excludeNotifications: true,
  })

  return useMemo(() => {
    return assetData.assets.flatMap(asset => {
      return {
        id: asset.assetId,
        locationId: getLocationId(asset),
        type: !asset.assetTypes.length
          ? [AssetType.ASSET_TYPE_UNSPECIFIED]
          : asset.assetTypes,
        wktCentroid: asset.baseEvent?.geotags[0]?.wktCentroid,
      } satisfies MarkerLocation
    })
  }, [assetData.assets])
}
