import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material"
import { Disease_Type } from "@phc-health/connect-query"
import { AlertLevel } from "@phc/common"
import { useMapContext } from "../../contexts/MapContext"
import { convertStringToTitleCase } from "../../utils/helpers"
import { assetTypeEnumToString } from "../../utils/helpers/assetHelper"
import { diseaseTypeMapping } from "../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../utils/theme"
import {
  InfoIconStyled,
  LightTooltip,
} from "../LocationDetails/LocationRisk/Shared"

const Container = styled("div")(() => ({
  position: "relative",
  zIndex: 9999,
  backgroundColor: extraColors.white,
}))

const SectionHeader = styled(Typography)(() => ({
  color: extraColors.hint,
  textTransform: "uppercase",
  padding: "6px 12px",
}))

const Form = styled(FormGroup)(() => ({
  padding: "0",
}))

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  gap: "6px",
  paddingLeft: "24px",
})) as typeof FormControlLabel

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: "6px 0px",
}))

const StyledRadioGroup = styled(RadioGroup)(() => ({
  backgroundColor: extraColors.light,
  gap: "12px",
  padding: "6px 0 6px 18px",
})) as typeof RadioGroup

const StyledRadio = styled(Radio)(() => ({
  padding: "2px 4px",
}))

const SecondaryOptions = styled("div")(() => ({
  backgroundColor: extraColors.light,
  paddingLeft: "6px",
}))

const OptionsWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const ControlPanel: React.FC = () => {
  const {
    availableAssetTypes,
    setDiseaseType,
    diseaseType,
    alertFilters,
    setAlertFilters,
    setAssetFilters,
    assetFilters,
  } = useMapContext()

  return (
    <Container>
      {!!availableAssetTypes.length && (
        <div>
          <SectionHeader variant="small1">Asset Types</SectionHeader>
          <Form>
            {availableAssetTypes.map(type => {
              return (
                <StyledFormControlLabel
                  key={type}
                  control={<StyledCheckbox />}
                  checked={!!assetFilters?.[type]}
                  label={assetTypeEnumToString(type)}
                  onChange={(e, checked) => {
                    setAssetFilters({
                      ...assetFilters,
                      [type]: checked,
                    })
                  }}
                />
              )
            })}
          </Form>
        </div>
      )}
      <div>
        <SectionHeader variant="small1">Threats</SectionHeader>
        <Form>
          <StyledRadioGroup
            aria-labelledby="infectious-disease-radio-buttons-group-label"
            value={diseaseType}
            onChange={event => {
              setDiseaseType(parseInt(event.target.value))
            }}
          >
            {[
              Disease_Type.RESP,
              Disease_Type.COVID,
              Disease_Type.RSV,
              Disease_Type.FLU,
            ].map(d => (
              <ThreatRadio key={d} diseaseType={d} />
            ))}
          </StyledRadioGroup>
        </Form>
      </div>
      <div>
        <SectionHeader variant="small1">Intelligence</SectionHeader>
        <Form>
          <StyledFormControlLabel
            control={
              <StyledCheckbox indeterminate={!!(alertFilters.length % 3)} />
            }
            checked={alertFilters.length === 3}
            onChange={(e, checked) => {
              if (checked) {
                setAlertFilters([
                  AlertLevel.ADVISORY,
                  AlertLevel.WATCH,
                  AlertLevel.WARNING,
                ])
              } else {
                setAlertFilters([])
              }
            }}
            label={
              <>
                Alerts{" "}
                <Typography variant="inherit" component="span" fontSize={12}>
                  (last 30 days)
                </Typography>
              </>
            }
          />
          <AlertOptions />
        </Form>
      </div>
    </Container>
  )
}

const ThreatRadio: React.FC<{ diseaseType: Disease_Type }> = ({
  diseaseType,
}) => {
  const { bucketData } = useMapContext()
  return (
    <FormControlLabel
      value={diseaseType}
      control={<StyledRadio />}
      label={diseaseTypeMapping(diseaseType)}
      disabled={!bucketData}
    />
  )
}

function alertTooltip(alertLevel: AlertLevel) {
  switch (alertLevel) {
    case AlertLevel.ADVISORY:
      return "For awareness only, no action(s) recommended"
    case AlertLevel.WATCH:
      return "Tracking for awareness recommended to inform early preventive action(s)"
    case AlertLevel.WARNING:
      return "Urgent attention or mitigation action(s) recommended"
    default:
      return ""
  }
}

const AlertOptions: React.FC = () => {
  return (
    <SecondaryOptions>
      {Object.values(AlertLevel).map((value, _) => {
        return (
          <OptionsWrapper key={value}>
            <AlertLevelCheckbox alertLevel={value} />
            <LightTooltip
              title={alertTooltip(value)}
              arrow
              placement="right-start"
            >
              <InfoIconStyled />
            </LightTooltip>
          </OptionsWrapper>
        )
      })}
    </SecondaryOptions>
  )
}

const AlertLevelCheckbox: React.FC<{ alertLevel: AlertLevel }> = ({
  alertLevel,
}) => {
  const { alertFilters, setAlertFilters } = useMapContext()

  return (
    <StyledFormControlLabel
      control={<StyledCheckbox />}
      label={convertStringToTitleCase(alertLevel)}
      value={alertLevel}
      checked={alertFilters.includes(alertLevel)}
      onChange={(e, checked) => {
        if (checked) {
          setAlertFilters(prev => [...prev, alertLevel])
        } else {
          setAlertFilters(prev => prev.filter(f => f !== alertLevel))
        }
      }}
    />
  )
}
