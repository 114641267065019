import {
  determineSourceLayerContext,
  isAlertLevel,
  mapboxConfig,
} from "@phc/common"
import { useMemo } from "react"
import { GLOBAL_GEOTAG } from "../../../../../../common/src/constants"
import { useMapContext } from "../../../../contexts/MapContext"
import { getLocationCenter } from "../../../../utils/helpers/locationCenterHelper"
import { useCapServiceContext } from "../../../CriticalThreats/capServiceContext"

export const useAlertGeoJson = () => {
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()
  const { alertFilters } = useMapContext()

  const alertGeoJSON = useMemo(() => {
    if (!alerts) return
    const features = alerts
      .filter(alert => {
        const isAlert =
          isAlertLevel(alert.alertLevel) &&
          alertFilters.includes(alert.alertLevel)

        const hasCenter = alert.geotags?.every(
          geotag =>
            !!getLocationCenter(geotag.locationId, geotag.centroid) ||
            // don't throw out alerts with global tag!
            geotag.locationId === GLOBAL_GEOTAG.locationId
        )

        return isAlert && hasCenter
      })
      .flatMap(alert => {
        const alertFeatures = alert.geotags?.flatMap(geotag => {
          const location = getLocationCenter(geotag.locationId, geotag.centroid)
          if (!location) {
            if (geotag.locationId !== GLOBAL_GEOTAG.locationId) {
              console.error(
                "Could not find location center for alert",
                alert._id,
                geotag.locationId
              )
            }
            return []
          }

          const layerConfig = mapboxConfig.sourceLayer.find(
            layer =>
              layer.sourceInfo.source === geotag.countryCode &&
              layer.context === determineSourceLayerContext(geotag.locationId)
          )

          return [
            {
              type: "Feature" as const,
              geometry: {
                type: "Point" as const,
                coordinates: [location.lng, location.lat],
              },
              properties: {
                _id: alert._id,
                locationId: geotag.locationId,
                maxZoom: layerConfig?.defaultZoomLevels.maxzoom,
              },
            },
          ]
        })
        return alertFeatures ?? []
      }) satisfies GeoJSON.Feature[]
    return {
      type: "FeatureCollection",
      features,
    } satisfies GeoJSON.FeatureCollection
  }, [alertFilters, alerts])
  return alertGeoJSON
}
