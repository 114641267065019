import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import MobileStepper from "@mui/material/MobileStepper"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import type * as React from "react"
import { useState } from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { extraColors } from "../../../../../utils/theme"
import alerts from "./alerts.png"
import analysis from "./analysis.png"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const TextContainer = styled("div")({
  display: "grid",
  gridAutoFlow: "row",
  gap: 10,
})

export const ExpertInsightsEmailExamples: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)

  return (
    <Box sx={{ maxWidth: 600, flexGrow: 1 }}>
      <CarouselImageHeader activeStep={activeStep} />
      <CarouselPages activeStep={activeStep} setActiveStep={setActiveStep} />
      <CarouselControls activeStep={activeStep} setActiveStep={setActiveStep} />
    </Box>
  )
}

const CarouselImageHeader: React.FC<{ activeStep: number }> = ({
  activeStep,
}) => {
  return (
    <TextContainer sx={{ marginBottom: "10px" }}>
      <Typography variant="h3Bold" sx={{ color: extraColors.medium }}>
        Expert Insight Emails
      </Typography>
      <Typography variant="body1Bold">
        {pageConfigurations[activeStep]?.label}
      </Typography>
      <Typography variant="body1">
        {pageConfigurations[activeStep]?.text}
      </Typography>
    </TextContainer>
  )
}

const CarouselPages: React.FC<{
  activeStep: number
  setActiveStep: (step: number) => void
}> = ({ activeStep, setActiveStep }) => {
  const theme = useTheme()

  return (
    <AutoPlaySwipeableViews
      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
      index={activeStep}
      onChangeIndex={(step: number) => setActiveStep(step)}
      enableMouseEvents
      interval={8000}
    >
      {pageConfigurations.map((step, index) => (
        <div key={step.label}>
          {Math.abs(activeStep - index) <= 2 ? (
            <Box
              component="img"
              sx={{
                display: "block",
                overflow: "hidden",
                width: "100%",
              }}
              src={step.imgPath}
              alt={step.label}
            />
          ) : null}
        </div>
      ))}
    </AutoPlaySwipeableViews>
  )
}

const CarouselControls: React.FC<{
  activeStep: number
  setActiveStep: (step: number) => void
}> = ({ activeStep, setActiveStep }) => {
  const theme = useTheme()
  const maxSteps = pageConfigurations.length

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <MobileStepper
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          Next
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  )
}

const pageConfigurations = [
  {
    label: "Alerts",
    imgPath: alerts,
    text: (
      <>
        Alerts provide early warning of emerging biorisk events around the globe
        and expert recommendations for how businesses can mitigate their impact.
        <br />
        <br />
        They are sent to your email as a daily digest and are accessible at any
        time on PHC Pharos™.
      </>
    ),
  },
  {
    label: "Analysis",
    imgPath: analysis,
    text: (
      <>
        Analysis posts provide expert commentary to help you navigate the
        biorisk landscape when events build in complexity or converge. <br />
        <br />
        They provide the nuanced understanding your business needs to
        effectively build resilience to biorisk. <br />
        <br />
        Analyses are sent to your email upon publication and are accessible at
        any time on PHC Pharos™.
      </>
    ),
  },
] as const
