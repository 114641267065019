import { styled } from "@mui/material"

import type React from "react"
import { SettingCheckbox } from "../../Settings/notifications/SettingCheckbox"

const SettingsContainer = styled("div")({
  display: "grid",
  gap: 12,
  paddingTop: 6,
})

export const LocationNotificationSettings: React.FC<{
  riskIncreaseEnabled: boolean
  setRiskIncreaseEnabled: React.Dispatch<React.SetStateAction<boolean>>
  absenteeismEnabled: boolean
  setAbsenteeismEnabled: React.Dispatch<React.SetStateAction<boolean>>
  expertInsightsEnabled: boolean
  setExpertInsightsEnabled: React.Dispatch<React.SetStateAction<boolean>>
  globalExpertInsightsEnabled: boolean
  saveOnChangeFn?: () => void
}> = ({
  riskIncreaseEnabled,
  setRiskIncreaseEnabled,
  absenteeismEnabled,
  setAbsenteeismEnabled,
  expertInsightsEnabled,
  setExpertInsightsEnabled,
  globalExpertInsightsEnabled,
  saveOnChangeFn,
}) => {
  return (
    <SettingsContainer>
      <SettingCheckbox
        settingEnabled={riskIncreaseEnabled}
        setSettingEnabled={setRiskIncreaseEnabled}
        title="Receive Risk Increase notifications for this location."
        customSettingFn={saveOnChangeFn}
      />
      <SettingCheckbox
        settingEnabled={absenteeismEnabled}
        setSettingEnabled={setAbsenteeismEnabled}
        title=" Receive Absenteeism Forecast notifications for this location."
        customSettingFn={saveOnChangeFn}
      />
      <SettingCheckbox
        settingEnabled={expertInsightsEnabled}
        setSettingEnabled={setExpertInsightsEnabled}
        title="Receive Expert Insights for this location."
        disabled={globalExpertInsightsEnabled}
        disabledText="You are already receiving global Expert Insights."
        customSettingFn={saveOnChangeFn}
      />
    </SettingsContainer>
  )
}
