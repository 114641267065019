import type { MapboxConfig } from "./mapboxConfig"

/**
 * This list is generated from the recipes published in the Mapbox Tile Service
 *
 * How to generate:
 * cd packages/common
 * yarn gen-layers
 *
 * The array below will be replaced and formatted automatically
 *
 * The generator will respect existing zoom levels, so adjust as needed.
 * Though be aware if a new context is added, then you will need to manual readjust zoom levels
 */
export const mapboxSourceLayers: MapboxConfig["sourceLayer"] = [
  {
    id: "ad",
    sourceInfo: {
      source: "ad",
      sourceUrl: "mapbox://thepublichealthco.ad",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ad-subnational",
    sourceInfo: {
      source: "ad",
      sourceUrl: "mapbox://thepublichealthco.ad",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ae",
    sourceInfo: {
      source: "ae",
      sourceUrl: "mapbox://thepublichealthco.ae",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ae-subnational",
    sourceInfo: {
      source: "ae",
      sourceUrl: "mapbox://thepublichealthco.ae",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "af",
    sourceInfo: {
      source: "af",
      sourceUrl: "mapbox://thepublichealthco.af",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "af-subnational",
    sourceInfo: {
      source: "af",
      sourceUrl: "mapbox://thepublichealthco.af",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ag",
    sourceInfo: {
      source: "ag",
      sourceUrl: "mapbox://thepublichealthco.ag",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 10,
    },
  },
  {
    id: "ag-subnational",
    sourceInfo: {
      source: "ag",
      sourceUrl: "mapbox://thepublichealthco.ag",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 10,
      maxzoom: 22,
    },
  },
  {
    id: "ai",
    sourceInfo: {
      source: "ai",
      sourceUrl: "mapbox://thepublichealthco.ai",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "al",
    sourceInfo: {
      source: "al",
      sourceUrl: "mapbox://thepublichealthco.al",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 7,
    },
  },
  {
    id: "al-subnational",
    sourceInfo: {
      source: "al",
      sourceUrl: "mapbox://thepublichealthco.al",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "am",
    sourceInfo: {
      source: "am",
      sourceUrl: "mapbox://thepublichealthco.am",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "am-subnational",
    sourceInfo: {
      source: "am",
      sourceUrl: "mapbox://thepublichealthco.am",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ao",
    sourceInfo: {
      source: "ao",
      sourceUrl: "mapbox://thepublichealthco.ao",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ao-subnational",
    sourceInfo: {
      source: "ao",
      sourceUrl: "mapbox://thepublichealthco.ao",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "aq",
    sourceInfo: {
      source: "aq",
      sourceUrl: "mapbox://thepublichealthco.aq",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "ar",
    sourceInfo: {
      source: "ar",
      sourceUrl: "mapbox://thepublichealthco.ar",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ar-subnational",
    sourceInfo: {
      source: "ar",
      sourceUrl: "mapbox://thepublichealthco.ar",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "as",
    sourceInfo: {
      source: "as",
      sourceUrl: "mapbox://thepublichealthco.as",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 9,
    },
  },
  {
    id: "as-subnational",
    sourceInfo: {
      source: "as",
      sourceUrl: "mapbox://thepublichealthco.as",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 9,
      maxzoom: 22,
    },
  },
  {
    id: "at",
    sourceInfo: {
      source: "at",
      sourceUrl: "mapbox://thepublichealthco.at",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "at-subnational",
    sourceInfo: {
      source: "at",
      sourceUrl: "mapbox://thepublichealthco.at",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "au",
    sourceInfo: {
      source: "au",
      sourceUrl: "mapbox://thepublichealthco.au",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 4,
    },
  },
  {
    id: "au-subnational",
    sourceInfo: {
      source: "au",
      sourceUrl: "mapbox://thepublichealthco.au",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 4,
      maxzoom: 22,
    },
  },
  {
    id: "aw",
    sourceInfo: {
      source: "aw",
      sourceUrl: "mapbox://thepublichealthco.aw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "ax",
    sourceInfo: {
      source: "ax",
      sourceUrl: "mapbox://thepublichealthco.ax",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 8,
    },
  },
  {
    id: "ax-subnational",
    sourceInfo: {
      source: "ax",
      sourceUrl: "mapbox://thepublichealthco.ax",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 8,
      maxzoom: 22,
    },
  },
  {
    id: "az",
    sourceInfo: {
      source: "az",
      sourceUrl: "mapbox://thepublichealthco.az",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 7,
    },
  },
  {
    id: "az-subnational",
    sourceInfo: {
      source: "az",
      sourceUrl: "mapbox://thepublichealthco.az",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "ba",
    sourceInfo: {
      source: "ba",
      sourceUrl: "mapbox://thepublichealthco.ba",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ba-subnational",
    sourceInfo: {
      source: "ba",
      sourceUrl: "mapbox://thepublichealthco.ba",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bb",
    sourceInfo: {
      source: "bb",
      sourceUrl: "mapbox://thepublichealthco.bb",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bb-subnational",
    sourceInfo: {
      source: "bb",
      sourceUrl: "mapbox://thepublichealthco.bb",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bd",
    sourceInfo: {
      source: "bd",
      sourceUrl: "mapbox://thepublichealthco.bd",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bd-subnational",
    sourceInfo: {
      source: "bd",
      sourceUrl: "mapbox://thepublichealthco.bd",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "be",
    sourceInfo: {
      source: "be",
      sourceUrl: "mapbox://thepublichealthco.be",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "be-subnational",
    sourceInfo: {
      source: "be",
      sourceUrl: "mapbox://thepublichealthco.be",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bf",
    sourceInfo: {
      source: "bf",
      sourceUrl: "mapbox://thepublichealthco.bf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bf-subnational",
    sourceInfo: {
      source: "bf",
      sourceUrl: "mapbox://thepublichealthco.bf",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bg",
    sourceInfo: {
      source: "bg",
      sourceUrl: "mapbox://thepublichealthco.bg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bg-subnational",
    sourceInfo: {
      source: "bg",
      sourceUrl: "mapbox://thepublichealthco.bg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bh",
    sourceInfo: {
      source: "bh",
      sourceUrl: "mapbox://thepublichealthco.bh",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bh-subnational",
    sourceInfo: {
      source: "bh",
      sourceUrl: "mapbox://thepublichealthco.bh",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bi",
    sourceInfo: {
      source: "bi",
      sourceUrl: "mapbox://thepublichealthco.bi",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bi-subnational",
    sourceInfo: {
      source: "bi",
      sourceUrl: "mapbox://thepublichealthco.bi",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bj",
    sourceInfo: {
      source: "bj",
      sourceUrl: "mapbox://thepublichealthco.bj",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bj-subnational",
    sourceInfo: {
      source: "bj",
      sourceUrl: "mapbox://thepublichealthco.bj",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bl",
    sourceInfo: {
      source: "bl",
      sourceUrl: "mapbox://thepublichealthco.bl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "bm",
    sourceInfo: {
      source: "bm",
      sourceUrl: "mapbox://thepublichealthco.bm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bm-subnational",
    sourceInfo: {
      source: "bm",
      sourceUrl: "mapbox://thepublichealthco.bm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bn",
    sourceInfo: {
      source: "bn",
      sourceUrl: "mapbox://thepublichealthco.bn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bn-subnational",
    sourceInfo: {
      source: "bn",
      sourceUrl: "mapbox://thepublichealthco.bn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bo",
    sourceInfo: {
      source: "bo",
      sourceUrl: "mapbox://thepublichealthco.bo",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bo-subnational",
    sourceInfo: {
      source: "bo",
      sourceUrl: "mapbox://thepublichealthco.bo",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bq",
    sourceInfo: {
      source: "bq",
      sourceUrl: "mapbox://thepublichealthco.bq",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 7,
    },
  },
  {
    id: "bq-subnational",
    sourceInfo: {
      source: "bq",
      sourceUrl: "mapbox://thepublichealthco.bq",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "br",
    sourceInfo: {
      source: "br",
      sourceUrl: "mapbox://thepublichealthco.br",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "br-subnational",
    sourceInfo: {
      source: "br",
      sourceUrl: "mapbox://thepublichealthco.br",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bs",
    sourceInfo: {
      source: "bs",
      sourceUrl: "mapbox://thepublichealthco.bs",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bs-subnational",
    sourceInfo: {
      source: "bs",
      sourceUrl: "mapbox://thepublichealthco.bs",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bt",
    sourceInfo: {
      source: "bt",
      sourceUrl: "mapbox://thepublichealthco.bt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bt-subnational",
    sourceInfo: {
      source: "bt",
      sourceUrl: "mapbox://thepublichealthco.bt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bv",
    sourceInfo: {
      source: "bv",
      sourceUrl: "mapbox://thepublichealthco.bv",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "bw",
    sourceInfo: {
      source: "bw",
      sourceUrl: "mapbox://thepublichealthco.bw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 6,
    },
  },
  {
    id: "bw-subnational",
    sourceInfo: {
      source: "bw",
      sourceUrl: "mapbox://thepublichealthco.bw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 6,
      maxzoom: 22,
    },
  },
  {
    id: "by",
    sourceInfo: {
      source: "by",
      sourceUrl: "mapbox://thepublichealthco.by",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "by-subnational",
    sourceInfo: {
      source: "by",
      sourceUrl: "mapbox://thepublichealthco.by",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "bz",
    sourceInfo: {
      source: "bz",
      sourceUrl: "mapbox://thepublichealthco.bz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "bz-subnational",
    sourceInfo: {
      source: "bz",
      sourceUrl: "mapbox://thepublichealthco.bz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ca",
    sourceInfo: {
      source: "ca",
      sourceUrl: "mapbox://thepublichealthco.ca",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 4,
    },
  },
  {
    id: "ca-subnational",
    sourceInfo: {
      source: "ca",
      sourceUrl: "mapbox://thepublichealthco.ca",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 4,
      maxzoom: 22,
    },
  },
  {
    id: "cc",
    sourceInfo: {
      source: "cc",
      sourceUrl: "mapbox://thepublichealthco.cc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "cd",
    sourceInfo: {
      source: "cd",
      sourceUrl: "mapbox://thepublichealthco.cd",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cd-subnational",
    sourceInfo: {
      source: "cd",
      sourceUrl: "mapbox://thepublichealthco.cd",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cf",
    sourceInfo: {
      source: "cf",
      sourceUrl: "mapbox://thepublichealthco.cf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cf-subnational",
    sourceInfo: {
      source: "cf",
      sourceUrl: "mapbox://thepublichealthco.cf",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cg",
    sourceInfo: {
      source: "cg",
      sourceUrl: "mapbox://thepublichealthco.cg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cg-subnational",
    sourceInfo: {
      source: "cg",
      sourceUrl: "mapbox://thepublichealthco.cg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ch",
    sourceInfo: {
      source: "ch",
      sourceUrl: "mapbox://thepublichealthco.ch",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ch-subnational",
    sourceInfo: {
      source: "ch",
      sourceUrl: "mapbox://thepublichealthco.ch",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ci",
    sourceInfo: {
      source: "ci",
      sourceUrl: "mapbox://thepublichealthco.ci",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ci-subnational",
    sourceInfo: {
      source: "ci",
      sourceUrl: "mapbox://thepublichealthco.ci",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ck",
    sourceInfo: {
      source: "ck",
      sourceUrl: "mapbox://thepublichealthco.ck",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ck-subnational",
    sourceInfo: {
      source: "ck",
      sourceUrl: "mapbox://thepublichealthco.ck",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cl",
    sourceInfo: {
      source: "cl",
      sourceUrl: "mapbox://thepublichealthco.cl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cl-subnational",
    sourceInfo: {
      source: "cl",
      sourceUrl: "mapbox://thepublichealthco.cl",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cm",
    sourceInfo: {
      source: "cm",
      sourceUrl: "mapbox://thepublichealthco.cm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cm-subnational",
    sourceInfo: {
      source: "cm",
      sourceUrl: "mapbox://thepublichealthco.cm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cn",
    sourceInfo: {
      source: "cn",
      sourceUrl: "mapbox://thepublichealthco.cn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cn-subnational",
    sourceInfo: {
      source: "cn",
      sourceUrl: "mapbox://thepublichealthco.cn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "co",
    sourceInfo: {
      source: "co",
      sourceUrl: "mapbox://thepublichealthco.co",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "co-subnational",
    sourceInfo: {
      source: "co",
      sourceUrl: "mapbox://thepublichealthco.co",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cr",
    sourceInfo: {
      source: "cr",
      sourceUrl: "mapbox://thepublichealthco.cr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cr-subnational",
    sourceInfo: {
      source: "cr",
      sourceUrl: "mapbox://thepublichealthco.cr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cu",
    sourceInfo: {
      source: "cu",
      sourceUrl: "mapbox://thepublichealthco.cu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cu-subnational",
    sourceInfo: {
      source: "cu",
      sourceUrl: "mapbox://thepublichealthco.cu",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cv",
    sourceInfo: {
      source: "cv",
      sourceUrl: "mapbox://thepublichealthco.cv",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "cv-subnational",
    sourceInfo: {
      source: "cv",
      sourceUrl: "mapbox://thepublichealthco.cv",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "cw",
    sourceInfo: {
      source: "cw",
      sourceUrl: "mapbox://thepublichealthco.cw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "cx",
    sourceInfo: {
      source: "cx",
      sourceUrl: "mapbox://thepublichealthco.cx",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "cy",
    sourceInfo: {
      source: "cy",
      sourceUrl: "mapbox://thepublichealthco.cy",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 8,
    },
  },
  {
    id: "cy-subnational",
    sourceInfo: {
      source: "cy",
      sourceUrl: "mapbox://thepublichealthco.cy",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 8,
      maxzoom: 22,
    },
  },
  {
    id: "cz",
    sourceInfo: {
      source: "cz",
      sourceUrl: "mapbox://thepublichealthco.cz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 6,
    },
  },
  {
    id: "cz-subnational",
    sourceInfo: {
      source: "cz",
      sourceUrl: "mapbox://thepublichealthco.cz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 6,
      maxzoom: 22,
    },
  },
  {
    id: "de",
    sourceInfo: {
      source: "de",
      sourceUrl: "mapbox://thepublichealthco.de",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "de-subnational",
    sourceInfo: {
      source: "de",
      sourceUrl: "mapbox://thepublichealthco.de",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 7,
    },
  },
  {
    id: "de-subdivision",
    sourceInfo: {
      source: "de",
      sourceUrl: "mapbox://thepublichealthco.de",
    },
    context: "subdivision",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "dj",
    sourceInfo: {
      source: "dj",
      sourceUrl: "mapbox://thepublichealthco.dj",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "dj-subnational",
    sourceInfo: {
      source: "dj",
      sourceUrl: "mapbox://thepublichealthco.dj",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "dk",
    sourceInfo: {
      source: "dk",
      sourceUrl: "mapbox://thepublichealthco.dk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "dk-subnational",
    sourceInfo: {
      source: "dk",
      sourceUrl: "mapbox://thepublichealthco.dk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "dm",
    sourceInfo: {
      source: "dm",
      sourceUrl: "mapbox://thepublichealthco.dm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "dm-subnational",
    sourceInfo: {
      source: "dm",
      sourceUrl: "mapbox://thepublichealthco.dm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "do",
    sourceInfo: {
      source: "do",
      sourceUrl: "mapbox://thepublichealthco.do",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "do-subnational",
    sourceInfo: {
      source: "do",
      sourceUrl: "mapbox://thepublichealthco.do",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "dz",
    sourceInfo: {
      source: "dz",
      sourceUrl: "mapbox://thepublichealthco.dz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "dz-subnational",
    sourceInfo: {
      source: "dz",
      sourceUrl: "mapbox://thepublichealthco.dz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ec",
    sourceInfo: {
      source: "ec",
      sourceUrl: "mapbox://thepublichealthco.ec",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ec-subnational",
    sourceInfo: {
      source: "ec",
      sourceUrl: "mapbox://thepublichealthco.ec",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ee",
    sourceInfo: {
      source: "ee",
      sourceUrl: "mapbox://thepublichealthco.ee",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ee-subnational",
    sourceInfo: {
      source: "ee",
      sourceUrl: "mapbox://thepublichealthco.ee",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "eg",
    sourceInfo: {
      source: "eg",
      sourceUrl: "mapbox://thepublichealthco.eg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "eg-subnational",
    sourceInfo: {
      source: "eg",
      sourceUrl: "mapbox://thepublichealthco.eg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "eh",
    sourceInfo: {
      source: "eh",
      sourceUrl: "mapbox://thepublichealthco.eh",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "er",
    sourceInfo: {
      source: "er",
      sourceUrl: "mapbox://thepublichealthco.er",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "er-subnational",
    sourceInfo: {
      source: "er",
      sourceUrl: "mapbox://thepublichealthco.er",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "es",
    sourceInfo: {
      source: "es",
      sourceUrl: "mapbox://thepublichealthco.es",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "es-subnational",
    sourceInfo: {
      source: "es",
      sourceUrl: "mapbox://thepublichealthco.es",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "et",
    sourceInfo: {
      source: "et",
      sourceUrl: "mapbox://thepublichealthco.et",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "et-subnational",
    sourceInfo: {
      source: "et",
      sourceUrl: "mapbox://thepublichealthco.et",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "fi",
    sourceInfo: {
      source: "fi",
      sourceUrl: "mapbox://thepublichealthco.fi",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "fi-subnational",
    sourceInfo: {
      source: "fi",
      sourceUrl: "mapbox://thepublichealthco.fi",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "fj",
    sourceInfo: {
      source: "fj",
      sourceUrl: "mapbox://thepublichealthco.fj",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "fj-subnational",
    sourceInfo: {
      source: "fj",
      sourceUrl: "mapbox://thepublichealthco.fj",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "fk",
    sourceInfo: {
      source: "fk",
      sourceUrl: "mapbox://thepublichealthco.fk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "fm",
    sourceInfo: {
      source: "fm",
      sourceUrl: "mapbox://thepublichealthco.fm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "fm-subnational",
    sourceInfo: {
      source: "fm",
      sourceUrl: "mapbox://thepublichealthco.fm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "fo",
    sourceInfo: {
      source: "fo",
      sourceUrl: "mapbox://thepublichealthco.fo",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "fo-subnational",
    sourceInfo: {
      source: "fo",
      sourceUrl: "mapbox://thepublichealthco.fo",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "fr",
    sourceInfo: {
      source: "fr",
      sourceUrl: "mapbox://thepublichealthco.fr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "fr-subnational",
    sourceInfo: {
      source: "fr",
      sourceUrl: "mapbox://thepublichealthco.fr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 7,
    },
  },
  {
    id: "fr-subdivision",
    sourceInfo: {
      source: "fr",
      sourceUrl: "mapbox://thepublichealthco.fr",
    },
    context: "subdivision",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "ga",
    sourceInfo: {
      source: "ga",
      sourceUrl: "mapbox://thepublichealthco.ga",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ga-subnational",
    sourceInfo: {
      source: "ga",
      sourceUrl: "mapbox://thepublichealthco.ga",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gb",
    sourceInfo: {
      source: "gb",
      sourceUrl: "mapbox://thepublichealthco.gb",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gb-subnational-e",
    sourceInfo: {
      source: "gb",
      sourceUrl: "mapbox://thepublichealthco.gb",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 7,
    },
  },
  {
    id: "gb-subnational-swi",
    sourceInfo: {
      source: "gb",
      sourceUrl: "mapbox://thepublichealthco.gb",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 7,
    },
  },
  {
    id: "gb-subdivision",
    sourceInfo: {
      source: "gb",
      sourceUrl: "mapbox://thepublichealthco.gb",
    },
    context: "subdivision",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "gb-ltla",
    sourceInfo: {
      source: "gb",
      sourceUrl: "mapbox://thepublichealthco.gb",
    },
    context: "subdivision",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "gd",
    sourceInfo: {
      source: "gd",
      sourceUrl: "mapbox://thepublichealthco.gd",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gd-subnational",
    sourceInfo: {
      source: "gd",
      sourceUrl: "mapbox://thepublichealthco.gd",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ge",
    sourceInfo: {
      source: "ge",
      sourceUrl: "mapbox://thepublichealthco.ge",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ge-subnational",
    sourceInfo: {
      source: "ge",
      sourceUrl: "mapbox://thepublichealthco.ge",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gf",
    sourceInfo: {
      source: "gf",
      sourceUrl: "mapbox://thepublichealthco.gf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "gg",
    sourceInfo: {
      source: "gg",
      sourceUrl: "mapbox://thepublichealthco.gg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 10,
    },
  },
  {
    id: "gg-subnational",
    sourceInfo: {
      source: "gg",
      sourceUrl: "mapbox://thepublichealthco.gg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 10,
      maxzoom: 22,
    },
  },
  {
    id: "gh",
    sourceInfo: {
      source: "gh",
      sourceUrl: "mapbox://thepublichealthco.gh",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gh-subnational",
    sourceInfo: {
      source: "gh",
      sourceUrl: "mapbox://thepublichealthco.gh",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gi",
    sourceInfo: {
      source: "gi",
      sourceUrl: "mapbox://thepublichealthco.gi",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "gl",
    sourceInfo: {
      source: "gl",
      sourceUrl: "mapbox://thepublichealthco.gl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gl-subnational",
    sourceInfo: {
      source: "gl",
      sourceUrl: "mapbox://thepublichealthco.gl",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gm",
    sourceInfo: {
      source: "gm",
      sourceUrl: "mapbox://thepublichealthco.gm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gm-subnational",
    sourceInfo: {
      source: "gm",
      sourceUrl: "mapbox://thepublichealthco.gm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gn",
    sourceInfo: {
      source: "gn",
      sourceUrl: "mapbox://thepublichealthco.gn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gn-subnational",
    sourceInfo: {
      source: "gn",
      sourceUrl: "mapbox://thepublichealthco.gn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gp",
    sourceInfo: {
      source: "gp",
      sourceUrl: "mapbox://thepublichealthco.gp",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "gq",
    sourceInfo: {
      source: "gq",
      sourceUrl: "mapbox://thepublichealthco.gq",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gq-subnational",
    sourceInfo: {
      source: "gq",
      sourceUrl: "mapbox://thepublichealthco.gq",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gr",
    sourceInfo: {
      source: "gr",
      sourceUrl: "mapbox://thepublichealthco.gr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 6,
    },
  },
  {
    id: "gr-subnational",
    sourceInfo: {
      source: "gr",
      sourceUrl: "mapbox://thepublichealthco.gr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 6,
      maxzoom: 22,
    },
  },
  {
    id: "gs",
    sourceInfo: {
      source: "gs",
      sourceUrl: "mapbox://thepublichealthco.gs",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "gt",
    sourceInfo: {
      source: "gt",
      sourceUrl: "mapbox://thepublichealthco.gt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gt-subnational",
    sourceInfo: {
      source: "gt",
      sourceUrl: "mapbox://thepublichealthco.gt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gu",
    sourceInfo: {
      source: "gu",
      sourceUrl: "mapbox://thepublichealthco.gu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 10,
    },
  },
  {
    id: "gu-subnational",
    sourceInfo: {
      source: "gu",
      sourceUrl: "mapbox://thepublichealthco.gu",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 10,
      maxzoom: 22,
    },
  },
  {
    id: "gw",
    sourceInfo: {
      source: "gw",
      sourceUrl: "mapbox://thepublichealthco.gw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gw-subnational",
    sourceInfo: {
      source: "gw",
      sourceUrl: "mapbox://thepublichealthco.gw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "gy",
    sourceInfo: {
      source: "gy",
      sourceUrl: "mapbox://thepublichealthco.gy",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "gy-subnational",
    sourceInfo: {
      source: "gy",
      sourceUrl: "mapbox://thepublichealthco.gy",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "hk",
    sourceInfo: {
      source: "hk",
      sourceUrl: "mapbox://thepublichealthco.hk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 10,
    },
  },
  {
    id: "hk-subnational",
    sourceInfo: {
      source: "hk",
      sourceUrl: "mapbox://thepublichealthco.hk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 10,
      maxzoom: 22,
    },
  },
  {
    id: "hm",
    sourceInfo: {
      source: "hm",
      sourceUrl: "mapbox://thepublichealthco.hm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "hn",
    sourceInfo: {
      source: "hn",
      sourceUrl: "mapbox://thepublichealthco.hn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "hn-subnational",
    sourceInfo: {
      source: "hn",
      sourceUrl: "mapbox://thepublichealthco.hn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "hr",
    sourceInfo: {
      source: "hr",
      sourceUrl: "mapbox://thepublichealthco.hr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "hr-subnational",
    sourceInfo: {
      source: "hr",
      sourceUrl: "mapbox://thepublichealthco.hr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ht",
    sourceInfo: {
      source: "ht",
      sourceUrl: "mapbox://thepublichealthco.ht",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ht-subnational",
    sourceInfo: {
      source: "ht",
      sourceUrl: "mapbox://thepublichealthco.ht",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "hu",
    sourceInfo: {
      source: "hu",
      sourceUrl: "mapbox://thepublichealthco.hu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 6,
    },
  },
  {
    id: "hu-subnational",
    sourceInfo: {
      source: "hu",
      sourceUrl: "mapbox://thepublichealthco.hu",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 6,
      maxzoom: 22,
    },
  },
  {
    id: "id",
    sourceInfo: {
      source: "id",
      sourceUrl: "mapbox://thepublichealthco.id",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "id-subnational",
    sourceInfo: {
      source: "id",
      sourceUrl: "mapbox://thepublichealthco.id",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ie",
    sourceInfo: {
      source: "ie",
      sourceUrl: "mapbox://thepublichealthco.ie",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ie-subnational",
    sourceInfo: {
      source: "ie",
      sourceUrl: "mapbox://thepublichealthco.ie",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 7,
    },
  },
  {
    id: "ie-subdivision",
    sourceInfo: {
      source: "ie",
      sourceUrl: "mapbox://thepublichealthco.ie",
    },
    context: "subdivision",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "il",
    sourceInfo: {
      source: "il",
      sourceUrl: "mapbox://thepublichealthco.il",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "il-subnational",
    sourceInfo: {
      source: "il",
      sourceUrl: "mapbox://thepublichealthco.il",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "im",
    sourceInfo: {
      source: "im",
      sourceUrl: "mapbox://thepublichealthco.im",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 9,
    },
  },
  {
    id: "im-subnational",
    sourceInfo: {
      source: "im",
      sourceUrl: "mapbox://thepublichealthco.im",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 9,
      maxzoom: 22,
    },
  },
  {
    id: "in",
    sourceInfo: {
      source: "in",
      sourceUrl: "mapbox://thepublichealthco.in",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "in-subnational",
    sourceInfo: {
      source: "in",
      sourceUrl: "mapbox://thepublichealthco.in",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "io",
    sourceInfo: {
      source: "io",
      sourceUrl: "mapbox://thepublichealthco.io",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "iq",
    sourceInfo: {
      source: "iq",
      sourceUrl: "mapbox://thepublichealthco.iq",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "iq-subnational",
    sourceInfo: {
      source: "iq",
      sourceUrl: "mapbox://thepublichealthco.iq",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ir",
    sourceInfo: {
      source: "ir",
      sourceUrl: "mapbox://thepublichealthco.ir",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ir-subnational",
    sourceInfo: {
      source: "ir",
      sourceUrl: "mapbox://thepublichealthco.ir",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "is",
    sourceInfo: {
      source: "is",
      sourceUrl: "mapbox://thepublichealthco.is",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "is-subnational",
    sourceInfo: {
      source: "is",
      sourceUrl: "mapbox://thepublichealthco.is",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "it",
    sourceInfo: {
      source: "it",
      sourceUrl: "mapbox://thepublichealthco.it",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "it-subnational",
    sourceInfo: {
      source: "it",
      sourceUrl: "mapbox://thepublichealthco.it",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "je",
    sourceInfo: {
      source: "je",
      sourceUrl: "mapbox://thepublichealthco.je",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "je-subnational",
    sourceInfo: {
      source: "je",
      sourceUrl: "mapbox://thepublichealthco.je",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "jm",
    sourceInfo: {
      source: "jm",
      sourceUrl: "mapbox://thepublichealthco.jm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "jm-subnational",
    sourceInfo: {
      source: "jm",
      sourceUrl: "mapbox://thepublichealthco.jm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "jo",
    sourceInfo: {
      source: "jo",
      sourceUrl: "mapbox://thepublichealthco.jo",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "jo-subnational",
    sourceInfo: {
      source: "jo",
      sourceUrl: "mapbox://thepublichealthco.jo",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "jp",
    sourceInfo: {
      source: "jp",
      sourceUrl: "mapbox://thepublichealthco.jp",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "jp-subnational",
    sourceInfo: {
      source: "jp",
      sourceUrl: "mapbox://thepublichealthco.jp",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ke",
    sourceInfo: {
      source: "ke",
      sourceUrl: "mapbox://thepublichealthco.ke",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ke-subnational",
    sourceInfo: {
      source: "ke",
      sourceUrl: "mapbox://thepublichealthco.ke",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "kg",
    sourceInfo: {
      source: "kg",
      sourceUrl: "mapbox://thepublichealthco.kg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "kg-subnational",
    sourceInfo: {
      source: "kg",
      sourceUrl: "mapbox://thepublichealthco.kg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "kh",
    sourceInfo: {
      source: "kh",
      sourceUrl: "mapbox://thepublichealthco.kh",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "kh-subnational",
    sourceInfo: {
      source: "kh",
      sourceUrl: "mapbox://thepublichealthco.kh",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ki",
    sourceInfo: {
      source: "ki",
      sourceUrl: "mapbox://thepublichealthco.ki",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ki-subnational",
    sourceInfo: {
      source: "ki",
      sourceUrl: "mapbox://thepublichealthco.ki",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "km",
    sourceInfo: {
      source: "km",
      sourceUrl: "mapbox://thepublichealthco.km",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "km-subnational",
    sourceInfo: {
      source: "km",
      sourceUrl: "mapbox://thepublichealthco.km",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "kn",
    sourceInfo: {
      source: "kn",
      sourceUrl: "mapbox://thepublichealthco.kn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 10,
    },
  },
  {
    id: "kn-subnational",
    sourceInfo: {
      source: "kn",
      sourceUrl: "mapbox://thepublichealthco.kn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 10,
      maxzoom: 22,
    },
  },
  {
    id: "kp",
    sourceInfo: {
      source: "kp",
      sourceUrl: "mapbox://thepublichealthco.kp",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "kp-subnational",
    sourceInfo: {
      source: "kp",
      sourceUrl: "mapbox://thepublichealthco.kp",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "kr",
    sourceInfo: {
      source: "kr",
      sourceUrl: "mapbox://thepublichealthco.kr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "kr-subnational",
    sourceInfo: {
      source: "kr",
      sourceUrl: "mapbox://thepublichealthco.kr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "kw",
    sourceInfo: {
      source: "kw",
      sourceUrl: "mapbox://thepublichealthco.kw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "kw-subnational",
    sourceInfo: {
      source: "kw",
      sourceUrl: "mapbox://thepublichealthco.kw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ky",
    sourceInfo: {
      source: "ky",
      sourceUrl: "mapbox://thepublichealthco.ky",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ky-subnational",
    sourceInfo: {
      source: "ky",
      sourceUrl: "mapbox://thepublichealthco.ky",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "kz",
    sourceInfo: {
      source: "kz",
      sourceUrl: "mapbox://thepublichealthco.kz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "kz-subnational",
    sourceInfo: {
      source: "kz",
      sourceUrl: "mapbox://thepublichealthco.kz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "la",
    sourceInfo: {
      source: "la",
      sourceUrl: "mapbox://thepublichealthco.la",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "la-subnational",
    sourceInfo: {
      source: "la",
      sourceUrl: "mapbox://thepublichealthco.la",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lb",
    sourceInfo: {
      source: "lb",
      sourceUrl: "mapbox://thepublichealthco.lb",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "lb-subnational",
    sourceInfo: {
      source: "lb",
      sourceUrl: "mapbox://thepublichealthco.lb",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lc",
    sourceInfo: {
      source: "lc",
      sourceUrl: "mapbox://thepublichealthco.lc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "lc-subnational",
    sourceInfo: {
      source: "lc",
      sourceUrl: "mapbox://thepublichealthco.lc",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "li",
    sourceInfo: {
      source: "li",
      sourceUrl: "mapbox://thepublichealthco.li",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "li-subnational",
    sourceInfo: {
      source: "li",
      sourceUrl: "mapbox://thepublichealthco.li",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lk",
    sourceInfo: {
      source: "lk",
      sourceUrl: "mapbox://thepublichealthco.lk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "lk-subnational",
    sourceInfo: {
      source: "lk",
      sourceUrl: "mapbox://thepublichealthco.lk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lr",
    sourceInfo: {
      source: "lr",
      sourceUrl: "mapbox://thepublichealthco.lr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "lr-subnational",
    sourceInfo: {
      source: "lr",
      sourceUrl: "mapbox://thepublichealthco.lr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ls",
    sourceInfo: {
      source: "ls",
      sourceUrl: "mapbox://thepublichealthco.ls",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ls-subnational",
    sourceInfo: {
      source: "ls",
      sourceUrl: "mapbox://thepublichealthco.ls",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lt",
    sourceInfo: {
      source: "lt",
      sourceUrl: "mapbox://thepublichealthco.lt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "lt-subnational",
    sourceInfo: {
      source: "lt",
      sourceUrl: "mapbox://thepublichealthco.lt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lu",
    sourceInfo: {
      source: "lu",
      sourceUrl: "mapbox://thepublichealthco.lu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "lu-subnational",
    sourceInfo: {
      source: "lu",
      sourceUrl: "mapbox://thepublichealthco.lu",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "lv",
    sourceInfo: {
      source: "lv",
      sourceUrl: "mapbox://thepublichealthco.lv",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 6,
    },
  },
  {
    id: "lv-subnational",
    sourceInfo: {
      source: "lv",
      sourceUrl: "mapbox://thepublichealthco.lv",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 6,
      maxzoom: 22,
    },
  },
  {
    id: "ly",
    sourceInfo: {
      source: "ly",
      sourceUrl: "mapbox://thepublichealthco.ly",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ly-subnational",
    sourceInfo: {
      source: "ly",
      sourceUrl: "mapbox://thepublichealthco.ly",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ma",
    sourceInfo: {
      source: "ma",
      sourceUrl: "mapbox://thepublichealthco.ma",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ma-subnational",
    sourceInfo: {
      source: "ma",
      sourceUrl: "mapbox://thepublichealthco.ma",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mc",
    sourceInfo: {
      source: "mc",
      sourceUrl: "mapbox://thepublichealthco.mc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mc-subnational",
    sourceInfo: {
      source: "mc",
      sourceUrl: "mapbox://thepublichealthco.mc",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "md",
    sourceInfo: {
      source: "md",
      sourceUrl: "mapbox://thepublichealthco.md",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "md-subnational",
    sourceInfo: {
      source: "md",
      sourceUrl: "mapbox://thepublichealthco.md",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "me",
    sourceInfo: {
      source: "me",
      sourceUrl: "mapbox://thepublichealthco.me",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "me-subnational",
    sourceInfo: {
      source: "me",
      sourceUrl: "mapbox://thepublichealthco.me",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mf",
    sourceInfo: {
      source: "mf",
      sourceUrl: "mapbox://thepublichealthco.mf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "mg",
    sourceInfo: {
      source: "mg",
      sourceUrl: "mapbox://thepublichealthco.mg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mg-subnational",
    sourceInfo: {
      source: "mg",
      sourceUrl: "mapbox://thepublichealthco.mg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mh",
    sourceInfo: {
      source: "mh",
      sourceUrl: "mapbox://thepublichealthco.mh",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mh-subnational",
    sourceInfo: {
      source: "mh",
      sourceUrl: "mapbox://thepublichealthco.mh",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mk",
    sourceInfo: {
      source: "mk",
      sourceUrl: "mapbox://thepublichealthco.mk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mk-subnational",
    sourceInfo: {
      source: "mk",
      sourceUrl: "mapbox://thepublichealthco.mk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ml",
    sourceInfo: {
      source: "ml",
      sourceUrl: "mapbox://thepublichealthco.ml",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ml-subnational",
    sourceInfo: {
      source: "ml",
      sourceUrl: "mapbox://thepublichealthco.ml",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mm",
    sourceInfo: {
      source: "mm",
      sourceUrl: "mapbox://thepublichealthco.mm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mm-subnational",
    sourceInfo: {
      source: "mm",
      sourceUrl: "mapbox://thepublichealthco.mm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mn",
    sourceInfo: {
      source: "mn",
      sourceUrl: "mapbox://thepublichealthco.mn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mn-subnational",
    sourceInfo: {
      source: "mn",
      sourceUrl: "mapbox://thepublichealthco.mn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mo",
    sourceInfo: {
      source: "mo",
      sourceUrl: "mapbox://thepublichealthco.mo",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 11,
    },
  },
  {
    id: "mo-subnational",
    sourceInfo: {
      source: "mo",
      sourceUrl: "mapbox://thepublichealthco.mo",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 11,
      maxzoom: 22,
    },
  },
  {
    id: "mp",
    sourceInfo: {
      source: "mp",
      sourceUrl: "mapbox://thepublichealthco.mp",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mp-subnational",
    sourceInfo: {
      source: "mp",
      sourceUrl: "mapbox://thepublichealthco.mp",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mq",
    sourceInfo: {
      source: "mq",
      sourceUrl: "mapbox://thepublichealthco.mq",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "mr",
    sourceInfo: {
      source: "mr",
      sourceUrl: "mapbox://thepublichealthco.mr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mr-subnational",
    sourceInfo: {
      source: "mr",
      sourceUrl: "mapbox://thepublichealthco.mr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ms",
    sourceInfo: {
      source: "ms",
      sourceUrl: "mapbox://thepublichealthco.ms",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ms-subnational",
    sourceInfo: {
      source: "ms",
      sourceUrl: "mapbox://thepublichealthco.ms",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mt",
    sourceInfo: {
      source: "mt",
      sourceUrl: "mapbox://thepublichealthco.mt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mt-subnational",
    sourceInfo: {
      source: "mt",
      sourceUrl: "mapbox://thepublichealthco.mt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mu",
    sourceInfo: {
      source: "mu",
      sourceUrl: "mapbox://thepublichealthco.mu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mu-subnational",
    sourceInfo: {
      source: "mu",
      sourceUrl: "mapbox://thepublichealthco.mu",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mv",
    sourceInfo: {
      source: "mv",
      sourceUrl: "mapbox://thepublichealthco.mv",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mv-subnational",
    sourceInfo: {
      source: "mv",
      sourceUrl: "mapbox://thepublichealthco.mv",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mw",
    sourceInfo: {
      source: "mw",
      sourceUrl: "mapbox://thepublichealthco.mw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mw-subnational",
    sourceInfo: {
      source: "mw",
      sourceUrl: "mapbox://thepublichealthco.mw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mx",
    sourceInfo: {
      source: "mx",
      sourceUrl: "mapbox://thepublichealthco.mx",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mx-subnational",
    sourceInfo: {
      source: "mx",
      sourceUrl: "mapbox://thepublichealthco.mx",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "my",
    sourceInfo: {
      source: "my",
      sourceUrl: "mapbox://thepublichealthco.my",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "my-subnational",
    sourceInfo: {
      source: "my",
      sourceUrl: "mapbox://thepublichealthco.my",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "mz",
    sourceInfo: {
      source: "mz",
      sourceUrl: "mapbox://thepublichealthco.mz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "mz-subnational",
    sourceInfo: {
      source: "mz",
      sourceUrl: "mapbox://thepublichealthco.mz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "na",
    sourceInfo: {
      source: "na",
      sourceUrl: "mapbox://thepublichealthco.na",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "na-subnational",
    sourceInfo: {
      source: "na",
      sourceUrl: "mapbox://thepublichealthco.na",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "nc",
    sourceInfo: {
      source: "nc",
      sourceUrl: "mapbox://thepublichealthco.nc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "nc-subnational",
    sourceInfo: {
      source: "nc",
      sourceUrl: "mapbox://thepublichealthco.nc",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ne",
    sourceInfo: {
      source: "ne",
      sourceUrl: "mapbox://thepublichealthco.ne",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ne-subnational",
    sourceInfo: {
      source: "ne",
      sourceUrl: "mapbox://thepublichealthco.ne",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "nf",
    sourceInfo: {
      source: "nf",
      sourceUrl: "mapbox://thepublichealthco.nf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "ng",
    sourceInfo: {
      source: "ng",
      sourceUrl: "mapbox://thepublichealthco.ng",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ng-subnational",
    sourceInfo: {
      source: "ng",
      sourceUrl: "mapbox://thepublichealthco.ng",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ni",
    sourceInfo: {
      source: "ni",
      sourceUrl: "mapbox://thepublichealthco.ni",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ni-subnational",
    sourceInfo: {
      source: "ni",
      sourceUrl: "mapbox://thepublichealthco.ni",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "nl",
    sourceInfo: {
      source: "nl",
      sourceUrl: "mapbox://thepublichealthco.nl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "nl-subnational",
    sourceInfo: {
      source: "nl",
      sourceUrl: "mapbox://thepublichealthco.nl",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "no",
    sourceInfo: {
      source: "no",
      sourceUrl: "mapbox://thepublichealthco.no",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "no-subnational",
    sourceInfo: {
      source: "no",
      sourceUrl: "mapbox://thepublichealthco.no",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "np",
    sourceInfo: {
      source: "np",
      sourceUrl: "mapbox://thepublichealthco.np",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "np-subnational",
    sourceInfo: {
      source: "np",
      sourceUrl: "mapbox://thepublichealthco.np",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "nr",
    sourceInfo: {
      source: "nr",
      sourceUrl: "mapbox://thepublichealthco.nr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "nr-subnational",
    sourceInfo: {
      source: "nr",
      sourceUrl: "mapbox://thepublichealthco.nr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "nu",
    sourceInfo: {
      source: "nu",
      sourceUrl: "mapbox://thepublichealthco.nu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "nz",
    sourceInfo: {
      source: "nz",
      sourceUrl: "mapbox://thepublichealthco.nz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "nz-subnational",
    sourceInfo: {
      source: "nz",
      sourceUrl: "mapbox://thepublichealthco.nz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "om",
    sourceInfo: {
      source: "om",
      sourceUrl: "mapbox://thepublichealthco.om",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "om-subnational",
    sourceInfo: {
      source: "om",
      sourceUrl: "mapbox://thepublichealthco.om",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pa",
    sourceInfo: {
      source: "pa",
      sourceUrl: "mapbox://thepublichealthco.pa",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pa-subnational",
    sourceInfo: {
      source: "pa",
      sourceUrl: "mapbox://thepublichealthco.pa",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pe",
    sourceInfo: {
      source: "pe",
      sourceUrl: "mapbox://thepublichealthco.pe",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pe-subnational",
    sourceInfo: {
      source: "pe",
      sourceUrl: "mapbox://thepublichealthco.pe",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pf",
    sourceInfo: {
      source: "pf",
      sourceUrl: "mapbox://thepublichealthco.pf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pf-subnational",
    sourceInfo: {
      source: "pf",
      sourceUrl: "mapbox://thepublichealthco.pf",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pg",
    sourceInfo: {
      source: "pg",
      sourceUrl: "mapbox://thepublichealthco.pg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pg-subnational",
    sourceInfo: {
      source: "pg",
      sourceUrl: "mapbox://thepublichealthco.pg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ph",
    sourceInfo: {
      source: "ph",
      sourceUrl: "mapbox://thepublichealthco.ph",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ph-subnational",
    sourceInfo: {
      source: "ph",
      sourceUrl: "mapbox://thepublichealthco.ph",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pk",
    sourceInfo: {
      source: "pk",
      sourceUrl: "mapbox://thepublichealthco.pk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pk-subnational",
    sourceInfo: {
      source: "pk",
      sourceUrl: "mapbox://thepublichealthco.pk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pl",
    sourceInfo: {
      source: "pl",
      sourceUrl: "mapbox://thepublichealthco.pl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pl-subnational",
    sourceInfo: {
      source: "pl",
      sourceUrl: "mapbox://thepublichealthco.pl",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pm",
    sourceInfo: {
      source: "pm",
      sourceUrl: "mapbox://thepublichealthco.pm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pm-subnational",
    sourceInfo: {
      source: "pm",
      sourceUrl: "mapbox://thepublichealthco.pm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pn",
    sourceInfo: {
      source: "pn",
      sourceUrl: "mapbox://thepublichealthco.pn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "pr",
    sourceInfo: {
      source: "pr",
      sourceUrl: "mapbox://thepublichealthco.pr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pr-subnational",
    sourceInfo: {
      source: "pr",
      sourceUrl: "mapbox://thepublichealthco.pr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ps",
    sourceInfo: {
      source: "ps",
      sourceUrl: "mapbox://thepublichealthco.ps",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ps-subnational",
    sourceInfo: {
      source: "ps",
      sourceUrl: "mapbox://thepublichealthco.ps",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pt",
    sourceInfo: {
      source: "pt",
      sourceUrl: "mapbox://thepublichealthco.pt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pt-subnational",
    sourceInfo: {
      source: "pt",
      sourceUrl: "mapbox://thepublichealthco.pt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "pw",
    sourceInfo: {
      source: "pw",
      sourceUrl: "mapbox://thepublichealthco.pw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "pw-subnational",
    sourceInfo: {
      source: "pw",
      sourceUrl: "mapbox://thepublichealthco.pw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "py",
    sourceInfo: {
      source: "py",
      sourceUrl: "mapbox://thepublichealthco.py",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "py-subnational",
    sourceInfo: {
      source: "py",
      sourceUrl: "mapbox://thepublichealthco.py",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "qa",
    sourceInfo: {
      source: "qa",
      sourceUrl: "mapbox://thepublichealthco.qa",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "qa-subnational",
    sourceInfo: {
      source: "qa",
      sourceUrl: "mapbox://thepublichealthco.qa",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "re",
    sourceInfo: {
      source: "re",
      sourceUrl: "mapbox://thepublichealthco.re",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "ro",
    sourceInfo: {
      source: "ro",
      sourceUrl: "mapbox://thepublichealthco.ro",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ro-subnational",
    sourceInfo: {
      source: "ro",
      sourceUrl: "mapbox://thepublichealthco.ro",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "rs",
    sourceInfo: {
      source: "rs",
      sourceUrl: "mapbox://thepublichealthco.rs",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "rs-subnational",
    sourceInfo: {
      source: "rs",
      sourceUrl: "mapbox://thepublichealthco.rs",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ru",
    sourceInfo: {
      source: "ru",
      sourceUrl: "mapbox://thepublichealthco.ru",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ru-subnational",
    sourceInfo: {
      source: "ru",
      sourceUrl: "mapbox://thepublichealthco.ru",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "rw",
    sourceInfo: {
      source: "rw",
      sourceUrl: "mapbox://thepublichealthco.rw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "rw-subnational",
    sourceInfo: {
      source: "rw",
      sourceUrl: "mapbox://thepublichealthco.rw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sa",
    sourceInfo: {
      source: "sa",
      sourceUrl: "mapbox://thepublichealthco.sa",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sa-subnational",
    sourceInfo: {
      source: "sa",
      sourceUrl: "mapbox://thepublichealthco.sa",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sb",
    sourceInfo: {
      source: "sb",
      sourceUrl: "mapbox://thepublichealthco.sb",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sb-subnational",
    sourceInfo: {
      source: "sb",
      sourceUrl: "mapbox://thepublichealthco.sb",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sc",
    sourceInfo: {
      source: "sc",
      sourceUrl: "mapbox://thepublichealthco.sc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sc-subnational",
    sourceInfo: {
      source: "sc",
      sourceUrl: "mapbox://thepublichealthco.sc",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sd",
    sourceInfo: {
      source: "sd",
      sourceUrl: "mapbox://thepublichealthco.sd",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sd-subnational",
    sourceInfo: {
      source: "sd",
      sourceUrl: "mapbox://thepublichealthco.sd",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "se",
    sourceInfo: {
      source: "se",
      sourceUrl: "mapbox://thepublichealthco.se",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "se-subnational",
    sourceInfo: {
      source: "se",
      sourceUrl: "mapbox://thepublichealthco.se",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sg",
    sourceInfo: {
      source: "sg",
      sourceUrl: "mapbox://thepublichealthco.sg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "sh",
    sourceInfo: {
      source: "sh",
      sourceUrl: "mapbox://thepublichealthco.sh",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sh-subnational",
    sourceInfo: {
      source: "sh",
      sourceUrl: "mapbox://thepublichealthco.sh",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "si",
    sourceInfo: {
      source: "si",
      sourceUrl: "mapbox://thepublichealthco.si",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 8,
    },
  },
  {
    id: "si-subnational",
    sourceInfo: {
      source: "si",
      sourceUrl: "mapbox://thepublichealthco.si",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 8,
      maxzoom: 22,
    },
  },
  {
    id: "sj",
    sourceInfo: {
      source: "sj",
      sourceUrl: "mapbox://thepublichealthco.sj",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sj-subnational",
    sourceInfo: {
      source: "sj",
      sourceUrl: "mapbox://thepublichealthco.sj",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sk",
    sourceInfo: {
      source: "sk",
      sourceUrl: "mapbox://thepublichealthco.sk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sk-subnational",
    sourceInfo: {
      source: "sk",
      sourceUrl: "mapbox://thepublichealthco.sk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sl",
    sourceInfo: {
      source: "sl",
      sourceUrl: "mapbox://thepublichealthco.sl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sl-subnational",
    sourceInfo: {
      source: "sl",
      sourceUrl: "mapbox://thepublichealthco.sl",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sm",
    sourceInfo: {
      source: "sm",
      sourceUrl: "mapbox://thepublichealthco.sm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sm-subnational",
    sourceInfo: {
      source: "sm",
      sourceUrl: "mapbox://thepublichealthco.sm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sn",
    sourceInfo: {
      source: "sn",
      sourceUrl: "mapbox://thepublichealthco.sn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sn-subnational",
    sourceInfo: {
      source: "sn",
      sourceUrl: "mapbox://thepublichealthco.sn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "so",
    sourceInfo: {
      source: "so",
      sourceUrl: "mapbox://thepublichealthco.so",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "so-subnational",
    sourceInfo: {
      source: "so",
      sourceUrl: "mapbox://thepublichealthco.so",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sr",
    sourceInfo: {
      source: "sr",
      sourceUrl: "mapbox://thepublichealthco.sr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sr-subnational",
    sourceInfo: {
      source: "sr",
      sourceUrl: "mapbox://thepublichealthco.sr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ss",
    sourceInfo: {
      source: "ss",
      sourceUrl: "mapbox://thepublichealthco.ss",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ss-subnational",
    sourceInfo: {
      source: "ss",
      sourceUrl: "mapbox://thepublichealthco.ss",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "st",
    sourceInfo: {
      source: "st",
      sourceUrl: "mapbox://thepublichealthco.st",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "st-subnational",
    sourceInfo: {
      source: "st",
      sourceUrl: "mapbox://thepublichealthco.st",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sv",
    sourceInfo: {
      source: "sv",
      sourceUrl: "mapbox://thepublichealthco.sv",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sv-subnational",
    sourceInfo: {
      source: "sv",
      sourceUrl: "mapbox://thepublichealthco.sv",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sx",
    sourceInfo: {
      source: "sx",
      sourceUrl: "mapbox://thepublichealthco.sx",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "sy",
    sourceInfo: {
      source: "sy",
      sourceUrl: "mapbox://thepublichealthco.sy",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sy-subnational",
    sourceInfo: {
      source: "sy",
      sourceUrl: "mapbox://thepublichealthco.sy",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "sz",
    sourceInfo: {
      source: "sz",
      sourceUrl: "mapbox://thepublichealthco.sz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "sz-subnational",
    sourceInfo: {
      source: "sz",
      sourceUrl: "mapbox://thepublichealthco.sz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tc",
    sourceInfo: {
      source: "tc",
      sourceUrl: "mapbox://thepublichealthco.tc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "td",
    sourceInfo: {
      source: "td",
      sourceUrl: "mapbox://thepublichealthco.td",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "td-subnational",
    sourceInfo: {
      source: "td",
      sourceUrl: "mapbox://thepublichealthco.td",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tf",
    sourceInfo: {
      source: "tf",
      sourceUrl: "mapbox://thepublichealthco.tf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tf-subnational",
    sourceInfo: {
      source: "tf",
      sourceUrl: "mapbox://thepublichealthco.tf",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tg",
    sourceInfo: {
      source: "tg",
      sourceUrl: "mapbox://thepublichealthco.tg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tg-subnational",
    sourceInfo: {
      source: "tg",
      sourceUrl: "mapbox://thepublichealthco.tg",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "th",
    sourceInfo: {
      source: "th",
      sourceUrl: "mapbox://thepublichealthco.th",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "th-subnational",
    sourceInfo: {
      source: "th",
      sourceUrl: "mapbox://thepublichealthco.th",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tj",
    sourceInfo: {
      source: "tj",
      sourceUrl: "mapbox://thepublichealthco.tj",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tj-subnational",
    sourceInfo: {
      source: "tj",
      sourceUrl: "mapbox://thepublichealthco.tj",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tk",
    sourceInfo: {
      source: "tk",
      sourceUrl: "mapbox://thepublichealthco.tk",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tk-subnational",
    sourceInfo: {
      source: "tk",
      sourceUrl: "mapbox://thepublichealthco.tk",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tl",
    sourceInfo: {
      source: "tl",
      sourceUrl: "mapbox://thepublichealthco.tl",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tl-subnational",
    sourceInfo: {
      source: "tl",
      sourceUrl: "mapbox://thepublichealthco.tl",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tm",
    sourceInfo: {
      source: "tm",
      sourceUrl: "mapbox://thepublichealthco.tm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tm-subnational",
    sourceInfo: {
      source: "tm",
      sourceUrl: "mapbox://thepublichealthco.tm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tn",
    sourceInfo: {
      source: "tn",
      sourceUrl: "mapbox://thepublichealthco.tn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tn-subnational",
    sourceInfo: {
      source: "tn",
      sourceUrl: "mapbox://thepublichealthco.tn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "to",
    sourceInfo: {
      source: "to",
      sourceUrl: "mapbox://thepublichealthco.to",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "to-subnational",
    sourceInfo: {
      source: "to",
      sourceUrl: "mapbox://thepublichealthco.to",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tr",
    sourceInfo: {
      source: "tr",
      sourceUrl: "mapbox://thepublichealthco.tr",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tr-subnational",
    sourceInfo: {
      source: "tr",
      sourceUrl: "mapbox://thepublichealthco.tr",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tt",
    sourceInfo: {
      source: "tt",
      sourceUrl: "mapbox://thepublichealthco.tt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tt-subnational",
    sourceInfo: {
      source: "tt",
      sourceUrl: "mapbox://thepublichealthco.tt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tv",
    sourceInfo: {
      source: "tv",
      sourceUrl: "mapbox://thepublichealthco.tv",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tv-subnational",
    sourceInfo: {
      source: "tv",
      sourceUrl: "mapbox://thepublichealthco.tv",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tw",
    sourceInfo: {
      source: "tw",
      sourceUrl: "mapbox://thepublichealthco.tw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tw-subnational",
    sourceInfo: {
      source: "tw",
      sourceUrl: "mapbox://thepublichealthco.tw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "tz",
    sourceInfo: {
      source: "tz",
      sourceUrl: "mapbox://thepublichealthco.tz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "tz-subnational",
    sourceInfo: {
      source: "tz",
      sourceUrl: "mapbox://thepublichealthco.tz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ua",
    sourceInfo: {
      source: "ua",
      sourceUrl: "mapbox://thepublichealthco.ua",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ua-subnational",
    sourceInfo: {
      source: "ua",
      sourceUrl: "mapbox://thepublichealthco.ua",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ug",
    sourceInfo: {
      source: "ug",
      sourceUrl: "mapbox://thepublichealthco.ug",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ug-subnational",
    sourceInfo: {
      source: "ug",
      sourceUrl: "mapbox://thepublichealthco.ug",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "um",
    sourceInfo: {
      source: "um",
      sourceUrl: "mapbox://thepublichealthco.um",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "um-subnational",
    sourceInfo: {
      source: "um",
      sourceUrl: "mapbox://thepublichealthco.um",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "us",
    sourceInfo: {
      source: "us",
      sourceUrl: "mapbox://thepublichealthco.us",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 4,
    },
  },
  {
    id: "us-subnational",
    sourceInfo: {
      source: "us",
      sourceUrl: "mapbox://thepublichealthco.us",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 4,
      maxzoom: 7,
    },
  },
  {
    id: "us-subdivision",
    sourceInfo: {
      source: "us",
      sourceUrl: "mapbox://thepublichealthco.us",
    },
    context: "subdivision",
    defaultZoomLevels: {
      minzoom: 7,
      maxzoom: 22,
    },
  },
  {
    id: "uy",
    sourceInfo: {
      source: "uy",
      sourceUrl: "mapbox://thepublichealthco.uy",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "uy-subnational",
    sourceInfo: {
      source: "uy",
      sourceUrl: "mapbox://thepublichealthco.uy",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "uz",
    sourceInfo: {
      source: "uz",
      sourceUrl: "mapbox://thepublichealthco.uz",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "uz-subnational",
    sourceInfo: {
      source: "uz",
      sourceUrl: "mapbox://thepublichealthco.uz",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "va",
    sourceInfo: {
      source: "va",
      sourceUrl: "mapbox://thepublichealthco.va",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "vc",
    sourceInfo: {
      source: "vc",
      sourceUrl: "mapbox://thepublichealthco.vc",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "vc-subnational",
    sourceInfo: {
      source: "vc",
      sourceUrl: "mapbox://thepublichealthco.vc",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ve",
    sourceInfo: {
      source: "ve",
      sourceUrl: "mapbox://thepublichealthco.ve",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ve-subnational",
    sourceInfo: {
      source: "ve",
      sourceUrl: "mapbox://thepublichealthco.ve",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "vg",
    sourceInfo: {
      source: "vg",
      sourceUrl: "mapbox://thepublichealthco.vg",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 22,
    },
  },
  {
    id: "vi",
    sourceInfo: {
      source: "vi",
      sourceUrl: "mapbox://thepublichealthco.vi",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "vi-subnational",
    sourceInfo: {
      source: "vi",
      sourceUrl: "mapbox://thepublichealthco.vi",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "vn",
    sourceInfo: {
      source: "vn",
      sourceUrl: "mapbox://thepublichealthco.vn",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "vn-subnational",
    sourceInfo: {
      source: "vn",
      sourceUrl: "mapbox://thepublichealthco.vn",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "vu",
    sourceInfo: {
      source: "vu",
      sourceUrl: "mapbox://thepublichealthco.vu",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "vu-subnational",
    sourceInfo: {
      source: "vu",
      sourceUrl: "mapbox://thepublichealthco.vu",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "wf",
    sourceInfo: {
      source: "wf",
      sourceUrl: "mapbox://thepublichealthco.wf",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "wf-subnational",
    sourceInfo: {
      source: "wf",
      sourceUrl: "mapbox://thepublichealthco.wf",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ws",
    sourceInfo: {
      source: "ws",
      sourceUrl: "mapbox://thepublichealthco.ws",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ws-subnational",
    sourceInfo: {
      source: "ws",
      sourceUrl: "mapbox://thepublichealthco.ws",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "ye",
    sourceInfo: {
      source: "ye",
      sourceUrl: "mapbox://thepublichealthco.ye",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "ye-subnational",
    sourceInfo: {
      source: "ye",
      sourceUrl: "mapbox://thepublichealthco.ye",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "yt",
    sourceInfo: {
      source: "yt",
      sourceUrl: "mapbox://thepublichealthco.yt",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 10,
    },
  },
  {
    id: "yt-subnational",
    sourceInfo: {
      source: "yt",
      sourceUrl: "mapbox://thepublichealthco.yt",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 10,
      maxzoom: 22,
    },
  },
  {
    id: "za",
    sourceInfo: {
      source: "za",
      sourceUrl: "mapbox://thepublichealthco.za",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "za-subnational",
    sourceInfo: {
      source: "za",
      sourceUrl: "mapbox://thepublichealthco.za",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "zm",
    sourceInfo: {
      source: "zm",
      sourceUrl: "mapbox://thepublichealthco.zm",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "zm-subnational",
    sourceInfo: {
      source: "zm",
      sourceUrl: "mapbox://thepublichealthco.zm",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
  {
    id: "zw",
    sourceInfo: {
      source: "zw",
      sourceUrl: "mapbox://thepublichealthco.zw",
    },
    context: "country",
    defaultZoomLevels: {
      minzoom: 0,
      maxzoom: 5,
    },
  },
  {
    id: "zw-subnational",
    sourceInfo: {
      source: "zw",
      sourceUrl: "mapbox://thepublichealthco.zw",
    },
    context: "subnational",
    defaultZoomLevels: {
      minzoom: 5,
      maxzoom: 22,
    },
  },
]
