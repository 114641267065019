import { styled, Typography } from "@mui/material"
import { extraColors } from "../utils/theme"

export const Content = styled("div")(({ theme }) => ({
  padding: "20px 40px 45px 35px",
  margin: "0 auto",
  borderRadius: 12,
  background: "white",

  [theme.breakpoints.down("sm")]: {
    padding: 10,
    borderRadius: 0,
  },
}))

export const Title = styled(Typography)({
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "36px",
  letterSpacing: "0.2px",
  color: extraColors.medium,
  margin: "10px 0 30px 0",
}) as typeof Typography

export const TopContentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: 875,
  margin: "20px auto",
})

export const BodyContent = styled("div")(({ theme }) => ({
  display: "grid",
  padding: "20px 60px",
  maxWidth: 800,
  [theme.breakpoints.down("sm")]: {
    padding: 5,
  },
  justifyContent: "center",
}))
