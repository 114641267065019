import { styled } from "@mui/material"
import { extraColors } from "../../../utils/theme"

export const CardContainer = styled("div")<{ isMapCard?: boolean }>(
  ({ isMapCard }) => ({
    display: "flex",
    border: `1px solid ${isMapCard ? extraColors.white : extraColors.disabled}`,
    borderRadius: 6,
    backgroundColor: extraColors.white,
    boxShadow: "inset -1px -1px 0px #F1F1F5",
    overflow: "hidden",
    height: "100%",
    maxWidth: 376,
  })
)

export const LeftBorder = styled("div")<{ color: string }>(({ color }) => ({
  background: color,
  width: "24px",
}))

export const ColumnContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: 8,
})
