import { TextField } from "@mui/material"
import type { AssetGroup } from "@phc-health/connect-query"
import {
  Asset,
  AssetNotificationAlertLevel,
  AssetNotificationType,
  AssetType,
  Disease_Type,
  Geotag,
  IndustryType,
} from "@phc-health/connect-query"

import type { GeocoderResult } from "@phc/common"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import {
  convertGeocoderResultToLocation,
  getNonGlobalAssets,
  isAdminBoundaryFromPlaceType,
} from "../../../utils/helpers/assetHelper"
import { getCentroidFromGeocoder } from "../../../utils/helpers/locationCenterHelper"
import { AssetGroupAutocomplete } from "./AssetGroupAutocomplete"
import { AssetTypeAutocomplete } from "./AssetTypeAutocomplete"
import { AssetGeoLocationSearchInput } from "./GeoLocationSearchInput"
import { ThreatRadiusSelect } from "./ThreatRadiusSelect"
import { useCreateAsset } from "./hooks/useCreateAsset"
import { useUpdateAsset } from "./hooks/useUpdateAsset"

import { useListAssets } from "../hooks/useAssetService"

import { LocationNotificationSettings } from "./LocationNotificationSettings"

import { getLocationId } from "../../../utils/helpers"
import { trackEvent } from "../../../utils/mixpanel"
import {
  alertSettingLevel,
  assetsHaveEnabledSettingOfType,
  buildUpdatedAssetWithSettings,
  getDBEnabledForecastRange,
  getGlobalEnabledSettingOfType,
  riskIncreaseSelectedThreats,
  SettingGranularity,
} from "../../Settings/notifications/contexts/NotificationContext"
import { IndustryAutocomplete } from "./IndustryAutocomplete"
import { AssetDialogControls, DialogHeader, DialogStyled } from "./Shared"

interface ManageAssetDialogProps {
  handleModalClose: () => void
  asset?: Asset
  assetGroups?: AssetGroup[]
  isLocationSelector: boolean
  addToSelectedAssets: (asset: Asset) => void
}

export const ManageAssetDialog: React.FC<ManageAssetDialogProps> = ({
  handleModalClose,
  asset,
  assetGroups,
  isLocationSelector,
  addToSelectedAssets,
}) => {
  const isUpdate = !!asset
  const { data: assets, isLoading: assetsLoading } = useListAssets({
    includeGlobal: true,
  })
  const nonGlobalAssets = useMemo(() => {
    return getNonGlobalAssets(assets.assets)
  }, [assets])

  const globalExpertInsightsEnabled =
    !!getGlobalEnabledSettingOfType(
      AssetNotificationType.ALERT,
      assets.assets
    ) ||
    !!getGlobalEnabledSettingOfType(
      AssetNotificationType.ANALYSIS,
      assets.assets
    )

  const [selectedGroups, setSelectedGroups] = useState<
    AssetGroup[] | undefined
  >(asset?.assetGroups)
  const [selectedAssetType, setSelectedAssetType] = useState(
    asset?.assetTypes[0] || AssetType.ASSET_TYPE_UNSPECIFIED
  )

  const { mutateAsync: create, isPending: createIsLoading } = useCreateAsset()
  const { mutateAsync: update, isPending: updateIsLoading } = useUpdateAsset()
  const [locationId, setLocationId] = useState(getLocationId(asset))
  const [name, setName] = useState(asset?.name || "")

  const [threatRadius, setThreatRadius] = useState(asset?.threatRadius)
  const [selectedIndustryType, setSelectedIndustryType] = useState(
    asset?.industry || IndustryType.UNSPECIFIED
  )
  const [groups, setGroups] = useState<AssetGroup[] | undefined>(assetGroups)
  const [location, setLocation] = useState<GeocoderResult | undefined>()
  const [isAdminBoundary, setIsAdminBoundary] = useState(
    isAdminBoundaryFromPlaceType(asset?.baseEvent?.mapboxLocation?.placeType)
  )

  // If updating an asset, look at this asset's settings
  // If adding new, look at all existing settings
  const dbRiskIncreaseEnabled = useMemo(() => {
    return assetsHaveEnabledSettingOfType(
      AssetNotificationType.RISK_INCREASE,
      asset ? [asset] : nonGlobalAssets
    )
  }, [asset, nonGlobalAssets])

  const dbExpertInsightsEnabled = useMemo(() => {
    return (
      assetsHaveEnabledSettingOfType(
        AssetNotificationType.ALERT,
        asset ? [asset] : nonGlobalAssets
      ) ||
      assetsHaveEnabledSettingOfType(
        AssetNotificationType.ANALYSIS,
        asset ? [asset] : nonGlobalAssets
      )
    )
  }, [asset, nonGlobalAssets])

  const dbAbsenteeismEnabled = useMemo(() => {
    return (
      assetsHaveEnabledSettingOfType(
        AssetNotificationType.HISTORICAL_AVG_ABSENTEEISM_INCREASE,
        asset ? [asset] : nonGlobalAssets
      ) ||
      assetsHaveEnabledSettingOfType(
        AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE,
        asset ? [asset] : nonGlobalAssets
      )
    )
  }, [asset, nonGlobalAssets])

  const dbForecastRange = useMemo(() => {
    return getDBEnabledForecastRange(nonGlobalAssets)
  }, [nonGlobalAssets])

  const defaultSelectedThreats = useMemo(() => {
    // If risk increase is already enabled for any assets, this will default to current
    // selected threats
    return dbRiskIncreaseEnabled
      ? Array.from(riskIncreaseSelectedThreats(nonGlobalAssets))
      : [Disease_Type.RESP]
  }, [dbRiskIncreaseEnabled, nonGlobalAssets])

  const dbAlertSettingLevel = useMemo(() => {
    return alertSettingLevel(nonGlobalAssets)
  }, [nonGlobalAssets])

  const [riskIncreaseEnabled, setRiskIncreaseEnabled] = useState(
    dbRiskIncreaseEnabled
  )

  const [absenteeismEnabled, setAbsenteeismEnabled] =
    useState(dbAbsenteeismEnabled)

  const [expertInsightsEnabled, setExpertInsightsEnabled] = useState(
    dbExpertInsightsEnabled || globalExpertInsightsEnabled
  )

  const saveAsset = useCallback(async () => {
    const existingGeotag = asset?.baseEvent?.geotags[0]
    const newGeotag = new Geotag({
      ...existingGeotag,
      locationId,
      // if location hasn't changed, use the original location
      mapboxLocation: location
        ? convertGeocoderResultToLocation(location)
        : existingGeotag?.mapboxLocation,
      wktCentroid: getCentroidFromGeocoder(location),
    })
    const newAsset = new Asset({
      ...asset,
      name,
      industry: selectedIndustryType,
      threatRadius: threatRadius,
      assetGroups: selectedGroups,
      assetTypes: [selectedAssetType],
      baseEvent: {
        ...asset?.baseEvent,
        geotags: [newGeotag],
      },
    })

    // Keep SettingGranularity as GLOBAL if global is currently selected for expert insights.
    const notificationPayload: Parameters<
      typeof buildUpdatedAssetWithSettings
    >[0] = {
      assetName: asset?.name || "",
      selectedThreats: defaultSelectedThreats,
      isSelectedForRiskIncrease: riskIncreaseEnabled,
      isSelectedForExpertInsights: expertInsightsEnabled,
      assetId: newAsset.assetId,
      expertInsightsSettingGranularity: globalExpertInsightsEnabled
        ? SettingGranularity.GLOBAL
        : SettingGranularity.ASSET,
      alertsEnabled: expertInsightsEnabled,
      alertLevel: dbAlertSettingLevel || AssetNotificationAlertLevel.ADVISORY,
      analysisEnabled: expertInsightsEnabled,
      riskIncreaseEnabled,
      isSelectedForAbsenteeism: dbAbsenteeismEnabled,
      historicalAbsenteeismEnabled: dbAbsenteeismEnabled,
      forecastedAbsenteeismEnabled: dbAbsenteeismEnabled,
      forecastRange: dbForecastRange,
      globallyTaggedEnabled: false,
    }
    newAsset.notificationSettings =
      buildUpdatedAssetWithSettings(notificationPayload)

    if (isUpdate) {
      await update({ asset: newAsset })
    } else {
      const resultAsset = await create({ asset: newAsset })

      if (resultAsset.asset && isLocationSelector) {
        addToSelectedAssets(resultAsset.asset)
      }
    }
    trackEvent("MANAGE_LOCATION", {
      action: isUpdate ? "edit" : "add",
      locationId: locationId,
      industry: IndustryType[selectedIndustryType],
      alertLevel: AssetNotificationAlertLevel[notificationPayload.alertLevel],
      analysisEnabled: notificationPayload.analysisEnabled,
      alertsEnabled: notificationPayload.alertsEnabled,
      expertInsightsSettingGranularity:
        notificationPayload.expertInsightsSettingGranularity,
      forecastedAbsenteeismEnabled:
        notificationPayload.forecastedAbsenteeismEnabled,
      historicalAbsenteeismEnabled:
        notificationPayload.historicalAbsenteeismEnabled,
      riskIncreaseEnabled: notificationPayload.riskIncreaseEnabled,
      selectedThreats: notificationPayload.selectedThreats.map(
        threat => Disease_Type[threat]
      ),
    })
  }, [
    addToSelectedAssets,
    asset,
    create,
    dbAbsenteeismEnabled,
    dbAlertSettingLevel,
    dbForecastRange,
    defaultSelectedThreats,
    expertInsightsEnabled,
    globalExpertInsightsEnabled,
    isLocationSelector,
    isUpdate,
    location,
    locationId,
    name,
    riskIncreaseEnabled,
    selectedAssetType,
    selectedGroups,
    selectedIndustryType,
    threatRadius,
    update,
  ])

  const mutationInProgress = createIsLoading || updateIsLoading

  if (assetsLoading) return null

  return (
    <DialogStyled
      open
      onClose={handleModalClose}
      aria-labelledby={
        isUpdate ? "asset-modal-title" : "asset-modal-description"
      }
      sx={{
        ".MuiPaper-root": {
          display: "grid",
          gap: "20px",
        },
      }}
    >
      <DialogHeader
        title={isUpdate ? "Edit Location" : "Add Location"}
        desc={
          !isUpdate
            ? "Add locations to track their risk levels and stay up to date on threats concerning nearby areas."
            : undefined
        }
      />

      <AssetGeoLocationSearchInput
        onChangeGeo={setLocation}
        setLocationId={setLocationId}
        placeholder={asset?.baseEvent?.mapboxLocation?.placeName}
        setIsAdminBoundary={setIsAdminBoundary}
        setName={setName}
      />

      <TextField
        id="outlined"
        label="Location Nickname"
        value={name}
        onChange={event => {
          setName(event.target.value)
        }}
      />

      <AssetGroupAutocomplete
        groups={groups || []}
        setGroups={setGroups}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
      />

      <IndustryAutocomplete
        selectedIndustry={selectedIndustryType}
        setSelectedIndustry={setSelectedIndustryType}
        isDisabled={isAdminBoundary}
      />

      <AssetTypeAutocomplete
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        isDisabled={isAdminBoundary}
      />

      <ThreatRadiusSelect
        threatRadius={threatRadius}
        setThreatRadius={setThreatRadius}
        isDisabled={isAdminBoundary}
      />

      {!isLocationSelector && (
        <LocationNotificationSettings
          riskIncreaseEnabled={riskIncreaseEnabled}
          setRiskIncreaseEnabled={setRiskIncreaseEnabled}
          absenteeismEnabled={absenteeismEnabled}
          setAbsenteeismEnabled={setAbsenteeismEnabled}
          expertInsightsEnabled={expertInsightsEnabled}
          setExpertInsightsEnabled={setExpertInsightsEnabled}
          globalExpertInsightsEnabled={globalExpertInsightsEnabled}
        />
      )}

      <AssetDialogControls
        handleModalClose={handleModalClose}
        isDisabled={
          mutationInProgress ||
          (!location && !asset?.baseEvent?.mapboxLocation?.placeType)
        }
        action={saveAsset}
        text={isUpdate ? "Save Changes" : "Add Location"}
      />
    </DialogStyled>
  )
}
