import type { SVGProps } from "react"
const SvgCheckboxUnchecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    viewBox="0 0 19 18"
    {...props}
  >
    <rect width={17} height={17} x={0.912} y={0.5} fill="#fff" rx={1.5} />
    <rect
      width={17}
      height={17}
      x={0.912}
      y={0.5}
      stroke="rgba(24, 52, 94, 1)"
      rx={1.5}
    />
  </svg>
)
export default SvgCheckboxUnchecked
