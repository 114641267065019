import type { SVGProps } from "react"

const SvgAssets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11.25C10.6569 11.25 12 9.90685 12 8.25C12 6.59315 10.6569 5.25 9 5.25C7.34315 5.25 6 6.59315 6 8.25C6 9.90685 7.34315 11.25 9 11.25ZM9 9.75C8.17157 9.75 7.5 9.07843 7.5 8.25C7.5 7.42157 8.17157 6.75 9 6.75C9.82843 6.75 10.5 7.42157 10.5 8.25C10.5 9.07843 9.82843 9.75 9 9.75Z"
      fill={props.fill || "#0E2348"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 8.15254C2.25 4.47567 5.2748 1.5 9 1.5C12.7252 1.5 15.75 4.47567 15.75 8.15254C15.75 9.96874 15.0071 11.6698 13.7213 12.907L9.526 17.0346C9.23414 17.3218 8.76591 17.3218 8.47403 17.0347L4.27301 12.9018C2.98833 11.6594 2.25 9.96313 2.25 8.15254ZM12.6752 11.8319C13.6767 10.8682 14.25 9.55567 14.25 8.15254C14.25 5.30965 11.9022 3 9 3C6.09778 3 3.75 5.30965 3.75 8.15254C3.75 9.55136 4.31973 10.8603 5.32037 11.8281L8.99997 15.4479L12.6752 11.8319Z"
      fill={props.fill || "#0E2348"}
    />
  </svg>
)

export default SvgAssets
