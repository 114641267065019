import LayersIcon from "@mui/icons-material/Layers"
import { Box, Drawer, ToggleButton, styled, useMediaQuery } from "@mui/material"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import * as React from "react"
import { useLocation } from "react-router-dom"
import { ROUTES } from "../../utils/constants"
import { BORDER_RADIUS, extraColors, theme } from "../../utils/theme"
import { MobileDrawerHeader } from "../Map/Drawers/DrawerHeader"
import { DrawerContainer } from "../Map/Drawers/Locations/Locations"
import { ControlPanel } from "./ControlPanel"

const MobileLayerFilterDrawer = styled(Drawer)(() => ({
  position: "relative",
  overflow: "auto",
  padding: 0,
  gap: "12px",
  "& .MuiDrawer-paper": {
    border: "none",
    backgroundColor: "transparent",
    minWidth: "max-content",
    position: "relative",
    gridAutoFlow: "row",
    overflowX: "auto",
    maxHeight: "fit-content",
    padding: 0,
  },
}))

const StyledFilterButton = styled(ToggleButton)(() => ({
  position: "relative",
  top: "54px",
  left: "4px",
  height: "38px",
  width: "38px",
  backgroundColor: extraColors.white,
  border: `1px solid ${extraColors.navy}`,
  borderRadius: BORDER_RADIUS,
  "&:hover": {
    background: extraColors.white,
  },
  "&.Mui-selected": {
    background: extraColors.navy,
    borderColor: extraColors.white,
    "&:hover": {
      background: extraColors.navy,
    },
  },
}))

const StyledPopper = styled(Popper)<{ ishomemap: string }>(({ ishomemap }) => ({
  height: ishomemap === "true" ? "320px" : undefined,
  overflowY: ishomemap === "true" ? "scroll" : undefined,
  zIndex: 3,
}))

export const LayerFilterButton = () => {
  const location = useLocation()
  const isHomeMap = location.pathname === ROUTES.HOME
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current && !open && anchorRef.current) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  React.useEffect(() => {
    setOpen(!isMobile && !isHomeMap)
  }, [isHomeMap, isMobile])

  return (
    <>
      {isMobile ? (
        <DrawerContainer>
          <MobileDrawerHeader
            toggleDrawer={() => setOpen(!open)}
            open={!!open}
            title="Map Layers"
            hideManage={true}
          />
          <MobileLayerFilterDrawer
            open={open}
            variant="persistent"
            anchor="top"
            transitionDuration={0}
          >
            {open && <ControlPanel />}
          </MobileLayerFilterDrawer>
        </DrawerContainer>
      ) : (
        <Box paddingLeft={"6px"} paddingRight={1}>
          <StyledFilterButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onChange={handleToggle}
            selected={open}
            value="check"
          >
            <LayersIcon
              sx={{
                fill: !open ? extraColors.navy : extraColors.white,
              }}
            />
          </StyledFilterButton>
          <StyledPopper
            ishomemap={isHomeMap.toString()}
            anchorEl={anchorRef.current}
            open={open}
            role={undefined}
            placement="left-start"
            disablePortal
          >
            <Paper sx={{ marginLeft: "6px" }}>
              <ControlPanel />
            </Paper>
          </StyledPopper>
        </Box>
      )}
    </>
  )
}
