import { Box, Button, Typography, styled } from "@mui/material"
import { PortableText } from "@portabletext/react"

import { useMemo } from "react"

import {
  filterBlockArray,
  getFootnotes,
  type AnalysisWithReferences,
  type GuidanceWithReferences,
} from "@phc/common"
import type { Dayjs } from "dayjs"
import { generatePath, useNavigate } from "react-router-dom"
import { Disclaimer } from "../../../pages/SinglePost/Disclaimer"
import { Footnotes } from "../../../pages/SinglePost/Footnotes"
import { getPublishedAtDate } from "../../../pages/SinglePost/PostDates"
import { useDynamicComponents } from "../../../pages/SinglePost/hooks/useDynamicComponents"
import { ROUTES } from "../../../utils/constants"
import { dayjsUTC } from "../../../utils/helpers"
import { extraColors } from "../../../utils/theme"
import { CategoryTag } from "../../LocationDetails/LocationRisk/CategoryTag"
import { SectionContainer } from "../../LocationDetails/LocationRisk/Shared"
import { useCapServiceContext } from "../capServiceContext"
import { TagRow } from "./Shared"

/**
 * STYLES
 */

const BodyContentStyled = styled("div")(({ theme }) => ({
  ...theme.typography.small1,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.body2,
  },
}))

const SectionContainerStyled = styled(SectionContainer)({
  marginBottom: 12,
  border: "none",
  marginTop: 0,
})

const ButtonStyled = styled(Button)({
  width: "fit-content",
  alignSelf: "center",
  marginTop: "26px",
})

interface ExpandedContentProps {
  postContent: AnalysisWithReferences | GuidanceWithReferences
  setIsExpanded: (val: boolean) => void
}

/**
 * COMPONENTS
 */

export const ExpandedContent: React.FC<ExpandedContentProps> = ({
  postContent,
  setIsExpanded,
}) => {
  const { sanityDataset } = useCapServiceContext()

  const { footnotesOrder } = useMemo(
    () => getFootnotes(filterBlockArray(postContent.body ?? [])),
    [postContent.body]
  )

  const dynamicComponents = useDynamicComponents(footnotesOrder, sanityDataset)

  const publishedAtTimestamp = getPublishedAtDate({
    postPublishedAt: postContent.publishedAt,
    postUpdatedAt: postContent.updatedAt,
    postUpdatedAtAlternate: postContent._updatedAt,
  })

  return (
    <SectionContainerStyled>
      <BodyContentStyled>
        <ExpandedContentHeader
          postContent={postContent}
          publishedAtTimestamp={publishedAtTimestamp}
        />
        <ExpandedContentTags postContent={postContent} />
        <PortableText
          value={postContent.body ?? []}
          components={dynamicComponents}
        />
        <Footnotes footnotesOrder={footnotesOrder} />
        {/* Workaround for not double publishing disclaimer and not being able to update previously published articles
          anything after 9/22/2022, 1pm PST will need the disclaimer below
        */}
        {publishedAtTimestamp.toDate().getTime() > 1663846357394 ? (
          <Disclaimer />
        ) : null}
      </BodyContentStyled>
      <ExpandedContentControls
        postContent={postContent}
        setIsExpanded={setIsExpanded}
      />
    </SectionContainerStyled>
  )
}

const ExpandedContentTags: React.FC<{
  postContent: AnalysisWithReferences | GuidanceWithReferences
}> = ({ postContent }) => {
  const tags = useMemo(() => {
    return postContent.categories?.flatMap(item => item.title?.toString() ?? [])
  }, [postContent.categories])

  return (
    <TagRow>
      {tags?.map(tag => <CategoryTag key={tag} category={tag} />)}
    </TagRow>
  )
}

const ExpandedContentHeader: React.FC<{
  postContent: AnalysisWithReferences | GuidanceWithReferences
  publishedAtTimestamp: Dayjs
}> = ({ postContent, publishedAtTimestamp }) => {
  const isDraft = postContent._id.startsWith("drafts.")
  const title = isDraft
    ? `${postContent.title ?? ""} (Draft)`
    : (postContent.title ?? "")

  const formattedDate = dayjsUTC(publishedAtTimestamp).local().format("MM/D/YY")

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h3Bold">{title}</Typography>
      <Typography
        variant="small1"
        sx={{ color: extraColors.hint, marginLeft: "12px" }}
      >
        {formattedDate}
      </Typography>
    </Box>
  )
}

const ExpandedContentControls: React.FC<ExpandedContentProps> = ({
  postContent,
}) => {
  const navigate = useNavigate()

  return (
    <ButtonStyled
      variant="outlined"
      color="secondary"
      onClick={() => {
        navigate(getContentLink(postContent))
      }}
    >
      View Page
    </ButtonStyled>
  )
}

function getContentLink(
  postContent: AnalysisWithReferences | GuidanceWithReferences
) {
  const basePath =
    postContent._type === "guidance"
      ? ROUTES.GUIDANCE_POST
      : ROUTES.ANALYSIS_POST

  return generatePath(basePath, {
    slug: postContent.slug?.current ?? "",
  })
}
