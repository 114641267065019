import { styled } from "@mui/material"
import { extraColors } from "../../../../utils/theme"

export const SummarySelectionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: extraColors.subtle,
  padding: 16,
  borderRadius: 6,
  marginLeft: 30,
  marginTop: 6,
})

export const OptionSection = styled("div")({
  display: "flex",
  flexDirection: "column",
})
