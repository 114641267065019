import { CircularProgress, Dialog, Typography, styled } from "@mui/material"

import type React from "react"

import {
  AssetType,
  IndustryType,
  type Asset,
  type AssetGroup,
} from "@phc-health/connect-query"
import { useMemo, useState } from "react"
import { isAdminBoundaryFromPlaceType } from "../../../utils/helpers/assetHelper"
import { extraColors } from "../../../utils/theme"
import Button from "../../Shared/Button"
import { useListAssetGroups } from "../hooks/useAssetService"
import { AssetGroupAutocomplete } from "./AssetGroupAutocomplete"
import { AssetTypeAutocomplete } from "./AssetTypeAutocomplete"
import { useBulkUpdateAssets } from "./hooks/useBulkUpdateAssets"
import { IndustryAutocomplete } from "./IndustryAutocomplete"
import { RemoveAssetDialog } from "./RemoveAssetDialog"
import { ThreatRadiusSelect } from "./ThreatRadiusSelect"

export const MIXED_TEXT = "[MIXED]"

const DialogStyled = styled(Dialog)(({ theme }) => ({
  ".MuiPaper-root": {
    height: "fit-content",
    maxHeight: "unset",
    maxWidth: 350,
    [theme.breakpoints.up("sm")]: {
      minWidth: 560,
    },
  },
  justifySelf: "center",
}))

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
})

const InputContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 20,
})

const DeleteButton = styled(Button)({
  alignSelf: "flex-start",
  color: extraColors.status.redMiddle,
  border: "none",
  padding: 0,
  marginTop: "-12px",
  "&:hover": {
    border: "none",
    background: "none",
  },
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    border: "none",
  },
  "&:active": {
    border: "none",
  },
})

interface EditAssetsDialogProps {
  assets: Asset[]
  onClose: () => void
}

export const EditAssetsDialog: React.FC<EditAssetsDialogProps> = ({
  assets,
  onClose,
}) => {
  const { data: assetGroups } = useListAssetGroups()
  const {
    saveAssets,
    assetsByGroupId,
    ubiquitousAssetGroups,
    selectedGroups,
    setSelectedGroups,
    selectedIndustryType,
    setSelectedIndustryType,
    selectedAssetType,
    setSelectedAssetType,
    selectedThreatRadius,
    setSelectedThreatRadius,
    updateIsPending,
  } = useBulkUpdateAssets(assets)

  const assetIds = useMemo(() => assets.map(asset => asset.assetId), [assets])

  const industries = useMemo(() => {
    const industrySet = new Set<IndustryType>()
    assets.forEach(asset => industrySet.add(asset.industry))
    return industrySet
  }, [assets])

  const ubiquitousIndustry = useMemo(() => {
    const sharedIndustry = Array.from(industries)[0]

    return sharedIndustry &&
      industries.size === 1 &&
      assets.every(asset => asset.industry === sharedIndustry)
      ? sharedIndustry
      : undefined
  }, [assets, industries])

  const assetTypes = useMemo(() => {
    const assetTypeSet = new Set<AssetType>()
    assets.forEach(asset => {
      if (asset.assetTypes[0]) {
        assetTypeSet.add(asset.assetTypes[0])
      }
    })
    return assetTypeSet
  }, [assets])

  const ubiquitousAssetType = useMemo(() => {
    const sharedType = Array.from(assetTypes)[0]

    return sharedType &&
      assetTypes.size === 1 &&
      assets.every(asset => asset.assetTypes[0] === sharedType)
      ? sharedType
      : undefined
  }, [assetTypes, assets])

  const threatRadii = useMemo(() => {
    const threatRadiusSet = new Set<number>()
    assets.forEach(asset => {
      threatRadiusSet.add(asset.threatRadius)
    })
    return threatRadiusSet
  }, [assets])

  const ubiquitousThreatRadius = useMemo(() => {
    const sharedRadius = Array.from(threatRadii)[0]

    return sharedRadius &&
      threatRadii.size === 1 &&
      assets.every(asset => asset.threatRadius === sharedRadius)
      ? sharedRadius
      : undefined
  }, [assets, threatRadii])

  const hasMixedGroups =
    assetsByGroupId.size > 0 &&
    ubiquitousAssetGroups.length !== assetsByGroupId.size

  const [groups, setGroups] = useState<AssetGroup[] | undefined>(
    assetGroups?.assetGroups || []
  )

  const allAssetsAreAdminBoundaries = useMemo(() => {
    return assets.every(asset =>
      isAdminBoundaryFromPlaceType(asset.baseEvent?.mapboxLocation?.placeType)
    )
  }, [assets])

  const isMultiple = assets.length > 1
  return (
    <DialogStyled open>
      <Typography variant="h3Bold" style={{ color: extraColors.medium }}>
        Edit location{isMultiple && "s"}
      </Typography>
      <Typography
        variant="body1"
        style={{ marginTop: "6px", marginBottom: "26px" }}
      >
        {`You are editing ${isMultiple ? ` ${assets.length.toLocaleString()} locations` : "this location"}.`}
      </Typography>
      <InputContainer>
        <AssetGroupAutocomplete
          groups={groups || []}
          setGroups={setGroups}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          showMixed={hasMixedGroups}
        />

        <IndustryAutocomplete
          selectedIndustry={
            selectedIndustryType !== undefined
              ? selectedIndustryType
              : ubiquitousIndustry || IndustryType.UNSPECIFIED
          }
          setSelectedIndustry={setSelectedIndustryType}
          showMixed={!ubiquitousIndustry && industries.size > 1}
          isDisabled={allAssetsAreAdminBoundaries}
        />

        <AssetTypeAutocomplete
          selectedAssetType={
            selectedAssetType !== undefined
              ? selectedAssetType
              : ubiquitousAssetType || AssetType.ASSET_TYPE_UNSPECIFIED
          }
          setSelectedAssetType={setSelectedAssetType}
          showMixed={!ubiquitousAssetType && assetTypes.size > 0}
          isDisabled={allAssetsAreAdminBoundaries}
        />

        <ThreatRadiusSelect
          threatRadius={
            selectedThreatRadius !== undefined
              ? selectedThreatRadius
              : ubiquitousThreatRadius
          }
          setThreatRadius={setSelectedThreatRadius}
          isDisabled={allAssetsAreAdminBoundaries}
          showMixed={!ubiquitousThreatRadius && threatRadii.size > 1}
        />

        <EditActions
          assetIds={assetIds}
          onClose={onClose}
          updateIsPending={updateIsPending}
          saveAssets={saveAssets}
        />
      </InputContainer>
    </DialogStyled>
  )
}

const EditActions: React.FC<{
  assetIds: string[]
  onClose: () => void
  updateIsPending: boolean
  saveAssets: () => void
}> = ({ assetIds, onClose, updateIsPending, saveAssets }) => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  return (
    <>
      <DeleteButton
        variant="destructive"
        onClick={() => setShowRemoveDialog(true)}
      >
        {`Delete Location${assetIds.length > 1 ? "s" : ""}`}
      </DeleteButton>

      <ButtonContainer>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={updateIsPending}
          onClick={() => {
            saveAssets()
            onClose()
          }}
        >
          {updateIsPending ? (
            <CircularProgress size={20} color="info" />
          ) : (
            "Save Changes"
          )}
        </Button>
      </ButtonContainer>

      {showRemoveDialog && (
        <RemoveAssetDialog
          clearRemoveAssetIds={() => setShowRemoveDialog(false)}
          assetIds={assetIds}
          onClose={onClose}
        />
      )}
    </>
  )
}
