import type { ModalProps } from "@mui/material"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import type React from "react"
import { extraColors } from "../../utils/theme"
import Button from "./Button"

export const Modal: React.FC<
  {
    open: boolean
    onClose?: () => void
    onConfirm?: () => void
    title: string
    cancelLabel?: string
    confirmLabel?: string
    disableBtns?: boolean
    hideCancelBtn?: boolean
  } & ModalProps
> = ({
  open,
  onClose,
  onConfirm,
  title,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  children = "",
  disableBtns = false,
  hideCancelBtn = false,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle
        sx={{
          justifyContent: "start",
          paddingLeft: "40px",
          background: extraColors.dark,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <div>{children}</div>
      </DialogContent>
      <DialogActions>
        {!hideCancelBtn && (
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            style={{ width: 101 }}
            data-testid="modal-cancel-button"
            disabled={disableBtns}
          >
            {cancelLabel}
          </Button>
        )}
        {onConfirm && (
          <Button
            onClick={onConfirm}
            variant="contained"
            color="primary"
            style={{ width: "auto", marginLeft: 20 }}
            data-testid="modal-confirm-button"
            disabled={disableBtns}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
