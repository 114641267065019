import { mapboxSourceLayers } from "./mapboxSourceLayers"
import { getMapboxSourceLayers } from "./scripts/mapboxTilesetConfig"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sourceLayerContext = ["country", "subnational", "subdivision"] as const

export type SourceLayerContext = (typeof sourceLayerContext)[number]

export interface SourceLayer {
  id: string
  sourceInfo: {
    source: string
    sourceUrl: string
  }

  // specific for mapbox contexts in geocoding queries
  context: SourceLayerContext
  defaultZoomLevels: {
    minzoom: number
    maxzoom: number
  }
}

export interface MapboxConfig {
  sourceLayer: SourceLayer[]
  allBySource: Map<string, SourceLayer[]>
}

export const determineSourceLayerContext = (
  locationId: string | undefined
): SourceLayer["context"] | undefined => {
  if (!locationId) return
  if (locationId.length <= 3) return "country"
  if (locationId.length <= 5) return "subnational"
  return "subdivision"
}

export const getSource = (username: string, source: string) => {
  return {
    source,
    sourceUrl: `mapbox://${username}.${source}`,
  }
}

const MAPBOX_SBX_TOKEN_KEY = "MAPBOX_SBX_TOKEN"
const MAPBOX_SBX_ACCESS_TOKEN_KEY = "MAPBOX_SBX_ACCESS_TOKEN"
const MAPBOX_SBX_MODE_KEY = "MAPBOX_SBX_MODE"
const MAPBOX_SBX_LAYERS_KEY = "MAPBOX_SBX_LAYERS"

export const isMapboxSbxMode =
  typeof window !== "undefined" &&
  window.localStorage.getItem(MAPBOX_SBX_MODE_KEY) === "true"
if (isMapboxSbxMode) {
  console.warn(`${MAPBOX_SBX_MODE_KEY} is enabled`)
}

export const MAPBOX_USERNAME = isMapboxSbxMode
  ? "thepublichealthco-sbx"
  : "thepublichealthco"

export const MAPBOX_SBX_ACCESS_TOKEN =
  (typeof window !== "undefined" &&
    window.localStorage.getItem(MAPBOX_SBX_ACCESS_TOKEN_KEY)) ||
  undefined

const mapboxDevLayers = (
  isMapboxSbxMode
    ? JSON.parse(window.localStorage.getItem(MAPBOX_SBX_LAYERS_KEY) ?? "")
    : []
) as SourceLayer[]

if (isMapboxSbxMode && !mapboxDevLayers.length) {
  console.error(
    `${MAPBOX_SBX_MODE_KEY} enabled, but ${MAPBOX_SBX_LAYERS_KEY} are not in localstorage`
  )
}
export const mapboxConfig: MapboxConfig = {
  sourceLayer: isMapboxSbxMode ? mapboxDevLayers : mapboxSourceLayers,
  // get by source
  get allBySource() {
    return this.sourceLayer.reduce((acc, curr) => {
      if (acc.has(curr.sourceInfo.source)) {
        acc.get(curr.sourceInfo.source)?.push(curr)
      } else {
        acc.set(curr.sourceInfo.source, [curr])
      }
      return acc
    }, new Map<string, SourceLayer[]>())
  },
}

/** How to activate SBX mapbox mode
 *
 * - Open the console in the browser
 * - If not set, set the token with `window.localStorage.setItem('MAPBOX_SBX_TOKEN', 'sk.<secret
 * - Run `activateMapboxDevMode(true)`
 *
 * - To deactivate, run `activateMapboxDevMode(false)`
 * - To deactivate and clear the token, run `activateMapboxDevMode(false, true)`
 */
const activateMapboxSbxMode = async (activate = true, clearToken = false) => {
  if (!activate) {
    console.log("deactivating mapbox sbx mode")
    window.localStorage.removeItem(MAPBOX_SBX_LAYERS_KEY)
    window.localStorage.removeItem(MAPBOX_SBX_MODE_KEY)
    console.log("successfully deactivated sbx mode")
    if (clearToken) {
      window.sessionStorage.removeItem(MAPBOX_SBX_TOKEN_KEY)
      console.log("successfully cleared mapbox token")
    }
    return
  }
  const secretToken = window.sessionStorage.getItem(MAPBOX_SBX_TOKEN_KEY)
  if (!secretToken) {
    console.error(`${MAPBOX_SBX_TOKEN_KEY} is not defined.`)
    console.log(
      `In your console, please do the following:
      window.sessionStorage.setItem('${MAPBOX_SBX_TOKEN_KEY}', 'sk.<secret token>')`
    )
    return
  }
  console.log("successfully found sbx token to sessionstorage")

  console.log("checking for mapbox sbx access token...")
  const accessToken = window.localStorage.getItem(MAPBOX_SBX_ACCESS_TOKEN_KEY)
  if (!accessToken) {
    console.error(`${MAPBOX_SBX_ACCESS_TOKEN_KEY} is not defined.`)
    console.log(
      `In your console, please do the following:
      window.localStorage.setItem('${MAPBOX_SBX_ACCESS_TOKEN_KEY}', 'pk.<public token>')`
    )
    return
  }
  console.log("successfully found sbx access token in localstorage")
  console.log("building mapbox sbx layer config...")
  const sbxMapboxLayers = await getMapboxSourceLayers({
    accessToken: secretToken,
    username: "thepublichealthco-sbx",
  })
  window.localStorage.setItem(
    "MAPBOX_SBX_LAYERS",
    JSON.stringify(sbxMapboxLayers)
  )
  console.log(sbxMapboxLayers)
  console.log("successfully saved sbx layers to localstorage")
  console.log("activating mapbox sbx mode, please wait...")
  window.localStorage.setItem(MAPBOX_SBX_MODE_KEY, "true")
  console.warn("please refresh the page to see the changes")
}

// add to window object
if (typeof window !== "undefined") {
  // @ts-expect-error i know ts doesn't like the window...
  window.activateMapboxSbxMode = activateMapboxSbxMode
}
