import { Settings } from "@mui/icons-material"
import { Typography, styled } from "@mui/material"
import type { Asset } from "@phc-health/connect-query"
import type React from "react"
import { Link } from "react-router-dom"
import { ROUTES } from "../../../../utils/constants"
import { extraColors } from "../../../../utils/theme"
import Button from "../../../Shared/Button"
import { SectionContainer } from "../../LocationRisk/Shared"
import { LocationAbsenteeismSettings } from "./LocationAbsenteeismSettings"
import { LocationExpertInsightsSettings } from "./LocationExpertInsightSettings"
import { LocationRiskIncreaseSettings } from "./LocationRiskIncreaseSettings"

const SectionHeaderContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
})

const SettingsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 30,
  marginTop: 20,
})

export const SiteDetailsNotificationSettingsSection: React.FC<{
  asset: Asset
}> = ({ asset }) => {
  return (
    <SectionContainer>
      <SiteDetailsNotificationsHeader />
      <SettingsContainer>
        <LocationRiskIncreaseSettings asset={asset} />
        <LocationAbsenteeismSettings asset={asset} />
        <LocationExpertInsightsSettings asset={asset} />
      </SettingsContainer>
    </SectionContainer>
  )
}

const SiteDetailsNotificationsHeader: React.FC = () => {
  return (
    <SectionHeaderContainer>
      <Typography variant="h3Bold" sx={{ color: extraColors.medium }}>
        Notifications
      </Typography>
      <Button
        component={Link}
        to={ROUTES.PREFERENCES}
        variant="text"
        sx={{ color: extraColors.navy }}
        startIcon={<Settings />}
      >
        Settings
      </Button>
    </SectionHeaderContainer>
  )
}
