import type { OAuthError } from "@auth0/auth0-react"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { decodeJwt } from "jose"
import { useSnackbar } from "notistack"
import type { Permission } from "../utils/constants"
import { queries } from "../utils/constants"

export function useGetPermissions() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { enqueueSnackbar } = useSnackbar()
  return useQuery({
    queryKey: [queries.permissions, isAuthenticated],
    queryFn: async () => {
      if (!isAuthenticated) {
        return []
      }
      try {
        const accessToken = await getAccessTokenSilently()
        const decoded = decodeJwt(accessToken)

        return (decoded.permissions as Permission[] | undefined) ?? []
      } catch (err) {
        const error = err as OAuthError
        if (error.error !== "login_required") {
          console.error({ ...error })
          enqueueSnackbar("Failed to decode token", {
            variant: "error",
          })
        }
        return []
      }
    },
    enabled: isAuthenticated,
  })
}
