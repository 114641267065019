import { getTrendIconSize, type IconSize } from "../IconSize"

const Decreasing: React.FC<{
  fill?: string
  size?: IconSize
}> = ({ size, fill }) => {
  const sizeProps = getTrendIconSize(size)
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...sizeProps}>
      <path
        d="M10.8555 10.5625H2.98047L2.10547 9.6875L4.94922 6.84375L0.792969 2.6875L2.98047 0.5L7.13672 4.65625L9.98047 1.8125L10.8555 2.6875V10.5625Z"
        fill={fill || "#78909C"}
      />
    </svg>
  )
}

export default Decreasing
