import { Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import type { AlertWithReferences } from "@phc/common"
import type React from "react"
import { useMemo } from "react"
import { extraColors } from "../../../utils/theme"

import { LinkOutlined, WarningAmber } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { CategoryTag } from "../../LocationDetails/LocationRisk/CategoryTag"
import { getConfidenceFromJustification } from "../../Shared/AlertConfidence"
import { getAlertColor } from "../../Shared/AlertPill"
import { ConfidenceIndicator } from "../../Shared/ConfidenceIndicator"
import { ContentHeader, ContentSideBar } from "./Shared"

const AlertDetailsContainer = styled("div")({
  display: "grid",
  gridAutoRows: "min-content",
  padding: 24,
})

const TagRow = styled("div")({
  display: "flex",
  gap: 6,
  alignItems: "center",
  margin: "6px 0 12px 0",
  flexFlow: "wrap",
})

const CardRow = styled("div")<{ borderColor: string }>(({ borderColor }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  border: `1px solid ${borderColor}`,
  background: extraColors.white,
  borderRadius: 8,
  marginTop: 16,
}))

const WarningAmberStyled = styled(WarningAmber)<{ iconcolor: string }>(
  ({ iconcolor }) => ({
    height: 16,
    width: 16,
    marginTop: 12,
    marginLeft: 4,
    color: iconcolor,
  })
)

const ViewSourceLink = styled(Link)(({ theme }) => ({
  ...theme.typography.small1,
  display: "flex",
  marginTop: 10,
  color: extraColors.status.teal,
}))

const SourceRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "baseline",
  marginTop: 10,
})

export interface JustificationConfidence {
  parsedConfidence?: number
  justificationWithoutConfidence?: string
}

export const RelatedAlert: React.FC<{
  relatedAlert: AlertWithReferences
}> = ({ relatedAlert }) => {
  const {
    _id,
    publishedAt,
    updatedAt,
    categories,
    title,
    alertLevel,
    geotags,
    justification,
    recommendation,
  } = relatedAlert
  const alertColor = getAlertColor(alertLevel)

  const tags = useMemo(() => {
    return categories?.flatMap(item => item.title?.toString() ?? [])
  }, [categories])

  const justificationParsed: JustificationConfidence = useMemo(
    () => getConfidenceFromJustification(justification),
    [justification]
  )

  return (
    <CardRow borderColor={alertColor?.background || extraColors.disabled}>
      <AlertSideBanner relatedAlert={relatedAlert} />
      <AlertDetailsContainer>
        <ContentHeader
          date={publishedAt || updatedAt}
          title={title ?? ""}
          id={_id}
        />
        <TagRow>
          {geotags?.map(geotag => (
            <CategoryTag key={geotag._key} category={geotag.name ?? ""} />
          ))}
          {tags?.map(tag => <CategoryTag key={tag} category={tag} />)}
        </TagRow>
        <AlertText
          justification={justificationParsed.justificationWithoutConfidence}
          recommendation={recommendation}
        />
        <SourceDetails
          link={relatedAlert.link}
          confidence={justificationParsed.parsedConfidence}
        />
      </AlertDetailsContainer>
    </CardRow>
  )
}

const AlertSideBanner: React.FC<{
  relatedAlert: AlertWithReferences
}> = ({ relatedAlert }) => {
  const { alertLevel } = relatedAlert
  const alertColor = getAlertColor(alertLevel)

  return (
    <ContentSideBar
      textColor={alertColor?.text}
      backgroundColor={alertColor?.background}
      icon={
        <WarningAmberStyled iconcolor={alertColor?.text || extraColors.dark} />
      }
      text={alertLevel || ""}
    />
  )
}

const AlertText: React.FC<{
  recommendation?: string
  justification?: string
}> = ({ recommendation, justification }) => {
  const theme = useTheme()
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <>
      {recommendation && (
        <Typography
          variant={isMobile ? "small1" : "body2"}
          sx={{ color: isMobile ? extraColors.medium : "inherit" }}
        >
          <Typography variant="small1Bold">RECOMMENDATION - </Typography>
          {recommendation}
        </Typography>
      )}

      {justification && (
        <Typography
          variant={isMobile ? "small1" : "body2"}
          sx={{
            marginTop: "10px",
            color: isMobile ? extraColors.medium : "inherit",
          }}
        >
          <Typography variant="small1Bold">SITUATION - </Typography>
          {justification}
        </Typography>
      )}
    </>
  )
}

const SourceDetails: React.FC<{
  link?: string
  confidence?: number
}> = ({ link, confidence }) => {
  return (
    <SourceRow>
      <ViewSourceLink to={link ?? ""} target={"_blank"}>
        <Typography variant="tiny" sx={{ color: extraColors.hint }}>
          SOURCE
        </Typography>
        <LinkOutlined
          sx={{
            height: "14px",
            color: extraColors.hint,
            marginLeft: "-4px",
            opacity: link ? 1 : 0,
          }}
        />
      </ViewSourceLink>
      <ConfidenceIndicator confidenceInteger={confidence} />
    </SourceRow>
  )
}
