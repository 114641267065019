import { CircularProgress, styled } from "@mui/material"
import type { Role } from "@phc-health/connect-query"
import { isPHCEmail } from "../../contexts/IsEmployeeContext"
import { theme } from "../../utils/theme"

/**
 * STYLES
 */

export const FormContainer = styled("div")({
  background: theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  gap: 40,
  height: "100%",
  marginTop: 30,
})

const FormContainerLoading = styled(FormContainer)({
  justifyContent: "center",
  marginTop: 0,
})

const CircularProgressStyled = styled(CircularProgress)({
  alignSelf: "center",
})

/**
 * COMPONENTS
 */

export const UserFormLoading: React.FC = () => {
  return (
    <FormContainerLoading>
      <CircularProgressStyled />
    </FormContainerLoading>
  )
}

export function getRolesByAvailablility(
  roles: Role[],
  isAdmin: boolean,
  isPHCAdmin: boolean,
  userEmail?: string
) {
  const targetIsEmployee = isPHCEmail(userEmail)
  const addingNewMember = !userEmail

  return Array.from(
    roles
      .filter(role => isPHCAdmin || !role.name.includes("phc"))
      .reduce((byAvail, role) => {
        switch (role.name) {
          case "roles/phc-admin":
          case "roles/phc-user":
            return isPHCAdmin && (targetIsEmployee || addingNewMember)
              ? byAvail.set(role, true)
              : byAvail.set(role, false)
          case "roles/admin":
          case "roles/user":
            return isAdmin ? byAvail.set(role, true) : byAvail.set(role, false)
          default:
            byAvail.set(role, false)
        }
        return byAvail
      }, new Map<Role, boolean>())
  ).sort((a, b) => (a[0].displayName > b[0].displayName ? -1 : 1))
}
