import type React from "react"
import { createContext, useContext, useMemo } from "react"

const EMPLOYEE_EMAIL_SUFFIX = "@phc.health"

const IsEmployeeContext = createContext<{ isEmployee: boolean }>({
  isEmployee: false,
})

export const IsEmployeeContextProvider: React.FC<{
  userEmail?: string
  children?: React.ReactNode
}> = ({ userEmail, children }) => {
  const context = useMemo(() => {
    return {
      isEmployee: isPHCEmail(userEmail),
    }
  }, [userEmail])
  return (
    <IsEmployeeContext.Provider value={context}>
      {children}
    </IsEmployeeContext.Provider>
  )
}

export function useIsPHCEmployee() {
  const context = useContext(IsEmployeeContext)
  return useMemo(() => context, [context])
}

export function isPHCEmail(email?: string): boolean {
  return email?.endsWith(EMPLOYEE_EMAIL_SUFFIX) || false
}
