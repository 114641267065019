import styled from "@emotion/styled"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import type { TooltipProps } from "@mui/material"
import { Tooltip, tooltipClasses } from "@mui/material"

import { dayjsUTC } from "../../../utils/helpers"
import { extraColors, theme } from "../../../utils/theme"

export const SectionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: extraColors.white,
  padding: 24,
  borderRadius: 8,
  border: `1px solid ${extraColors.disabled}`,
  marginTop: 16,
})

export const InfoIconStyled = styled(InfoOutlinedIcon)({
  height: 16,
  marginLeft: 4,
  fill: extraColors.purpleMiddle,
})

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.small1,
    backgroundColor: extraColors.subtle,
    color: extraColors.dark,
    fontFamily: theme.typography.fontFamily,
    border: `1px solid ${extraColors.disabled}`,
    borderRadius: 2,
    filter: "drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.2))",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: `1px solid ${extraColors.disabled}`,
    },
    color: extraColors.subtle,
  },
})

export function getDataDate(date: number) {
  return dayjsUTC(date * 1000)
    .local()
    .format("M/D/YYYY")
}
