import { Typography, styled } from "@mui/material"
import {
  GridActionsCell,
  useGridApiContext,
  type GridSlotProps,
  type GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro"
import type { AlertLevel, AlertWithReferences, Geotag } from "@phc/common"
import { extraColors } from "../../utils/theme"
import { GeotagsContainer } from "../Map/Cards/GeotagsContainer"
import { getConfidenceFromJustification } from "../Shared/AlertConfidence"
import { ConfidenceIndicator } from "../Shared/ConfidenceIndicator"
import { GridRowCard } from "../Shared/GridRowCard"
import { ThreatCategory } from "../Shared/ThreatCategory"
import { DateRenderCell } from "./AlertsShared"
import { AlertLevelCell } from "./AlertTableColumns"

const TitleBox = styled("div")(() => ({
  alignSelf: "start",
  display: "grid",
  gap: 4,
}))

const FirstRow = styled("div")(() => ({
  display: "grid",
  gridAutoFlow: "column",
  alignItems: "center",
  gridTemplateColumns: "1fr",
}))

export const MobileRowRender = (
  props: GridSlotProps["row"] & { row: AlertWithReferences }
) => {
  const dataGridRef = useGridApiContext()

  const actions = dataGridRef.current.getCellParams<
    any,
    unknown,
    unknown,
    GridTreeNodeWithRender
  >(props.rowId, "actions")
  // if the _id starts with "drafts." it's a draft
  const isDraft = props.row._id.startsWith("drafts.")
  //add draft to the title if it's a draft
  const title = isDraft ? `${props.row.title ?? ""} (Draft)` : props.row.title
  const confidence = getConfidenceFromJustification(props.row.justification)

  return (
    <GridRowCard
      {...props}
      style={{
        display: "grid",
        gap: 4,
        padding: "4px 9px 16px",
      }}
    >
      <FirstRow>
        <ThreatCategory
          contentId={props.row._id}
          threat={props.row.categories?.[0]?.title}
        />
        <DateRenderCell
          dateString={props.row.publishedAt ?? props.row.updatedAt ?? ""}
        />
        <GridActionsCell {...actions} />
      </FirstRow>
      <AlertContent
        alertLevel={props.row.alertLevel}
        title={title}
        recommendation={props.row.recommendation}
        justification={confidence.justificationWithoutConfidence}
        geotags={props.row.geotags}
      />
      <ConfidenceIndicator
        confidenceInteger={confidence.parsedConfidence}
        style={{ marginLeft: "auto" }}
      />
    </GridRowCard>
  )
}

const AlertContent: React.FC<{
  alertLevel?: AlertLevel
  title?: string
  recommendation?: string
  justification?: string
  geotags: Geotag[] | undefined
}> = ({ alertLevel, title, recommendation, justification, geotags }) => {
  return (
    <TitleBox>
      <Typography variant="body2Bold">{title}</Typography>
      <div style={{ display: "flex", gap: 4, paddingBottom: 8 }}>
        <AlertLevelCell alertLevel={alertLevel} />
        <GeotagsContainer geotags={geotags} />
      </div>
      <Typography variant="body2" color={extraColors.medium}>
        <Typography
          variant="inherit"
          component="span"
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Recommendation:{" "}
        </Typography>
        {recommendation}
      </Typography>
      <Typography variant="body2" color={extraColors.medium}>
        <Typography
          variant="inherit"
          component="span"
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Situation:{" "}
        </Typography>
        {justification}
      </Typography>
    </TitleBox>
  )
}
