import { ButtonBase, Typography, styled } from "@mui/material"
import type { AlertLevel } from "@phc/common"
import type React from "react"
import { useMemo, type CSSProperties } from "react"
import SvgAlertIconAdvisory from "../../../assets/svg-components/AlertIconAdvisory"
import SvgAlertIconWarning from "../../../assets/svg-components/AlertIconWarning"
import SvgAlertIconWatch from "../../../assets/svg-components/AlertIconWatch"
import { useMapContext } from "../../../contexts/MapContext"
import { sortAlertLevel } from "../../../utils/helpers"
import { extraColors } from "../../../utils/theme"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { useMarkerHighlightHover } from "./hooks/useAlertHighlightSelected"

const AlertCount = styled(Typography)(() => ({
  position: "absolute",
  top: -8,
  left: 12,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  // white circle background
  "&::before": {
    content: '""',
    width: 16,
    height: 16,
    borderRadius: "50%",
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
    boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
  },
}))

const AlertIcon: React.FC<
  {
    alertLevel: AlertLevel
  } & React.HTMLAttributes<SVGElement>
> = ({ alertLevel, ...props }) => {
  switch (alertLevel) {
    case "advisory":
      return <SvgAlertIconAdvisory {...props} />
    case "watch":
      return <SvgAlertIconWatch {...props} />
    case "warning":
      return <SvgAlertIconWarning {...props} />
  }
}

export const AlertClusterMarker: React.FC<{
  alertIds: string[]
  style?: CSSProperties
  disableClick?: boolean
}> = ({ alertIds, style, disableClick }) => {
  const { setClusterAlertsIds } = useMapContext()
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()

  const { setHoverFeatureState } = useMarkerHighlightHover(alertIds)
  const clusterAlerts = useMemo(() => {
    if (!alerts) return []
    return alertIds.map(a => alerts.find(alert => alert._id === a))
  }, [alertIds, alerts])

  const uniqueAlertLevels = useMemo(() => {
    return Array.from(
      new Set(clusterAlerts.flatMap(a => (a?.alertLevel ? [a.alertLevel] : [])))
    ).sort(sortAlertLevel)
  }, [clusterAlerts])
  if (!alerts) return
  const uniqueIdCount = new Set(alertIds).size

  return (
    <ButtonBase
      component={disableClick ? "div" : "button"}
      onClick={(e: React.MouseEvent) => {
        if (disableClick) return
        e.stopPropagation()
        setClusterAlertsIds(alertIds)
      }}
      onPointerMove={() => {
        setHoverFeatureState(true)
      }}
      onPointerLeave={() => {
        setHoverFeatureState(false)
      }}
      style={style}
      disableRipple
    >
      {uniqueAlertLevels.map((level, i) => (
        <AlertIcon
          key={level}
          alertLevel={level}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "50%",
            left: `calc(50% + ${i * 4}px)`,
            transform: "translate(-50%, -50%)",
            zIndex: uniqueAlertLevels.length - i,
          }}
        />
      ))}
      {uniqueIdCount > 1 ? (
        <AlertCount variant="small1Bold">{uniqueIdCount}</AlertCount>
      ) : null}
    </ButtonBase>
  )
}
