import type { SVGProps } from "react"
const SvgAlertIconWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={37}
    fill="none"
    viewBox="0 0 42 37"
    {...props}
  >
    <g filter="url(#AlertIconWarning_svg__a)">
      <path fill="#fff" d="M21.038 8 34 31H8L21.038 8Z" />
      <path
        fill="#DA1E28"
        d="M4.898 29.679 19.031 5.39 21 2l1.898 3.39L37.031 29.68 39 33H3l1.898-3.321Zm28.266 0L21 8.712 8.766 29.68h24.398Zm-13.852-9.411v-6.09h3.375v7.75h-3.375v-1.66Zm3.375 7.196h-3.375v-3.321h3.375v3.321Z"
      />
    </g>
    <defs>
      <filter
        id="AlertIconWarning_svg__a"
        width={42}
        height={37}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3068_7138"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
        <feBlend
          in2="effect1_dropShadow_3068_7138"
          result="effect2_dropShadow_3068_7138"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          radius={1}
          result="effect3_dropShadow_3068_7138"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend
          in2="effect2_dropShadow_3068_7138"
          result="effect3_dropShadow_3068_7138"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_3068_7138"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgAlertIconWarning
