import type { AppState } from "@auth0/auth0-react"
import { Auth0Provider } from "@auth0/auth0-react"
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material"
import * as Sentry from "@sentry/react"
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
// dayjs only used here to setup plugins
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import bigIntSupport from "dayjs/plugin/bigIntSupport"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  type RouteObject,
} from "react-router-dom"
import App from "./App"
import { ToastProvider } from "./components/Toast"
import { useCapClient } from "./hooks/useCapClient"
import { RouteConfig } from "./RouteConfig"
import { cap } from "./utils/cap"
import { ENVIRONMENTS, SeverityLevel } from "./utils/constants"
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, ENV } from "./utils/env"
import { theme } from "./utils/theme"

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.SENTRY_DSN,
    environment: ENVIRONMENTS[ENV],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
    ],

    tracesSampleRate: 1.0,
  })
}

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(bigIntSupport)

const inputGlobalStyles = <GlobalStyles styles={{ a: { color: "inherit" } }} />

const launchDarklyId = import.meta.env.LAUNCHDARKLY_CLIENT_ID

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // inspiration for the following code:
    // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#a-bad-api
    onError: (error, query) => {
      Sentry.captureException(error, {
        tags: {
          queryKey: query.queryKey.toString(),
        },
      })
    },
  }),
})

const LDProvider = withLDProvider({
  clientSideID: launchDarklyId,
  options: {
    bootstrap: "localStorage",
  },
})(App)

const container = document.getElementById("root")
if (!container) {
  Sentry.captureMessage("No root element found", SeverityLevel.Fatal)
  throw new Error("No root element found")
}

export const AuthWrapper = () => {
  const navigate = useNavigate()
  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
    >
      <Outlet />
    </Auth0Provider>
  )
}
export const AppProviders: React.FC = () => {
  const capClient = useCapClient()
  return (
    <StrictMode>
      <cap.Provider client={capClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {import.meta.env.DEV && <ReactQueryDevtools />}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {inputGlobalStyles}
            <ToastProvider>
              <LDProvider />
            </ToastProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </cap.Provider>
    </StrictMode>
  )
}

function routeConfig(): RouteObject[] {
  return [
    {
      element: <AuthWrapper />,
      children: [
        {
          element: <AppProviders />,
          children: RouteConfig,
        },
      ],
    },
  ]
}

createRoot(container).render(
  <RouterProvider router={createBrowserRouter(routeConfig())} />
)
