import type { MapboxLocation, PlaceType } from "@phc-health/connect-query"
import { Asset } from "@phc-health/connect-query"
import { getMapboxGeocodeResult } from "@phc/common"
import { useEffect, useMemo } from "react"
import usePersistentContext from "../../../hooks/usePersistentContext"
import { FALSE, TRUE } from "../../../utils/constants"
import { MAPBOX_ACCESS_TOKEN } from "../../../utils/env"
import {
  convertGeocoderResultToLocation,
  placeTypeStringToEnum,
} from "../../../utils/helpers/assetHelper"
import { useUpdateAssets } from "../../WatchedLocations/AssetManagement/hooks/useUpdateAsset"
import { useListAssets } from "../../WatchedLocations/hooks/useAssetService"

export function useMigrateAssetPlaceTypes() {
  const [hasBeenMigrated, setHasBeenMigrated, hasBeenMigratedLoading] =
    usePersistentContext(["place-type-migration"])

  const { data: assets, isLoading: assetsLoading } = useListAssets({
    includeGlobal: false,
    excludeGroups: true,
    excludeNotifications: true,
  })
  const { mutateAsync } = useUpdateAssets()

  const assetsWithoutPlaceTypes = useMemo(
    () =>
      assets.assets.filter(
        asset => !asset.baseEvent?.mapboxLocation?.placeType
      ),
    [assets.assets]
  )

  useEffect(() => {
    async function updateAssetPlaceTypes() {
      // If there aren't any assets missing place_types, no migration is needed
      if (!assetsLoading && !assetsWithoutPlaceTypes.length) {
        await setHasBeenMigrated(TRUE)
      }

      if (
        hasBeenMigratedLoading ||
        hasBeenMigrated === TRUE ||
        assetsLoading ||
        !assetsWithoutPlaceTypes.length
      ) {
        return
      }

      await setHasBeenMigrated(TRUE)

      const assetsWithIds: Asset[] = []
      const assetsWithoutIds: Asset[] = []

      assetsWithoutPlaceTypes.forEach(asset => {
        if (asset.baseEvent?.mapboxLocation?.id) {
          // Assets with mapbox ids come from a real geocoder selection
          assetsWithIds.push(asset)
        } else {
          // Assets without mapbox ids are created from buckets via the map -> location details page "start watching locations" button
          assetsWithoutIds.push(asset)
        }
      })

      const assetsWithIdsToUpdate = rebuildAssetsWithIdPlaceTypes(assetsWithIds)
      const assetsWithoutIdsToUpdate: Asset[] = []

      try {
        await Promise.allSettled(
          assetsWithoutIds.map(async asset => {
            const res = await getPlaceTypeFromGeocoder(
              asset.baseEvent?.mapboxLocation
            )
            if (!res) return

            assetsWithoutIdsToUpdate.push(replaceAssetPlaceType(asset, res))
          })
        )

        const assetsToUpdate = assetsWithIdsToUpdate.concat(
          assetsWithoutIdsToUpdate
        )

        if (!assetsToUpdate.length) return

        await mutateAsync({
          assets: assetsToUpdate,
        })
      } catch (error) {
        console.error(error)
        await setHasBeenMigrated(FALSE)
      }
    }

    updateAssetPlaceTypes().catch(err => {
      console.error(err)
    })
  }, [
    assets.assets,
    assetsLoading,
    assetsWithoutPlaceTypes,
    hasBeenMigrated,
    hasBeenMigratedLoading,
    mutateAsync,
    setHasBeenMigrated,
  ])
}

const rebuildAssetsWithIdPlaceTypes = (assets: Asset[]) => {
  return assets.map(asset => {
    const id = asset.baseEvent?.mapboxLocation?.id
    if (!id) return asset
    const placeTypeStringFromId = id.substring(0, id.indexOf("."))
    const placeTypeFromString = placeTypeStringToEnum(placeTypeStringFromId)
    return replaceAssetPlaceType(asset, placeTypeFromString)
  })
}

const replaceAssetPlaceType = (asset: Asset, placeType: PlaceType) => {
  return new Asset({
    ...asset,
    baseEvent: {
      ...asset.baseEvent,
      mapboxLocation: {
        ...asset.baseEvent?.mapboxLocation,
        placeType: placeType,
      },
    },
  })
}

const getPlaceTypeFromGeocoder = async (mapboxLocation?: MapboxLocation) => {
  if (!mapboxLocation) return undefined
  const searchText = mapboxLocation.placeName
  const result = await getMapboxGeocodeResult({
    query: searchText,
    types: "",
    accessToken: MAPBOX_ACCESS_TOKEN,
  })

  if (result?.features.length === 0 || !result?.features[0]) {
    throw new Error(`No results found for: ${searchText}`)
  }

  return convertGeocoderResultToLocation({
    result: result.features[0],
  }).placeType
}
