import { styled, Typography } from "@mui/material"
import type React from "react"
import clipboard from "../assets/clipboard.png"

const NothingToSeeHere = styled("div")(() => ({
  display: "grid",
  gap: 16,
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  justifyItems: "center",
  textAlign: "center",
  minHeight: 400,
  fontSize: 64,
}))

const Image = styled("img")(() => ({
  height: 60,
}))

export interface NotFoundContentProps {
  errorText?: string
}

export const NotFoundContent: React.FC<NotFoundContentProps> = ({
  errorText = "We're looking into why you might be seeing this page 😬",
}) => {
  return (
    <>
      <NothingToSeeHere>
        <Image src={clipboard} alt={"clipboard"} />
        <Typography>{errorText}</Typography>
      </NothingToSeeHere>
    </>
  )
}
