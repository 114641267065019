import { CircularProgress, Typography, keyframes, styled } from "@mui/material"
import { AssetNotificationType } from "@phc-health/connect-query"
import { extraColors } from "../../../utils/theme"
import Button from "../../Shared/Button"
import { useListAssets } from "../../WatchedLocations/hooks/useAssetService"
import { ExpertInsights } from "./ExpertInsights/ExpertInsights"
import { RiskIncreaseNotifications } from "./RiskIncrease/RiskIncreaseNotifications"

import { SettingsContainer } from "../shared/SettingsContainer"
import { Absenteeism } from "./Absenteeism/Absenteeism"
import {
  NotificationContextProvider,
  useNotificationContext,
} from "./contexts/NotificationContext"
import { NotificationTypeContextProvider } from "./contexts/NotificationTypeContext"

const fadeInOutAnimation = keyframes`
0% {
  opacity: .3;
}
50% {
  opacity: 1;
}
100% {
  opacity: .3;
}
`

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  gap: 24,
  paddingBottom: 12,
  maxWidth: 800,
  alignContent: "center",
  justifyContent: "center",
}))

const ToolBar = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  padding: "0 24px",
  backgroundColor: extraColors.white,
  height: "72px",
  borderTop: `1px solid ${extraColors.light}`,
  boxShadow: `0px 3px 7px 1px ${extraColors.shadow}`,
  [theme.breakpoints.up("md")]: {
    justifyContent: "end",
    paddingRight: 80,
  },
}))

const UnsavedText = styled(Typography)({
  animation: `${fadeInOutAnimation} 4s ease infinite`,
  color: extraColors.status.orangeMiddle,
  marginRight: 40,
})

const SettingsContainerStyled = styled(SettingsContainer)({
  height: 300,
})

const ProgressContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  height: "100%",
})

export const Notifications = () => {
  const {
    data: assets,
    isFetchingNextPage,
    isLoading,
  } = useListAssets({
    includeGlobal: true,
  })

  if (isFetchingNextPage || isLoading)
    return (
      <Container>
        <ContainersLoading title="Risk Increase Notifications" />
        <ContainersLoading title="Absenteeism Notifications" />
        <ContainersLoading title="Expert Insights" />
        <NotificationSettingsActionsLoading />
      </Container>
    )

  return (
    <NotificationContextProvider assets={assets.assets}>
      <Container>
        <NotificationTypeContextProvider
          notificationType={AssetNotificationType.RISK_INCREASE}
        >
          <RiskIncreaseNotifications />
        </NotificationTypeContextProvider>
        <NotificationTypeContextProvider
          notificationType={
            AssetNotificationType.FORECASTED_ABSENTEEISM_INCREASE
          }
        >
          <Absenteeism />
        </NotificationTypeContextProvider>
        <NotificationTypeContextProvider
          notificationType={AssetNotificationType.ALERT}
        >
          <ExpertInsights />
        </NotificationTypeContextProvider>
      </Container>
      <NotificationSettingsActions />
    </NotificationContextProvider>
  )
}

const NotificationSettingsActions: React.FC = () => {
  const {
    submitNotificationSettings,
    discardNotificationSettings,
    hasChangedSettings,
    isSaving,
  } = useNotificationContext()
  return (
    <ToolBar>
      {(hasChangedSettings || isSaving) && (
        <UnsavedText variant="body1Bold">
          {isSaving ? "Saving settings" : "You have unsaved settings"}
        </UnsavedText>
      )}
      <Button
        variant="outlined"
        color="secondary"
        onClick={discardNotificationSettings}
        disabled={!hasChangedSettings || isSaving}
        sx={{ marginRight: "40px" }}
      >
        Discard Changes
      </Button>
      <Button
        variant="contained"
        onClick={() => void submitNotificationSettings?.()}
        disabled={!hasChangedSettings || isSaving}
      >
        Submit
      </Button>
    </ToolBar>
  )
}

const NotificationSettingsActionsLoading: React.FC = () => {
  return (
    <ToolBar>
      <Button
        variant="outlined"
        color="secondary"
        disabled
        sx={{ marginRight: "40px", minWidth: "fit-content" }}
      >
        Discard Changes
      </Button>
      <Button variant="contained" disabled>
        Submit
      </Button>
    </ToolBar>
  )
}

const ContainersLoading: React.FC<{ title: string }> = ({ title }) => {
  return (
    <SettingsContainerStyled title={title}>
      <ProgressContainer>
        <CircularProgress sx={{ color: extraColors.purpleMiddle }} />
      </ProgressContainer>
    </SettingsContainerStyled>
  )
}
