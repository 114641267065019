import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"

enum InvalidUrlStart {
  Code = "/?code=",
  Error = "/?error=",
  State = "/?state=",
  SignIn = "/sign-in",
}

const isValidInitialUrl = (url: string): boolean => {
  return !Object.values(InvalidUrlStart).some(invalidStart =>
    url.startsWith(invalidStart)
  )
}

export const useStoreInitialUrl = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const signOutUrl = window.location.pathname + window.location.search

    if (isValidInitialUrl(signOutUrl)) {
      localStorage.setItem("initialUrl", signOutUrl)
    }
  }, [])

  const initialUrl = window.location.pathname + window.location.search
  useEffect(() => {
    const checkSessionExpiration = async () => {
      if (!isAuthenticated) {
        try {
          await getAccessTokenSilently()
        } catch (_) {
          if (isValidInitialUrl(initialUrl)) {
            localStorage.setItem("initialUrl", initialUrl)
          }
        }
      }
    }

    if (!isLoading) {
      void checkSessionExpiration()
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, initialUrl])
}
