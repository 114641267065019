import type { SxProps, Theme } from "@mui/material"
import { MenuItem, TextField } from "@mui/material"

interface DropdownProps {
  id?: string
  name?: string
  label?: string
  value?: string
  options: DropdownOption[] | undefined
  disabled?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  sx?: SxProps<Theme> | undefined
  className?: string
  placeholder?: string
}

export interface DropdownOption {
  name: string | undefined
  value: string | undefined
}

export const Dropdown: React.FC<DropdownProps> = ({
  id,
  name,
  label,
  value,
  options,
  disabled,
  onChange,
  error,
  sx,
  className,
  placeholder,
  ...props
}) => {
  return (
    <TextField
      {...props}
      label={label}
      select
      id={id}
      name={name}
      value={value ?? ""}
      onChange={onChange}
      disabled={disabled}
      error={error}
      sx={sx}
      className={className}
      SelectProps={{
        displayEmpty: !!placeholder,
      }}
    >
      {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
      {options?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
