import { Typography } from "@mui/material"
import type { Asset } from "@phc-health/connect-query"
import { useState } from "react"
import { extraColors } from "../../../../utils/theme"
import { SettingCheckbox } from "../../../Settings/notifications/SettingCheckbox"
import { useLocationNotificationSettings } from "./hooks/useLocationNotificationSettings"
import { OptionSection, SummarySelectionContainer } from "./Shared"

export const LocationAbsenteeismSettings: React.FC<{
  asset: Asset
}> = ({ asset }) => {
  const {
    currentAssetAbsenteeismEnabled,
    summaryForecastedAbsenteeismEnabled,
    summaryHistoricalAbsenteeismEnabled,
    saveAssetNotifications,
    updateIsPending,
    summaryForecastRange,
  } = useLocationNotificationSettings(asset)

  const [absenteeismEnabled, setAbsenteeismEnabled] = useState(
    currentAssetAbsenteeismEnabled
  )

  const allSummariesEnabled =
    summaryForecastedAbsenteeismEnabled && summaryHistoricalAbsenteeismEnabled
  const anySummaryEnabled =
    summaryForecastedAbsenteeismEnabled || summaryHistoricalAbsenteeismEnabled

  const historicalText = summaryHistoricalAbsenteeismEnabled
    ? "forecasts are expected to be worse than historic averages for my industry"
    : ""
  const forecastedText = summaryForecastedAbsenteeismEnabled
    ? "there is an increase in forecasted absenteeism"
    : ""
  const conjunction = allSummariesEnabled ? " and " : ""
  const puncutation = anySummaryEnabled ? "." : ""

  const summaryText = `${historicalText}${conjunction}${forecastedText}${puncutation}`

  return (
    <OptionSection>
      <SettingCheckbox
        disabled={updateIsPending}
        settingEnabled={absenteeismEnabled}
        setSettingEnabled={setAbsenteeismEnabled}
        title="Receive Absenteeism Forecast notifications for this location."
        customSettingFn={() => {
          setAbsenteeismEnabled(prev => !prev)
          saveAssetNotifications({ toggleAbsenteeism: true })
        }}
      />
      {anySummaryEnabled && (
        <SummarySelectionContainer>
          <Typography variant="small1" sx={{ marginBottom: "6px" }}>
            Receiving absenteeism notifications when:
          </Typography>

          <Typography variant="small1" sx={{ color: extraColors.medium }}>
            {summaryText}
          </Typography>
          {summaryForecastRange && (
            <Typography
              variant="small1"
              sx={{ color: extraColors.medium, marginTop: "6px" }}
            >
              {summaryForecastRange} weeks out
            </Typography>
          )}
        </SummarySelectionContainer>
      )}
    </OptionSection>
  )
}
