import { Box, styled, Typography } from "@mui/material"
import absenteeism from "./absenteeism.png"
const Container = styled("div")({
  display: "grid",
  gap: "12px",
  width: "100%",
})

export const AbsenteeismEmailExample = () => {
  return (
    <Container>
      <Typography variant="h3Bold">Absenteeism Notifications</Typography>
      <Typography variant="body1">
        PHC Pharos™ provides forecasts of anticipated absenteeism rates due to
        respiratory illness to help you plan ahead for your workforce.
        Notifications will show the forecasted absenteeism rate according to
        your settings.
      </Typography>
      <Box
        component="img"
        sx={{
          display: "block",
          overflow: "auto",
          width: "100%",
        }}
        src={absenteeism}
        alt={"Absenteeism"}
      />
    </Container>
  )
}
