import { Typography, styled } from "@mui/material"
import type { Asset } from "@phc-health/connect-query"
import type React from "react"
import {
  assetTypeEnumToString,
  industryTypeEnumToString,
} from "../../../../utils/helpers/assetHelper"
import { extraColors } from "../../../../utils/theme"

const AssetDetailContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 4,
})

const AssetDetailsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  marginTop: 20,
})

interface EditSiteDetailsgProps {
  asset: Asset
}

export const DisplaySiteDetails: React.FC<EditSiteDetailsgProps> = ({
  asset,
}) => {
  return (
    <AssetDetailsContainer>
      <AssetDetail name="Nickname" value={asset.name || "None"} />

      <AssetDetail
        name="Address"
        value={asset.baseEvent?.mapboxLocation?.placeName || "None"}
      />

      <AssetDetail
        name="Industry"
        value={industryTypeEnumToString(asset.industry)}
      />

      <AssetDetail
        name="Groups"
        value={asset.assetGroups.map(g => g.name).join(", ") || "None"}
      />

      <AssetDetail
        name="Type"
        value={assetTypeEnumToString(asset.assetTypes[0])}
      />

      <AssetDetail name="Notes" value={asset.notes || "None"} />
    </AssetDetailsContainer>
  )
}

const AssetDetail: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => {
  return (
    <AssetDetailContainer>
      <Typography variant="small1" sx={{ color: extraColors.medium }}>
        {name}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </AssetDetailContainer>
  )
}
