import { getTileSetResponse } from "./helpers"

export interface GeocoderResult {
  result:
    | {
        id: string
        center?: [number, number]
        context?: {
          id: string
          short_code: string
          text: string
        }[]
        properties: { short_code: string }
        place_type: (
          | "country"
          | "region"
          | "postcode"
          | "district"
          | "place"
          | "locality"
          | "neighborhood"
          | "address"
          | "poi"
          | (string & Record<never, never>)
        )[]
        place_name: string
        geometry?: GeoJSON.GeoJSON
        address?: string
        text: string
      }
    | undefined
}

/**
 * Get the relevant PHC ids from a Mapbox geocoder result
 *
 * @returns {number[]} An array of ids in the order by specificity: county to country
 *
 * If the result is a country, it will only return only the last result
 * If the result is a region, it will only return the region and the country
 *
 * It must be a geocoder result because it needs the country short code of where the lat/lng came from.
 * This could be broken out into a separate function if the short code can be provided in a different way
 */
export const getIdsFromGeocoderResult = async (
  { result }: GeocoderResult,
  accessToken = ""
) => {
  if (!result) return []
  const lng = result.center?.[0]
  const lat = result.center?.[1]
  if (!lng || !lat) return []

  const countryShortCode =
    result.context?.find(context => context.id.includes("country"))
      ?.short_code ??
    // country results don't come back with context, just use properties.short_code
    result.properties.short_code
  const response = await getTileSetResponse(
    {
      lat,
      lng,
    },
    accessToken,
    countryShortCode
  )

  if (!response) return []

  const { features } = response
  // order features by id (county to country)
  const data = features.sort(
    (a, b) =>
      Number(b.properties.location_code) - Number(a.properties.location_code)
  )

  if (result.place_type.includes("country")) {
    // if the result is a country, only return the last location id
    return data.slice(-1)
  }
  if (result.place_type.includes("region")) {
    // if the result is a region, only return the last 2 location ids
    return data.slice(-2)
  }
  return data
}
