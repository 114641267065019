import type React from "react"

import { Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import {
  cmsUrlBuilder,
  type AnalysisWithReferences,
  type BlockContentImage,
  type GuidanceWithReferences,
} from "@phc/common"
import { useEffect, useMemo, useState } from "react"

import { NewspaperOutlined, StackedBarChartOutlined } from "@mui/icons-material"
import {
  PortableText,
  toPlainText,
  type PortableTextReactComponents,
} from "@portabletext/react"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import type { PortableTextBlock } from "sanity"
import { extraColors } from "../../../utils/theme"
import { CategoryTag } from "../../LocationDetails/LocationRisk/CategoryTag"
import { SectionContainer } from "../../LocationDetails/LocationRisk/Shared"
import { useCapServiceContext } from "../capServiceContext"
import { getCriticalThreatImageFromSeed } from "../defaultImageSelector"
import { ExpandedContent } from "./ExpandedContent"
import { ContentHeader, ContentSideBar, MAX_IMAGE_WIDTH } from "./Shared"

const Image = styled("img")({
  maxWidth: "100%",
})

const ContentDetailsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
})

const ShowMore = styled("div")({
  display: "flex",
  color: extraColors.purpleMiddle,
  alignItems: "center",
  justifyContent: "flex-end",
  margin: "0 -8px -10px 0",
  cursor: "pointer",
  willChange: "transform",
  transition: "color 250ms ease",
  "&:hover": {
    color: extraColors.purpleDark,
    svg: {
      color: extraColors.purpleDark,
    },
    span: {
      color: extraColors.purpleDark,
    },
  },
})

const ClampedText = styled("div")(({ theme }) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  lineClamp: "3",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical",
  minWidth: 0,
  margin: "0px 0 6px",
  ...theme.typography.small1,
  color: extraColors.medium,
  willChange: "transform",
  [theme.breakpoints.up("sm")]: {
    margin: 0,
    ...theme.typography.body1,
  },
}))

const SectionContainerStyled = styled(SectionContainer)<{ isexpanded: number }>(
  ({ isexpanded }) => ({
    width: "100%",
    transition:
      "border-color 250ms ease, transform 250ms ease, box-shadow 250ms ease",
    willChange: "transform",
    boxShadow: isexpanded ? `5px 5px 16px -3px ${extraColors.shadow}` : "none",
    borderColor: extraColors.disabled,
    cursor: "pointer",
    padding: 0,
    "&:hover": {
      boxShadow: `5px 5px 16px -3px ${extraColors.shadow}`,
    },
  })
)

const TagRow = styled("div")({
  display: "flex",
  gap: 6,
  alignItems: "center",
  margin: "6px 0 12px 0",
  flexWrap: "wrap",
})

const CardColumn = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto",
  paddingBottom: 10,
  ".show-more": {
    // marginTop: -48,
    marginRight: 40,
  },
})

const CardRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
})

const DefaultImage = styled("img")(({ theme }) => ({
  borderRadius: 6,
  width: "100%",
  height: 80,
  objectFit: "cover",
  objectPosition: "top",
  marginRight: 20,
  marginBottom: 10,
  [theme.breakpoints.up("sm")]: {
    width: MAX_IMAGE_WIDTH,
    height: "100%",
    objectFit: "contain",
    marginBottom: 0,
  },
}))

const ContentContainer = styled("div")({
  display: "flex",
  padding: 24,
})

const AnalysisIconStyled = styled(NewspaperOutlined)<{ iconcolor: string }>(
  ({ iconcolor }) => ({
    height: 14,
    width: 14,
    marginTop: 12,
    marginLeft: 4,
    color: iconcolor,
  })
)

const StackedBarChartOutlinedStyled = styled(StackedBarChartOutlined)<{
  iconcolor: string
}>(({ iconcolor }) => ({
  height: 16,
  width: 16,
  marginTop: 12,
  marginLeft: 4,
  color: iconcolor,
}))

export const RelatedContent: React.FC<{
  relatedContent: AnalysisWithReferences | GuidanceWithReferences
}> = ({ relatedContent }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <SectionContainerStyled
      isexpanded={isExpanded ? 1 : 0}
      onClick={() => setIsExpanded(prev => !prev)}
    >
      <ContentDetails
        relatedContent={relatedContent}
        setIsExpanded={setIsExpanded}
        isExpanded={isExpanded}
      />
    </SectionContainerStyled>
  )
}

const DetailContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  div: {
    height: "unset",
  },
})

const CollapsedContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 24,
})

const ContentDetails: React.FC<{
  relatedContent: AnalysisWithReferences | GuidanceWithReferences
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isExpanded: boolean
}> = ({ relatedContent, setIsExpanded, isExpanded }) => {
  const theme = useTheme()
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"))
  const { body } = relatedContent

  const bodyText = body ? toPlainText(body) : ""

  if (isMobile) {
    return (
      <DetailContainer>
        <ContentSideBanner relatedContent={relatedContent} />
        <CardColumn>
          {isExpanded ? (
            <>
              <ContentImage relatedContent={relatedContent} />
              <ExpandedContent
                postContent={relatedContent}
                setIsExpanded={setIsExpanded}
              />
            </>
          ) : (
            <CollapsedContainer>
              <ContentItemDescription relatedContent={relatedContent} />
              <ContentImage relatedContent={relatedContent} />
              <ClampedText>{bodyText}</ClampedText>
            </CollapsedContainer>
          )}
          <ShowMoreButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </CardColumn>
      </DetailContainer>
    )
  }

  return (
    <CardRow>
      <ContentSideBanner relatedContent={relatedContent} />
      <ContentContainer>
        <ContentImage relatedContent={relatedContent} />
        <ContentDetailsContainer>
          {isExpanded ? (
            <ExpandedContent
              postContent={relatedContent}
              setIsExpanded={setIsExpanded}
            />
          ) : (
            <div>
              <ContentItemDescription relatedContent={relatedContent} />
              <ClampedText>{bodyText}</ClampedText>
            </div>
          )}
          <ShowMoreButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </ContentDetailsContainer>
      </ContentContainer>
    </CardRow>
  )
}

const ContentSideBanner: React.FC<{
  relatedContent: GuidanceWithReferences | AnalysisWithReferences
}> = ({ relatedContent }) => {
  const isGuidance = relatedContent._type === "guidance"

  return (
    <ContentSideBar
      icon={
        isGuidance ? (
          <StackedBarChartOutlinedStyled iconcolor={extraColors.medium} />
        ) : (
          <AnalysisIconStyled iconcolor={extraColors.medium} />
        )
      }
      text={isGuidance ? "GUIDANCE" : "ANALYSIS"}
    />
  )
}

const ContentItemDescription: React.FC<{
  relatedContent: AnalysisWithReferences | GuidanceWithReferences
}> = ({ relatedContent }) => {
  const { _id, publishedAt, updatedAt, categories, title } = relatedContent

  const tags = useMemo(() => {
    return categories?.flatMap(item => item.title?.toString() ?? [])
  }, [categories])

  const geotagNames = useMemo(() => {
    const geotags =
      relatedContent._type === "headlines" ? relatedContent.geotags : []
    return geotags?.flatMap(geotag => geotag.name ?? [])
  }, [relatedContent])

  return (
    <div>
      <ContentHeader
        date={publishedAt || updatedAt}
        title={title ?? ""}
        id={_id}
      />
      <TagRow>
        <>
          {geotagNames?.map(name => <CategoryTag key={name} category={name} />)}
          {tags?.map(tag => <CategoryTag key={tag} category={tag} />)}
        </>
      </TagRow>
    </div>
  )
}

const ContentImage: React.FC<{
  relatedContent: AnalysisWithReferences | GuidanceWithReferences
}> = ({ relatedContent }) => {
  const theme = useTheme()
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"))
  const { sanityDataset } = useCapServiceContext()

  const { title, body } = relatedContent
  // @ts-expect-error `_type` not showing up in the type
  const image: PortableTextBlock | null = body?.find(b => b._type === "image")

  if (image) {
    return (
      <PortableText
        value={image}
        components={imageComponents(isMobile, sanityDataset)}
      />
    )
  }

  return (
    <DefaultImage
      src={getCriticalThreatImageFromSeed(title ?? "")}
      alt="crit-threat-img"
    />
  )
}

const ShowMoreButton: React.FC<{
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isExpanded, setIsExpanded }) => {
  const [scrollPosition, setScrollPosition] = useState<number>()

  useEffect(() => {
    // If we've collapsed the "show more" content, scroll back to our
    // starting point and unset the scroll position
    if (!isExpanded && scrollPosition) {
      scrollTo({ top: scrollPosition })
      setScrollPosition(undefined)
    }
  }, [isExpanded, scrollPosition])

  return (
    <ShowMore
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setIsExpanded(prev => !prev)
        // If we're expanding the "show more" content, preserve the position
        // so we can scroll back up to it when we collapse the window
        if (!isExpanded) {
          setScrollPosition(window.scrollY)
        }
      }}
    >
      <Typography
        variant="small1"
        className="show-more"
        sx={{
          color: extraColors.purpleMiddle,
          textDecoration: "underline",
          marginRight: "12px",
        }}
      >
        show {isExpanded ? "less" : "more"}
      </Typography>
    </ShowMore>
  )
}

function imageComponents(
  isMobile: boolean,
  sanityDataset: string
): Partial<PortableTextReactComponents> {
  return {
    types: {
      image: (props: { value: BlockContentImage }) => {
        const { value } = props
        if (!value.asset.url) return null
        const { alt, align } = value
        const url = cmsUrlBuilder(sanityDataset).image(value).url()

        return (
          <TransformWrapper disabled>
            <TransformComponent
              wrapperStyle={{
                borderRadius: "6px",
                width: isMobile ? "100%" : MAX_IMAGE_WIDTH,
                justifySelf: align,
                marginRight: isMobile ? 0 : "20px",
                height: isMobile ? 80 : "unset",
                marginBottom: isMobile ? "10px" : 0,
              }}
            >
              <Image src={url} alt={alt} />
            </TransformComponent>
          </TransformWrapper>
        )
      },
    },
  }
}
