import {
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from "@mui/material"
import { AssetNotificationType } from "@phc-health/connect-query"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import { BORDER_RADIUS, extraColors } from "../../../../utils/theme"
import { PageContainer } from "../../../PageContainer"
import { LocationsTable } from "../../../WatchedLocations/WatchedLocations"
import { useNotificationContext } from "../contexts/NotificationContext"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { LocationSelectorDialogControls } from "./LocationSelectorDialogControls"

const SelectorContainerStyled = styled(PageContainer)({
  borderRadius: BORDER_RADIUS,
  maxHeight: 500,
  marginBottom: 0,
})

const LocationsTableStyled = styled(LocationsTable)({
  minHeight: "400px",
  border: "none",
  padding: "6px 0 0",
})

const TextContainer = styled("div")({
  display: "grid",
  gridAutoFlow: "row",
  gap: 10,
})

const SelectText = styled("span")(({ theme }) => ({
  ...theme.typography.body1Bold,
  color: extraColors.purpleMiddle,
}))

interface LocationSelectorDialogProps {
  dialogIsOpen: boolean
  setDialogIsOpen: (newState: boolean) => void
  title: string
  notificationType: AssetNotificationType
}

export const LocationSelectorDialog: React.FC<LocationSelectorDialogProps> = ({
  dialogIsOpen,
  setDialogIsOpen,
  title,
}) => {
  const { notificationType } = useNotificationTypeContext()
  const {
    assetsLoading,
    nonGlobalAssets,
    selectedAssets,
    dialogSelectedAssets,
    setDialogSelectedAssets,
  } = useNotificationContext(notificationType)

  return (
    <Dialog
      open={dialogIsOpen}
      PaperProps={{
        style: {
          padding: 35,
          maxHeight: "unset",
          height: "fit-content",
          maxWidth: "1200px",
          width: "100%",
        },
      }}
    >
      <LocationSelectorHeader title={title} />
      <SelectorContainerStyled>
        <LocationsTableStyled
          isLocationSelector
          assets={nonGlobalAssets}
          assetsLoading={assetsLoading}
          selectedAssets={selectedAssets}
          setDialogSelectedAssets={setDialogSelectedAssets}
          dialogSelectedAssets={dialogSelectedAssets}
        />
      </SelectorContainerStyled>
      <LocationSelectorDialogControls setDialogIsOpen={setDialogIsOpen} />
    </Dialog>
  )
}

const LocationSelectorHeader: React.FC<{ title: string }> = ({ title }) => {
  return (
    <TextContainer sx={{ marginBottom: "10px" }}>
      <Typography variant="h3Bold" sx={{ color: extraColors.medium }}>
        Select locations
      </Typography>
      <Typography variant="body1">
        Select the locations you’d like to receive
        <SelectText>{` ${title} `}</SelectText>
        for.
      </Typography>
      <GlobalRelevanceCheckbox />
    </TextContainer>
  )
}

const GlobalRelevanceCheckbox: React.FC = () => {
  const { showGloballyTaggedContentSetting } = useFlags()
  const { notificationType } = useNotificationTypeContext()
  const { globallyTaggedEnabled, setGloballyTaggedEnabled } =
    useNotificationContext(notificationType)

  if (
    !showGloballyTaggedContentSetting ||
    notificationType !== AssetNotificationType.ALERT
  )
    return

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={globallyTaggedEnabled}
            onChange={() => setGloballyTaggedEnabled(prev => !prev)}
          />
        }
        label="Also receive notifications for Expert Insights with Global relevance"
      />
    </FormGroup>
  )
}
