import { styled } from "@mui/material"
import { useNotificationContext } from "../../contexts/NotificationContext"
import { useNotificationTypeContext } from "../../contexts/NotificationTypeContext"
import { SettingCheckbox } from "../../SettingCheckbox"
import { AlertDefinitions } from "./AlertDefinitions"
import { AlertLevelRadioGroup } from "./AlertLevelRadioGroup"

const AlertsContainer = styled("div")({
  display: "grid",
  gridAutoFlow: "row",
  gap: 12,
})

const AlertLevelDetailContainer = styled("div")({
  marginLeft: 26,
})

export const Alerts: React.FC = () => {
  const { notificationType } = useNotificationTypeContext()
  const { alertsEnabled, setAlertsEnabled } =
    useNotificationContext(notificationType)

  return (
    <AlertsContainer>
      <SettingCheckbox
        settingEnabled={alertsEnabled || false}
        setSettingEnabled={setAlertsEnabled}
        title="Alerts"
      />
      {alertsEnabled && (
        <AlertLevelDetailContainer>
          <AlertLevelRadioGroup />
          <AlertDefinitions />
        </AlertLevelDetailContainer>
      )}
    </AlertsContainer>
  )
}
