import type { SVGProps } from "react"
const SvgCheckboxIndeterminate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    viewBox="0 0 19 18"
    {...props}
  >
    <rect
      width={17}
      height={17}
      x={0.912}
      y={0.5}
      fill="rgba(24, 52, 94, 1)"
      rx={1.5}
    />
    <path fill="#fff" d="M4.912 7.875h8.75v1.75h-8.75v-1.75Z" />
    <rect
      width={17}
      height={17}
      x={0.912}
      y={0.5}
      stroke="rgba(24, 52, 94, 1)"
      rx={1.5}
    />
  </svg>
)
export default SvgCheckboxIndeterminate
