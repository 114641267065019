import { Skeleton, styled, Typography } from "@mui/material"
import type { Organization } from "@phc-health/connect-query"
import type React from "react"
import { useSearchParams } from "../hooks/useSearchParams"
import { dayjsUTC } from "../utils/helpers"

import ContentCard from "./ContentCard"

const Data = styled("div")({
  display: "grid",
  gap: 27,
  marginBottom: 48,
})

const DataContainer = styled("div")({
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "300px 1fr",
})

const DataRow: React.FC<{ label: string; value: string; loading: boolean }> = ({
  label,
  value,
  loading,
}) => {
  const Component: React.FC = () => (
    <DataContainer>
      <Typography variant="body1" color="textPrimary">
        {label}:
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {value}
      </Typography>
    </DataContainer>
  )
  return loading ? (
    <Skeleton>
      <Component />
    </Skeleton>
  ) : (
    <Component />
  )
}

const OrganizationAccountDetails: React.FC<{
  organization?: Organization
}> = ({ organization }) => {
  const { organizationName } = useSearchParams()
  // const { mutate } = useUpdateOrganization(organizationName ?? "")
  return (
    <ContentCard
      data-testid="organization-account-details"
      title={organizationName ?? ""}
      sections={[
        {
          title: "Account Details",
          content: (
            <div>
              <Data>
                <DataRow
                  loading={!organization}
                  label="Members"
                  value={`${(organization?.userCount ?? 0).toLocaleString()} Members`}
                />
                <DataRow
                  loading={!organization}
                  label="Joined"
                  value={dayjsUTC(
                    Number(organization?.startTime?.seconds) * 1000
                  ).format("MM/DD/YYYY")}
                />
                <DataRow
                  loading={!organization}
                  label="Expiration"
                  value={dayjsUTC(
                    Number(organization?.expireTime?.seconds) * 1000
                  ).format("MM/DD/YYYY")}
                />
              </Data>
            </div>
          ),
        },
      ]}
    />
  )
}

export default OrganizationAccountDetails
