import {
  hashKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const persistentContextKeys = [
  "accept-terms",
  "dismissed-stale-flu-data-banner",
  "dismissed-location-banner",
  "dismissed-public-health-emergency-banner",
  "dismissed-critical-threat-banner",
  "notifications-bump",
  "last-alert-definition-banner-date",
  "place-type-migration",
] as const
type PersistentContextKey =
  | (typeof persistentContextKeys)[number]
  // allow for arbitrary keys
  | (string & Record<never, never>)
  | undefined

const usePersistentContext = (key: PersistentContextKey[]) => {
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery({
    queryKey: key,
    queryFn: () => localStorage.getItem(hashKey(key)),
    enabled: !key.includes(undefined),
  })

  const { mutateAsync: setValue } = useMutation({
    mutationFn: (value: string) => {
      localStorage.setItem(hashKey(key), value)
      return Promise.resolve(value)
    },
    onMutate: mutatedData => {
      const current = data
      queryClient.setQueryData(key, mutatedData)
      return current
    },

    onError: (_, __, rollback) => {
      queryClient.setQueryData(key, rollback)
    },
  })

  return [data, setValue, isLoading || key.includes(undefined)] as const
}

export default usePersistentContext
