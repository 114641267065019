import { Autocomplete, TextField } from "@mui/material"
import { AssetType } from "@phc-health/connect-query"

import type React from "react"
import {
  assetTypeEnumToString,
  assetTypeStringList,
  getAssetStringToEnumMap as getAssetTypeStringToEnumMap,
} from "../../../utils/helpers/assetHelper"
import { MIXED_TEXT } from "./EditAssetsDialog"

interface AssetTypeAutocompleteProps {
  selectedAssetType: AssetType
  setSelectedAssetType: (type: AssetType) => void
  isDisabled: boolean
  showMixed?: boolean
}

export const AssetTypeAutocomplete: React.FC<AssetTypeAutocompleteProps> = ({
  selectedAssetType,
  setSelectedAssetType,
  isDisabled,
  showMixed,
}) => {
  const assetTypeList = assetTypeStringList.concat([MIXED_TEXT])
  const assetStringToEnumMap = getAssetTypeStringToEnumMap()
  if (isDisabled) return null

  return (
    <Autocomplete
      id="tags-outlined"
      disableClearable
      openOnFocus
      autoHighlight
      selectOnFocus
      options={assetTypeList}
      isOptionEqualToValue={(option, value) => {
        return option === value
      }}
      value={
        showMixed && !selectedAssetType
          ? MIXED_TEXT
          : assetTypeEnumToString(selectedAssetType)
      }
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Asset Type" />
      )}
      onChange={(_, newValue) => {
        const assetString = assetStringToEnumMap.get(
          newValue || assetTypeEnumToString(AssetType.ASSET_TYPE_UNSPECIFIED)
        )
        setSelectedAssetType(assetString || AssetType.ASSET_TYPE_UNSPECIFIED)
      }}
      filterOptions={(options, state) => {
        const filtered = options.filter(
          option =>
            option.toLowerCase().includes(state.inputValue.toLowerCase()) &&
            option !== MIXED_TEXT
        )
        if (filtered.length === 0 && state.inputValue) {
          return [assetTypeEnumToString(AssetType.ASSET_TYPE_UNSPECIFIED)]
        }
        return filtered
      }}
    />
  )
}
