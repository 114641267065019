import { Asset, AssetGroup, Geotag } from "@phc-health/connect-query"
import { getIdsFromGeocoderResult, getMapboxGeocodeResult } from "@phc/common"
import { MAPBOX_ACCESS_TOKEN } from "../../../../utils/env"
import { convertGeocoderResultToLocation } from "../../../../utils/helpers/assetHelper"
import type { AssetCSVRow } from "./parseBulkCSV"

const reverseGeocodeToPHCLocation = async (asset: AssetCSVRow) => {
  const query = `${asset.Longitude},${asset.Latitude}`
  const result = await getMapboxGeocodeResult({
    query,
    accessToken: MAPBOX_ACCESS_TOKEN,
  })

  if (result?.features.length === 0 || !result?.features[0]) {
    throw new Error(`No results found for: ${query}`)
  }
  const [feature] = await getIdsFromGeocoderResult(
    { result: result.features[0] },
    MAPBOX_ACCESS_TOKEN
  )

  if (!feature) {
    throw new Error(`No feature found for: ${query}`)
  }

  const location = convertGeocoderResultToLocation({
    result: result.features[0],
  })

  // remove potentially wrong geocoding data
  location.address = ""
  return new Geotag({
    locationId: feature.properties.location_code,
    wktCentroid: `POINT (${asset.Longitude} ${asset.Latitude})`,
    mapboxLocation: location,
  })
}

/**
 * Try to match existing groups, otherwise create new ones
 * return all groups
 */
const getGroups = (asset: AssetCSVRow): AssetGroup[] => {
  const groups = asset.Groups.concat(BULK_ASSET_GROUP)
    .map(g => new AssetGroup({ name: g })) // dedupe by name
    .filter((group, idx, arr) => {
      return arr.findIndex(g => g.name === group.name) === idx
    })
  return groups
}

export const LOCAL_STORAGE_PREPARED_ASSETS_KEY = "preparedAssets"

export const getLocalStorageAssets = () => {
  const localStorageAssets = window.localStorage.getItem(
    LOCAL_STORAGE_PREPARED_ASSETS_KEY
  )
  return localStorageAssets
    ? (JSON.parse(localStorageAssets) as Asset[])
    : undefined
}

/** Metadata to keep track of bulk added items */
export const BULK_ASSET_GROUP = "Bulk"

export const makeAssetFromBulkCSV = async (
  csvAsset: AssetCSVRow,
  localStorageAssets: Asset[] | undefined
) => {
  const groups = getGroups(csvAsset)
  // look for exact match in local storage
  if (localStorageAssets) {
    const assetExists = localStorageAssets.find(
      (a: Asset) =>
        a.name === csvAsset.Name &&
        groups
          .map(g => g.name)
          .sort()
          .join("") ===
          a.assetGroups
            .map(g => g.name)
            .sort()
            .join("") &&
        csvAsset.Type === a.assetTypes[0] &&
        a.threatRadius === csvAsset["Threat Radius"] &&
        a.baseEvent?.geotags[0]?.wktCentroid ===
          `POINT (${csvAsset.Longitude} ${csvAsset.Latitude})`
    )
    if (assetExists) {
      console.log("asset in local storage")
      return assetExists
    }
  }
  const locationData = await reverseGeocodeToPHCLocation(csvAsset)
  const newAsset = new Asset({
    name: csvAsset.Name,
    assetTypes: [csvAsset.Type],
    baseEvent: {
      geotags: [locationData],
    },
    assetGroups: groups,
    threatRadius: csvAsset["Threat Radius"],
  })

  // save to local storage so we don't have to re-geocode
  const localStorageAssets2 = window.localStorage.getItem(
    LOCAL_STORAGE_PREPARED_ASSETS_KEY
  )
  const assets = localStorageAssets2
    ? (JSON.parse(localStorageAssets2) as Asset[])
    : []
  // save replace existing asset if name exists (in case edits were made with new upload)
  const assetIdx = assets.findIndex((a: Asset) => a.name === newAsset.name)
  if (assetIdx > -1) {
    assets[assetIdx] = newAsset
  } else {
    assets.push(newAsset)
  }
  window.localStorage.setItem(
    LOCAL_STORAGE_PREPARED_ASSETS_KEY,
    JSON.stringify(assets)
  )
  return newAsset
}
