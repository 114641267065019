import { useMutation } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { useSnackbar } from "notistack"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useCreateUser() {
  const { enqueueSnackbar } = useSnackbar()

  const transport = useTransport(ACCESS_API)
  return useMutation(AccessManagementServiceQuery.createUser, {
    transport,
    onError: err => {
      console.error(err)
      enqueueSnackbar(err.message, { variant: "error" })
    },
    onSuccess: () => {
      enqueueSnackbar("User invited sent", {
        variant: "success",
      })
    },
  })
}
