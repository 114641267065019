import { Typography } from "@mui/material"
import type { Asset } from "@phc-health/connect-query"
import { useState } from "react"
import { extraColors } from "../../../../utils/theme"
import { SettingCheckbox } from "../../../Settings/notifications/SettingCheckbox"
import { useLocationNotificationSettings } from "./hooks/useLocationNotificationSettings"
import { OptionSection, SummarySelectionContainer } from "./Shared"

export const LocationExpertInsightsSettings: React.FC<{
  asset: Asset
}> = ({ asset }) => {
  const {
    globalAlertsEnabled,
    globalAnalysisEnabled,
    summaryAlertsEnabled,
    summaryAnalysisEnabled,
    saveAssetNotifications,
    updateIsPending,
    currentAssetExpertInsightsEnabled,
    isLoading,
  } = useLocationNotificationSettings(asset)

  const globalExpertInsightsEnabled =
    globalAlertsEnabled || globalAnalysisEnabled

  const [expertInsightsEnabled, setExpertInsightsEnabled] = useState(
    currentAssetExpertInsightsEnabled || globalExpertInsightsEnabled
  )

  const summaryEnabled =
    summaryAlertsEnabled ||
    globalAlertsEnabled ||
    summaryAnalysisEnabled ||
    globalAnalysisEnabled

  return (
    <OptionSection>
      <SettingCheckbox
        settingEnabled={expertInsightsEnabled || globalExpertInsightsEnabled}
        setSettingEnabled={setExpertInsightsEnabled}
        title="Receive Expert Insights for this location."
        disabled={globalExpertInsightsEnabled || updateIsPending}
        disabledText={
          isLoading || updateIsPending
            ? ""
            : "You are already receiving global Expert Insights."
        }
        customSettingFn={() => {
          setExpertInsightsEnabled(prev => !prev)
          saveAssetNotifications({ toggleExpertInsights: true })
        }}
      />
      {summaryEnabled && (
        <SummarySelectionContainer>
          <Typography variant="small1" sx={{ marginBottom: "6px" }}>
            Receiving expert insights on the following content:
          </Typography>
          {(summaryAlertsEnabled || globalAlertsEnabled) && (
            <Typography variant="small1" sx={{ color: extraColors.medium }}>
              Alerts
            </Typography>
          )}
          {(summaryAnalysisEnabled || globalAnalysisEnabled) && (
            <Typography variant="small1" sx={{ color: extraColors.medium }}>
              Analysis
            </Typography>
          )}
        </SummarySelectionContainer>
      )}
    </OptionSection>
  )
}
