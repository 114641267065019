const CONFIDENCE_TEXT = "[Confidence:"

export interface JustificationConfidence {
  parsedConfidence?: number
  justificationWithoutConfidence?: string
}

export function getConfidenceFromJustification(justification?: string) {
  if (!justification?.includes(CONFIDENCE_TEXT))
    return {
      parsedConfidence: undefined,
      justificationWithoutConfidence: justification,
    }

  const confidenceTextStart = justification.indexOf(CONFIDENCE_TEXT)

  return {
    parsedConfidence: getConfidenceFromString(
      justification.slice(
        confidenceTextStart + CONFIDENCE_TEXT.length,
        justification.indexOf("]")
      )
    ),
    justificationWithoutConfidence: justification.slice(0, confidenceTextStart),
  }
}

function getConfidenceFromString(confidenceStr: string) {
  switch (confidenceStr.toLowerCase().trim()) {
    case "very high":
      return 5
    case "high":
      return 4
    case "acceptable":
    case "moderate":
      return 3
    case "low":
      return 2
    case "very low":
      return 1
    default:
      return 0
  }
}
