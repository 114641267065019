import { useMutation } from "@connectrpc/connect-query"
import { AssetServiceQuery } from "@phc-health/connect-query"
import { useSnackbar } from "notistack"
import { useTransport } from "../../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../../utils/constants"

export const useUpdateNotificationSettings = () => {
  const { enqueueSnackbar } = useSnackbar()
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.updateNotificationSettings, {
    transport,
    onError: (err: any) => {
      enqueueSnackbar("Unable to update your notification settings.", {
        variant: "error",
      })
      console.error(err)
    },
  })
}
