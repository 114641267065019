import { useAuth0 } from "@auth0/auth0-react"
import type React from "react"
import { useLocation } from "react-router-dom"
import { MainContent } from "../components/MainContent"
import { NotFoundContent } from "../components/PageNotFound"
import { usePermissions } from "../hooks/usePermissions"
import { useSearchParams } from "../hooks/useSearchParams"
import { useUser } from "../hooks/useUser"

export const NotFound: React.FC = () => {
  const location = useLocation()
  const params = useSearchParams()
  const auth0 = useAuth0()
  const user = useUser({
    orgId: params.organizationId,
    email: auth0.user?.email,
  })
  const permissions = usePermissions()
  console.error({ location, params, auth0, user: user.data, permissions })
  return (
    <>
      <MainContent
        headerProps={{
          title: "Page Not Found",
        }}
      >
        <NotFoundContent />
      </MainContent>
    </>
  )
}
