import { styled, TextField } from "@mui/material"
import type React from "react"
import MagnifyingGlass from "../icons/MagnifyingGlass"

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    marginRight: 8,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minWidth: "unset",
  },
}))

export const SearchInput: React.FC<{
  searchText?: string
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  placeholder?: string
  className?: string
}> = ({ searchText, onChange, placeholder, className }) => {
  return (
    <TextFieldStyled
      name="search"
      data-testid="tool-bar-search-field"
      value={searchText}
      onChange={onChange}
      placeholder={placeholder || "Search"}
      InputProps={{
        endAdornment: <MagnifyingGlass />,
      }}
      autoComplete="off"
      className={className}
    />
  )
}
