import { styled, Typography } from "@mui/material"
import { isAlertLevel } from "@phc/common"
import type React from "react"
import { GLOBAL_GEOTAG } from "../../../../../common/src/constants.js"
import { useLocationId } from "../../../hooks/useLocationId.js"
import { RiskDetails } from "./RiskDetails.js"

import { extraColors } from "../../../utils/theme"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { AlertCard } from "../../Map/Cards/AlertCard"
import { AnalysisCard } from "../../Map/Cards/AnalysisCard.js"

const DetailsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr minmax(350px, 1fr)",
  gap: 24,
  marginBottom: 24,
  "@media print": {
    gridTemplateColumns: "unset",
  },
  [theme.breakpoints.down("md")]: {
    gap: 12,
    gridTemplateColumns: "unset",
    padding: 6,
  },
}))

export const LocationRiskDetails: React.FC = () => {
  const locationId = useLocationId()
  const { useRelatedAlerts, useRelatedAnalyses } = useCapServiceContext()
  const alerts = useRelatedAlerts(locationId)
  const analyses = useRelatedAnalyses(locationId)
  const isGlobal = locationId === GLOBAL_GEOTAG.locationId
  // TODO: Add loading state
  if (!locationId) return null

  return (
    <DetailsContainer>
      {!isGlobal && (
        <div>
          <RiskDetails locationId={locationId} />
        </div>
      )}
      <div>
        <RelatedContent title="Related Alerts">
          {alerts.data?.map(alert => (
            <AlertCard
              key={alert._id}
              id={alert._id}
              geotags={alert.geotags}
              title={alert.title ?? ""}
              date={alert.publishedAt ?? alert.updatedAt ?? ""}
              alertLevel={
                isAlertLevel(alert.alertLevel) ? alert.alertLevel : undefined
              }
              justification={alert.justification}
              recommendation={alert.recommendation}
              sourceURL={alert.link}
              threat={alert.categories?.[0]?.title || ""}
            />
          ))}
        </RelatedContent>
        <RelatedContent title="Related Analyses">
          {analyses.data?.map(card => (
            <AnalysisCard
              key={card._id}
              title={card.title ?? ""}
              geotags={card.geotags}
              date={card.publishedAt ?? card.updatedAt ?? ""}
              id={card._id}
              sourceURL={card.slug?.current}
              threat={card.categories?.[0]?.title || ""}
              content={card.excerpt || ""}
              slug={card.slug?.current}
            />
          ))}
        </RelatedContent>
      </div>
    </DetailsContainer>
  )
}

const AlertCardsContainer = styled("div")(() => ({
  paddingTop: 12,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gap: 24,
}))

export const RelatedContent: React.FC<{
  title: string
  children?: React.ReactNode[]
}> = ({ title, children }) => {
  if (!children?.length) return null
  return (
    <div
      style={{
        marginBottom: "48px",
        marginTop: "4px",
      }}
    >
      <Typography variant="h3Bold" color={extraColors.medium}>
        {title}
      </Typography>
      <AlertCardsContainer>{children}</AlertCardsContainer>
    </div>
  )
}
