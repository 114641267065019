import {
  gridClasses,
  useGridApiContext,
  useGridRootProps,
  type GridRowClassNameParams,
  type GridRowProps,
} from "@mui/x-data-grid-pro"
import { useGridVisibleRows } from "@mui/x-data-grid/hooks/utils/useGridVisibleRows"
import { clsx } from "clsx"
import * as React from "react"

/**
 * Use this component as a replacement for the `GridRow` component from `@mui/x-data-grid-pro`
 * to format the row as a card, but still have it integrate with the data grid.
 *
 * See Alerts page for an example of this component in use.
 */
export const GridRowCard: React.FC<GridRowProps> = ({
  children,
  className,
  style,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const dataGridRef = useGridApiContext()

  // copied from: https://github.com/mui/mui-x/blob/18cb971960d103e6aeb022d4807b1cc830c7d04c/packages/grid/x-data-grid/src/components/GridRow.tsx#L372-L384
  const rootProps = useGridRootProps()
  const currentPage = useGridVisibleRows(dataGridRef, rootProps)
  const indexRelativeToCurrentPage =
    props.index - (currentPage.range?.firstRowIndex || 0)
  const rowParams: GridRowClassNameParams = {
    ...dataGridRef.current.getRowParams(props.rowId),
    isFirstVisible: indexRelativeToCurrentPage === 0,
    isLastVisible: indexRelativeToCurrentPage === currentPage.rows.length - 1,
    indexRelativeToCurrentPage,
  }
  const rowClasses = rootProps.getRowClassName?.(rowParams)

  // copied from: https://github.com/mui/mui-x/blob/18cb971960d103e6aeb022d4807b1cc830c7d04c/packages/grid/x-data-grid/src/components/GridRow.tsx#L160C11-L192
  React.useLayoutEffect(() => {
    if (currentPage.range) {
      const rowIndex = dataGridRef.current.getRowIndexRelativeToVisibleRows(
        props.rowId
      )
      // I trust this type
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (rowIndex != null) {
        dataGridRef.current.unstable_setLastMeasuredRowIndex(rowIndex)
      }
    }
    const rootElement = ref.current
    const hasFixedHeight = props.rowHeight !== "auto"
    if (
      !rootElement ||
      hasFixedHeight ||
      typeof ResizeObserver === "undefined"
    ) {
      return undefined
    }

    const resizeObserver = new ResizeObserver(entries => {
      const [entry] = entries
      const height =
        entry?.borderBoxSize && entry.borderBoxSize.length > 0
          ? entry.borderBoxSize[0]?.blockSize
          : entry?.contentRect.height
      if (!height) return
      dataGridRef.current.unstable_storeRowHeightMeasurement(
        props.rowId,
        height
      )
    })
    resizeObserver.observe(rootElement)
    return () => resizeObserver.disconnect()
  }, [
    dataGridRef,
    currentPage.range,
    props.index,
    props.rowHeight,
    props.rowId,
    props.position,
    ref,
  ])

  return (
    <div
      ref={ref}
      className={clsx(
        rootProps.className,
        gridClasses.row,
        rowClasses,
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}
