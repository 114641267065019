import { getTrendIconSize, type IconSize } from "../IconSize"

const Increasing: React.FC<{
  fill?: string
  size?: IconSize
}> = ({ size, fill }) => {
  const sizeProps = getTrendIconSize(size)

  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...sizeProps}>
      <path
        d="M10.8555 0.9375V8.8125L9.98047 9.6875L7.13672 6.84375L2.98047 11L0.792969 8.8125L4.94922 4.65625L2.10547 1.8125L2.98047 0.9375H10.8555Z"
        fill={fill || "#78909C"}
      />
    </svg>
  )
}

export default Increasing
