import { useEffect, useState } from "react"
import type { MapRef } from "react-map-gl"
import { useMap } from "react-map-gl"
import { ALERT_SOURCE_ID } from "../AlertMarkers"

import { useMapContext } from "../../../../contexts/MapContext"

export interface AlertClusterMarkerProps {
  cluster_id?: number
  lng: number
  lat: number
  alertIds: string[]
}

/**
 * Creates a cluster marker for the Mapbox map.
 *
 * The callback function needs to do several things:
 * 1. Filter out alerts that are not relevant to the current zoom level.
 * 2. Add a single marker if the cluster only has 1 alert.
 * 3. Add a cluster marker if the cluster has more than 1 alert.
 */
const makeClusterMarker = (
  map: MapRef,
  cluster_id: number | undefined,
  clusteredFeatures: GeoJSON.Feature[] | undefined
): AlertClusterMarkerProps | null => {
  const currentZoom = map.getZoom()
  const visibleFeatures = clusteredFeatures?.filter(
    f => f.properties?.maxZoom >= currentZoom
  )
  const alertCount = visibleFeatures?.length
  if (alertCount === 0) return null

  const firstFeature = visibleFeatures?.[0]

  // check if feature is valid (and to make ts happy)
  if (
    !firstFeature ||
    firstFeature.geometry.type !== "Point" ||
    !firstFeature.properties ||
    typeof firstFeature.properties._id !== "string" ||
    typeof firstFeature.properties.locationId !== "string"
  )
    return null
  const lng = firstFeature.geometry.coordinates[0]
  const lat = firstFeature.geometry.coordinates[1]
  if (!lng || !lat) return null

  // add single marker if cluster only ends up having 1 alert
  if (alertCount === 1) {
    return {
      alertIds: [firstFeature.properties._id],
      lng,
      lat,
    }
  }

  const alertIds = visibleFeatures
    .map(f => (typeof f.properties?._id === "string" ? f.properties._id : ""))
    .filter(Boolean)

  return {
    cluster_id,
    lng,
    lat,
    alertIds,
  }
}

export const useAlertCluster = () => {
  const { alertFilters } = useMapContext()

  const mapRef = useMap()
  const [clusterMarkerProps, setClusterMarkerProps] = useState<
    AlertClusterMarkerProps[]
  >([])

  useEffect(() => {
    const map = mapRef.current
    if (!map) return

    const setAlerts = async () => {
      const features = map.querySourceFeatures(
        ALERT_SOURCE_ID
      ) as unknown as GeoJSON.Feature<
        GeoJSON.Point,
        {
          cluster_id?: number
          point_count?: number
          maxZoom?: number
          _id?: string
          locationId?: string
        }
      >[]

      // make clusters
      const clusterPromises = features.map(feature => {
        return new Promise<ReturnType<typeof makeClusterMarker>>(
          (resolve, reject) => {
            const source = map.getSource(ALERT_SOURCE_ID)
            const pointCount = feature.properties.point_count
            const cluster_id = feature.properties.cluster_id
            if (!cluster_id) {
              // just make a single marker
              resolve(makeClusterMarker(map, undefined, [feature]))
            }
            if (source?.type == "geojson" && cluster_id && pointCount) {
              source.getClusterLeaves(
                cluster_id,
                pointCount,
                0,
                (error, clusteredFeatures) => {
                  // will be refactored with maplibre
                  if (error) {
                    if (error.message === "No cluster with the specified id.") {
                      resolve(null)
                      return
                    }
                    console.error(error)
                    // will be refactored with maplibre
                    reject(error)
                    return
                  }
                  if (!clusteredFeatures) {
                    resolve(null)
                    return
                  }
                  const newClusters = makeClusterMarker(
                    map,
                    cluster_id,
                    clusteredFeatures
                  )
                  resolve(newClusters)
                }
              )
            } else {
              resolve(null)
            }
          }
        )
      })

      const clusterMarkers = await Promise.all(clusterPromises)
      // dedupe cluster markers by lat and lng
      const dedupedClusterMarkers = clusterMarkers.flatMap(
        (m, clusterIdx, self) =>
          m &&
          self.findIndex(
            other => other?.lat === m.lat && other.lng === m.lng
          ) === clusterIdx
            ? [m]
            : []
      )

      setClusterMarkerProps(current => {
        // deep array by length and lat/lng
        if (
          current.length === dedupedClusterMarkers.length &&
          current.every(
            (c, i) =>
              c.lat === dedupedClusterMarkers[i]?.lat &&
              c.lng === dedupedClusterMarkers[i]?.lng
          )
        ) {
          return current
        }
        return dedupedClusterMarkers
      })
    }

    const handleRender = () => {
      setAlerts().catch(console.error)
    }

    map.on("render", handleRender)

    return () => {
      map.off("render", handleRender)
    }
  }, [alertFilters, mapRef])
  return {
    clusterMarkerProps,
  }
}
