import type { SVGProps } from "react"
const SvgVisibleOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.10999 11.4375C3.95424 6.90818 7.75922 4 12 4C16.2408 4 20.0458 6.90818 21.89 11.4375C22.0367 11.7976 22.0367 12.2024 21.89 12.5625C20.0458 17.0918 16.2408 20 12 20C7.75922 20 3.95424 17.0918 2.10999 12.5625C1.96334 12.2024 1.96334 11.7976 2.10999 11.4375ZM12 18.1497C15.419 18.1497 18.5537 15.7868 20.1499 12C18.5537 8.21325 15.419 5.85034 12 5.85034C8.58095 5.85034 5.44625 8.21325 3.85009 12C5.44625 15.7868 8.58095 18.1497 12 18.1497ZM12 15.6463C10.0212 15.6463 8.41711 14.0138 8.41711 12C8.41711 9.98623 10.0212 8.35374 12 8.35374C13.9788 8.35374 15.5829 9.98623 15.5829 12C15.5829 14.0138 13.9788 15.6463 12 15.6463ZM12 13.7959C12.9746 13.7959 13.7647 12.9919 13.7647 12C13.7647 11.0081 12.9746 10.2041 12 10.2041C11.0254 10.2041 10.2353 11.0081 10.2353 12C10.2353 12.9919 11.0254 13.7959 12 13.7959Z"
      fill="#0E2348"
    />
  </svg>
)
export default SvgVisibleOutlined
