import { Button, Typography, styled } from "@mui/material"
import type React from "react"
import { useCallback } from "react"
import { extraColors } from "../../../../utils/theme"
import { useNotificationContext } from "../contexts/NotificationContext"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"

const SelectCloseContainer = styled("div")({
  display: "flex",
  alignItems: "center",
})

const DialogBottomRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 30,
})

export const LocationSelectorDialogControls: React.FC<{
  setDialogIsOpen: (newState: boolean) => void
}> = ({ setDialogIsOpen }) => {
  const { notificationType } = useNotificationTypeContext()
  const {
    nonGlobalAssets,
    selectedAssets,
    setSelectedAssets,
    dialogSelectedAssets,
    setDialogSelectedAssets,
  } = useNotificationContext(notificationType)

  // Reset dialog selection when we close without confirming
  const resetSelection = useCallback(() => {
    setDialogSelectedAssets?.(...[selectedAssets])
  }, [selectedAssets, setDialogSelectedAssets])

  // Update asset selection with the dialog selection values when we confirm our desired assets
  const confirmSelectedAssets = useCallback(() => {
    setSelectedAssets?.(...[dialogSelectedAssets])
  }, [dialogSelectedAssets, setSelectedAssets])

  return (
    <DialogBottomRow>
      <CloseButton
        setDialogIsOpen={setDialogIsOpen}
        resetSelection={resetSelection}
      />
      <SelectCloseContainer>
        <Typography variant="body2Bold" sx={{ color: extraColors.medium }}>
          {`${(dialogSelectedAssets?.length || 0).toLocaleString()}/${(
            nonGlobalAssets?.length || 0
          ).toLocaleString()} selected`}
        </Typography>
        <SelectLocationsButton
          setDialogIsOpen={setDialogIsOpen}
          confirmSelectedAssets={confirmSelectedAssets}
        />
      </SelectCloseContainer>
    </DialogBottomRow>
  )
}

const CloseButton: React.FC<{
  setDialogIsOpen: (newState: boolean) => void
  resetSelection: () => void
}> = ({ setDialogIsOpen, resetSelection: clearSelection }) => {
  const onClick = useCallback(() => {
    setDialogIsOpen(false)
    clearSelection()
  }, [clearSelection, setDialogIsOpen])

  return (
    <Button
      variant="text"
      color="primary"
      onClick={onClick}
      sx={{
        padding: "0",
        width: "fit-content",
        height: "fit-content",
      }}
    >
      Cancel
    </Button>
  )
}

const SelectLocationsButton: React.FC<{
  setDialogIsOpen: (newState: boolean) => void
  confirmSelectedAssets: () => void
}> = ({ setDialogIsOpen, confirmSelectedAssets }) => {
  const onClick = useCallback(() => {
    setDialogIsOpen(false)
    confirmSelectedAssets()
  }, [confirmSelectedAssets, setDialogIsOpen])

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        marginLeft: "20px",
      }}
    >
      Select Locations
    </Button>
  )
}
