import {
  OrganizationStatusEnum,
  UserStatusEnum,
} from "@phc-health/connect-query"
import * as Sentry from "@sentry/react"
import { SeverityLevel } from "../constants"

export function getOrganizationStatusNameFromStatus(
  status?: OrganizationStatusEnum
) {
  switch (status) {
    case OrganizationStatusEnum.ORGANIZATION_ACTIVE:
      return "Active"
    case OrganizationStatusEnum.ORGANIZATION_DISABLED:
      return "Disabled"
    case OrganizationStatusEnum.ORGANIZATION_TRIAL:
      return "Trial"
    case OrganizationStatusEnum.ORGANIZATION_STATUS_UNSPECIFIED:
    case undefined:
    default:
      return "Unknown"
  }
}

export function getUserStatusNameFromStatus(status?: UserStatusEnum): string {
  switch (status) {
    case UserStatusEnum.USER_ACTIVE:
      return "Active"
    case UserStatusEnum.USER_DISABLED:
      return "Disabled"
    case UserStatusEnum.USER_EXPIRED:
      return "Expired"
    case UserStatusEnum.USER_INVITED:
      return "Invited"
    case UserStatusEnum.USER_STATUS_UNSPECIFIED:
    case undefined:
    default:
      return "Unknown"
  }
}

export function getUserStatusEnumFromString(status?: string): UserStatusEnum {
  switch (status) {
    case "Active":
      return UserStatusEnum.USER_ACTIVE
    case "Disabled":
      return UserStatusEnum.USER_DISABLED
    case "Expired":
      return UserStatusEnum.USER_EXPIRED
    case "Invited":
      return UserStatusEnum.USER_INVITED
    case "Unknown":
    case undefined:
    default:
      Sentry.captureMessage(
        `Status value ${status || "undefined"} not found`,
        SeverityLevel.Error
      )
      return UserStatusEnum.USER_STATUS_UNSPECIFIED
  }
}
