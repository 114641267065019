import "@fontsource/poppins"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import { createTheme } from "@mui/material"

import SvgRadioSelected from "../assets/RadioSelected"
import SvgRadioUnselected from "../assets/RadioUnselected"
import CheckboxChecked from "../assets/svg-components/CheckboxChecked"
import CheckboxIndeterminate from "../assets/svg-components/CheckboxIndeterminate"
import CheckboxUnchecked from "../assets/svg-components/CheckboxUnchecked"

export const FONT_FAMILY = "Poppins"
export const BORDER_RADIUS = 2

declare module "@mui/material/styles" {
  interface TypographyVariants {
    display: React.CSSProperties
    h1: React.CSSProperties
    h2: React.CSSProperties
    h2Bold: React.CSSProperties
    h3: React.CSSProperties
    h3Bold: React.CSSProperties
    h4: React.CSSProperties
    body1: React.CSSProperties
    body1Bold: React.CSSProperties
    body2: React.CSSProperties
    body2Bold: React.CSSProperties
    small1: React.CSSProperties
    small1Bold: React.CSSProperties
    tiny: React.CSSProperties
    tinyLink: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    display: React.CSSProperties
    h1: React.CSSProperties
    h2: React.CSSProperties
    h2Bold: React.CSSProperties
    h3: React.CSSProperties
    h3Bold: React.CSSProperties
    h4: React.CSSProperties
    body1: React.CSSProperties
    body1Bold: React.CSSProperties
    body2: React.CSSProperties
    body2Bold: React.CSSProperties
    small1: React.CSSProperties
    small1Bold: React.CSSProperties
    tiny: React.CSSProperties
    tinyLink: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display: true
    h1: true
    h2: true
    h2Bold: true
    h3: true
    h3Bold: true
    h4: true
    body1: true
    body1Bold: true
    body2: true
    body2Bold: true
    small1: true
    small1Bold: true
    tiny: true
    tinyLink: true
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    destructive: true
  }
}

export const extraColors = {
  risk: {
    low: "rgba(61, 219, 217, 1)",
    moderate: "rgba(8, 189, 186, 1)",
    medium: "rgba(8, 189, 186, 1)",
    high: "rgba(0, 125, 121, 1)",
    severe: "rgba(28, 94, 105, 1)",
  },
  status: {
    greenDark: "rgba(0, 107, 71, 1)",
    green: "rgba(51, 172, 136, 1)",
    greenLight: "rgba(172, 226, 196, 1)",
    yellowDark: "rgba(157, 98, 0, 1)",
    yellowMiddle: "rgba(255, 191, 84, 1)",
    yellowLight: "rgba(254, 231, 169, 1)",
    orangeDark: "rgba(180, 56, 0, 1)",
    orangeMiddle: "rgba(255, 131, 43, 1)",
    orangeLight: "rgba(255, 216, 189, 1)",
    redDark: "rgba(160, 0, 0, 1)",
    redMiddle: "rgba(218, 30, 40, 1)",
    redLight: "rgba(250, 168, 172, 1)",
    redSubtle: "rgba(253, 229, 230, 1)",
    tealDark: "rgba(0, 103, 117, 1)",
    teal: "rgba(0, 140, 159, 1)",
    tealLight: "rgba(184, 246, 255, 1)",
    tealSubtle: "rgba(234, 252, 255, 1)",
  },
  dark: "rgba(29, 34, 41, 1)",
  medium: "rgba(72, 86, 103, 1)",
  hint: "rgba(103, 119, 137, 1)",
  disabled: "rgba(175, 184, 193, 1)",
  light: "rgba(243, 244, 246, 1)",
  subtle: "rgba(248, 249, 250, 1)",
  highlightBlue: "rgba(229, 252, 255, 1)",
  highlightRed: "rgba(255, 225, 227, 1)",
  low: "rgba(61, 219, 217, 1)",
  moderate: "rgba(8, 189, 186, 1)",
  high: "rgba(0, 125, 121, 1)",
  severe: "rgba(28, 94, 105, 1)",
  white: "rgba(255, 255, 255, 1)",
  translucentWhite: "rgba(255, 255, 255, 0.7)",
  black: "rgba(0, 0, 0, 1)",
  shadow: "rgba(0, 0, 0, 0.20)",
  navy: "rgba(24, 52, 94, 1)",
  purple: "rgba(161, 0, 191, 1)",
  purpleLight: "rgba(217, 153, 229, 1)",
  purpleSubtle: "rgba(249, 236, 255, 1)",
  purpleMiddle: "rgba(129, 0, 153, 1)",
  purpleDark: "rgba(81, 0, 96, 1)",
  blueMiddle: "rgba(63, 119, 204, 1)",
  blueLight: "rgba(176, 195, 244, 1)",
  blueSubtle: "rgba(230, 235, 251, 1)",
  gold: "rgba(255, 191, 52, 1)",
} as const

export const theme = createTheme({
  palette: {
    primary: {
      main: extraColors.dark,
    },
    secondary: {
      main: extraColors.light,
    },
    info: {
      main: extraColors.navy,
    },
    text: {
      primary: extraColors.dark,
      secondary: extraColors.white,
      disabled: extraColors.disabled,
    },
    background: {
      default: extraColors.subtle,
      paper: extraColors.white,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    display: {
      fontSize: 50,
      fontWeight: 600,
      color: extraColors.dark,
    },
    h1: {
      fontSize: 36,
      fontWeight: 600,
      color: extraColors.dark,
    },
    h2: {
      fontSize: 26,
      fontWeight: 400,
      color: extraColors.dark,
    },
    h2Bold: {
      fontSize: 26,
      fontWeight: 600,
      color: extraColors.dark,
    },
    h3: {
      fontSize: 20,
      letterSpacing: "0.2px",
      fontWeight: 600,
      color: extraColors.dark,
    },
    h3Bold: {
      fontSize: 20,
      fontWeight: 600,
      color: extraColors.medium,
    },
    h4: {
      fontSize: 24,
      letterSpacing: "0.1px",
      fontWeight: 600,
      color: extraColors.dark,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      color: extraColors.dark,
    },
    body1Bold: {
      fontSize: 16,
      fontWeight: 600,
      color: extraColors.dark,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      color: extraColors.dark,
    },
    body2Bold: {
      fontSize: 14,
      fontWeight: 600,
      color: extraColors.dark,
    },
    small1: {
      fontSize: 12,
      fontWeight: 400,
      color: extraColors.dark,
    },
    small1Bold: {
      fontSize: 12,
      fontWeight: 600,
      color: extraColors.dark,
    },
    tiny: {
      fontSize: 9,
      fontWeight: 600,
      color: extraColors.dark,
    },
    tinyLink: {
      fontSize: 9,
      fontWeight: 600,
      color: extraColors.purple,
      textDecoration: "underline",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "destructive" },
          style: {
            border: `1px solid ${extraColors.status.redMiddle}`,
            backgroundColor: extraColors.white,
            color: extraColors.status.redMiddle,
            paddingLeft: 24,
            paddingRight: 24,
            "&:hover": {
              border: `1px solid ${extraColors.status.redMiddle}`,
              color: extraColors.status.redMiddle,
              backgroundColor: extraColors.highlightRed,
            },
            "&:focus": {
              outline: `2px solid ${extraColors.blueLight}`,
              color: extraColors.status.redMiddle,
            },
            "&:focus-visible": {
              border: `1px solid ${extraColors.status.redMiddle}`,
              color: extraColors.status.redMiddle,
              outline: `2px solid ${extraColors.blueLight}`,
            },
            "&:active": {
              border: `1px solid ${extraColors.status.redMiddle}`,
              color: extraColors.status.redMiddle,
            },
            "&.Mui-disabled": {
              border: `1px solid ${extraColors.disabled}`,
              color: extraColors.disabled,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: FONT_FAMILY,
          borderRadius: 2,
          fontWeight: 600,
          fontSize: 16,
          minHeight: 40,
          padding: "7px 20px",
          border: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          backgroundColor: extraColors.navy,
          color: extraColors.white,
          "&:hover": {
            backgroundColor: extraColors.dark,
            color: extraColors.white,
          },
          "&:focus": {
            outline: `2px solid ${extraColors.purpleDark}`,
          },
          "&:focus-visible": {
            backgroundColor: extraColors.navy,
            color: extraColors.white,
            outline: `2px solid ${extraColors.purpleDark}`,
          },
          "&:active": {
            backgroundColor: extraColors.navy,
            color: extraColors.white,
          },
          "&:disabled": {
            backgroundColor: extraColors.disabled,
            color: extraColors.white,
            outline: "none",
          },
        },
        outlinedSecondary: {
          border: `1px solid ${extraColors.navy}`,
          color: extraColors.navy,
          backgroundColor: extraColors.white,
          paddingLeft: 24,
          paddingRight: 24,
          "&:hover": {
            border: `1px solid ${extraColors.navy}`,
            color: extraColors.dark,
            backgroundColor: extraColors.light,
          },
          "&:focus": {
            outline: `2px solid ${extraColors.blueLight}`,
            backgroundColor: extraColors.white,
          },
          "&:focus-visible": {
            border: `1px solid ${extraColors.navy}`,
            color: extraColors.navy,
            outline: `2px solid ${extraColors.blueLight}`,
            backgroundColor: extraColors.white,
          },
          "&:active": {
            border: `1px solid ${extraColors.navy}`,
            color: extraColors.navy,
            backgroundColor: extraColors.white,
          },
          "&.Mui-disabled": {
            border: `1px solid ${extraColors.disabled}`,
            color: extraColors.disabled,
            backgroundColor: extraColors.white,
          },
        },
        text: {
          color: extraColors.navy,
          fontWeight: 600,
          fontFamily: FONT_FAMILY,
          fontSize: 14,
          letterSpacing: 0.5,
          padding: 8,
          "&:hover": {
            color: extraColors.navy,
            backgroundColor: "transparent",
          },
          "&:focus": {
            color: extraColors.navy,
            outline: extraColors.blueLight,
            backgroundColor: "transparent",
          },
          "&:focus-visible": {
            color: extraColors.navy,
            outline: extraColors.blueLight,
            textDecorationColor: extraColors.dark,
            backgroundColor: "transparent",
          },
          "&:active": {
            color: extraColors.navy,
            textDecorationColor: extraColors.navy,
            backgroundColor: "transparent",
          },
        },
        textSecondary: {
          color: extraColors.white,
          "&:hover": {
            color: extraColors.white,
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0px 8px",
          minHeight: "fit-content",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: extraColors.medium,
          "&.Mui-focused": {
            color: extraColors.navy,
          },
          "&.Mui-disabled": {
            color: extraColors.disabled,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          ".MuiOutlinedInput-notchedOutline": {
            transition: "border 250ms ease",
            border: `1px solid ${extraColors.disabled}`,
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${extraColors.navy}`,
            },
            color: extraColors.medium,
            label: {
              color: extraColors.black,
            },
          },
          label: {
            color: extraColors.navy,
          },
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${extraColors.navy}`,
            },
            label: {
              color: extraColors.disabled,
            },
          },
          "&.Mui-disabled": {
            "&:hover": {
              label: {
                color: extraColors.disabled,
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${extraColors.disabled}`,
              },
            },
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ".MuiDateRangePickerDay-day": {
            backgroundColor: extraColors.navy,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: BORDER_RADIUS,
          minWidth: 444,
          height: "100%",
          maxHeight: 500,
          padding: "30px 40px",
          background: extraColors.white,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "flex",
          background: extraColors.white,
          padding: 0,
          justifyContent: "space-between",
          color: extraColors.dark,
          fontSize: 18,
        },
      },
    },

    MuiFormGroup: {
      styleOverrides: {
        root: {
          fontSize: 14,
          ".MuiFormControlLabel-label": {
            fontSize: 14,
          },
        },
      },
    },

    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        icon: <SvgRadioUnselected />,
        checkedIcon: <SvgRadioSelected fill={extraColors.navy} />,
      },
      styleOverrides: {
        root: {
          paddingRight: 6,
          ".body": {
            fontSize: 14,
          },
          backgroundColor: "transparent",
          ".MuiTypography-body": {
            outline: `2px solid ${extraColors.blueLight}`,
          },
          "&:focus": {
            outline: `2px solid ${extraColors.blueLight}`,
          },
          "&:focus-visible": {
            outline: `2px solid ${extraColors.blueLight}`,
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-checked": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          ".MuiFormControlLabel-label": {
            fontSize: 14,
            marginLeft: 10,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        checkedIcon: <CheckboxChecked />,
        icon: <CheckboxUnchecked />,
        indeterminateIcon: <CheckboxIndeterminate />,
        focusVisibleClassName: "Mui-focusVisible",
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.4,
          },
          "&.Mui-focusVisible svg rect": {
            stroke: extraColors.status.greenLight,
            strokeWidth: 3,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "& .MuiAlert-icon": {
            fontSize: 33,
            margin: "auto 12px auto 0",
          },
        },
      },
      variants: [
        {
          props: { severity: "warning", color: "warning" },
          style: {
            backgroundColor: extraColors.status.yellowMiddle,
            "& .MuiAlert-icon": {
              color: extraColors.status.yellowMiddle,
            },
          },
        },
      ],
    },
    MuiPopper: {
      // map pie chart labels were fighting zIndexes
      styleOverrides: {
        root: {
          zIndex: 9999,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: extraColors.hint,
          fontWeight: 600,
          fontSize: "14px",
          "&.Mui-selected": {
            color: extraColors.navy,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: extraColors.navy,
        },
      },
    },
  },
})
