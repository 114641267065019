import { styled } from "@mui/material"
import * as Sentry from "@sentry/react"
import type React from "react"
import { useLocation } from "react-router-dom"
import { MainContent } from "../components/MainContent"
import { ReactComponent as Privacy } from "../texts/privacy.md"
import { ReactComponent as Terms } from "../texts/terms.md"
import { ROUTES, SeverityLevel } from "../utils/constants"

const Container = styled("div")({
  background: "white",
  padding: "16px 32px 40px",
  marginBottom: 32,
  borderRadius: 10,
})

/**
 * Default page wrapper for markdown pages
 * - Add ROUTE to src/utils/constants.ts
 * - Add component to `content`
 * - Add title
 * 🎉
 */
export const Texts: React.FC = () => {
  const location = useLocation()
  const content = {
    [ROUTES.TERMS_AND_CONDITIONS]: {
      component: <Terms />,
      title: "Terms and Conditions",
    },
    [ROUTES.PRIVACY_POLICY]: {
      component: <Privacy />,
      title: "Privacy Policy",
    },
  }[location.pathname]

  if (!content) {
    Sentry.captureMessage(
      `Component not found for path: ${location.pathname}. Please add it to src/pages/Texts.tsx`,
      SeverityLevel.Fatal
    )
    throw new Error(
      `Component not found for path: ${location.pathname}. Please add it to src/pages/Texts.tsx`
    )
  }

  return (
    <MainContent
      headerProps={{
        title: content.title,
      }}
    >
      <Container>{content.component}</Container>
    </MainContent>
  )
}
