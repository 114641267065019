import {
  isMapboxSbxMode,
  MAPBOX_SBX_ACCESS_TOKEN,
  type PHC_ENV,
} from "@phc/common"

export const ENV: PHC_ENV = import.meta.env.ENV || "sbx"

export const AUTH0_DOMAIN = import.meta.env.AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = import.meta.env.AUTH0_CLIENT_ID

export const AUTH0_AUDIENCE = import.meta.env.AUTH0_AUDIENCE

export const UPLOAD_CACHE_KEY = import.meta.env.UPLOAD_CACHE_KEY

export const MAPBOX_ACCESS_TOKEN = isMapboxSbxMode
  ? MAPBOX_SBX_ACCESS_TOKEN
  : import.meta.env.MAPBOX_API_KEY
