import { Box, Typography } from "@mui/material"
import { useMemo } from "react"
import { extraColors } from "../../utils/theme"
import { useCapServiceContext } from "../CriticalThreats/capServiceContext"
import { getCriticalThreatByContentId } from "../CriticalThreats/criticalThreatHelper"
import { CriticalThreatTag } from "../CriticalThreats/CriticalThreatTag"

interface ThreatCategoryProps {
  contentId: string
  threat: string | undefined
}

export const ThreatCategory = ({ contentId, threat }: ThreatCategoryProps) => {
  const { useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()

  const relatedCriticalThreat = useMemo(() => {
    return getCriticalThreatByContentId(contentId, data)
  }, [data, contentId])
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap="7px"
    >
      <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
        <Typography
          variant="body1Bold"
          color={extraColors.purple}
          sx={{ textDecoration: "none", textTransform: "uppercase" }}
        >
          {threat}
        </Typography>
        <CriticalThreatTag relatedCriticalThreat={relatedCriticalThreat} />
      </Box>
    </Box>
  )
}
