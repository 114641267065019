import dayjs from "dayjs"
import type { PreviewConfig, SortOrdering } from "sanity"
import { defineField } from "sanity"

// Todo: turn into a wrapper function to add the shared fields and fieldsets
/** Shared fields:
 * - title
 * - publishedAt
 * - updatedAt
 * - setUpdatedAt
 */
export const sharedContent = [
  defineField({
    name: "title",
    title: "Title",
    type: "string",
    validation: Rule => Rule.required(),
  }),
  defineField({
    name: "publishedAt",
    title: "Publish Date",
    description:
      "If blank, will be set to first publish date. Will send notification if this date is new or changed.",
    type: "datetime",
  }),
  defineField({
    name: "updatedAt",
    type: "datetime",
    initialValue: () => new Date().toISOString(),
    fieldset: "updatedAt",
    validation: Rule => Rule.required(),
  }),
  defineField({
    name: "setUpdatedAt",
    title: "Update Date",
    description: "If checked, date will be updated on publish",
    type: "boolean",
    initialValue: true,
    fieldset: "updatedAt",
  }),
  defineField({
    name: "isHiddenFromCriticalThreats",
    description: "Hide this content on critical threat pages",
    type: "boolean",
    initialValue: false,
    hidden: ({ document }) => document?._type === "criticalThreat",
  }),
  defineField({
    name: "publishingOverrideEnabled",
    title: "Enable Publishing Override",
    description:
      "If toggled, will send to all users regardless of their watched locations",
    type: "boolean",
  }),
] as const

export const sharedOrderings: SortOrdering[] = [
  {
    title: "Updated Date",
    name: "updatedAt",
    by: [{ field: "updatedAt", direction: "desc" }],
  },
  {
    title: "Published Date",
    name: "publishedAt",
    by: [{ field: "publishedAt", direction: "desc" }],
  },
  {
    title: "Title",
    name: "title",
    by: [{ field: "title", direction: "asc" }],
  },
]

export const sharedPreview: PreviewConfig = {
  select: {
    title: "title",
    updatedAt: "updatedAt",
    publishedAt: "publishedAt",
  },
  prepare: (selection: Record<string, string>) => {
    return {
      title: selection.title,
      subtitle: `Updated: ${dayjs(selection.updatedAt).format(
        "h:mmA, M/D/YYYY"
      )}`,
      description: `Published: ${
        (selection.publishedAt &&
          dayjs(selection.publishedAt).format(`
          h:mmA, M/D/YYYY
        `)) ??
        "Not published"
      }`,
    }
  },
}
