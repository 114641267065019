import { Typography, styled } from "@mui/material"
import { AlertLevel } from "@phc/common"
import { extraColors } from "../../../../../utils/theme"
import { AlertPill } from "../../../../Shared/AlertPill"

const DefinitionContainer = styled("div")({
  display: "grid",
  gridAutoFlow: "row",
  gap: 12,
  padding: 12,
  background: extraColors.light,
  borderRadius: 6,
})

const DefinitionRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "60px 1fr",
  gap: 12,
  alignItems: "center",
})

const DefinitionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.small1,
  color: extraColors.medium,
}))

export const AlertDefinitions: React.FC = () => {
  return (
    <DefinitionContainer sx={{ marginTop: "16px" }}>
      <DefinitionRow>
        <AlertPill alertLevel={AlertLevel.WARNING} />
        <DefinitionText>
          Urgent attention or mitigation actions recommended
        </DefinitionText>
      </DefinitionRow>
      <DefinitionRow>
        <AlertPill alertLevel={AlertLevel.WATCH} />
        <DefinitionText>
          Tracking for awareness recommended to inform early preventative
          actions
        </DefinitionText>
      </DefinitionRow>
      <DefinitionRow>
        <AlertPill alertLevel={AlertLevel.ADVISORY} />
        <DefinitionText>
          For awareness only, no actions recommended
        </DefinitionText>
      </DefinitionRow>
    </DefinitionContainer>
  )
}
