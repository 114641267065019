import { MainContent } from "../components/MainContent"
import { WorkforcePlanningTool } from "../components/WorkforcePlanning/WorkforcePlanningTool"

export const WorkforcePlanning = () => {
  return (
    <MainContent
      headerProps={{
        title: "Workforce Planning",
      }}
      disableLeftGutter
    >
      <WorkforcePlanningTool />
    </MainContent>
  )
}
