import {
  IndustryType,
  type Asset,
  type AssetGroup,
  type AssetType,
} from "@phc-health/connect-query"
import {
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react"
import { getLocationId } from "../../../../utils/helpers"
import { isAdminBoundaryFromPlaceType } from "../../../../utils/helpers/assetHelper"
import { trackEvent } from "../../../../utils/mixpanel"
import { MIXED_TEXT } from "../EditAssetsDialog"
import { useUpdateAssets } from "./useUpdateAsset"

interface BulkUpdateAssetsProps {
  saveAssets: () => void
  assetsByGroupId: Map<string, Set<Asset>>
  ubiquitousAssetGroups: AssetGroup[]
  selectedGroups?: AssetGroup[]
  setSelectedGroups: Dispatch<SetStateAction<AssetGroup[] | undefined>>
  selectedIndustryType?: IndustryType
  setSelectedIndustryType: Dispatch<SetStateAction<IndustryType | undefined>>
  selectedAssetType?: AssetType
  setSelectedAssetType: Dispatch<SetStateAction<AssetType | undefined>>
  selectedThreatRadius?: number
  setSelectedThreatRadius: Dispatch<SetStateAction<number | undefined>>
  updateIsPending: boolean
}

export const useBulkUpdateAssets = (assets: Asset[]): BulkUpdateAssetsProps => {
  const { mutate: updateAssets, isPending } = useUpdateAssets()

  const [selectedIndustryType, setSelectedIndustryType] =
    useState<IndustryType>()
  const [selectedAssetType, setSelectedAssetType] = useState<AssetType>()
  const [selectedThreatRadius, setSelectedThreatRadius] = useState<number>()

  const assetGroupsById = useMemo(() => {
    const byGroupId = new Map<string, AssetGroup>()
    assets.forEach(asset => {
      asset.assetGroups.forEach(group =>
        byGroupId.set(group.assetGroupId, group)
      )
    })
    return byGroupId
  }, [assets])

  // If every asset has the same group assigned to it, display that group in its own pill
  const assetsByGroupId = useMemo(() => {
    const byGroupId = new Map<string, Set<Asset>>()
    assets.forEach(asset => {
      asset.assetGroups.forEach(group => {
        const groupIdAssets = byGroupId.get(group.assetGroupId) || new Set()
        byGroupId.set(group.assetGroupId, groupIdAssets.add(asset))
      })
    })
    return byGroupId
  }, [assets])

  // If an asset group id has the same number of assets as assets.length, then every asset
  // has that group assigned to it.
  const ubiquitousAssetGroups = useMemo(() => {
    return Array.from(assetsByGroupId.entries())
      .filter(entry => {
        const groupAssets = entry[1]
        return groupAssets.size === assets.length
      })
      .flatMap(entry => assetGroupsById.get(entry[0]) ?? [])
  }, [assetGroupsById, assets.length, assetsByGroupId])

  const [selectedGroups, setSelectedGroups] = useState<
    AssetGroup[] | undefined
  >(ubiquitousAssetGroups)

  const selectedGroupIdSet = useMemo(
    () =>
      selectedGroups?.reduce(
        (idSet, group) => idSet.add(group.assetGroupId),
        new Set<string>()
      ),
    [selectedGroups]
  )

  const ubiquitousAssetGroupIdSet = useMemo(
    () =>
      ubiquitousAssetGroups.reduce(
        (idSet, group) => idSet.add(group.assetGroupId),
        new Set<string>()
      ),
    [ubiquitousAssetGroups]
  )

  const saveAssets = useCallback(() => {
    const updatedAssets = assets.map(asset => {
      const isAdminBoundary = isAdminBoundaryFromPlaceType(
        asset.baseEvent?.mapboxLocation?.placeType
      )

      const updatedGroups = getUpdatedGroupsForAsset(
        asset,
        ubiquitousAssetGroupIdSet,
        selectedGroupIdSet,
        selectedGroups
      )

      trackEvent("MANAGE_LOCATION", {
        action: "edit",
        locationId: getLocationId(asset),
        industry: IndustryType[selectedIndustryType ?? asset.industry],
      })

      // Admin boundaries can't have industries, threat radii, or asset types
      if (isAdminBoundary) {
        return {
          ...asset,
          assetGroups: updatedGroups,
        }
      }

      return {
        ...asset,
        industry: selectedIndustryType ?? asset.industry,
        threatRadius:
          // number can be 0, so check for undefined to see if we've changed it
          selectedThreatRadius !== undefined
            ? selectedThreatRadius
            : asset.threatRadius,
        assetGroups: updatedGroups,
        assetTypes: selectedAssetType ? [selectedAssetType] : asset.assetTypes,
      }
    })

    updateAssets({ assets: updatedAssets })
  }, [
    assets,
    selectedAssetType,
    selectedGroupIdSet,
    selectedGroups,
    selectedIndustryType,
    selectedThreatRadius,
    ubiquitousAssetGroupIdSet,
    updateAssets,
  ])
  return {
    saveAssets,
    assetsByGroupId,
    ubiquitousAssetGroups,
    selectedGroups,
    setSelectedGroups,
    selectedIndustryType,
    setSelectedIndustryType,
    selectedAssetType,
    setSelectedAssetType,
    selectedThreatRadius,
    setSelectedThreatRadius,
    updateIsPending: isPending,
  }
}

function getUpdatedGroupsForAsset(
  asset: Asset,
  ubiquitousAssetGroupIdSet: Set<string>,
  selectedGroupIdSet?: Set<string>,
  selectedGroups?: AssetGroup[]
) {
  // Filter out the mixed text item which is used as a placeholder
  const selectedWithMixedFilteredOut =
    selectedGroups?.filter(g => g.assetGroupId !== MIXED_TEXT) || []

  // Add the selected groups to the exising asset groups
  const assetGroupsWithSelected = asset.assetGroups.concat(
    selectedWithMixedFilteredOut
  )

  // If an existing asset group is in the ubiquitous set but is not in the selected set,
  // we need to remove it from the asset's group list since that means it was removed
  return assetGroupsWithSelected.filter(
    g =>
      // if the current asset group is in the ubiquitous set but is not in the selected set, remove it
      // if the current asset group is in the ubiquitous set and the selected set, keep it
      // if the current asset group is not in the ubiquitious set and is in the selected set, keep it
      // if the current asset group is not in the ubiquitious set and is not in the selected set, still keep
      !(
        ubiquitousAssetGroupIdSet.has(g.assetGroupId) &&
        !selectedGroupIdSet?.has(g.assetGroupId)
      )
  )
}
