import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Typography, styled } from "@mui/material"
import type { CriticalThreatWithReferences, Geotag } from "@phc/common"
import type React from "react"
import { useLayoutEffect, useMemo, useRef, useState } from "react"
import { extraColors } from "../../../utils/theme"

const CloseStyled = styled(Close)<{ isselected: number }>(({ isselected }) => ({
  width: isselected ? 12 : 0,
  height: "unset",
  transition: "width 250ms ease",
  color: extraColors.purpleMiddle,
  marginLeft: isselected ? 2 : 0,
}))

const TagRow = styled("div")<{ height: string }>(({ height }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 6,
  alignItems: "center",
  marginTop: 20,
  maxHeight: height,
  overflow: "hidden",
}))

const Tag = styled("div")<{ isselected: number }>(({ theme, isselected }) => ({
  ...theme.typography.small1Bold,
  padding: "1px 6px",
  borderRadius: 60,
  border: `1px solid ${
    isselected ? extraColors.purpleMiddle : extraColors.disabled
  }`,
  color: isselected ? extraColors.purpleMiddle : "inherit",
  display: "flex",
  alignItems: "center",
  textWrap: "nowrap",
  cursor: "pointer",
  transition:
    "background-color 250ms ease, border 250ms ease, color 250ms ease",
  backgroundColor: isselected ? extraColors.purpleSubtle : extraColors.light,
  "&:hover": {
    border: `1px solid ${isselected ? extraColors.navy : extraColors.medium}`,
  },
}))

const CloseAllTag = styled(Tag)({
  borderColor: extraColors.navy,
  color: extraColors.navy,
  backgroundColor: extraColors.white,
  "&:hover": {
    backgroundColor: extraColors.blueSubtle,
  },
})

const ShowMore = styled("div")({
  display: "flex",
  color: extraColors.purpleMiddle,
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: 12,
  cursor: "pointer",
})

export const GeoTagFilters: React.FC<{
  content?: CriticalThreatWithReferences["content"]
  selectedGeotagIds: Set<string>
  setSelectedGeotagIds: React.Dispatch<React.SetStateAction<Set<string>>>
}> = ({ content, selectedGeotagIds, setSelectedGeotagIds }) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const geotagMap = useMemo(() => {
    return getGeotagsFromContent(content)
  }, [content])

  const [isExpanded, setIsExpanded] = useState(false)
  const [canBeExpanded, setCanBeExpanded] = useState(false)

  useLayoutEffect(() => {
    const container = contentRef.current

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        if (container.scrollHeight > container.clientHeight) {
          setCanBeExpanded(true)
        } else {
          setCanBeExpanded(false)
        }
      })

      resizeObserver.observe(container)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <>
      <TagRow height={isExpanded ? "unset" : "60px"} ref={contentRef}>
        {Array.from(geotagMap.entries()).map(entry => {
          const tag = entry[1]
          return (
            <GeotagPill
              key={tag.id}
              tag={tag}
              allGeotags={geotagMap}
              isSelected={selectedGeotagIds.has(tag.id ?? "")}
              setSelectedGeotagIds={setSelectedGeotagIds}
            />
          )
        })}
        <CloseAllButton
          selectedGeotagIds={selectedGeotagIds}
          setSelectedGeotagIds={setSelectedGeotagIds}
        />
      </TagRow>
      {(canBeExpanded || isExpanded) && (
        <ShowMore
          onClick={() => {
            setIsExpanded(prev => !prev)
          }}
        >
          <Typography
            variant="small1Bold"
            sx={{
              color: extraColors.purpleMiddle,
            }}
          >
            {isExpanded
              ? "Show Less"
              : `Show All ${geotagMap.size.toLocaleString()} Location Filters`}
          </Typography>
          {isExpanded ? (
            <KeyboardArrowUp sx={{ color: extraColors.purpleMiddle }} />
          ) : (
            <KeyboardArrowDown sx={{ color: extraColors.purpleMiddle }} />
          )}
        </ShowMore>
      )}
    </>
  )
}

const GeotagPill: React.FC<{
  tag: Geotag
  allGeotags: Map<string, Geotag>
  isSelected: boolean
  setSelectedGeotagIds: (value: React.SetStateAction<Set<string>>) => void
}> = ({ tag, allGeotags, isSelected, setSelectedGeotagIds }) => {
  return (
    <Tag
      key={tag.id}
      isselected={isSelected ? 1 : 0}
      onClick={() =>
        setSelectedGeotagIds(prev => {
          // create new local set to avoid mutating the set in state
          // When a tag is clicked, find the tag and its children
          // If a parent is selected, select its children
          // If a parent is deselected, deselect its children
          const localSet = new Set(prev)
          if (!tag.id) return localSet
          localSet.has(tag.id) ? localSet.delete(tag.id) : localSet.add(tag.id)

          Array.from(allGeotags.values()).forEach(geotag => {
            if (!geotag.id || !tag.id) return
            if (geotag.id.startsWith(tag.id) && geotag.id !== tag.id) {
              localSet.has(geotag.id)
                ? localSet.delete(geotag.id)
                : localSet.add(geotag.id)
            }
          })

          return localSet
        })
      }
    >
      {tag.name}
      <CloseStyled isselected={isSelected ? 1 : 0} />
    </Tag>
  )
}

const CloseAllButton: React.FC<{
  selectedGeotagIds: Set<string>
  setSelectedGeotagIds: (value: React.SetStateAction<Set<string>>) => void
}> = ({ selectedGeotagIds, setSelectedGeotagIds }) => {
  return (
    <>
      {selectedGeotagIds.size > 0 && (
        <CloseAllTag
          key={"clear-all"}
          isselected={0}
          onClick={() => setSelectedGeotagIds(new Set())}
        >
          Clear All
          <CloseStyled isselected={0} />
        </CloseAllTag>
      )}
    </>
  )
}

function getGeotagsFromContent(
  content?: CriticalThreatWithReferences["content"]
) {
  const tagMap = new Map<string, Geotag>()
  content?.forEach(contentItem => {
    switch (contentItem._type) {
      case "alert":
      case "headlines":
        contentItem.geotags?.forEach(
          tag => tag.locationId && tagMap.set(tag.locationId, tag)
        )
        break
      case "guidance":
      default:
        break
    }
  }, new Map<string, Geotag>())

  return tagMap
}
