import { SearchIcon } from "@sanity/icons"
import { Autocomplete, Card, type BaseAutocompleteOption } from "@sanity/ui"
import { useState } from "react"
import {
  useClient,
  useFormValue,
  useGetFormValue,
  type QueryParams,
} from "sanity"
import type { GeotagGroup } from "../sanity.types"
import type { Geotag } from "../sanityTypesManual"

interface Option extends BaseAutocompleteOption {
  payload: GeotagGroup
}

export const GeotagGroupInput = () => {
  const client = useClient({
    apiVersion: "2024-10-19",
  })
  const id = useFormValue(["_id"]) as string
  const getCurrentGeotags = useGetFormValue()
  const [options, setOptions] = useState<Option[]>([])
  return (
    <Card padding={4}>
      <Autocomplete
        fontSize={[2, 2, 3]}
        icon={SearchIcon}
        id="geotag-group-input"
        options={options}
        placeholder="Add Geotag Groups"
        filterOption={(query, option) =>
          !!option.payload.title?.toLowerCase().includes(query.toLowerCase())
        }
        onQueryChange={e => {
          if (!e) return
          client
            .fetch<GeotagGroup[], QueryParams>(
              /* groq */ `*[_type == "geotagGroup" && title match $query + "*"]`,
              {
                query: e,
              }
            )
            .then(res => {
              setOptions(
                res.map(p => ({
                  value: p.title ?? "<missing title>",
                  payload: p,
                }))
              )
            })
            .catch(console.error)
        }}
        onSelect={item => {
          const selectedOption = options.find(o => o.value === item)
          if (!selectedOption) {
            console.error("No option found")
            return
          }
          const currentGeotags = getCurrentGeotags(["geotags"]) as
            | Geotag[]
            | undefined
          const newGeotags = selectedOption.payload.geotags?.filter(
            curr => !currentGeotags?.find(g => g.locationId === curr.locationId)
          )
          if (!newGeotags) {
            console.error("No new geotags to add")
            return
          }
          client
            .patch(id, {
              insert: {
                after: "geotags[-1]",
                items: newGeotags,
              },
            })
            .commit()
            .catch(console.error)
        }}
      />
    </Card>
  )
}
