import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material"
import { AssetNotificationAlertLevel } from "@phc-health/connect-query"
import { AlertLevel, isAlertLevel } from "@phc/common"
import { useCallback } from "react"
import { AlertPill } from "../../../../Shared/AlertPill"
import { useNotificationContext } from "../../contexts/NotificationContext"
import { useNotificationTypeContext } from "../../contexts/NotificationTypeContext"

const AlertPillRow = styled("div")({
  display: "grid",
  gridAutoFlow: "column",
  gap: 6,
})

function convertAlertLevelToProto(level: AlertLevel) {
  switch (level) {
    case AlertLevel.ADVISORY:
      return AssetNotificationAlertLevel.ADVISORY
    case AlertLevel.WARNING:
      return AssetNotificationAlertLevel.WARNING
    case AlertLevel.WATCH:
      return AssetNotificationAlertLevel.WATCH
    default:
      return AssetNotificationAlertLevel.UNSPECIFIED
  }
}

function convertProtoToAlertLevel(proto: AssetNotificationAlertLevel) {
  switch (proto) {
    case AssetNotificationAlertLevel.ADVISORY:
      return AlertLevel.ADVISORY
    case AssetNotificationAlertLevel.WARNING:
      return AlertLevel.WARNING
    case AssetNotificationAlertLevel.WATCH:
      return AlertLevel.WATCH
    case AssetNotificationAlertLevel.UNSPECIFIED:
    default:
      return ""
  }
}

export const AlertLevelRadioGroup: React.FC = () => {
  const { notificationType } = useNotificationTypeContext()
  const { alertLevel, setAlertLevel } = useNotificationContext(notificationType)

  const onChangeAlertLevel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const alertLvl = event.currentTarget.value
      if (isAlertLevel(alertLvl) && setAlertLevel) {
        setAlertLevel(convertAlertLevelToProto(alertLvl))
      }
    },
    [setAlertLevel]
  )
  return (
    <FormControl>
      <FormLabel>
        <Typography variant="body2">
          Select the alert levels at which you’d like to be notified.
        </Typography>
      </FormLabel>
      <RadioGroup
        value={convertProtoToAlertLevel(
          alertLevel || AssetNotificationAlertLevel.UNSPECIFIED
        )}
        name="alert levels"
        onChange={onChangeAlertLevel}
        sx={{ margin: "10px 0 0 12px", gap: "6px" }}
      >
        <FormControlLabel
          value={AlertLevel.ADVISORY}
          control={<Radio />}
          label={<AlertPillHierarchy alertLevel={AlertLevel.ADVISORY} />}
        />
        <FormControlLabel
          value={AlertLevel.WATCH}
          control={<Radio />}
          label={<AlertPillHierarchy alertLevel={AlertLevel.WATCH} />}
        />
        <FormControlLabel
          value={AlertLevel.WARNING}
          control={<Radio />}
          label={<AlertPillHierarchy alertLevel={AlertLevel.WARNING} />}
        />
      </RadioGroup>
    </FormControl>
  )
}

const AlertPillHierarchy: React.FC<{ alertLevel: AlertLevel }> = ({
  alertLevel,
}) => {
  switch (alertLevel) {
    case AlertLevel.ADVISORY:
      return (
        <AlertPillRow>
          <AlertPill alertLevel={AlertLevel.ADVISORY} />
          <AlertPill alertLevel={AlertLevel.WATCH} />
          <AlertPill alertLevel={AlertLevel.WARNING} />
        </AlertPillRow>
      )
    case AlertLevel.WATCH:
      return (
        <AlertPillRow>
          <AlertPill alertLevel={AlertLevel.WATCH} />
          <AlertPill alertLevel={AlertLevel.WARNING} />
        </AlertPillRow>
      )
    case AlertLevel.WARNING:
      return (
        <AlertPillRow>
          <AlertPill alertLevel={AlertLevel.WARNING} />
        </AlertPillRow>
      )
  }
}
