import { Typography } from "@mui/material"
import { isPrd } from "../../components/CriticalThreats/capServiceContext"
import { dayjsUTC } from "../../utils/helpers"
import { extraColors } from "../../utils/theme"

/**
 * INTERFACES
 */

interface PostDates {
  postPublishedAt?: string
  postUpdatedAt?: string
  postUpdatedAtAlternate?: string
}

/**
 * COMPONENTS
 */

export const PostDates: React.FC<PostDates> = ({
  postPublishedAt,
  postUpdatedAt,
  postUpdatedAtAlternate,
}) => {
  const updatedAt = isPrd
    ? getFormattedDate(postUpdatedAtAlternate)
    : getFormattedDate(postUpdatedAt)

  return (
    <Typography variant={"body2Bold"} sx={{ color: extraColors.disabled }}>
      {postPublishedAt && !postUpdatedAt
        ? `Published ${getFormattedDate(postPublishedAt)}`
        : `Updated ${updatedAt}`}
    </Typography>
  )
}

const getFormattedDate = (date?: string) => {
  if (!date) return ""
  return dayjsUTC(date).local().format("MMM DD, YYYY")
}

// Since scheduled publishes don't set the `publishedAt` field,
// fallback to `_updatedAt` when `publishedAt` is not set
export const getPublishedAtDate = (dates: PostDates) => {
  const { postPublishedAt, postUpdatedAt } = dates
  return isPrd
    ? dayjsUTC(postPublishedAt ?? postUpdatedAt).local()
    : dayjsUTC(postUpdatedAt).local()
}
