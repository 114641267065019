import type { LayersResponse } from "@phc-health/connect-query"

type NonFunctionPropertyNames<T> =
  // filter out null and undefined
  T extends null | undefined
    ? never
    : {
        [K in keyof T]: T[K] extends (...args: any[]) => any
          ? // if value is a function, exclude it from the list of possible keys
            never
          : // return key as string so it can index into T
            K extends string
            ? K
            : never
      }[keyof T]

// Select actual layer Type from LayersResponse
type PossibleLayers = LayersResponse[NonNullable<
  NonFunctionPropertyNames<LayersResponse>
>]

type ArrayOnly<T> = T extends (infer U)[] ? U : never
type Layer = ArrayOnly<PossibleLayers>

/**
 * Helper function to create a type safe field mask
 * @param layer new Bucket()
 * @param firstField "baseEvent"
 * @param secondField "locationId"

 * @returns "base_event.location_id"
 */
export const makeFieldConditionName = <
  L extends Layer,
  FirstField extends NonNullable<NonFunctionPropertyNames<L>>,
  SecondField extends
    | NonFunctionPropertyNames<L[FirstField]>
    | (L[FirstField] extends any[]
        ? NonFunctionPropertyNames<L[FirstField][number]>
        : never),
  // TODO: Make work for repeated fields
  ThirdField extends string,
>(
  layer: L,
  firstField: FirstField,
  // only allow secondField if firstField is not a string
  secondField?: L[FirstField] extends string
    ? never
    : L[FirstField] extends any[]
      ? NonFunctionPropertyNames<L[FirstField][number]>
      : SecondField,
  thirdField?: ThirdField
) => {
  const fieldQuery = []
  const fieldName = layer.getType().fields.findJsonName(firstField)
  if (fieldName) {
    fieldQuery.push(fieldName.name)
  }
  if (fieldName?.kind === "message" && secondField) {
    const nestedFieldName = fieldName.T.fields.findJsonName(secondField)
    if (nestedFieldName) {
      fieldQuery.push(nestedFieldName.name)
      if (nestedFieldName.kind === "message" && thirdField) {
        const nestedNestedFieldName =
          nestedFieldName.T.fields.findJsonName(thirdField)

        if (!nestedNestedFieldName) {
          throw new Error(`Third field not found: "${thirdField}"`)
        }
        fieldQuery.push(nestedNestedFieldName.name)
      }
    }
  }
  return fieldQuery.join(".")
}
