import { SaveAlt } from "@mui/icons-material"
import { styled } from "@mui/material"
import { trackEvent } from "../utils/mixpanel"
import { IconStyles } from "./LocationDetails/LocationRisk/Actions/ActionsShared"
import type { PrintHandle } from "./PrintTemplate"
import Button from "./Shared/Button"

const PDFButton = styled(Button)(() => ({
  height: 38,
  minWidth: "fit-content",
  "@media print": {
    display: "none",
  },
}))

export const SaveAsPDFButton: React.FC<{
  printRef: React.RefObject<PrintHandle>
  style?: React.CSSProperties
}> = ({ printRef, style }) => {
  return (
    <PDFButton
      variant="outlined"
      color="secondary"
      endIcon={<SaveAlt sx={{ ...IconStyles }} />}
      onClick={() => {
        if (printRef.current) {
          trackEvent("CLICK_SAVE_PDF")
          printRef.current.handlePrintClick()
        }
      }}
      style={style}
    >
      Save PDF
    </PDFButton>
  )
}
