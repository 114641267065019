import { Box, styled, Typography } from "@mui/material"
import risk from "./riskIncreaseNotifications.png"

const Container = styled("div")({
  display: "grid",
  gap: "12px",
  width: "100%",
})

export const RiskNotificationsEmailExample = () => {
  return (
    <Container>
      <Typography variant="h3Bold">Risk Increase Notifications</Typography>
      <Typography variant="body1">
        Risk increase emails are prompted when a location experiences an
        increase in assessed risk from one or more threats. They are sent as a
        digest with all of your affected assets and you may receive up to one
        per day.
      </Typography>
      <Box
        component="img"
        sx={{
          display: "block",
          overflow: "auto",
          width: "100%",
        }}
        src={risk}
        alt={"Risk"}
      />
    </Container>
  )
}
