import { useQuery } from "@connectrpc/connect-query"
import {
  AbsenteeismForecast,
  Bucket,
  Disease_Type,
  FieldExclusionCondition,
  LayersV1Query,
  SearchOperationType,
  type IndustryType,
} from "@phc-health/connect-query"
import { LAYERS_API } from "../utils/constants"
import { makeFieldConditionName } from "../utils/helpers/layersHelpers"
import { useTransport } from "./useTransport"

export const useLayersForMap = (disease: Disease_Type) => {
  const transport = useTransport(LAYERS_API)
  return useQuery(
    LayersV1Query.getLayers,
    {
      layers: ["bucket"],
      pagination: {
        size: 10000,
        from: 0,
      },
      searchCriteria: {
        excludeFields: new FieldExclusionCondition({
          field: [
            "factors",
            "confidence",
            "date*",
            "disease_indicator",
            "previous_severity",
            "trend_type",
            "base_event.geotags.boundary_level_hierarchy",
            "base_event.geotags.external_location_codes.country_iso*",
            "base_event.geotags.external_location_codes.world*",
            "base_event.geotags.external_location_codes.principal_subdivision_iso_3166_2_code",
          ],
        }),
        searchCondition: [
          {
            fieldCondition: [
              {
                field: "disease",
                operation: SearchOperationType.EQUALS,
                value: {
                  kind: {
                    case: "stringValue",
                    value: `TYPE_${Disease_Type[disease]}`,
                  },
                },
              },
            ],
          },
        ],
      },
    },
    {
      staleTime: 1000 * 60 * 5,
      transport,
    }
  )
}

export const useLayersByLocationId = (locationId: string | undefined) => {
  const transport = useTransport(LAYERS_API)
  return useQuery(
    LayersV1Query.getLayers,
    {
      layers: ["bucket"],
      pagination: {
        size: 100,
        from: 0,
      },
      searchCriteria: {
        searchCondition: [
          {
            fieldCondition: [
              {
                field: makeFieldConditionName(
                  new Bucket(),
                  "baseEvent",
                  "geotags",
                  "locationId"
                ),
                operation: SearchOperationType.EQUALS,
                value: {
                  kind: {
                    case: "stringValue",
                    value: locationId ?? "",
                  },
                },
              },
            ],
          },
        ],
      },
    },
    {
      transport,
      enabled: !!locationId,
      staleTime: 1000 * 60 * 5,
    }
  )
}

export const useLayersForAbsenteeismById = ({
  locationId,
  industry,
}: {
  locationId: string | undefined
  industry: IndustryType
}) => {
  const transport = useTransport(LAYERS_API)
  const stateLocationId = locationId?.slice(0, 5) ?? ""
  return useQuery(
    LayersV1Query.getLayers,
    {
      layers: ["absenteeism_forecast"],
      pagination: {
        size: 100,
        from: 0,
      },
      searchCriteria: {
        searchCondition: [
          {
            fieldCondition: [
              {
                field: makeFieldConditionName(
                  new AbsenteeismForecast(),
                  "baseEvent",
                  "geotags",
                  "locationId"
                ),
                operation: SearchOperationType.ANY_IN,
                value: {
                  kind: {
                    case: "listValue",

                    value: {
                      values: [
                        {
                          kind: {
                            case: "stringValue",
                            value: stateLocationId,
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
    {
      transport,
      enabled: !!locationId,
      staleTime: 1000 * 60 * 5,
      select: data => {
        const absenteeismForecasts = data.absenteeismForecasts
          .filter(forecast => forecast.industryType === industry)
          .sort(
            (a, b) =>
              Number(a.forecastStartedAt?.seconds) -
              Number(b.forecastStartedAt?.seconds)
          )

        /** In order to show significant changes, the scale needs to be zoomed in.
         *
         * The min will be:
         * - the lowest value of the industryBaseline and overallAbsenteeism
         * - minus 0.5 to give some space at the bottom
         * - but not less than 0
         */
        const min = Math.max(
          Math.min(
            ...data.absenteeismForecasts.map(
              forecast => forecast.industryBaseline
            ),
            ...data.absenteeismForecasts.map(
              forecast => forecast.overallAbsenteeism
            )
          ),
          0
        )
        const max = Math.max(
          ...data.absenteeismForecasts.map(
            forecast => forecast.industryBaseline
          ),
          ...data.absenteeismForecasts.map(
            forecast => forecast.overallAbsenteeism
          )
        )
        return { absenteeismForecasts, min, max }
      },
    }
  )
}
