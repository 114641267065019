import { useAuth0 } from "@auth0/auth0-react"
import { styled } from "@mui/material"
import { PortableText } from "@portabletext/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import Siren from "../../assets/svg-components/Siren"
import usePersistentContext from "../../hooks/usePersistentContext"
import { components } from "../../pages/SinglePost/PostComponents"
import { onMissingComponent } from "../../pages/SinglePost/SinglePost"
import { extraColors } from "../../utils/theme"
import { useCapServiceContext } from "../CriticalThreats/capServiceContext"
import { GradientBanner } from "./shared"

const BannerItemContainer = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  alignItems: "center",
  p: {
    margin: 0,
  },
}))

export const CriticalThreatBanner: React.FC = () => {
  const { showCriticalThreatBanner } = useFlags()
  const { isAuthenticated } = useAuth0()

  const { sanityDataset, useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()
  const criticalThreat = data?.[0]

  const [
    criticalThreatBannerDismissed,
    setCriticalThreatBannerDismissed,
    isLoading,
  ] = usePersistentContext([criticalThreat?.slug?.current])

  const showBanner =
    isAuthenticated &&
    !!criticalThreat?.banner &&
    !!showCriticalThreatBanner &&
    !isLoading &&
    (criticalThreatBannerDismissed === "false" ||
      criticalThreatBannerDismissed === null)

  return (
    <GradientBanner
      display={showBanner ? "block" : "none"}
      type="warning"
      open={showBanner}
      dismissFunction={() => void setCriticalThreatBannerDismissed("true")}
    >
      <BannerItemContainer>
        <div style={{ marginRight: "6px" }}>
          <Siren />
        </div>
        <PortableText
          value={criticalThreat?.banner ?? []}
          components={components(sanityDataset, {
            linkStyle: {
              color: extraColors.status.teal,
              fontSize: 12,
              fontWeight: 600,
              marginLeft: "6px",
            },
          })}
          onMissingComponent={onMissingComponent}
        />
      </BannerItemContainer>
    </GradientBanner>
  )
}
