import { MenuItem, Select, Typography, useMediaQuery } from "@mui/material"
import { useGridApiContext } from "@mui/x-data-grid-pro"
import type React from "react"
import { useCallback, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { extraColors, theme } from "../../utils/theme"

interface SelectFilterProps {
  items: string[]
  ALL_ITEMS_TEXT: string
  placeholder?: string
  filterField: string
}

export const SelectFilter: React.FC<SelectFilterProps> = ({
  items,
  ALL_ITEMS_TEXT,
  placeholder,
  filterField: filterField,
}) => {
  const dataGridRef = useGridApiContext()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParam = useMemo(() => {
    return items.find(
      item =>
        item.toLowerCase() === searchParams.get(filterField)?.toLowerCase()
    )
  }, [filterField, items, searchParams])

  const onChangeTab = useCallback(
    (item: string) => {
      setSearchParams(currentParams => {
        if (item) {
          currentParams.set(filterField, item)
        } else {
          currentParams.delete(filterField)
        }
        return currentParams
      })
    },
    [filterField, setSearchParams]
  )

  useEffect(() => {
    if (searchParam == null || searchParam === ALL_ITEMS_TEXT) {
      dataGridRef.current.deleteFilterItem({
        id: filterField,
        field: filterField,
        operator: "contains",
      })
    } else {
      dataGridRef.current.upsertFilterItem({
        id: filterField,
        field: filterField,
        operator: "contains",
        value: searchParam,
      })
    }
  }, [ALL_ITEMS_TEXT, searchParam, dataGridRef, filterField])

  return (
    <Select
      value={searchParam ?? ""}
      onChange={e => onChangeTab(e.target.value)}
      sx={{
        height: "40px",
        width: isMobile ? "100%" : "240px",
        ".MuiSelect-icon": { color: extraColors.purpleMiddle },
      }}
      displayEmpty
      renderValue={value =>
        value || (
          <Typography variant="inherit" color={extraColors.disabled}>
            {placeholder ?? ALL_ITEMS_TEXT}
          </Typography>
        )
      }
    >
      <MenuItem value="">{ALL_ITEMS_TEXT}</MenuItem>
      {items.map(item => (
        <MenuItem value={item} key={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  )
}
