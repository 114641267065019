import type { SVGProps } from "react"

const SvgSortUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m11.25 8.56-2.72 2.72a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 1 1-1.06 1.06l-2.72-2.72v8.69a.75.75 0 0 1-1.5 0V8.56Z"
      fill="#0E2348"
    />
  </svg>
)

export default SvgSortUp
