import { styled, useMediaQuery, useTheme } from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import type React from "react"
import { extraColors } from "../../../../utils/theme"
import { getMapBreakpoint } from "../../Shared"
import { DrawerHeader, MobileDrawerHeader } from "../DrawerHeader"
import { LocationItems } from "./LocationItems"

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  padding: 0,
  pointerEvents: "all",
  overflow: "auto",

  "& .MuiDrawer-paper": {
    border: "none",
    position: "relative",
    maxWidth: "fit-content",
    height: "fit-content",
    backgroundColor: "transparent",
    [getMapBreakpoint(theme)]: {
      maxWidth: "fit-content",
    },
  },
})) as typeof MuiDrawer

const CardGroup = styled("div")(({ theme }) => ({
  pointerEvents: "all",
  display: "grid",
  maxHeight: "fit-content",
  [getMapBreakpoint(theme)]: {
    gridAutoFlow: "row",
    width: "auto",
    maxHeight: 270,
  },
}))

interface LocationsProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const DrawerContainer = styled("div")(({ theme }) => ({
  zIndex: 1201,
  position: "relative",
  display: "grid",
  gridTemplateRows: "auto 1fr",
  background: extraColors.translucentWhite,
  backdropFilter: "blur(2px)",
  padding: 16,
  scrollbarColor: "transparent transparent",
  "*::-webkit-scrollbar": {
    display: "none",
    width: "none",
  },
  "*::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "*::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
  [theme.breakpoints.down("lg")]: {
    padding: 8,
  },
}))

export const Locations: React.FC<LocationsProps> = ({ open, setOpen }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(getMapBreakpoint(theme))

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <DrawerContainer style={{ overflowY: "auto" }}>
      {isMobile ? (
        <MobileDrawerHeader
          title="Watched Locations"
          toggleDrawer={toggleDrawer}
          open={open}
          hideManage
        />
      ) : (
        <DrawerHeader toggleDrawer={toggleDrawer} open={open} />
      )}
      <Drawer
        open={open}
        variant="persistent"
        anchor={isMobile ? "bottom" : "right"}
        transitionDuration={0}
      >
        {open && (
          <CardGroup>
            {" "}
            <LocationItems />
          </CardGroup>
        )}
      </Drawer>
    </DrawerContainer>
  )
}
