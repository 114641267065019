import { styled } from "@mui/material"
import { Risk_Severity } from "@phc-health/connect-query"
import { extraColors } from "../../../../utils/theme"
import { SeverityVisualIndicator } from "../SeverityVisualIndicator"

const RiskVisualizerContainer = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: 20,
  margin: "20px 0 16px",
  position: "relative",
  background: extraColors.white,
  [theme.breakpoints.down("sm")]: {
    background: "unset",
  },
  border: `1px solid ${extraColors.light}`,
}))

const SeverityElements = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns:
    "minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)",
  gap: 4,
  padding: 3,
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
  },
}))

const SeverityIndicatorStyled = styled(SeverityVisualIndicator)({
  display: "flex",
  width: "100%",
  padding: 2,
  height: "100%",
  justifyContent: "center",
})

export const OverallRiskVisualizer: React.FC<{
  severity: Risk_Severity
}> = ({ severity }) => (
  <RiskVisualizerContainer>
    <SeverityElements>
      <SeverityIndicatorStyled
        severity={Risk_Severity.LOW}
        showTrend={severity === Risk_Severity.LOW}
        activeSeverity={severity}
      />
      <SeverityIndicatorStyled
        severity={Risk_Severity.MODERATE}
        showTrend={severity === Risk_Severity.MODERATE}
        activeSeverity={severity}
        size="large"
      />
      <SeverityIndicatorStyled
        severity={Risk_Severity.HIGH}
        showTrend={severity === Risk_Severity.HIGH}
        activeSeverity={severity}
        size="large"
      />
      <SeverityIndicatorStyled
        severity={Risk_Severity.SEVERE}
        showTrend={severity === Risk_Severity.SEVERE}
        activeSeverity={severity}
        size="large"
      />
    </SeverityElements>
  </RiskVisualizerContainer>
)
