import { styled, Typography } from "@mui/material"
import type React from "react"
import { extraColors } from "../../../utils/theme"

export const Tag = styled("div")({
  backgroundColor: extraColors.light,
  padding: "0px 6px",
  borderRadius: 12,
  border: `1px solid ${extraColors.disabled}`,
  display: "flex",
  alignItems: "center",
  textWrap: "nowrap",
  width: "fit-content",
})

export const CategoryTag: React.FC<{
  category: string
  className?: string
}> = ({ category, className }) => {
  return (
    <Tag className={className}>
      <Typography variant="tiny">{category}</Typography>
    </Tag>
  )
}
