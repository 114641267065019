import { useQuery } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useGetAuth0OrgByEmail(userId: string) {
  const transport = useTransport(ACCESS_API)
  return useQuery(
    AccessManagementServiceQuery.lookupAuth0Organization,
    { userId },
    {
      transport,
      enabled: false,
    }
  )
}
