import { styled } from "@mui/material"
import { useParams } from "react-router-dom"
import { useGetAsset } from "../../WatchedLocations/hooks/useAssetService"
import { SiteDetailsNotificationSettingsSection } from "./NotificationSettings/SiteDetailsNotifications"
import { SiteDetailSiteSection } from "./Site/Site"

const SiteDetailsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "columns",
  gap: 20,
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}))

export const SiteDetails: React.FC = () => {
  const { assetId } = useParams<{ assetId: string }>()
  const { data } = useGetAsset(assetId)

  // TODO: add loading state
  if (!data?.asset) return null

  return (
    <SiteDetailsContainer>
      <SiteDetailSiteSection asset={data.asset} />
      <SiteDetailsNotificationSettingsSection asset={data.asset} />
    </SiteDetailsContainer>
  )
}
