import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material"
import { AssetNotificationType } from "@phc-health/connect-query"
import type React from "react"
import { useCallback, useState } from "react"
import { extraColors } from "../../../../utils/theme"
import {
  ABSENTEEISM_INSIGHT_TYPES,
  SettingGranularity,
  isSettingGranularity,
  useNotificationContext,
} from "../contexts/NotificationContext"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { LocationSelectorDialog } from "../LocationSelector/LocationSelectorDialog"

const SelectionSummaryContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: extraColors.subtle,
  padding: 12,
  borderRadius: 3,
  marginTop: 12,
})

export const GlobalSettingsRadioGroup: React.FC<{
  title: string
}> = ({ title }) => {
  const { notificationType } = useNotificationTypeContext()
  const {
    setSelectedAssets,
    selectedAssets,
    nonGlobalAssets,
    settingGranularity,
    setSettingGranularity,
    setDialogSelectedAssets,
    setGloballyTaggedEnabled,
  } = useNotificationContext(notificationType)

  const [locationSelectorIsOpen, setLocationSelectorIsOpen] = useState(false)

  const onChangeGranularity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const granularity = event.currentTarget.value
      if (isSettingGranularity(granularity) && setSettingGranularity) {
        setSettingGranularity(granularity)

        if (granularity == SettingGranularity.GLOBAL) {
          // remove all selected assets in context and dialog when switching to global
          setDialogSelectedAssets(undefined)
          setSelectedAssets(undefined)
          setGloballyTaggedEnabled(false)
        }
      }
    },
    [
      setDialogSelectedAssets,
      setGloballyTaggedEnabled,
      setSelectedAssets,
      setSettingGranularity,
    ]
  )

  const onOpenSelector = useCallback(
    (newState: boolean) => {
      if (newState && !selectedAssets?.length) {
        setSelectedAssets?.(nonGlobalAssets)
        setDialogSelectedAssets?.(nonGlobalAssets)
      }
      setLocationSelectorIsOpen(newState)
    },
    [
      nonGlobalAssets,
      selectedAssets?.length,
      setDialogSelectedAssets,
      setSelectedAssets,
    ]
  )

  return (
    <>
      <Divider sx={{ borderColor: extraColors.light, margin: "6px 0" }} />
      <FormControl>
        <Typography variant="small1" sx={{ color: extraColors.medium }}>
          The below settings apply to Alerts and Analysis when one or both are
          selected.
        </Typography>
        <RadioGroup
          value={settingGranularity}
          name="alert levels"
          onChange={onChangeGranularity}
        >
          <FormControlLabel
            value={SettingGranularity.GLOBAL}
            control={<Radio />}
            label={"Get notifications for all locations"}
          />
          <FormControlLabel
            value={SettingGranularity.ASSET}
            control={<Radio />}
            label={
              <>
                Get notifications for {title} <b>only</b> for my selected
                locations
              </>
            }
            onClick={() => setLocationSelectorIsOpen(true)}
          />
        </RadioGroup>
        <SelectionSummary
          notificationType={notificationType}
          setLocationSelectorIsOpen={onOpenSelector}
        />
        <LocationSelectorDialog
          dialogIsOpen={locationSelectorIsOpen}
          setDialogIsOpen={onOpenSelector}
          title="Expert Insights"
          notificationType={notificationType}
        />
      </FormControl>
    </>
  )
}

export const SelectionSummary: React.FC<{
  notificationType: AssetNotificationType
  setLocationSelectorIsOpen: (value: boolean) => void
}> = ({ notificationType, setLocationSelectorIsOpen }) => {
  const { nonGlobalAssets, selectedAssets, settingGranularity } =
    useNotificationContext(notificationType)

  const showSummary =
    notificationType === AssetNotificationType.RISK_INCREASE ||
    settingGranularity === SettingGranularity.ASSET ||
    ABSENTEEISM_INSIGHT_TYPES.includes(notificationType)

  return (
    showSummary && (
      <SelectionSummaryContainer>
        <Typography variant="body2Bold" sx={{ color: extraColors.dark }}>
          Receiving notifications for {selectedAssets?.length || 0}/
          {nonGlobalAssets?.length || 0} locations
        </Typography>
        <Typography
          variant="body2Bold"
          onClick={() => setLocationSelectorIsOpen(true)}
          sx={{ color: extraColors.purpleMiddle, cursor: "pointer" }}
        >
          Edit
        </Typography>
      </SelectionSummaryContainer>
    )
  )
}
