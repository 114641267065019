import {
  Disease_Indicator,
  Disease_Type,
  Risk_Severity,
  Risk_Trend,
} from "@phc-health/connect-query"

export interface TrendSeverity {
  severity: Risk_Severity
  diseaseType: Disease_Type
}

export const severityMapping = (severity: Risk_Severity) => {
  switch (severity) {
    case Risk_Severity.HIGH:
      return "High"
    case Risk_Severity.LOW:
      return "Low"
    case Risk_Severity.MODERATE:
      return "Moderate"
    case Risk_Severity.SEVERE:
      return "Severe"
    case Risk_Severity.UNSPECIFIED:
    default:
      return "Unknown"
  }
}

export const trendMapping = (trend: Risk_Trend) => {
  switch (trend) {
    case Risk_Trend.DECREASING:
      return "Decreasing"
    case Risk_Trend.DECREASING_RAPIDLY:
      return "Decreasing Rapidly"
    case Risk_Trend.INCREASING:
      return "Increasing"
    case Risk_Trend.INCREASING_RAPIDLY:
      return "Increasing Rapidly"
    case Risk_Trend.PERSISTENT:
      return "Persistent"
    case Risk_Trend.UNSPECIFIED:
    default:
      return "Unknown"
  }
}

export const diseaseTypeMapping = (disease: Disease_Type) => {
  switch (disease) {
    case Disease_Type.COVID:
      return "COVID-19"
    case Disease_Type.FLU:
      return "Influenza"
    case Disease_Type.RSV:
      return "RSV"
    case Disease_Type.RESP:
      return "Respiratory Illness"
    case Disease_Type.UNSPECIFIED:
    default:
      return "Unknown"
  }
}

export const diseaseIndicatorMapping = (indicator: Disease_Indicator) => {
  switch (indicator) {
    case Disease_Indicator.CASES:
      return "Reported Cases"
    case Disease_Indicator.HOSPITALIZATIONS:
      return "Hospitalizations"
    case Disease_Indicator.UNSPECIFIED:
    default:
      return "Unknown"
  }
}
