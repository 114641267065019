import type { SVGProps } from "react"

const SvgTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.293.833a.5.5 0 0 1 .39.813L5.39 7.012a.5.5 0 0 1-.78 0L.315 1.646a.5.5 0 0 1 .39-.813h8.587Z"
      fill="#C2C9D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.293.833a.5.5 0 0 1 .39.813L5.39 7.012a.5.5 0 0 1-.78 0L.315 1.646a.5.5 0 0 1 .39-.813h8.587Z"
      fill="#000"
      fillOpacity={0.25}
    />
  </svg>
)

export default SvgTriangle
