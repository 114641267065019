import styled from "@emotion/styled"
import { Typography } from "@mui/material"

/**
 * STYLES
 */

const DisclaimerContainer = styled("div")({
  marginTop: 10,
})

/**
 * COMPONENTS
 */

export const Disclaimer: React.FC = () => {
  return (
    <DisclaimerContainer>
      <Typography variant="h6">Confidential and Proprietary</Typography>
      <Typography variant="small1" fontStyle={"italic"}>
        PHC Global provides this material for informational purposes only. It
        does not constitute, and is not a substitute for, medical services or
        advice and should not be construed as such.
      </Typography>
    </DisclaimerContainer>
  )
}
