import { useQuery } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { ACCESS_API } from "../utils/constants"
import { useTransport } from "./useTransport"

export function useOrganization(name: string) {
  const transport = useTransport(ACCESS_API)
  return useQuery(
    AccessManagementServiceQuery.getOrganization,
    { name },
    {
      transport,
      enabled: !!name,
      staleTime: 1000 * 60 * 5,
    }
  )
}
