import { Navigate, useParams } from "react-router-dom"
import { LocationDetails } from "../components/LocationDetails/LocationDetails"
import { useGetAsset } from "../components/WatchedLocations/hooks/useAssetService"
import { ROUTES } from "../utils/constants"
import { getAssetName, getLocationId } from "../utils/helpers"

export const Asset: React.FC = () => {
  const { assetId } = useParams<{ assetId: string }>()
  const { data, isLoading } = useGetAsset(assetId)
  const { title, subtitle } = getAssetName(data?.asset)

  if (!assetId) {
    console.error("Invalid parameters", { assetId })
    return <Navigate to={ROUTES.NOT_FOUND} />
  }

  const locationId = getLocationId(data?.asset)

  if (isLoading || !locationId || !data?.asset) {
    return null
  }

  return (
    <LocationDetails
      locationId={locationId}
      title={title || ""}
      subtitle={subtitle}
    />
  )
}
