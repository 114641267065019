import { MenuItem, TextField } from "@mui/material"
import { UserStatusEnum } from "@phc-health/connect-query"
import type { ChangeEvent } from "react"
import { enumKeys } from "../../utils/helpers"
import { getUserStatusNameFromStatus } from "../../utils/helpers/identityHelpers"

/**
 * INTERFACES
 */

interface UserStatusEnumDropdownProps {
  accountStatus?: UserStatusEnum
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

/**
 * COMPONENTS
 */

export const UserStatusEnumDropdown: React.FC<UserStatusEnumDropdownProps> = ({
  accountStatus,
  onChange,
}) => {
  return (
    <TextField
      value={accountStatus ? getUserStatusNameFromStatus(accountStatus) : ""}
      select
      label="Status"
      placeholder="Status"
      required
      onChange={onChange}
    >
      {enumKeys(UserStatusEnum)
        .filter(
          s => UserStatusEnum[s] !== UserStatusEnum.USER_STATUS_UNSPECIFIED
        )
        .map(s => {
          const statusValue = getUserStatusNameFromStatus(UserStatusEnum[s])
          return (
            <MenuItem key={s} value={statusValue}>
              {statusValue}
            </MenuItem>
          )
        })}
    </TextField>
  )
}
