import {
  createConnectQueryKey,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query"
import { AssetServiceQuery } from "@phc-health/connect-query"
import { useQueryClient } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { useTransport } from "../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../utils/constants"

export const useGetUserTermsOfService = () => {
  const transport = useTransport(ASSET_SERVICE_API)

  return useQuery(AssetServiceQuery.getPharosUserTOS, {}, { transport })
}

export const useUpdateUserTermsOfService = () => {
  const transport = useTransport(ASSET_SERVICE_API)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(AssetServiceQuery.updatePharosUserTOS, {
    transport,
    retry: 5,
    onError: err => {
      enqueueSnackbar("Failed to accept terms of service agreement.", {
        variant: "error",
      })
      console.error(err)
    },
    onSuccess: () => {
      enqueueSnackbar("Accepted terms of service agreement", {
        variant: "success",
      })

      void queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(AssetServiceQuery.getPharosUserTOS),
      })
    },
  })
}
