import { Link, List, ListItem, styled, Typography } from "@mui/material"
import type React from "react"
import "swagger-ui-react/swagger-ui.css"
import { AUTH0_CLIENT_ID, UPLOAD_CACHE_KEY } from "../../utils/env"
import { extraColors } from "../../utils/theme"
import { Container } from "../Settings/shared/SettingsContainer"

const CONNECTOR_FILE_NAME = "phc_org_login-1.0.2-py3-none-any.whl"
const JUPYTER_NOTEBOOK = `https://assets.thepublichealthco.com/${UPLOAD_CACHE_KEY}/assets/Using_Pharos_layers_api.ipynb`

const CodeText = styled(Typography)({
  color: extraColors.medium,
  background: extraColors.light,
  width: "fit-content",
  padding: "4px 8px",
  borderRadius: 4,
  fontFamily: "monospace",
})

const SectionHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: extraColors.purpleDark,
  marginTop: 20,
}))

const JSONWrapper = styled("pre")({
  overflowX: "scroll",
})

export const QuickstartDocumentaion: React.FC = () => {
  return (
    <Container
      title={"Quickstart"}
      sx={{
        display: "flex",
        maxWidth: "800px",
        marginBottom: "20px",
        padding: "36px",
      }}
    >
      <QuickstartIntroduction />
      <ConnectorInstallation />
      <RunConnector />
      <ExampleRequest />
      <JupyterNotebook />
    </Container>
  )
}

const QuickstartIntroduction: React.FC = () => {
  return (
    <>
      <SectionHeader sx={{ marginTop: 0 }}>Introduction</SectionHeader>
      <Typography>
        The Layers API is your gateway to PHC's location-based threat analysis
        data.
      </Typography>
      <Typography>
        To start making requests to it, click this link and download our custom
        Python connector (in .whl format) which can retrieve your access token:
      </Typography>
      <Link
        color={extraColors.purpleMiddle}
        href={`https://storage.googleapis.com/phc-prd-assets/${CONNECTOR_FILE_NAME}`}
      >
        <Typography
          variant="body1Bold"
          sx={{ color: extraColors.purpleMiddle }}
        >
          Python connector
        </Typography>
      </Link>
    </>
  )
}

const ConnectorInstallation: React.FC = () => {
  return (
    <>
      <SectionHeader>Installing the Connector</SectionHeader>
      <Typography>
        To install the connector, open up a terminal and navigate to the
        location where you downloaded it.
      </Typography>
      <Typography>
        Next, you can create a python virtual environment to install it. This
        will prevent future dependency issues with other Python projects:
      </Typography>
      <CodeText>python -m venv phc</CodeText>
      <Typography>To activate that virtual environment, run:</Typography>
      <CodeText>source phc/bin/activate</CodeText>
      <Typography>
        Now that the virtual environment has been activated, you can run this
        command to install the connector into it:
      </Typography>
      <CodeText>pip install {CONNECTOR_FILE_NAME}</CodeText>
    </>
  )
}

const RunConnector: React.FC = () => {
  return (
    <>
      <SectionHeader>Running the Connector</SectionHeader>
      <Typography>
        To confirm that the connector was installed properly and see its usage
        and options, you can run:
      </Typography>
      <CodeText>phc-org-login -h .</CodeText>
      <Typography>
        Now that the connector is installed and working, let's use it to
        retrieve your access token.
      </Typography>
      <Typography>
        You'll need to provide 3 pieces of information to the connector to get
        your token:
      </Typography>
      <List sx={{ marginTop: "-20px", marginLeft: "12px" }}>
        <ListItem>
          - Client ID:
          <Typography
            variant="body1Bold"
            sx={{ color: extraColors.purpleMiddle, marginLeft: "6px" }}
          >
            {AUTH0_CLIENT_ID}
          </Typography>
        </ListItem>
        <ListItem>- PHC login username (your email address)</ListItem>
        <ListItem>- PHC login password</ListItem>
      </List>
      <Typography>
        We're getting close! Replace the bracketed sections of this command to
        run the connector:
      </Typography>
      <CodeText>
        phc-org-login --client-id={AUTH0_CLIENT_ID} --password=[your password]{" "}
        --username=[your username]
      </CodeText>
      <Typography>
        The `access_token` is what you need to access the layers service in a
        REST client. If you're on MacOS, you can append this to your command to
        capture it in the clipboard:
      </Typography>
      <CodeText> | jq -r .access_token | pbcopy </CodeText>
      <Typography>
        For other operating systems, use your system's command to copy your
        access token to the clipboard.
      </Typography>
    </>
  )
}

const ExampleRequest: React.FC = () => {
  return (
    <>
      <SectionHeader>Using Your Access Token to Make a Request</SectionHeader>
      <Typography>
        Woohoo! You made it. Let's build and send a request to the Layers API
        using CURL.
      </Typography>
      <Typography>
        We're going to assign the larger sections of the request to their own
        variables first to improve readability.
      </Typography>
      <Typography>
        While you're still inside the virtual environment, paste in this request
        payload which is assigned to the variable called "request_data":
      </Typography>
      <CodeText variant="body2" sx={{ width: "auto" }}>
        <JSONWrapper>{`request_data='${JSON.stringify(requestJSON, null, 2)}'`}</JSONWrapper>
      </CodeText>
      <Typography>
        Now, assign the access token we generate via the connector to the
        variable called "access_token". Don't forget to replace the bracketed
        values again!
      </Typography>
      <CodeText>
        access_token=$(phc-org-login --client-id={AUTH0_CLIENT_ID}{" "}
        --password=[your password] --username=[your username] | jq -r
        .access_token)
      </CodeText>
      <Typography>
        Finally, let's put it all together into a curl request:
      </Typography>
      <CodeText>
        curl https://layers.prd.thepublichealthco.com/v1/layers -H "Accept:
        application/json" -H "Authorization: Bearer $access_token" --data-raw
        "$request_data"
      </CodeText>
      <Typography>
        That's it! You're all set to start building your own queries and
        retrieving data from the Layers API.
      </Typography>
    </>
  )
}

const JupyterNotebook: React.FC = () => {
  return (
    <>
      <SectionHeader sx={{ marginTop: 0 }}>Jupyter Notebook</SectionHeader>
      <Typography>
        For more examples of how to interact with the Layers API, please
        download this jupyter notebook:
      </Typography>
      <Link color={extraColors.purpleMiddle} href={JUPYTER_NOTEBOOK}>
        <Typography
          variant="body1Bold"
          sx={{ color: extraColors.purpleMiddle }}
        >
          Jupyter Notebook
        </Typography>
      </Link>
    </>
  )
}

const requestJSON = {
  layers: ["bucket"],
  search_criteria: {
    search_condition: [
      {
        nested_condition: {
          path: "base_event.geotags",
          query: {
            field_condition: [
              {
                field: "base_event.geotags.wkt_centroid",
                operation: "GEO_DISTANCE",
                value: {
                  geo_distance_value: {
                    point: {
                      lat: 32.96953573533567,
                      lon: -82.79589916846248,
                    },
                    distance: "10mi",
                    distance_type: 0,
                  },
                },
              },
            ],
          },
        },
      },
    ],
    sorting_criteria: [
      { field_sort: { order: "DESC", field_name: "confidence.score" } },
    ],
    pagination: { size: 50, from: 0 },
  },
  layer_filters: [
    {
      layer_name: "bucket",
      search_criteria: {
        search_condition: [
          {
            field_condition: [
              {
                field: "severity",
                operation: "EQUALS",
                value: { string_value: "SEVERITY_MODERATE" },
              },
            ],
          },
        ],
      },
    },
  ],
}
