import type { Asset } from "@phc-health/connect-query"
import type {
  AlertWithReferences,
  AnalysisWithReferences,
  CriticalThreatWithReferences,
  GuidanceWithReferences,
} from "@phc/common"
import { getLocationId } from "../../utils/helpers"

export function getCriticalThreatLocationIdSet(
  criticalThreat?: CriticalThreatWithReferences
) {
  const criticalThreatGeotagLocationIds = getGeotagLocationIds(criticalThreat)
  const relatedContentLocationIds =
    criticalThreat?.content?.flatMap(getGeotagLocationIds)

  const taggedLocationSet = new Set<string>(
    (relatedContentLocationIds || []).concat(criticalThreatGeotagLocationIds)
  )

  return taggedLocationSet
}

export function getCriticalThreatByContentId(
  contentId: string,
  criticalThreats?: CriticalThreatWithReferences[]
) {
  return criticalThreats?.find(threat =>
    threat.content?.find(contentItem => {
      return contentItem._id === contentId
    })
  )
}

// Look at critical threat geotags
// If there are any that are the same as the provided locationId, it's a match
// If there are any where the locationId starts with the geotag's locationId, it's a match
export function getFirstRelatedCriticalThreat(
  locationId: string,
  criticalThreats?: CriticalThreatWithReferences[]
) {
  return criticalThreats?.find(criticalThreat => {
    const critThreatLocationIds = getCriticalThreatLocationIdSet(criticalThreat)
    return Array.from(critThreatLocationIds).find(geotagLocId =>
      locationId.startsWith(geotagLocId)
    )
  })
}

export function getAssetsImpactedByCriticalThreat(
  assets?: Asset[],
  criticalThreat?: CriticalThreatWithReferences
) {
  const critThreatLocationIds = getCriticalThreatLocationIdSet(criticalThreat)

  return assets?.filter(asset =>
    Array.from(critThreatLocationIds).find(geotagLocId =>
      getLocationId(asset)?.startsWith(geotagLocId)
    )
  )
}

const getGeotagLocationIds = <
  Item extends
    | AnalysisWithReferences
    | GuidanceWithReferences
    | AlertWithReferences
    | CriticalThreatWithReferences,
>(
  item: Item | undefined
): string[] => {
  switch (item?._type) {
    case "alert":
    case "headlines":
      return item.geotags?.flatMap(geotag => geotag.locationId ?? []) ?? []
    case "guidance":
      return []
    case "criticalThreat":
      return item.geotags?.flatMap(geotag => geotag.locationId ?? []) ?? []
    case undefined:
    default:
      return []
  }
}
