import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material"
import usePersistentContext from "../hooks/usePersistentContext"
import { ReactComponent as Terms } from "../texts/terms.md"
import { Modal } from "./Shared/Modal"

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
})) as typeof Accordion

const Summary = styled(AccordionSummary)(() => ({
  borderRadius: 0,
  padding: 0,
  minHeight: 0,
})) as typeof AccordionSummary

const Details = styled(AccordionDetails)(() => ({
  padding: 0,
})) as typeof AccordionDetails

export const TermsModal: React.FC<{
  isFirstTimeUser: boolean
}> = ({ isFirstTimeUser }) => {
  const [acceptedTerms, setAcceptedTerms] = usePersistentContext([
    "accept-terms",
  ])

  const isOpen =
    (isFirstTimeUser && acceptedTerms === "false") ||
    (isFirstTimeUser && acceptedTerms == null)

  const handleConfirm = () => {
    void setAcceptedTerms("true")
  }

  return (
    <Modal
      open={isOpen}
      onConfirm={handleConfirm}
      title="Terms and Conditions"
      confirmLabel="Accept"
      disableBtns={false}
      hideCancelBtn={true}
    >
      <div>
        <Typography variant="body1" mt={2} pb={0} mb={0}>
          By clicking "Accept," I state that I have read and understood the
          Terms and Conditions.
        </Typography>
        <StyledAccordion>
          <Summary>
            <Typography variant="body2Bold" p={0} m={0}>
              View Terms and Conditions
            </Typography>
          </Summary>
          <Details>
            <Terms />
          </Details>
        </StyledAccordion>
      </div>
    </Modal>
  )
}
