import { MenuItem, styled } from "@mui/material"
import type { CSSProperties } from "react"
import { Link } from "react-router-dom"
import { extraColors } from "../../../../utils/theme"

export const IconStyles: CSSProperties = {
  fill: extraColors.navy,
  marginRight: "8px",
}

export const ActionMenuItem = styled(MenuItem)({
  padding: "6px 12px",
})

export const ActionMenuLink = styled(Link)({
  display: "flex",
  flexDirection: "row",
  textDecoration: "none",
})
