import type React from "react"
import type { SVGProps } from "react"

const MagnifyingGlass: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.875 6.4375C11.875 7.69531 11.4648 8.87109 10.7812 9.80078L13.7891 12.8359L14.418 13.4375L13.1875 14.6953L12.5586 14.0664L9.52344 11.0312C8.59375 11.7422 7.41797 12.125 6.1875 12.125C3.04297 12.125 0.5 9.58203 0.5 6.4375C0.5 3.32031 3.04297 0.75 6.1875 0.75C9.30469 0.75 11.875 3.32031 11.875 6.4375ZM6.1875 10.375C7.58203 10.375 8.86719 9.63672 9.57812 8.40625C10.2891 7.20312 10.2891 5.69922 9.57812 4.46875C8.86719 3.26562 7.58203 2.5 6.1875 2.5C4.76562 2.5 3.48047 3.26562 2.76953 4.46875C2.05859 5.69922 2.05859 7.20312 2.76953 8.40625C3.48047 9.63672 4.76562 10.375 6.1875 10.375Z"
      fill="rgba(129, 0, 153, 1)"
    />
  </svg>
)

export default MagnifyingGlass
