import type { SVGProps } from "react"
const Siren = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.88672 0.734375L3.19922 1.60938L3.74609 1.99219L3.00781 3.08594L2.46094 2.70312L1.14844 1.82812L0.601562 1.47266L1.33984 0.378906L1.88672 0.734375ZM16.3242 1.82812L15.0117 2.70312L14.4648 3.08594L13.7266 1.99219L14.2734 1.60938L15.5859 0.734375L16.1328 0.378906L16.8711 1.47266L16.3242 1.82812ZM0.65625 5H2.40625H3.0625V6.3125H2.40625H0.65625H0V5H0.65625ZM15.0938 5H16.8438H17.5V6.3125H16.8438H15.0938H14.4375V5H15.0938ZM3.9375 9.375L4.8125 2.375H12.6875L13.5625 9.375H6.39844L6.97266 4.61719L7.05469 4.125H6.15234L6.125 4.50781L5.49609 9.375H3.9375ZM2.625 10.25H14.875V12.875H2.625V10.25Z"
      fill="#810099"
    />
  </svg>
)
export default Siren
