import type { SVGProps } from "react"
const SvgNoData = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 257 200"
    {...props}
  >
    <path fill="#fff" d="M256.728 0h-256v200h256V0Z" />
    <path
      fill="#F1F4F3"
      d="M73.538 200c-30.88 0-55.83-25.36-55.18-56.38.61-28.87 23.78-52.63 52.62-53.94l.67-.03c16.53-.56 32.34-6.84 44.63-17.92 13.26-11.95 30.89-19.15 50.2-18.9 39.67.5 72.07 32.86 72.63 72.53.57 41.12-32.59 74.64-73.58 74.64H73.538Z"
    />
    <path
      fill="#fff"
      d="M88.907 167.88V25.66h100.98c3.14 0 5.69 2.55 5.69 5.69v130.84c0 3.14-2.55 5.69-5.69 5.69H88.907Z"
    />
    <g
      stroke="#80938D"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={0.5}
    >
      <path d="M96.018 32.77h92.45M96.018 43h92.45M96.018 54h92.45M96.018 65h92.45M96.018 76h92.45M96.018 87h92.45M96.018 98h92.45M96.018 109h92.45M96.018 120h92.45M96.018 131h92.45M96.018 142h92.45M96.018 153h92.45M96.018 164h92.45" />
    </g>
    <g
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      opacity={0.5}
    >
      <path d="M101.315 50h81.855M101.315 61h81.855M101.315 72h81.855M101.315 83h81.855M101.315 94h81.855M101.315 105h81.855M101.315 116h81.855M101.315 127h81.855M101.315 138h81.855" />
    </g>
    <path
      fill="#303030"
      d="M88.908 25.66v142.22h-15.64c-3.14 0-5.69-2.55-5.69-5.69V31.35c0-3.14 2.55-5.69 5.69-5.69h15.64Z"
    />
    <path
      fill="#fff"
      d="M78.238 157.22a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 143a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 128.77a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 114.55a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 100.33a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 86.11a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 71.89a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 57.66a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12ZM78.238 43.44a3.56 3.56 0 1 0 0-7.12 3.56 3.56 0 0 0 0 7.12Z"
    />
    <path
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M78.238 153.66h-16.35M78.238 139.44h-16.35M78.238 125.21h-16.35M78.238 110.99h-16.35M78.238 96.77h-16.35M78.238 82.55h-16.35M78.238 68.33h-16.35M78.238 54.1h-16.35M78.238 39.88h-16.35"
    />
    <circle cx={162.315} cy={123} r={36} fill="#fff" opacity={0.6} />
    <path
      stroke="#303030"
      strokeWidth={13}
      d="M188.457 150.191c-14.727 14.727-38.605 14.727-53.332 0-14.728-14.727-14.728-38.605 0-53.332 14.727-14.728 38.605-14.728 53.332 0 14.728 14.727 14.728 38.605 0 53.332Z"
    />
    <path
      stroke="#303030"
      strokeLinecap="round"
      strokeWidth={13}
      d="M130.528 154.787 96.315 189"
    />
  </svg>
)
export default SvgNoData
