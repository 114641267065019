import { Typography, styled } from "@mui/material"
import { extraColors } from "../../../utils/theme"

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  border: `1px solid ${extraColors.disabled}`,
  backgroundColor: extraColors.white,
  borderRadius: "6px",
  padding: 24,
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: 18,
    gap: theme.spacing(1),
  },
}))

export const SettingsContainer = ({
  children,
  title,
  className,
}: {
  children: React.ReactNode
  title?: string
  className?: string
}) => {
  return (
    <Container className={className}>
      {title && (
        <Typography variant="h3Bold" color={extraColors.medium}>
          {title}
        </Typography>
      )}
      {children}
    </Container>
  )
}
