import { styled, Typography } from "@mui/material"
import { Risk_Severity } from "@phc-health/connect-query"
import { Link, useLocation } from "react-router-dom"
import { ROUTES } from "../../../utils/constants"
import { enumKeys } from "../../../utils/helpers"
import { severityMapping } from "../../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../../utils/theme"
import { getIndicatorStyles } from "../../LocationDetails/LocationRisk/SeverityVisualIndicator"
import PopOver from "../../PopOver"
import { getMapBreakpoint } from "../Shared"
import { MapType } from "./ReactMapbox"

const MapKey = styled("div")<{ mapType: MapType }>(({ theme }) => ({
  backgroundColor: extraColors.light,
  pointerEvents: "none",
  alignSelf: "end",
  display: "grid",
  gridTemplateColumns: "auto auto auto auto auto 1fr",
  maxHeight: 26,
  borderRadius: 24,
  minWidth: 470,
  boxShadow: "0px 3px 7px 1px rgba(0, 0, 0, 0.2)",
  padding: "4px 12px",
  alignItems: "center",
  ".MuiIconButton-root": {
    padding: 0,
  },
  [getMapBreakpoint(theme)]: {
    gridTemplateColumns: "auto 1fr",
    maxHeight: "unset",
    minWidth: "unset",
    borderRadius: 6,
    width: "fit-content",
    padding: 8,
    alignItems: "baseline",
    margin: 16,
    ".key-container": {
      marginLeft: 0,
    },
  },
}))

const ColorBox = styled("div")(({ color }) => ({
  width: 12,
  height: 12,
  backgroundColor: color,
  marginRight: 6,
  borderRadius: 3,
  border: `0.5px solid ${extraColors.medium}`,
}))

const KeyContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: 20,
}))

const PopoverText = styled(Typography)(({ theme }) => ({
  ...theme.typography.small1,
  letterSpacing: "0.2px",
})) as typeof Typography

const LargeLegendContainer = styled("div")(({ theme }) => ({
  display: "grid",
  padding: "0 105px 16px 0px",
  // remove clickability
  pointerEvents: "none",
  [getMapBreakpoint(theme)]: {
    display: "none",
  },
}))

const MobileLegendContainer = styled("div")(({ theme }) => ({
  display: "none",
  [getMapBreakpoint(theme)]: {
    display: "contents",
  },
}))

const MobileLegendItemContainer = styled("div")({
  display: "inherit",
})

export const MapLegend: React.FC = () => {
  const location = useLocation()
  const mapType = location.pathname === ROUTES.HOME ? MapType.HOME : MapType.MAP

  return (
    <>
      <Legend mapType={mapType} />
      <MobileMapLegend mapType={mapType} />
    </>
  )
}

const Legend: React.FC<{ mapType: MapType }> = ({ mapType }) => {
  return (
    <LargeLegendContainer>
      <MapKey data-testid={"map-legend"} mapType={mapType}>
        <LegendPopover />
        <LegendItems />
      </MapKey>
    </LargeLegendContainer>
  )
}

const MobileMapLegend: React.FC<{ mapType: MapType }> = ({ mapType }) => {
  return (
    <MobileLegendContainer>
      <MapKey data-testid={"mobile-map-legend"} mapType={mapType}>
        <MobileLegendItemContainer>
          <LegendItems />
        </MobileLegendItemContainer>
        <LegendPopover />
      </MapKey>
    </MobileLegendContainer>
  )
}

const LegendPopover: React.FC = () => {
  return (
    <PopOver size="sm" vertical={-40} horizontal={"left"}>
      <PopoverText
        component={Link}
        to={ROUTES.SOURCES}
        sx={{ textDecoration: "underline" }}
      >
        Sources
      </PopoverText>
    </PopOver>
  )
}

const LegendItems: React.FC = () => {
  return (
    <>
      <LegendItemGroup />
      <KeyContainer className="key-container">
        <ColorBox color={extraColors.light} />
        <Typography variant="body2">No data</Typography>
      </KeyContainer>
    </>
  )
}

const LegendItemGroup: React.FC = () => {
  return (
    <>
      {enumKeys(Risk_Severity)
        .filter(k => Risk_Severity[k] !== Risk_Severity.UNSPECIFIED)
        .map(severity => (
          <LegendItem key={severity} severity={Risk_Severity[severity]} />
        ))}
    </>
  )
}

const LegendItem: React.FC<{ severity: Risk_Severity }> = ({ severity }) => {
  const threatColor = getIndicatorStyles(severity).background

  return (
    <KeyContainer className="key-container">
      <ColorBox color={threatColor} />
      <Typography variant="body2">{severityMapping(severity)}</Typography>
    </KeyContainer>
  )
}
