import type { PartialMessage } from "@bufbuild/protobuf"
import type { Role } from "@phc-health/connect-query"
import { User, UserStatusEnum } from "@phc-health/connect-query"
import { useCallback, useMemo, useState } from "react"
import validator from "validator"
import { useCreateUser } from "../../hooks/useCreateUser"
import { useSearchParams } from "../../hooks/useSearchParams"
import { AddUserForm } from "./AddUserForm"
import { UserManagementDialog } from "./UserManagementDialog"

const DEFAULT_ROLE = "roles/user"

/**
 * INTERFACES
 */

interface AddUserDialogProps {
  onClose: () => void
  roles?: Role[]
  isOpen: boolean
}

/**
 * COMPONENTS
 */

export const AddUserDialog: React.FC<AddUserDialogProps> = ({
  onClose,
  isOpen,
}) => {
  const { mutate } = useCreateUser()
  const { organizationId } = useSearchParams()
  const [newUser, setNewUser] = useState<PartialMessage<User>>({
    role: DEFAULT_ROLE,
  })

  const handleSave = useCallback(() => {
    mutate(
      {
        user: new User({
          displayName: newUser.displayName,
          role: newUser.role,
          status: UserStatusEnum.USER_ACTIVE,
          userId: newUser.userId,
        }),
        parent: organizationId,
      },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }, [mutate, newUser, organizationId, onClose])

  const canSave = useMemo(() => {
    return (
      newUser.displayName &&
      !validator.isEmpty(newUser.displayName) &&
      newUser.userId &&
      validator.isEmail(newUser.userId) &&
      newUser.role
    )
  }, [newUser])

  return (
    <UserManagementDialog
      isOpen={isOpen}
      onClose={onClose}
      title={"Add User"}
      handleSave={handleSave}
      canSave={!!canSave}
    >
      <AddUserForm newUser={newUser} setUpdatedUser={setNewUser} />
    </UserManagementDialog>
  )
}
