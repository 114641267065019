import type { AbsenteeismForecast } from "@phc-health/connect-query"
import type React from "react"
import { createContext, useContext, useState } from "react"

const WorkforcePlanningHoverContext = createContext<{
  hoverData?: AbsenteeismForecast
  setHoverData: React.Dispatch<
    React.SetStateAction<AbsenteeismForecast | undefined>
  >
} | null>(null)

export const useWorkforcePlanningHover = () => {
  const context = useContext(WorkforcePlanningHoverContext)
  if (!context) {
    throw new Error(
      "useWorkforcePlanningHover must be used within a WorkforcePlanningHoverProvider"
    )
  }
  return context
}

export const WorkforcePlanningHoverProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [hoverData, setHoverData] = useState<AbsenteeismForecast>()
  return (
    <WorkforcePlanningHoverContext.Provider value={{ hoverData, setHoverData }}>
      {children}
    </WorkforcePlanningHoverContext.Provider>
  )
}
