import { Box, Divider, styled } from "@mui/material"
import type React from "react"

import { useLayersByLocationId } from "../../../hooks/useLayersApi"
import { extraColors } from "../../../utils/theme"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { useGetAsset } from "../../WatchedLocations/hooks/useAssetService"
import { ContributingFactors } from "./ContributingFactors"
import { RelatedIntelligence } from "./RelatedIntelligence"
import { CardContainer, ColumnContainer } from "./Shared"
import { TitleHeader } from "./TitleHeader"
import { ViewButton } from "./ViewButton"

const Card = styled("div")({
  display: "grid",
  gridGap: 10,
  minWidth: 336,
  height: "100%",
})

const TitleRow = styled("div")({
  display: "flex",
  alignItems: "baseline",
  justifyContent: "space-between",
  gap: 7,
})

const ContentContainer = styled("div")<{ isMapCard: boolean }>(
  ({ isMapCard }) => ({
    display: "grid",
    border: isMapCard ? `1px solid ${extraColors.light}` : "none",
    borderRadius: 4,
    padding: "12px 12px 0px 12px",
    margin: isMapCard ? "0 12px 12px 12px" : 0,
  })
)

const StyledDivider = styled(Divider)({
  padding: "6px 0px 0px 0px",
})

export const LocationCard: React.FC<{
  id: string
  assetId: string | undefined
  isMapCard: boolean
  isMarker?: boolean
  title?: string
  subtitle?: string
}> = ({ id, assetId, isMapCard, title, subtitle }) => {
  const { data: asset } = useGetAsset(assetId)
  const { data } = useLayersByLocationId(id)
  const bucketData = data?.buckets[0]
  const { useRelatedAlerts, useRelatedAnalyses } = useCapServiceContext()
  const { data: alertData } = useRelatedAlerts(id)
  const { data: analysesData } = useRelatedAnalyses(id)

  const alertCount = alertData?.length
  const analysesCount = analysesData?.length
  const hasRelativeIntelligence = Boolean(alertCount || analysesCount)

  const selectedAsset = asset?.asset
  const assetTitle = selectedAsset?.name

  const assetSubtitle = selectedAsset?.baseEvent?.mapboxLocation?.placeName

  const trend = bucketData?.trendType
  const severity = bucketData?.severity

  return (
    <CardContainer isMapCard={isMapCard}>
      <ColumnContainer>
        <TitleRow>
          <TitleHeader
            id={id}
            title={assetTitle ?? title}
            subtitle={assetSubtitle ?? subtitle}
            subtitleShort={assetSubtitle}
          />
          <Box paddingRight="12px">
            <ViewButton
              id={id}
              title={assetTitle || title}
              assetId={selectedAsset?.assetId}
              subtitle={subtitle}
            />
          </Box>
        </TitleRow>
        <Card>
          <ContentContainer isMapCard={isMapCard}>
            <Box paddingBottom="12px">
              <ContributingFactors locationId={id} />
            </Box>
            {hasRelativeIntelligence && (
              <>
                {trend || severity ? <StyledDivider /> : undefined}
                <RelatedIntelligence
                  alertCount={alertCount}
                  analysesCount={analysesCount}
                  locationId={id}
                />
              </>
            )}
          </ContentContainer>
        </Card>
      </ColumnContainer>
    </CardContainer>
  )
}
