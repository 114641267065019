import { Navigate } from "react-router-dom"
import { LocationDetails } from "../components/LocationDetails/LocationDetails"
import { useSearchParams } from "../hooks/useSearchParams"
import { ROUTES } from "../utils/constants"
import { cleanSubtitle } from "../utils/helpers"

export const WatchedLocation: React.FC = () => {
  const { id, title, subtitle } = useSearchParams()

  if (!id) {
    console.error("Invalid parameters", { id })
    return <Navigate to={ROUTES.NOT_FOUND} />
  }

  return (
    <LocationDetails
      locationId={id}
      title={title ?? ""}
      subtitle={cleanSubtitle(title ?? "", subtitle ?? "")}
    />
  )
}
