import { KeyboardArrowDown } from "@mui/icons-material"
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp"
import { Typography, styled } from "@mui/material"
import type { OrganizationUsersTrials } from "@phc-health/connect-query"
import { useMemo, useState } from "react"
import { extraColors } from "../../../utils/theme"
import { useListAllTrials } from "../hooks/useManageAssetServiceUsers"
import { UserWithTrial } from "./AssetServiceUsers"

const PageContainer = styled("div")({
  maxWidth: 1000,
  width: "100%",
})

const TitleRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  alignItems: "center",
})

const KeyboardArrowDownStyled = styled(KeyboardArrowDown)({
  color: extraColors.status.teal,
  transform: "scale(1.05)",
  transition: "transform 200ms ease",
  "&:hover": {
    transform: "scale(1.2)",
  },
})

const KeyboardArrowUpStyled = styled(KeyboardArrowUp)({
  color: extraColors.status.teal,
  transform: "scale(1.05)",
  transition: "transform 200ms ease",
  "&:hover": {
    transform: "scale(1.2)",
  },
})

const UsersContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: 18,
  gap: 20,
})

const OrgContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "30px 40px",
  borderRadius: 6,
  border: `2px solid rgba(0, 140, 159, .3)`,
})

const OrgsContainer = styled("div")({
  display: "grid",
  gridAutoFlow: "row",
  gap: 20,
  marginTop: 20,
})

export const AssetServiceOrganizations: React.FC = () => {
  const { data: trialData } = useListAllTrials()

  return (
    <PageContainer>
      <Typography
        variant="h4"
        sx={{
          padding: "12px",
          borderRadius: "6px",
          color: extraColors.white,
          background: extraColors.status.tealDark,
        }}
      >
        Asset Service Organizations
      </Typography>
      <OrgsContainer>
        {trialData?.organizationsUsersTrials.map(org => (
          <Organization
            organization={org}
            key={org.organization?.organizationId}
          />
        ))}
      </OrgsContainer>
    </PageContainer>
  )
}

const Organization: React.FC<{ organization?: OrganizationUsersTrials }> = ({
  organization,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const sortedUsers = useMemo(() => {
    return organization?.usersTrials.sort((usr1, usr2) => {
      const usr1Email = usr1.user?.primaryEmailAddress || ""
      const usr2Email = usr2.user?.primaryEmailAddress || ""
      return usr1Email.localeCompare(usr2Email)
    })
  }, [organization?.usersTrials])

  const userCount = organization?.usersTrials.length
  const orgName = organization?.organization?.organizationName
  const prefix = "organizations/"
  const parsedOrgName = orgName?.substring(prefix.length)

  return (
    <OrgContainer>
      <TitleRow onClick={() => setIsCollapsed(prev => !prev)}>
        {isCollapsed ? <KeyboardArrowDownStyled /> : <KeyboardArrowUpStyled />}
        <Typography
          variant="h3"
          sx={{
            marginLeft: "4px",
            color: extraColors.status.tealDark,
          }}
        >
          {parsedOrgName}
        </Typography>
        <Typography
          variant="body1Bold"
          sx={{
            marginLeft: "12px",
            color: extraColors.status.tealDark,
            opacity: 0.6,
          }}
        >
          {`(${userCount?.toString() ?? "0"} user${userCount === 1 ? "" : "s"})`}
        </Typography>
      </TitleRow>
      {!isCollapsed && (
        <UsersContainer>
          {sortedUsers?.map(user => (
            <UserWithTrial userTrial={user} key={user.user?.userId} />
          ))}
        </UsersContainer>
      )}
    </OrgContainer>
  )
}
