import { styled, useTheme } from "@mui/material"
import {
  AreaPlot,
  BarPlot,
  ChartsAxisHighlight,
  ChartsAxisHighlightPath,
  ChartsClipPath,
  ChartsXAxis,
  ChartsYAxis,
  getAxisHighlightUtilityClass,
  LinePlot,
  ResponsiveChartContainer,
  useXScale,
  useYScale,
} from "@mui/x-charts"
import type { AbsenteeismForecast } from "@phc-health/connect-query"
import type { ScaleBand } from "d3-scale"
import type React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { extraColors } from "../../utils/theme"
import { ConfidenceIndicator } from "../Shared/ConfidenceIndicator"
import { useWorkforcePlanningHover } from "./WorkforcePlanningHoverContext"
import { WorkforcePlanningLegend } from "./WorkforcePlanningLegend"
import { WorkforcePlanningTooltip } from "./WorkforcePlanningTooltip"
import {
  BAR_COLOR,
  makeGraphDateString,
  sortPercentile,
} from "./workforcePlanningShared"

const GraphContainer = styled("div")({
  padding: 12,
  // for container queries in the legend
  containerType: "inline-size",
  "@media print": {
    pageBreakAfter: "always",
  },
})

export const WorkforcePlanningGraph: React.FC<{
  absenteeismData: AbsenteeismForecast[] | undefined
  /** In order to show significant changes, the scale needs to be zoomed in.
   *
   * The min will be:
   * - the lowest value of the industryBaseline and overallAbsenteeism
   * - minus 0.5 to give some space at the bottom
   * - but not less than 0
   */
  min: number | undefined
  max: number | undefined
}> = ({ absenteeismData, min = 0, max }) => {
  const theme = useTheme()
  const labelStyle = {
    fontFamily: theme.typography.small1.fontFamily,
    fontWeight: theme.typography.small1.fontWeight,
    fontSize: theme.typography.small1.fontSize,
    fill: extraColors.medium,
  }
  const tickLabelStyle = {
    fontFamily: theme.typography.small1Bold.fontFamily,
    fontWeight: theme.typography.small1Bold.fontWeight,
    fontSize: theme.typography.small1Bold.fontSize,
    fill: extraColors.medium,
  }
  const [graphWidth, setGraphWidth] = useState<number>()
  const graphRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!graphRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      setGraphWidth(graphRef.current?.clientWidth)
    })
    resizeObserver.observe(graphRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const industryBaseline = useMemo(
    () => absenteeismData?.map(d => d.industryBaseline),
    [absenteeismData]
  )
  const overallAbsenteeism = useMemo(
    () => absenteeismData?.map(d => d.overallAbsenteeism),
    [absenteeismData]
  )

  const percentile10 = useMemo(
    () => absenteeismData?.map(d => sortPercentile(d.percentiles)[0] ?? null),
    [absenteeismData]
  )

  const percentile90 = useMemo(
    () =>
      absenteeismData?.map(
        d =>
          (sortPercentile(d.percentiles)[1] ?? 0) -
          (sortPercentile(d.percentiles)[0] ?? 0)
      ),
    [absenteeismData]
  )

  if (!absenteeismData?.length) return null

  return (
    <GraphContainer ref={graphRef}>
      <ConfidenceIndicator
        confidenceInteger={absenteeismData[0]?.sourceConfidence}
      />
      <ResponsiveChartContainer
        margin={{
          top: 30,
        }}
        height={280}
        series={[
          {
            type: "bar",
            data: industryBaseline,
            id: "industryBaseline",
            color: BAR_COLOR,
          },
          {
            type: "line",
            area: true,
            data: percentile10,
            stack: "percentiles",
            id: "percentile10",
          },

          {
            type: "line",
            area: true,
            data: percentile90,
            stack: "percentiles",
            id: "percentile90",
          },
          {
            type: "line",
            data: overallAbsenteeism,
            color: extraColors.medium,
            id: "overallAbsenteeism",
          },
        ]}
        xAxis={[
          {
            data: absenteeismData.map(d =>
              Number(d.forecastStartedAt?.seconds)
            ),

            valueFormatter: (value: number) => makeGraphDateString(value),
            scaleType: "band",
            // @ts-expect-error Type generics don't work right. I hope they fix it.
            // https://next.mui.com/x/react-charts/bars/#bar-size
            // Also, can't figure out how to set a static width for the bars.
            categoryGapRatio: graphWidth < 500 ? 0.6 : 0.8,
            labelStyle,
            tickLabelStyle,
          },
        ]}
        yAxis={[
          {
            labelStyle,
            tickLabelStyle,
            fill: extraColors.medium,
            tickNumber: 4,
            min: min - 0.5,
            max: max ? max + 0.5 : undefined,
          },
        ]}
        sx={{
          [`& .MuiLineElement-series-percentile90, & .MuiLineElement-series-percentile10`]:
            {
              display: "none",
            },
          [`& .MuiAreaElement-series-percentile10`]: {
            display: "none",
          },
          [`& .MuiAreaElement-series-percentile90`]: {
            opacity: 0.3,
            fill: extraColors.dark,
          },
          [`& .MuiChartsAxis-line.MuiChartsAxis-line`]: {
            stroke: extraColors.light,
          },
          "& .MuiChartsAxisHighlight-root": {
            fill: extraColors.purpleSubtle,
            fillOpacity: 1,
          },
        }}
      >
        <HighlightParentListener />
        <ChartsAxisHighlight x="band" />
        {/* Clip bars when y axis starts at none zero */}
        <g clipPath={`url(#wp-clip-path)`}>
          <BarPlot />
        </g>
        {/* Skip animations on line and area. They are drawn stale */}
        <AreaPlot id="percentile10" skipAnimation />
        <AreaPlot id="percentile90" skipAnimation />
        <LinePlot id="overallAbsenteeism" skipAnimation />
        <ChartsXAxis disableTicks label="Week Starting Date" />
        <ChartsYAxis disableLine label="Absenteeism Rate (%)" />
        <ChartsClipPath id="wp-clip-path" />
        <WorkforcePlanningTooltip data={absenteeismData} />
      </ResponsiveChartContainer>
      <WorkforcePlanningLegend />
    </GraphContainer>
  )
}

/** Component patterned after Mui's ChartsAxisHighlight
 *
 * https://github.com/mui/mui-x/blob/next/packages/x-charts/src/ChartsAxisHighlight/ChartsAxisHighlight.tsx
 */
const HighlightParentListener = () => {
  const xScale = useXScale() as ScaleBand<string>
  const yScale = useYScale()

  const { hoverData } = useWorkforcePlanningHover()

  const classes = getAxisHighlightUtilityClass("root")
  if (!hoverData) return null
  const xValue = Number(hoverData.forecastStartedAt?.seconds)
  // @ts-expect-error Apparently xScale can't take a number
  const xScaleValue = xScale(xValue) ?? 0
  return (
    <ChartsAxisHighlightPath
      d={`M ${xScaleValue - (xScale.step() - xScale.bandwidth()) / 2} ${
        yScale.range()[0] ?? "0"
      } l ${xScale.step()} 0 l 0 ${
        (yScale.range()[1] ?? 0) - (yScale.range()[0] ?? 0)
      } l -${xScale.step()} 0 Z`}
      className={classes}
      ownerState={{ axisHighlight: "band" }}
    />
  )
}

export const mockAbsenteeismData = [
  {
    baseEvent: {
      geotags: [
        {
          locationId: "84029",
          displayName: "Missouri",
        },
      ],
    },
    forecastStartedAt: "2023-11-06T00:00:00Z",
    forecastEndedAt: "2023-11-12T00:00:00Z",
    industryType: "INDUSTRY_TYPE_RETAIL",
    overallAbsenteeism: 3.1837541806,
    industryBaseline: 3.2183037686,
    forecastConfidence: 5.0,
    sourceConfidence: 5.0,
    percentiles: [
      {
        value: 3.1653957655,
        percentile: 10.0,
      },
      {
        value: 3.2056482546,
        percentile: 90.0,
      },
    ],
  },
  {
    baseEvent: {
      geotags: [
        {
          locationId: "84029",
          displayName: "Missouri",
        },
      ],
    },
    forecastStartedAt: "2023-11-13T00:00:00Z",
    forecastEndedAt: "2023-11-19T00:00:00Z",
    industryType: "INDUSTRY_TYPE_RETAIL",
    overallAbsenteeism: 3.50439167,
    industryBaseline: 3.2183037686,
    forecastConfidence: 5.0,
    sourceConfidence: 5.0,
    percentiles: [
      {
        value: 3.1585767458,
        percentile: 10.0,
      },
      {
        value: 3.2162400583,
        percentile: 90.0,
      },
    ],
  },
  {
    baseEvent: {
      geotags: [
        {
          locationId: "84029",
          displayName: "Missouri",
        },
      ],
    },
    forecastStartedAt: "2023-11-20T00:00:00Z",
    forecastEndedAt: "2023-11-26T00:00:00Z",
    industryType: "INDUSTRY_TYPE_RETAIL",
    overallAbsenteeism: 3.1837435268,
    industryBaseline: 3.2183037686,
    forecastConfidence: 4.0,
    sourceConfidence: 5.0,
    percentiles: [
      {
        value: 3.1511588243,
        percentile: 10.0,
      },
      {
        value: 3.2262509962,
        percentile: 90.0,
      },
    ],
  },
  {
    baseEvent: {
      geotags: [
        {
          locationId: "84029",
          displayName: "Missouri",
        },
      ],
    },
    forecastStartedAt: "2023-11-27T00:00:00Z",
    forecastEndedAt: "2023-12-03T00:00:00Z",
    industryType: "INDUSTRY_TYPE_RETAIL",
    overallAbsenteeism: 3.1046299575,
    industryBaseline: 3.1644932518,
    forecastConfidence: 4.0,
    sourceConfidence: 5.0,
    percentiles: [
      {
        value: 3.0664603362,
        percentile: 10.0,
      },
      {
        value: 3.155020951,
        percentile: 90.0,
      },
    ],
  },
  {
    baseEvent: {
      geotags: [
        {
          locationId: "84029",
          displayName: "Missouri",
        },
      ],
    },
    forecastStartedAt: "2023-12-04T00:00:00Z",
    forecastEndedAt: "2023-12-10T00:00:00Z",
    industryType: "INDUSTRY_TYPE_RETAIL",
    overallAbsenteeism: 3.0987315295,
    industryBaseline: 3.1644932518,
    forecastConfidence: 4.0,
    sourceConfidence: 5.0,
    percentiles: [
      {
        value: 3.0538253162,
        percentile: 10.0,
      },
      {
        value: 3.1599829736,
        percentile: 90.0,
      },
    ],
  },
  {
    baseEvent: {
      geotags: [
        {
          locationId: "84029",
          displayName: "Missouri",
        },
      ],
    },
    forecastStartedAt: "2023-12-11T00:00:00Z",
    forecastEndedAt: "2023-12-17T00:00:00Z",
    industryType: "INDUSTRY_TYPE_RETAIL",
    overallAbsenteeism: 3.0984269431,
    industryBaseline: 3.1644932518,
    forecastConfidence: 3.0,
    sourceConfidence: 5.0,
    percentiles: [
      {
        value: 3.0406735685,
        percentile: 10.0,
      },
      {
        value: 3.1768024843,
        percentile: 90.0,
      },
    ],
  },
]
