import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableRowClasses,
} from "@mui/material"
import type { AbsenteeismForecast } from "@phc-health/connect-query"
import { extraColors } from "../../utils/theme"
import PopOver from "../PopOver"
import { ConfidenceIndicator } from "../Shared/ConfidenceIndicator"
import { useWorkforcePlanningHover } from "./WorkforcePlanningHoverContext"
import { WorkforcePlanningPill } from "./WorkforcePlanningPill"
import {
  makeGraphDateString,
  present2Decimal,
  sortPercentile,
  trunc,
} from "./workforcePlanningShared"

const HeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.small1Bold,
  padding: "12px 6px",
  lineHeight: "1rem",
  textAlign: "center",
  borderColor: extraColors.light,
}))

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  "&:last-child td": {
    border: 0,
  },
  [`&.${tableRowClasses.hover}:hover`]: {
    backgroundColor: extraColors.purpleSubtle,
  },
}))

const StyledHeaderCell = styled(HeaderCell)(() => ({
  minWidth: 150,
  "@media print": {
    minWidth: "unset",
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body2,
  padding: "9px 6px",
  textAlign: "center",
  borderColor: extraColors.light,
}))

const LegendContainer = styled("div")({
  borderColor: extraColors.disabled,
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: "solid",
  padding: "12px 24px",
  display: "flex",
  flexWrap: "wrap",
  gap: 12,
  width: "100%",
  marginTop: 20,
})

export const WorkforcePlanningTable: React.FC<{
  absenteeismData: AbsenteeismForecast[] | undefined
}> = ({ absenteeismData }) => {
  const { setHoverData } = useWorkforcePlanningHover()
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeaderCell style={{ textAlign: "left" }}>
                Week Starting Date
              </StyledHeaderCell>
              <StyledHeaderCell>Historical Absenteeism</StyledHeaderCell>
              <StyledHeaderCell style={{ minWidth: 200 }}>
                <ForecastedAbsenteeismPopover /> Forecasted Absenteeism
              </StyledHeaderCell>
              <StyledHeaderCell>Forecast Range</StyledHeaderCell>
              <StyledHeaderCell>
                <ForecastConfidencePopover /> Forecast Confidence
              </StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {absenteeismData?.map((data, index) => {
              const [forecastLow, forecastHigh] = sortPercentile(
                data.percentiles
              )
              const baseline = trunc(data.industryBaseline)
              const overall = trunc(data.overallAbsenteeism)
              const forecastedDelta = overall - baseline
              return (
                <StyledTableRow
                  key={index}
                  hover
                  onMouseEnter={() => setHoverData(data)}
                  onMouseLeave={() => setHoverData(undefined)}
                >
                  <StyledTableCell style={{ textAlign: "left" }}>
                    {makeGraphDateString(data.forecastStartedAt?.seconds)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {baseline.toFixed(2)}%
                  </StyledTableCell>
                  <StyledTableCell>
                    <WorkforcePlanningPill
                      text={`${overall.toFixed(2)}%`}
                      isHigh={forecastedDelta >= 0.3}
                    />
                    ({forecastedDelta.toFixed(2)}%)
                  </StyledTableCell>
                  <StyledTableCell>
                    {present2Decimal(forecastLow)}% -{" "}
                    {present2Decimal(forecastHigh)}%
                  </StyledTableCell>
                  <StyledTableCell>
                    <ConfidenceIndicator
                      confidenceInteger={data.forecastConfidence}
                      hideLink
                    />
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LegendContainer>
        <Typography variant="body2Bold">Key: </Typography>
        <WorkforcePlanningPill isHigh text="High" />
        <Typography variant="body2">
          Absenteeism forecast expected to be above the historic industry
          average
        </Typography>
      </LegendContainer>
    </>
  )
}

const ForecastedAbsenteeismPopover = () => {
  return (
    <PopOver
      size="sm"
      vertical={-80}
      horizontal={"left"}
      style={{ padding: 0 }}
    >
      <Typography variant="small1">
        PHC’s absenteeism forecast for the week, and the difference compared to
        historical data from the previous year.
      </Typography>
    </PopOver>
  )
}

const ForecastConfidencePopover = () => {
  return (
    <PopOver
      size="sm"
      vertical={-80}
      horizontal={"left"}
      style={{ padding: 0 }}
    >
      <Typography variant="small1">
        Forecast confidence is shown by dots here and by the gray forecast range
        in the chart. The range widens with decreasing confidence.
      </Typography>
    </PopOver>
  )
}
