import {
  Delete,
  Edit,
  GroupsTwoTone,
  Language,
  Notifications,
} from "@mui/icons-material"
import { Typography, styled } from "@mui/material"
import { GridActionsCellItem, type GridColDef } from "@mui/x-data-grid-pro"
import { AssetType, IndustryType, type Asset } from "@phc-health/connect-query"
import type { CriticalThreatWithReferences } from "@phc/common"
import { Link, createSearchParams, generatePath } from "react-router-dom"
import { ROUTES, stringifySearchParams } from "../../utils/constants"
import { getLocationId } from "../../utils/helpers"
import {
  assetTypeEnumToString,
  industryTypeEnumToString,
} from "../../utils/helpers/assetHelper"
import { extraColors } from "../../utils/theme"
import { CriticalThreatTag } from "../CriticalThreats/CriticalThreatTag"
import { getFirstRelatedCriticalThreat } from "../CriticalThreats/criticalThreatHelper"
import { LOCATION_DETAILS_TABS } from "../LocationDetails/LocationDetailsTabs"

const SmallCell = styled(Typography)(({ theme }) => ({
  ...theme.typography.small1,
  color: extraColors.medium,
}))

export const locationColumnInfo = (
  setEditRow: (val?: Asset) => void,
  setAssetModalOpen: (val: boolean) => void,
  setRemoveAssetId: (val: string[]) => void,
  isLocationSelector: boolean,
  criticalThreats?: CriticalThreatWithReferences[]
) => {
  let selectorColumns: GridColDef<Asset>[] = [
    {
      field: "name" as const,
      headerName: "Location",
      minWidth: 100,
      flex: 1,
      valueGetter: (_, row) => {
        return row.name
      },
    },

    {
      field: "address" as const,
      headerName: "Address",
      minWidth: 150,
      flex: 1,
      display: "flex",
      valueGetter: (_, row) => {
        return row.baseEvent?.mapboxLocation?.placeName
      },
      renderCell: ({ value }: { value?: string }) => {
        return <SmallCell>{value}</SmallCell>
      },
    },
    {
      field: "group" as const,
      headerName: "Group",
      minWidth: 136,
      maxWidth: 220,
      display: "flex",
      flex: 1,
      valueGetter: (_, row) => {
        return row.assetGroups.map(g => g.name).join(", ")
      },
      renderCell: ({ value }) => {
        return <SmallCell>{value}</SmallCell>
      },
    },
    {
      field: "industry" as const,
      headerName: "Industry",
      display: "flex",
      valueGetter: (_, row) => {
        return row.industry !== IndustryType.UNSPECIFIED
          ? industryTypeEnumToString(row.industry)
          : ""
      },
      renderCell: ({ value }) => {
        return <SmallCell>{value}</SmallCell>
      },
    },
    {
      field: "assetType" as const,
      headerName: "Asset Type",
      display: "flex",
      valueGetter: (_, row) => {
        return row.assetTypes
          .filter(a => a !== AssetType.ASSET_TYPE_UNSPECIFIED)
          .map(a => assetTypeEnumToString(a))
          .join(", ")
      },
      renderCell: ({ value }) => {
        return <SmallCell>{value}</SmallCell>
      },
    },
  ]

  if (criticalThreats?.length) {
    selectorColumns = selectorColumns.concat([
      {
        field: "criticalThreat" as const,
        headerName: "Critical Threat",
        minWidth: 125,
        maxWidth: 125,
        display: "flex",
        align: "center",
        valueGetter: (_, row) => {
          const hasCriticalThreat = getFirstRelatedCriticalThreat(
            getLocationId(row) || "",
            criticalThreats
          )

          // value must be string for filtering
          return hasCriticalThreat ? "true" : "false"
        },
        renderCell: ({ row }) => {
          const relatedCriticalThreat = getFirstRelatedCriticalThreat(
            getLocationId(row) || "",
            criticalThreats
          )

          return relatedCriticalThreat ? (
            <CriticalThreatTag relatedCriticalThreat={relatedCriticalThreat} />
          ) : null
        },
      },
    ])
  }

  if (isLocationSelector) return selectorColumns

  return selectorColumns.concat([
    // TODO: Add back once we want to display threat radius again
    // {
    //   field: "threatRadius" as const,
    //   headerName: "Threat Radius",
    //   flex: 1,
    //   renderCell: ({ formattedValue }) => {
    //     return (
    //       <Typography
    //         variant="small1"
    //         sx={{
    //           border: "1px solid",
    //           borderColor: extraColors.light,
    //           borderRadius: 2,
    //           padding: "4px",
    //         }}
    //       >
    //         {formattedValue}
    //       </Typography>
    //     )
    //   },
    //   valueFormatter: ({ value }) => {
    //     return value + " miles"
    //   },
    // } satisfies GridColDef<Asset, string>,
    {
      field: "actions" as const,
      type: "actions",
      width: 50,
      getActions: ({ row }) => [
        <GridActionsCellItem
          key="edit"
          label="Edit"
          icon={<Edit htmlColor={extraColors.navy} />}
          showInMenu
          onClick={() => {
            setEditRow(row)
            setAssetModalOpen(true)
          }}
        />,
        <GridActionsCellItem
          key="notification-preferences"
          label="Notification Preferences"
          icon={<Notifications htmlColor={extraColors.navy} />}
          showInMenu
          component={Link}
          // @ts-expect-error this `component` prop doesn't pass all inherited component props down
          to={{
            pathname: generatePath(ROUTES.ASSET, {
              assetId: row.assetId,
            }),
            search: createSearchParams({
              tab: LOCATION_DETAILS_TABS[2].path,
            }).toString(),
          }}
        />,
        <GridActionsCellItem
          key="view-absenteeism"
          label="Workforce Planning Forecast"
          icon={<GroupsTwoTone htmlColor={extraColors.navy} />}
          showInMenu
          component={Link}
          // @ts-expect-error this `component` prop doesn't pass all inherited component props down
          to={{
            pathname: generatePath(ROUTES.ASSET, {
              assetId: row.assetId,
            }),
            search: createSearchParams({
              tab: LOCATION_DETAILS_TABS[1].path,
            }).toString(),
          }}
        />,
        <GridActionsCellItem
          key="view-on-map"
          label="View on Threat Map"
          icon={<Language htmlColor={extraColors.navy} />}
          showInMenu
          component={Link}
          // @ts-expect-error this `component` prop doesn't pass all inherited component props down
          to={{
            pathname: ROUTES.MAP,
            search: stringifySearchParams({
              id: getLocationId(row),
              assetId: row.assetId,
              fitBounds: true,
            }),
          }}
        />,
        <GridActionsCellItem
          key="delete-location"
          label="Delete Location"
          icon={<Delete htmlColor={extraColors.navy} />}
          showInMenu
          onClick={() => setRemoveAssetId([row.assetId])}
        />,
      ],
    },
  ])
}
