import { getTrendIconSize, type IconSize } from "../IconSize"

const Persistant: React.FC<{
  fill?: string
  size?: IconSize
}> = ({ size, fill }) => {
  const sizeProps = getTrendIconSize(size)
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...sizeProps}>
      <path
        d="M13.168 5.75L7.91797 0.5H7.04297V4H0.917969V7.5H7.04297V11H7.91797L13.168 5.75Z"
        fill={fill || "#78909C"}
      />
    </svg>
  )
}

export default Persistant
