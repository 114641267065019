import { styled } from "@mui/material"
import { AlertLevel, isAlertLevel } from "@phc/common"
import type React from "react"
import { extraColors } from "../../utils/theme"
import { Pill, type PillColors, type PillProps } from "./Pill"

const PillContainer = styled("div")({
  ".MuiBox-root": {
    padding: "0 4px",
    height: "fit-content",
    justifyContent: "center",
  },
})

export const alertColors: Record<AlertLevel, PillColors> = {
  [AlertLevel.ADVISORY]: {
    background: extraColors.status.greenLight,
    border: extraColors.status.green,
    text: extraColors.status.greenDark,
  },
  [AlertLevel.WATCH]: {
    background: extraColors.status.orangeLight,
    border: extraColors.status.orangeMiddle,
    text: extraColors.status.orangeDark,
  },
  [AlertLevel.WARNING]: {
    background: extraColors.status.redLight,
    border: extraColors.status.redMiddle,
    text: extraColors.status.redDark,
  },
}

export const getAlertColor = (alertLevel: AlertLevel | undefined) => {
  if (isAlertLevel(alertLevel)) {
    return alertColors[alertLevel]
  }
}

export const getAlertVariant = (
  alertLevel: AlertLevel | undefined
): PillProps["variant"] => {
  if (isAlertLevel(alertLevel)) {
    switch (alertLevel) {
      case AlertLevel.ADVISORY:
        return "green"
      case AlertLevel.WATCH:
        return "orange"
      case AlertLevel.WARNING:
        return "red"
    }
  }
}

export const AlertPill: React.FC<{
  alertLevel: AlertLevel | undefined
  className?: string
}> = ({ alertLevel, className }) => {
  if (!alertLevel) return null
  return (
    <PillContainer className={className}>
      <Pill value={alertLevel} variant={getAlertVariant(alertLevel)} />
    </PillContainer>
  )
}
