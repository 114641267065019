import { AssetNotificationType } from "@phc-health/connect-query"
import type React from "react"
import { createContext, useContext, useMemo } from "react"

const NotificationTypeContext = createContext<{
  notificationType: AssetNotificationType
}>({ notificationType: AssetNotificationType.UNSPECIFIED })

// This context is used to wrap the individual settings sections so we know which
// setters and getters to provide from the global notifications context in downstream
// components which are implemented to be agnostic of notification types.
export const NotificationTypeContextProvider: React.FC<{
  notificationType: AssetNotificationType
  children?: React.ReactNode
}> = ({ notificationType, children }) => {
  const context = useMemo(() => {
    return {
      notificationType,
    }
  }, [notificationType])
  return (
    <NotificationTypeContext.Provider value={context}>
      {children}
    </NotificationTypeContext.Provider>
  )
}

export function useNotificationTypeContext() {
  return useContext(NotificationTypeContext)
}
